import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-steuerhinweis-popup',
  templateUrl: './steuerhinweis-popup.component.html',
  styleUrls: ['./steuerhinweis-popup.component.css']
})
export class SteuerhinweisPopupComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  vatSettings: any;
   colors: any;
  infotext:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    private langService:LanguageService,
    public dialogRef: MatDialogRef<SteuerhinweisPopupComponent>) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
   }

  ngOnInit() {
    // this.vatSettings = this.data.vatSettings;
  }

  onclick() {
    this.dialogRef.close();
  }

}
