import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaced'
})

export class aReplacePipe implements PipeTransform {
    transform(value: any , lang:any): any {
        // console.log(value , lang ,4485)
        value = String(value).toString();
        let value1 = value.replace(/[,.]/g, '');
        let newValue = value1.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        if(!lang){
         lang = 'GEN'
        }
        if(lang == 'GEN' ){
            return `${newValue}`;
        } else {
            newValue = newValue.replace('.' , ',');
            return `${newValue}`;
        }
    }
} 