import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from 'app/Services/admin.service';
import { LanguageService } from '../Services/language.service';
// import { NewsletterCancellationComponent } from 'app/newsletter-cancellation/newsletter-cancellation.component';
import {  MatDialog,  } from '@angular/material/dialog';
import { CommonService } from 'app/common.service';
@Component({
  selector: 'app-email-marketing',
  templateUrl: './email-marketing.component.html',
  styleUrls: ['./email-marketing.component.css']
})
export class EmailMarketingComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  @Input() colors;
  @Output() cancelNewsletter = new EventEmitter<boolean>();
  minMaxdata: any;
  

  constructor(private langService: LanguageService,
    private commonService: CommonService,
    private admin: AdminService,public dialog: MatDialog,) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit(): void {

  }
  
  deactivateNewsletter(){
    let kundenKontoId = this.commonService.getCustomerId();
    this.admin.newsLetter({status:false,kontoId:kundenKontoId}).subscribe((data:any)=>{
      this.cancelNewsletter.emit(false)
    })
  }

}
