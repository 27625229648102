// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: window["env"]["production"] || false,
  testSite_or_thirdPartySites: window["env"]["testSite_or_thirdPartySites"] || false,     // for thirdPartySites use "true" else make it "false"
  baseUrl: window["env"]["baseUrl"],
  pricecalc: window["env"]["pricecalc"],
  co2Server: window["env"]["co2Server"],
  socketUrl: window["env"]["socketUrl"],
  assetsUrl: window["env"]["assetsUrl"]
};  


  // production: false,
  // testSite_or_thirdPartySites: false,     // for thirdPartySites use "true" else make it "false"
  // baseUrl: "http://localhost:3900/price_calculator",
  // pricecalc: 'http://localhost:4900/',
  // co2Server: 'http://localhost:9000',
  // socketUrl: "http://localhost:3000",
  // assetsUrl: ""



  // production: true,
  // testSite_or_thirdPartySites: false,     // for thirdPartySites use "true" else make it "false"
  // baseUrl: "https://api.aupris.com/price_calculator",
  // pricecalc: "http://test.aupris.com/",
  // co2Server: 'http://localhost:9000',
  // socketUrl: "https://api.aupris.com",
  // assetsUrl: ""



  //   production:true,
  //   testSite_or_thirdPartySites: false,     // for thirdPartySites use "true" else make it "false"
  //   baseUrl:"https://api-backup.aupris.com/price_calculator",
  //   pricecalc:"http://test2.aupris.com/",
  //   co2Server:'http://localhost:9000',
  //   socketUrl:"https://backup.aupris.com",
  //   assetsUrl:""