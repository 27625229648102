<div class="aupris_main scrolltotopprogress">
  <div style="width:100%; float:right">

    <app-progress-bar *ngIf="showBigPC && !showSmallPc" [languageData]="languageData" [theme]="theme"
      [clickedoption]="clickedoption" [showFullScreenLoading]="showFullScreenLoading" [clickedPage]="clickedPage"
      (selectedPage)="selectedPage($event)">
    </app-progress-bar>

    <!-- [co2CompensateValue]="co2CompensateValue" -->
    <app-one
      *ngIf="!showSmallPc && showBigPC && sessionData && clickedPage == 'page1' && products.length && checkProcutInfo()"
      [products]="products" [clickedPage]="clickedPage" [languageData]="languageData"
      [_priceCalculationData]="priceCalculationData" [productInfoForWebPC]="productInfoForWebPC"
      [calculatedQualities]="calculatedQualities" [PCOptions]="PCOptions" [page]="page"
      [enableOrderBtn]="enableOrderBtn" [loader]="loader" [_voucher]="voucher" [dataService]="dataService"
      [deliveryDate]="deliveryDates" [pcErrorHandling]="pcErrorHandling" [alarmIndex]="alarmIndex"
      (updateOptionsEvent)="updateOptionsEvent($event)" [_localSessionUpdatedData]="localSessionUpdatedData"
      (BestellenEvent)="bestellenEvent($event)" [notSelectedOption]="notSelectedOption"
      (priceDetailsPopupEvent)="priceDetailsEvent($event)" (showPreisweckerEvent)="showPreisweckerEvent()"
      [inLimitHouseHold]="inLimitHouseHold">
    </app-one>

    <app-small-pc *ngIf="showSmallPc && products.length" [products]="products" [primaryAdd]="primaryAddressData">
    </app-small-pc>

    <!-- (onBankDetailsEvent) = "fillBankAccountDetails($event)" -->
    <app-stagetwo *ngIf="showBigPC && clickedPage == 'page2' && checkProcutInfo()" [clickedPage]="clickedPage"
      [loader]="loader" (changeAddress)="changeAddressHandler($event)" [stationFormGroup]="stationFormGroup"
      (addAdressStagetwo)="addAdressStagetwoHandler($event)" [productInfoForWebPC]="productInfoForWebPC"
      [languageData]="languageData" [isLoggedIn]="isLoggedIn" [dobText]="dobText"
      [dobRechnungsadresse]="dobRechnungsadresse" [stationCount]="stationCount" [addNewAddress]="addNewAddress"
      [page]="page" [RemainingMenge]="RemainingMenge" [mengecheck]="mengecheck" [selectedQuantity]="selectedQuantity"
      [mandatoryCheck]="mandatoryCheck" [postalCodeDetails]="postalCodeDetails" [bankDetailsForm]="bankDetailsForm"
      [bankRealtedData]="bankRealtedData" [forIbanReadOnly]="forIbanReadOnly" [forAccountReadOnly]="forAccountReadOnly"
      [billingAddressEnable]="billingAddressEnable" (rechnungCheckBoxEvent)="rechnungCheckBoxEvent($event)"
      (rechnungtoggleEvent)="rechnungtoggleEvent($event)" (page1Event)="page1Event($event)"
      (weiterEvent)="weiterEvent()" (onBlurEvent)="quantityCheckEvent($event)"
      [_localSessionUpdatedData]="localSessionUpdatedData" (geburtstagPopupEvent)="geburtstagPopupEvent()"
      [primaryAddresses]="primaryAddresses" [secondaryAddresses]="secondaryAddresses"
      [billingAddresses]="billingAddresses" (addNewAddressCancel)="addNewAddressCancel($event)"
      [addressType]="addressType" (setDefaultAddressesEvent)="setDefaultAddressesHandler($event)" [loader]="loader">
    </app-stagetwo>

    <!-- (resendMtanEvent)="resendMtanEvent($event)" -->
    <!-- [mtanInput]="mtanInput" -->
    <!-- [ausgewaehlterRatenplan]="ausgewaehlterRatenplan" -->

    <app-three *ngIf="showBigPC && clickedPage == 'page3' && checkProcutInfo()" [clickedPage]="clickedPage"
      [productInfoForWebPC]="productInfoForWebPC" [languageData]="languageData"
      [_priceCalculationData]="priceCalculationData" [thridStagePdfs]="thridStagePdfs"
      (downloadFileEvent)="downloadFileEvent($event)" [agbAccepted]="agbAccepted" [page]="page"
      [stationFormGroup]="stationFormGroup" (updateOptionsEvent)="updateOptionsEvent($event)"
      [_localSessionUpdatedData]="localSessionUpdatedData" [stationCount]="stationCount"
      [selectedBiilingOption]="selectedBiilingOption" [billingAddressEnable]="billingAddressEnable"
      [bankdetails]="bankdetails" (ZahlungMailEvent)="ZahlungMailEvent()" (page1Event)="page1Event($event)"
      (page2Event)="page2Event($event)" (priceChangeAcceptEvent)="priceChangeAcceptEvent($event)"
      (productTaxAcceptEvent)=productTaxAcceptEvent($event) [loader]="loader" [voucher]="voucher"
      [third_stage_pricechange]="third_stage_pricechange">
    </app-three>

    <app-stagefour *ngIf="showBigPC && clickedPage == 'page4'" [clickedPage]="clickedPage" [options]="options"
      (pageEmit)="pageEmitHandler()" [blockeduser]="blockedUser" [localoptions]="localoptions"
      [languageData]="languageData" [smallpclink]="smallpclink" [page]="page" [isGuestUser]="isGuestUser"
      [isLoggedIn]="isLoggedIn" [loader]="loader">
    </app-stagefour>
  </div>

  <div [ngClass]="{snackbar:true,show:viewsnack4}" style="left: 49%;">
    {{languageData['Bestatigung']?.VarWebPcBestatigung2 || 'Bitte vervollständigen Sie Ihre Lieferadresse!'}}
    <button mat-button class="close-icon" (click)="viewsnack4 = false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div [ngClass]="{snackbar:true,show:billingErr}" style="left: 49%;">
    {{languageData['Bestatigung']?.VarWebPcBestatigung1 || 'Bitte geben Sie eine Rechnungsadresse an!'}}
    <button mat-button class="close-icon" (click)="viewsnack4 = false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- <div [ngClass]="{snackbar:true,show:viewsnack5}">
    {{languageData['Bestatigung'].Var_Pflichtfelder || 'Alle Pflichtfelder müssen ausgefüllt werden!'}}
    <button mat-button class="close-icon" (click)="viewsnack5 = false;">
      <mat-icon>close</mat-icon>
    </button>
  </div> -->

  <div [ngClass]="{snackbar:true,show:viewsnack10}">
    {{languageData['Bestatigung']?.Var_wrongmTan || 'Ungültige mTAN!'}}
    <button mat-button class="close-icon" (click)="viewsnack10 = false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div [ngClass]="{snackbar:true,show:viewsnack}">
    {{languageData['Bestatigung']?.Var_Bitte_uberprufen_Sie_Ihren_Posteingang || 'Bitte überprüfen Sie Ihren
    Posteingang'}}
    <!-- Order Placed -->
    <button mat-button class="close-icon" (click)="viewsnack = false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div [ngClass]="{snackbar:true,show:viewsnack6}">
    {{
    languageData['Bestatigung']?.Var_Die_Preisanderung_muss_vor_Bestellung || 'Die Preisänderung muss vor
    Bestellungakzeptiert werden!'
    }}
    <button mat-button class="close-icon" (click)="viewsnack6 = false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div [ngClass]="{snackbar:true,show:contstrains}">
    <span>
      {{ error[currentErrorIndex]?.message }}
      <button mat-button class="close-icon" (click)="nextError()">
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>
</div>