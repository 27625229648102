import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }
  getData() {
    // return {
    //   "success": true,
    //   "code": 200,
    //   "msg": "fetched all dynamic options of a price calculator",
    //   "data": [
    //     {
    //       "_id": "63492eaf9b41821db051c0e7",
    //       "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //       "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //       "dynamicProductId": "61d421c387bf6a786895f827",
    //       "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //       "sortOrder": 7,
    //       "optionType": 7,
    //       "name": "Liefertermin",
    //       "dynamicOptions": [
    //         {
    //           "_id": "63492eaf9b41821db051c0e8",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //           "refCentralizedOptionId": "63206d948f36605064901f62",
    //           "refOptionSetId": "63492eaf9b41821db051c0e7",
    //           "status": true,
    //           "conversion": 0,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 1,
    //           "optionType": 7,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b15d",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f62",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //             "optionType": 7,
    //             "id": 1,
    //             "refName": "Liefertermin",
    //             "name": "bis 20 werktage",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 0,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 10,
    //             "additionalValueTo": 20,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 1,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 1,
    //             "optionValueToUnits": "63170dd11a16225f81b12b03",
    //             "helperId": "6319d7878f5d4723b0c36a1b",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b15d"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0ea",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //           "refCentralizedOptionId": "63206d948f36605064901f64",
    //           "refOptionSetId": "63492eaf9b41821db051c0e7",
    //           "status": true,
    //           "conversion": 0,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 2,
    //           "optionType": 7,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b15e",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f64",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //             "optionType": 7,
    //             "id": 3,
    //             "refName": "Liefertermin",
    //             "name": "we will deliver in 3 to 10 days",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 0,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 3,
    //             "additionalValueTo": 10,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 1,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 2,
    //             "optionValueToUnits": "63170dd11a16225f81b12b03",
    //             "helperId": "6319d7878f5d4723b0c36a1c",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b15e"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0ec",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //           "refCentralizedOptionId": "63206d948f36605064901f66",
    //           "refOptionSetId": "63492eaf9b41821db051c0e7",
    //           "status": true,
    //           "conversion": 2,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 3,
    //           "optionType": 7,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b15f",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f66",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //             "optionType": 7,
    //             "id": 5,
    //             "refName": "Liefertermin",
    //             "name": "48h Express",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 2,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": true,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "Samstage, Sonn- und Feiertage sind von der Berechnung des Liefertermins ausgeschlossen. Bestellungen an diesen Tagen, oder an Werktagen nach 17 Uhr gelten als abgegeben am nächsten Werktag.",
    //             "showSpecialText": false,
    //             "sortOrder": 3,
    //             "optionValueToUnits": "63170dd11a16225f81b12b03",
    //             "helperId": "6319d7878f5d4723b0c36a1d",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b15f"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0ef",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //           "refCentralizedOptionId": "63206d948f36605064901f69",
    //           "refOptionSetId": "63492eaf9b41821db051c0e7",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 4,
    //           "optionType": 7,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5c23228a177bb805e0de0433",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f69",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5e",
    //             "optionType": 7,
    //             "id": 8,
    //             "refName": "Liefertermin",
    //             "name": "Add extra delivery times",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 4,
    //             "optionValueToUnits": "63170dd11a16225f81b12b03",
    //             "helperId": "6319d7878f5d4723b0c36a1e",
    //             "tempGeneralSettingsOptionId": "5c23228a177bb805e0de0433"
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       "_id": "63492eaf9b41821db051c0f0",
    //       "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //       "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //       "dynamicProductId": "61d421c387bf6a786895f827",
    //       "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //       "sortOrder": 9,
    //       "optionType": 9,
    //       "name": "Lieferzeiten",
    //       "dynamicOptions": [
    //         {
    //           "_id": "63492eaf9b41821db051c0f3",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //           "refCentralizedOptionId": "63206d948f36605064901f6c",
    //           "refOptionSetId": "63492eaf9b41821db051c0f0",
    //           "status": true,
    //           "conversion": 0,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 1,
    //           "optionType": 9,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b163",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f6c",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //             "optionType": 9,
    //             "id": 3,
    //             "refName": "Lieferzeiten",
    //             "name": "Ganztags (7 bis 18 Uhr)",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 0,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 7,
    //             "additionalValueTo": 18,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 1,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 1,
    //             "optionValueToUnits": "63170dd11a16225f81b12b02",
    //             "helperId": "6319d7878f5d4723b0c36a2b",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b163"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0f2",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //           "refCentralizedOptionId": "63206d948f36605064901f6b",
    //           "refOptionSetId": "63492eaf9b41821db051c0f0",
    //           "status": true,
    //           "conversion": 0,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 2,
    //           "optionType": 9,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b164",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f6b",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //             "optionType": 9,
    //             "id": 2,
    //             "refName": "Lieferzeiten",
    //             "name": "Vormittags (7 bis 12 Uhr)",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 0,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 7,
    //             "additionalValueTo": 12,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 1,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 2,
    //             "optionValueToUnits": "63170dd11a16225f81b12b02",
    //             "helperId": "6319d7878f5d4723b0c36a2c",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b164"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0f1",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //           "refCentralizedOptionId": "63206d948f36605064901f6a",
    //           "refOptionSetId": "63492eaf9b41821db051c0f0",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 3,
    //           "optionType": 9,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b165",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f6a",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //             "optionType": 9,
    //             "id": 1,
    //             "refName": "Lieferzeiten",
    //             "name": "Nachmittags (12 bis 18 Uhr)",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 1,
    //             "additionalValueTo": 18,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 1,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 3,
    //             "optionValueToUnits": "63170dd11a16225f81b12b02",
    //             "helperId": "6319d7878f5d4723b0c36a2d",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b165"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0f4",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //           "refCentralizedOptionId": "63206d948f36605064901f6d",
    //           "refOptionSetId": "63492eaf9b41821db051c0f0",
    //           "status": true,
    //           "conversion": 0,
    //           "value": 0,
    //           "defaultTelephoneOption": true,
    //           "sortOrder": 4,
    //           "optionType": 9,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbd91ac6c024c18b988a272",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f6d",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //             "optionType": 9,
    //             "id": 4,
    //             "refName": "Lieferzeiten",
    //             "name": "Einstellungen",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 0,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": true,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 4,
    //             "optionValueToUnits": "63170dd11a16225f81b12b02",
    //             "helperId": "6319d7878f5d4723b0c36a2e",
    //             "tempGeneralSettingsOptionId": "5bbd91ac6c024c18b988a272"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0f6",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //           "refCentralizedOptionId": "63206d948f36605064901f6f",
    //           "refOptionSetId": "63492eaf9b41821db051c0f0",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 5,
    //           "optionType": 9,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5fcdd5e8f3b9e360be451392",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f6f",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //             "optionType": 9,
    //             "id": 6,
    //             "refName": "Lieferzeiten",
    //             "name": "test23455",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 5,
    //             "optionValueToUnits": "63170dd11a16225f81b12b02",
    //             "helperId": "6319d7878f5d4723b0c36a2f",
    //             "tempGeneralSettingsOptionId": "5fcdd5e8f3b9e360be451392"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0f5",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //           "refCentralizedOptionId": "63206d948f36605064901f6e",
    //           "refOptionSetId": "63492eaf9b41821db051c0f0",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 6,
    //           "optionType": 9,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5fce34aeada57f25c4a3a146",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f6e",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f5f",
    //             "optionType": 9,
    //             "id": 5,
    //             "refName": "Lieferzeiten",
    //             "name": "test3453",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 6,
    //             "optionValueToUnits": "63170dd11a16225f81b12b02",
    //             "helperId": "6319d7878f5d4723b0c36a30",
    //             "tempGeneralSettingsOptionId": "5fce34aeada57f25c4a3a146"
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       "_id": "63492eaf9b41821db051c0f7",
    //       "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //       "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //       "dynamicProductId": "61d421c387bf6a786895f827",
    //       "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //       "sortOrder": 10,
    //       "optionType": 10,
    //       "name": "Tankwagen",
    //       "dynamicOptions": [
    //         {
    //           "_id": "63492eaf9b41821db051c0f8",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //           "refCentralizedOptionId": "63206d948f36605064901f70",
    //           "refOptionSetId": "63492eaf9b41821db051c0f7",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 1,
    //           "optionType": 10,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b16a",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f70",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //             "optionType": 10,
    //             "id": 1,
    //             "refName": "Tankwagen",
    //             "name": "bis 18 Tonnen",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 3,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7878f5d4723b0c36a33",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b16a"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0f9",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //           "refCentralizedOptionId": "63206d948f36605064901f71",
    //           "refOptionSetId": "63492eaf9b41821db051c0f7",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": true,
    //           "sortOrder": 2,
    //           "optionType": 10,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b168",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f71",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //             "optionType": 10,
    //             "id": 2,
    //             "refName": "Tankwagen",
    //             "name": "egal (auch mit Hänger)",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": true,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 1,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7878f5d4723b0c36a31",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b168"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0fb",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //           "refCentralizedOptionId": "63206d948f36605064901f73",
    //           "refOptionSetId": "63492eaf9b41821db051c0f7",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 3,
    //           "optionType": 10,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b169",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f73",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //             "optionType": 10,
    //             "id": 4,
    //             "refName": "Tankwagen",
    //             "name": "bis 26 Tonnen",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 2,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7878f5d4723b0c36a32",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b169"
    //           }
    //         },
    //         {
    //           "_id": "63492eaf9b41821db051c0fa",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //           "refCentralizedOptionId": "63206d948f36605064901f72",
    //           "refOptionSetId": "63492eaf9b41821db051c0f7",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 4,
    //           "optionType": 10,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5fcdd5f9f3b9e360be451393",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f72",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f60",
    //             "optionType": 10,
    //             "id": 3,
    //             "refName": "Tankwagen",
    //             "name": "testt44",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 4,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7878f5d4723b0c36a34",
    //             "tempGeneralSettingsOptionId": "5fcdd5f9f3b9e360be451393"
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       "_id": "63492eb09b41821db051c0fc",
    //       "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //       "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //       "dynamicProductId": "61d421c387bf6a786895f827",
    //       "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //       "sortOrder": 11,
    //       "optionType": 11,
    //       "name": "Schlauchlänge",
    //       "dynamicOptions": [
    //         {
    //           "_id": "63492eb09b41821db051c101",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //           "refCentralizedOptionId": "63206d948f36605064901f78",
    //           "refOptionSetId": "63492eb09b41821db051c0fc",
    //           "status": true,
    //           "conversion": 0,
    //           "value": 0,
    //           "defaultTelephoneOption": true,
    //           "sortOrder": 1,
    //           "optionType": 11,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b172",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f78",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //             "optionType": 11,
    //             "id": 5,
    //             "refName": "Schlauchlänge",
    //             "name": "bis 36 Meter",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 0,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": true,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 1,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7888f5d4723b0c36a35",
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b172"
    //           }
    //         },
    //         {
    //           "_id": "63492eb09b41821db051c0fd",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //           "refCentralizedOptionId": "63206d948f36605064901f74",
    //           "refOptionSetId": "63492eb09b41821db051c0fc",
    //           "status": true,
    //           "conversion": 0,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 2,
    //           "optionType": 11,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5ca72f896e21fc2784c457fb",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f74",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //             "optionType": 11,
    //             "id": 1,
    //             "refName": "Schlauchlänge",
    //             "name": "bis 35",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 0,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 2,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7888f5d4723b0c36a36",
    //             "tempGeneralSettingsOptionId": "5ca72f896e21fc2784c457fb"
    //           }
    //         },
    //         {
    //           "_id": "63492eb09b41821db051c100",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //           "refCentralizedOptionId": "63206d948f36605064901f77",
    //           "refOptionSetId": "63492eb09b41821db051c0fc",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 3,
    //           "optionType": 11,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5e67770ed1a1422a24db2399",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f77",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //             "optionType": 11,
    //             "id": 4,
    //             "refName": "Schlauchlänge",
    //             "name": "bis 45",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 3,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7888f5d4723b0c36a37",
    //             "tempGeneralSettingsOptionId": "5e67770ed1a1422a24db2399"
    //           }
    //         },
    //         {
    //           "_id": "63492eb09b41821db051c102",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //           "refCentralizedOptionId": "63206d948f36605064901f79",
    //           "refOptionSetId": "63492eb09b41821db051c0fc",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 4,
    //           "optionType": 11,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5e68809f08e3dc42460da80e",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f79",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //             "optionType": 11,
    //             "id": 6,
    //             "refName": "Schlauchlänge",
    //             "name": "bis 500",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 4,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7888f5d4723b0c36a38",
    //             "tempGeneralSettingsOptionId": "5e68809f08e3dc42460da80e"
    //           }
    //         },
    //         {
    //           "_id": "63492eb09b41821db051c0ff",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //           "refCentralizedOptionId": "63206d948f36605064901f76",
    //           "refOptionSetId": "63492eb09b41821db051c0fc",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 5,
    //           "optionType": 11,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5fcdd614f3b9e360be451395",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f76",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //             "optionType": 11,
    //             "id": 3,
    //             "refName": "Schlauchlänge",
    //             "name": "testtttttttttt",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 5,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7888f5d4723b0c36a39",
    //             "tempGeneralSettingsOptionId": "5fcdd614f3b9e360be451395"
    //           }
    //         },
    //         {
    //           "_id": "63492eb09b41821db051c0fe",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //           "refCentralizedOptionId": "63206d948f36605064901f75",
    //           "refOptionSetId": "63492eb09b41821db051c0fc",
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "sortOrder": 6,
    //           "optionType": 11,
    //           "webOption": "",
    //           "tempGeneralSettingsOptionId": "5fcdd633f3b9e360be451397",
    //           "centralizedOptions": {
    //             "_id": "63206d948f36605064901f75",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "63206d948f36605064901f61",
    //             "optionType": 11,
    //             "id": 2,
    //             "refName": "Schlauchlänge",
    //             "name": "test2",
    //             "showName": true,
    //             "showToggle": true,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": 1,
    //             "conversionEditable": true,
    //             "showAdditionalvalues": true,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": true,
    //             "maxDistance": 0,
    //             "DOB": false,
    //             "additionalValueStatus": false,
    //             "maxDistanceStatus": false,
    //             "DOBStatus": false,
    //             "displayText": "",
    //             "displayTextStatus": false,
    //             "additionalValueType": 0,
    //             "additionalOptionValue": 0,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": "",
    //             "optionEditable": false,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 6,
    //             "optionValueToUnits": "",
    //             "helperId": "6319d7888f5d4723b0c36a3a",
    //             "tempGeneralSettingsOptionId": "5fcdd633f3b9e360be451397"
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       "_id": "6274f6bfa01b250d187f8743",
    //       "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //       "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //       "dynamicProductId": "61d421c387bf6a786895f827",
    //       "refCentralizedOptionSetId": "632071d1d9197630200fedf6",
    //       "sortOrder": 12,
    //       "optionType": 12,
    //       "name": "Zahlungsart",
    //       "dynamicOptions": [
    //         {
    //           "_id": "6274f6bfa01b250d187f8745",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "optionType": 12,
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": true,
    //           "webOption": true,
    //           "sortOrder": 1,
    //           "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b175",
    //           "refCentralizedOptionSetId": "632071d1d9197630200fedf6",
    //           "refCentralizedOptionId": "632071d1d9197630200fedfc",
    //           "refOptionSetId": "6274f6bfa01b250d187f8743",
    //           "centralizedOptions": {
    //             "_id": "632071d1d9197630200fedfc",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "632071d1d9197630200fedf6",
    //             "optionType": 12,
    //             "id": 6,
    //             "refName": "Bar bei Lieferungg",
    //             "name": "Bar bei Lieferungg",
    //             "showName": true,
    //             "showToggle": false,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": "Euro/Km",
    //             "conversionEditable": true,
    //             "showAdditionalvalues": false,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 3,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": true,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": true,
    //             "optionEditable": true,
    //             "specialText": null,
    //             "showSpecialText": false,
    //             "sortOrder": 1,
    //             "tempGeneralSettingsOptionId": "5bbc2fab217e3b1b5480b175",
    //             "helperId": "6274eb188a07554d72f4e57a"
    //           }
    //         },
    //         {
    //           "_id": "6274f6bfa01b250d187f8744",
    //           "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //           "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //           "dynamicProductId": "61d421c387bf6a786895f827",
    //           "optionType": 12,
    //           "status": true,
    //           "conversion": 1,
    //           "value": 0,
    //           "defaultTelephoneOption": false,
    //           "webOption": true,
    //           "sortOrder": 8,
    //           "tempGeneralSettingsOptionId": "5be419dad50608428cbedc4a",
    //           "refCentralizedOptionSetId": "632071d1d9197630200fedf6",
    //           "refCentralizedOptionId": "632071d1d9197630200fedf8",
    //           "refOptionSetId": "6274f6bfa01b250d187f8743",
    //           "centralizedOptions": {
    //             "_id": "632071d1d9197630200fedf8",
    //             "myMasterId": "5bbc2fab217e3b1b5480b13e",
    //             "unternehmenId": "5bbc4a276c024c18b988a1cc",
    //             "refCentralizedOptionSetId": "632071d1d9197630200fedf6",
    //             "optionType": 12,
    //             "id": 2,
    //             "refName": "Vorkasse",
    //             "name": "Vorkasse",
    //             "showName": true,
    //             "showToggle": false,
    //             "status": true,
    //             "showConversion": true,
    //             "conversion": "Euro/100l/Tonne",
    //             "conversionEditable": true,
    //             "showAdditionalvalues": false,
    //             "additionalValueFrom": 0,
    //             "additionalValueTo": 0,
    //             "additionalValueUnits": "",
    //             "showValue": true,
    //             "value": 0,
    //             "valueDropdown": false,
    //             "distanceLimit": false,
    //             "maxDistance": 0,
    //             "DOB": true,
    //             "standard": true,
    //             "defaultTelephoneOption": false,
    //             "webOption": true,
    //             "optionEditable": true,
    //             "specialText": "",
    //             "showSpecialText": false,
    //             "sortOrder": 8,
    //             "tempGeneralSettingsOptionId": "5be419dad50608428cbedc4a",
    //             "helperId": "6274eb188a07554d72f4e57c"
    //           }
    //         }
    //       ]
    //     }
    //   ]
    // }
    return {};
  }
}
