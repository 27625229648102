import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'app/Services/admin.service';
import { CommonService } from 'app/common.service';
import { LanguageService } from '../Services/language.service';
// import { SelectCalculatorComponent } from 'app/popups/select-calculator/select-calculator.component';
import { SmallPcService } from '../Services/small-pc.service';
import { WindowRefService } from '../Services/window.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
// import { environment } from '../../environments/environment';
import { BigpcService } from 'app/Services/bigpc.service';
import { environment } from 'environments/environment';
import { OrderService } from 'app/Services/order.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  colors: any;
  @Input('colors') color;
  minMaxData;
  portaluser_data: any;
  onlineKonto: any;

  iconColor1;
  iconColor2;
  dashboardData: any;
  address: any;
  params: URLSearchParams;
  addAddressinDashboard: boolean = false;
  orders: any;
  volume_short: any;
  weight_short: any;
  @Input("productInfoForWebPC") productInfoForWebPC: any;
  @Input("portalTermsAndConditions") portalTermsAndConditions: any;
  @Output() showAllOrders = new EventEmitter();
  @Output() showPricecalcFromPrimayAddress = new EventEmitter();
  private _window: any;
  url: any;
  affliatePostalCodes: any;
  affiliateDetailsExists: any;
  affliateDetails: { amount?: any; zip?: any; productId?: any; unloadingPoints?: any; product?: any; affiliateId?: any; callBackUrl?: any; };
  @Input()
  set _minMaxData(data) {
    this.minMaxData = data;
    if (this.minMaxData) {
      this.setValues(this.minMaxData)
    }
  }
  Pellets: any;
  Heizöl: any;
  hostname;
  languageData: any;
  sessionId: any;
  svgs = [
    environment.assetsUrl+'assets/svgs/dashboarduser.svg',
  ];
  loader:boolean = false;
  constructor(
    public adminService: AdminService,
    private smallPcService: SmallPcService,
    public dialog: MatDialog,
    private langService: LanguageService,
    private windowRef: WindowRefService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    private newBigPc: BigpcService,
    private commonService: CommonService,
    public orderService: OrderService
  ) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
    this._window = this.windowRef.nativeWindow;
    this.url = this._window.location.href;
    this.params = new URLSearchParams(this.url);
    this.getDynamicSVG(this.svgs)
  }

  async ngOnInit() {
    // this.getDashboardData();
    this.getPrimaryAddress();
    this.colors = this.productInfoForWebPC?.colors ;
    this.getDashboardData();
    this.getLatestOrders();
    let portalUser = JSON.parse(localStorage.getItem("portal_user"));
    this.onlineKonto = portalUser.portalOnlineId ;

    this.affiliateDetailsExists = await this.orderService.checkForAffliateQueryParams();

    if (this.affiliateDetailsExists.status) {
      this.affliateDetails = this.orderService.affliateDetails;
      await this.newBigPc.postalCode({ event: this.affliateDetails.zip, product: this.affliateDetails.product }).subscribe((postalCodes:any) => {
        if (postalCodes.success && postalCodes.data && postalCodes.data.length > 0) {
          this.affliatePostalCodes = postalCodes.data[0]
        } else {
          this.affliatePostalCodes = null;
        }
      });
    };
  };

  setValues(data) {
    let ActivateDeactivatePc = data.ActivateDeactivatePc;
    this.Pellets = ActivateDeactivatePc.Pellets;
    this.Heizöl = ActivateDeactivatePc.Heizol;

  };

  getDashboardData() {
    this.loader = true;
    this.adminService.getDashboardData().subscribe((result:any) => {
      this.loader = false;
      if (result.success) {
        this.dashboardData = result.data;
      }
    }, () => {
      this.loader = false;
    })
  };

  getPrimaryAddress() {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await this.adminService.getPrimaryAddress().toPromise();
        if (result.success) {
          this.address = result.data;
          this.checkAndCreateNewSession();
        } else {
         let newsessionId = this.smallPcService.genereateNewSessionId();
         this.setupUrl(newsessionId);
        }
        resolve(true);
      } catch (err) {
        reject(err)
      };
    });
  };

  getLatestOrders() {
    this.adminService.getLatestOrders().subscribe((result:any) => {
      if (result.success) {
        this.orders = result.data;
      };
    });
  };
 
  createAnAddress() {
    this.addAddressinDashboard = true;
  };

  cancelEdit = async () => {
    this.addAddressinDashboard = false;
    await this.getPrimaryAddress();
  };

  allOrders() {
    this.showAllOrders.emit('Bestellung')
  };

  async calculatePrice() {
    let params = new URLSearchParams(this.url); 
    let sessionId = decodeURIComponent(params.get("sid"));
    let sessionExists = await this.smallPcService.validateSessionData(sessionId) 
    if(!sessionExists){
      let obj = {primaryAdress : this.address, loginUser : true, selectedProduct: {}}; 
    this.newBigPc.createNewSession(obj)
    } else {
      this.showPricecalcFromPrimayAddress.emit(this.address); 
    };
  };

   async checkAndCreateNewSession(){
    let params = new URLSearchParams(this.url); 
    let sessionId = decodeURIComponent(params.get("sid"));
    let sessionExists = await this.smallPcService.validateSessionData(sessionId) 
    if(!sessionExists){
      let obj = {primaryAdress : this.address , loginUser : true, selectedProduct: {}} 
    this.newBigPc.createNewSession(obj)
    };
   };



  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
    
      this.httpClient.get(item, { responseType: 'text' })
        .subscribe((logo) => {
          // logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
          logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
          logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
          logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
          this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
        })
    });
  };
   
  setupUrl (newSessionId){
    let newUrl ;
    let domains = JSON.parse(localStorage.getItem("domainUrls"));
    if (environment.testSite_or_thirdPartySites) {
      newUrl =  `https://${domains.priceCalcUrl}#&view=bigpc&sid=${newSessionId}&page=Dashboard` 
    } else {
      newUrl =  `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&page=Dashboard` 
    };
    localStorage.setItem('sid', newSessionId);
    let newSession =  {
      priceCalcUrl:domains.priceCalcUrl,
      domainName: domains.domainName,
      quantity: 0,
      stations: 0,
      product: {
        "_id": "",
        "name": ""
      },
      postalCode: { plzId: '', city: '', postalcode: '', suburb: '' },
      localOptions: [
        { optionSetId: '', optionId: '', sort: 1 },
        { optionSetId: '', optionId: '', sort: 2 },
        { optionSetId: '', optionId: '', sort: 3 },
        { optionSetId: '', optionId: '', sort: 4 },
        { optionSetId: '', optionId: '', sort: 5 }
      ],
      ratePaymentSettings: { status: false },
      desiredDateSettings: {},
      cart: [
        { _id: '' }
      ],
      co2Compensation: [],
    }
    let setSessionData = JSON.stringify(newSession)
     localStorage.setItem(newSessionId , setSessionData)
    window.history.pushState('', document.title, newUrl);
  }
}

//old code 
// convertToColors(color) { 
//   let rgb = this.hextoRGB(color);
  
//   if (rgb) {
//     this.iconColor1 = 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')';
//     let r = (255 - Number(rgb.r)) / 3;
//     let g = (255 - Number(rgb.g)) / 3;
//     let b = (255 - Number(rgb.b)) / 3;

//     this.iconColor2 = 'rgb(' + (Number(rgb.r) + Number(r)) + ',' + (Number(rgb.g) + Number(g)) + ',' + (Number(rgb.b) + Number(b)) + ')';


//   }
// }

// hextoRGB(hex) {
//   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//   return result ? {
//     r: parseInt(result[1], 16),
//     g: parseInt(result[2], 16),
//     b: parseInt(result[3], 16)
//   } : this.rgbToArray(hex);
// }

// rgbToArray(hex) {
//   let result = hex.split("(")[1].split(")")[0];
//   result = result.split(',');
//   return {
//     r: parseInt(result[0]),
//     g: parseInt(result[1]),
//     b: parseInt(result[2])
//   }
// }