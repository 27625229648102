import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'langReplacer'
})
export class LangReplacerPipe implements PipeTransform {

  transform(value: any, languageVar: string): any {
    var regex = new RegExp("^[0-9,.,-]");
    if (value && regex.test(value)) {
      value = value.toString();
      if (value.includes('.')) {
        let spl = value.split(".");
        if ((spl.length == 2) && (spl[1].toString().length == 2 || spl[1].toString().length == 1)) {

        } else {
          value = value.replace('.', '');
        }
      }
      if (value.includes(',')) {
        value = value.replace(',', '.');
      }
      let v = value.split('.');
      if (v.length > 2) {
        value = v[0] + '' + v[1] + '.' + v[2];
      }
      let n = new Number(value);
      if (languageVar == 'GEN') {
        let val = n.toLocaleString("de-DE", { minimumFractionDigits: 2 });
        return val;
      }
      if (!(languageVar == 'GEN')) {
        let val = n.toLocaleString("en-EN", { minimumFractionDigits: 2 });
        return val;
      }
    } else {
      return value;
    }
  }

}
