import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from '../../Services/language.service';
import { maxminValidations } from 'app/Validations/maxmin.validators';
import { CompleterData, } from 'app/ng2Completer/services/completer-data';
import { CompleterService } from 'app/ng2Completer/services/completer-service';
import { ICustomWindow, WindowRefService } from '../../Services//window.service';
import { BigpcService } from '../../Services/bigpc.service'
import { SmallPcService } from '../../Services/small-pc.service'
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { LocalstorgeService } from 'app/Services/localstorge.service';

@Component({
  selector: 'app-small-pc',
  templateUrl: './small-pc.component.html',
  styleUrls: ['./small-pc.component.css']
})
export class SmallPcComponent implements OnInit {
  env;

  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  hostname: any;
  url: any;
  label: string = 'Liter';
  dataService: CompleterData;
  searchData: any;
  @Input("products") products: any;
  @Input("primaryAdd") primaryAdd: any;

  // pcMinMaxValues: any;
  minimumAmount: any;
  maximumAmount: any;
  minMumValue: number;
  productSelected = {};

  // Pc Info
  dynamicPCData: any = [];
  colorsPc: any;
  pricecalc: string = environment.pricecalc;
  pcOffPbbackend: boolean = false;

  // ratePayment
  ratepaymentInQuery: boolean;

  plzOptions: any;
  invalidLieferort: boolean;
  sessionId: string;
  traderRedirect: boolean = false;
  bigpclink: any;
  private _window: ICustomWindow;
  ActivateDeactivatePc = {
    'activatePc': false,
    'productText': ''
  };
  units: any;
  volueme: any;
  weight: any;
  currency: any;
  showAbladestelle: boolean = false;
  inLimitHouseHold: boolean = false;
  public abladestellen: any[] = [
    { value: 1 }
  ];


  selectedPostalCodeId: any;
  city: any = '';
  postalcode: any = '';
  suburb: any = '';
  postalLat: any = '';
  postalLng: any = '';

  //Eroor messages
  msg: string;
  errorMsg: boolean = false;
  // tabErrorMsg: boolean = false;
  createNewPc: boolean = false;
  showRemainingValues: boolean = false;

  tabErrorMsg: boolean = false;
  postalCodeErrorMsg: boolean = false;

  unitsSymbol = 'l';
  unitName = 'liter';
  loadingStations = [];
  public myForm: FormGroup;
  primaryAdress: any;

  @Output() openBigPc = new EventEmitter();
  constructor(
    private langService: LanguageService,
    private windowRef: WindowRefService,
    private _fb: FormBuilder,
    public completerService: CompleterService,
    private SmallPcService: SmallPcService,
    private MybigpcService: BigpcService,
    public dialog: MatDialog,
    public localstorage: LocalstorgeService
  ) {
    this._window = this.windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;


    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

  };

  smallpc_ttr_fr: boolean = true;
  productdup: string = '';
  dataLayer = window['dataLayer'] || [];
  singleProductSelect: any = {};

  ngOnInit() {
    this.ActivateDeactivatePc['activatePc'] = true;
    this.env = environment;
    this.url = this._window.location.href;
    let params = new URLSearchParams(this.url);

    if (!params.get('sid')) {
      let sessionId = this.SmallPcService.genereateNewSessionId();
      this.sessionId = sessionId;
    } else {
      this.sessionId = params.get('sid');
    };

    if (params.get("traderRedirect")) {
      this.traderRedirect = true;
    } else {
      this.traderRedirect = false;
    };

    if (params.get("ratepayment") === 'true') {
      this.ratepaymentInQuery = true;
    } else {
      this.ratepaymentInQuery = false;
    };

    if (!this.env.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));    /*test calc*/
    };

    this.SmallPcService.getIP().subscribe(() => {
    });

    this.dynamicPCData = this.products.length ? this.products : [];
    let stations = 0;
    this.abladestellen = [];
    this.loadingStations = [];
    this.singleProductSelect = this.dynamicPCData[0].dynamicPcBasicSettings;
    if (this.singleProductSelect) {
      this.showRemainingValues = true;
      this.showAbladestelle = true;

      this.minimumAmount = this.singleProductSelect['minimumAmount'];
      this.maximumAmount = this.singleProductSelect['maximumAmount'];
      this.ActivateDeactivatePc['activatePc'] = this.singleProductSelect['activatePc'];
      this.ActivateDeactivatePc['productText'] = this.singleProductSelect['pcText'];
      this.label = this.singleProductSelect['unitsLangVariable'];
      this.unitsSymbol = this.singleProductSelect['unitsSymbolForPc'];
      this.unitName = this.singleProductSelect['unitsName'];
      this.colorsPc = this.dynamicPCData[0]['loginColors'];
      stations = this.singleProductSelect['deliveryStationLimit'];

      for (let i = 0; i < stations; i++) {
        this.loadingStations.push({ value: i + 1 });
      };
      this.abladestellen = this.loadingStations;

      this.singleProductSelect.maxAmount = this.maximumAmount;
      this.singleProductSelect.minAmount = this.minimumAmount;
      this.myForm = this._fb.group({
        product: [' ', [Validators.required]],
        lieferort: [' ', [Validators.required]],
        liter: [, [Validators.required]],
        abladestellen: [],
      }, { validator: maxminValidations(this.singleProductSelect) });
      // if (this.primaryAdd && this.primaryAdd.length > 0) {
      //   let takeZipCode = this.primaryAdd[0];
      //   this.myForm.get('lieferort').setValue(takeZipCode.postalcode + ' ' + takeZipCode.city + ' ' + takeZipCode.suburb);
      // } else {
      //   this.myForm.get('lieferort').setValue('');
      // };
    };
  };

  routeToPriceCalculator(data, valid) {
    if (valid) {
      let smallPcInfo = {
        plzId: this.selectedPostalCodeId,
        city: this.city,
        zipCode: this.postalcode,
        suburb: this.suburb,
        latitude: this.postalLat,
        longitude: this.postalLng,
        quantityVal: data?.value?.liter,
        stationVal: data?.value?.abladestellen
      };
      let selctedProduct = this.dynamicPCData.find(i => i.dynamicProductId == data.value.product);
      let obj = { primaryAdress: smallPcInfo, seelctProduct: selctedProduct, loginUser: true };
      this.MybigpcService.createNewSession(obj);
    };
  };

  productSelectionChange(event) {
    let stations = 0;
    this.abladestellen = [];
    this.loadingStations = [];
    let productData = this.dynamicPCData.find(({ dynamicProductId }) => dynamicProductId == event.value);

    this.productSelected = {
      name: productData.appName,
      dynamicProductId: productData.dynamicProductId,
    };

    this.myForm.get('liter').reset();
    this.myForm.get('lieferort').reset();

    if (productData.dynamicProductId) {
      this.showRemainingValues = true;
      this.showAbladestelle = true;
    };
    let productBasicData = productData.dynamicPcBasicSettings;
    this.minimumAmount = productBasicData.minimumAmount;
    this.maximumAmount = productBasicData.maximumAmount;
    this.label = productBasicData.unitsLangVariable;
    this.unitsSymbol = productBasicData.unitsSymbolForPc;
    stations = productBasicData.deliveryStationLimit;
    for (let i = 0; i < stations; i++) {
      this.loadingStations.push({ value: i + 1 });
    };

    this.abladestellen = this.loadingStations;
    this.unitName = productBasicData.unitsName;
    this.ActivateDeactivatePc['activatePc'] = productBasicData.activatePc;
    this.ActivateDeactivatePc['productText'] = productBasicData.pcText;

    productBasicData.maxAmount = this.maximumAmount;
    productBasicData.minAmount = this.minimumAmount;

    this.myForm = this._fb.group({
      product: [productData.dynamicProductId, [Validators.required]],
      lieferort: [this.myForm?.value.lieferort, Validators.required],
      liter: [this.myForm?.value.liter, [Validators.required]],
      abladestellen: [this.myForm?.value.abladestellen || 1],
    }, { validator: maxminValidations(productBasicData) });

  };

  validateMenge() {
    this.minMumValue = this.minimumAmount * this.myForm.controls['abladestellen'].value;
    let liters = Number(this.myForm.controls['liter'].value);
    if (liters < this.minMumValue) {
      this.inLimitHouseHold = true;
      this.label = 'Liter';
    } else {
      this.inLimitHouseHold = false;
      this.label = 'Liter';
    };
  };

  validate(e) {
    console.log(e)
    var regex = new RegExp("[a-zA-Z0-9äöüÄÖÜß ]");
    var key = e.keyCode || e.which;
    key = String.fromCharCode(key);
    if (!regex.test(key)) {
      e.returnValue = false;
      if (e.preventDefault) {
        e.preventDefault();
      }
    }
  };

  selectPlz(event) {
    if (event) {
      this.tabErrorMsg = false;
      this.selectedPostalCodeId = event._id;
      this.city = event.place_name;
      this.postalcode = event.country_code;
      this.suburb = event.suburb;
      this.postalLat = event.latitude;
      this.postalLng = event.longitude;
    } else if (!event || !event._id) {
      this.myForm.setErrors({ lieferort: false });
      this.tabErrorMsg = true;
    } else {
      this.myForm.setErrors(null);
    };

  };

  onKeyy(e) {
    if (e.key == "Tab") {
      this.tabErrorMsg = this.selectedPostalCodeId ? false : true
    } else if (e.key == "Backspace") {
      this.selectedPostalCodeId = undefined;
      if (this.selectedPostalCodeId == undefined) {
        this.tabErrorMsg = true;
      };
    };
  };

  getPostalCode(e) {
    if (e !== null && e !== undefined && e.length >= 4) {
      let objectname = {
        event: e,
        dynamicProductId: this.myForm.value.product
      };

      this.MybigpcService.postalCode(objectname).subscribe((data: any) => {
        if (data.success) {
          this.plzOptions = data.data;
          let Options = data.data;
          this.plzOptions = Options.sort((a, b) => {
            if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
            if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
          });
          this.postalCodeErrorMsg = false;
          let postelAddress = objectname.event;
          let findPostalCode = this.plzOptions.find(item => (item.ortPlz).trim() == postelAddress.trim());
          if (typeof findPostalCode != 'object') {
            this.postalCodeErrorMsg = true;
          };
        } else {
          this.postalCodeErrorMsg = true;
          this.plzOptions.length = 0
        };
      });
    } else {
      this.plzOptions = [];
      this.errorMsg = false;
    };
  };

  onKey(Event: any) {
    if (Event.length >= 3) {
      Event = Event.trim();
      if (this.searchData) {
        this.searchData = this.searchData.reduce((prev, postalcode) => {
          let prevIndex = prev.findIndex(p => postalcode._id == p._id)
          if (postalcode.produkts.indexOf(this.myForm.controls['product'].value) >= 0 && prevIndex < 0) {
            prev = [...prev, postalcode]
          }
          return prev
        }, [])
        this.dataService = this.completerService.local(this.searchData.concat([]),
          'country_code,place_name,suburb',
          'place_name,country_code,suburb');
      }
    }
  };

  onNg2Blur() {
    if (this.myForm.controls['lieferort'].invalid) {
      this.invalidLieferort = true;
    } else {
      this.invalidLieferort = false;
    }
  };

  isvalid() {
    if (this.myForm.get('product').valid && this.selectedPostalCodeId && this.myForm.get('lieferort').valid && this.myForm.get('liter').valid && this.myForm.get('abladestellen').valid) {
      return false;
    } else {
      return true;
    }
  };


  // settingSIDData(data, selctedProduct, url) {
  //   let sessionData = {
  //     priceCalcUrl: this.bigpclink, // need to check for third party and local users
  //     domainName: this.hostname,
  //     quantity: data?.value?.liter ? parseInt(data?.value?.liter) : 3000,
  //     stations: data?.value?.abladestellen ? parseInt(data?.value?.abladestellen) : 1,
  //     product: {
  //       "_id": selctedProduct.dynamicProductId,
  //       "name": selctedProduct.appName
  //     },
  // postalCode: {
  //   plzId: this.selectedPostalCodeId,
  //   city: this.city,
  //   postalcode: this.postalcode,
  //   suburb: this.suburb,
  //   latitude: this.postalLat,
  //   longitude: this.postalLng,
  // },
  //     localOptions: [
  //       { optionSetId: '', optionId: '', sort: 1 },
  //       { optionSetId: '', optionId: '', sort: 2 },
  //       { optionSetId: '', optionId: '', sort: 3 },
  //       { optionSetId: '', optionId: '', sort: 4 },
  //       { optionSetId: '', optionId: '', sort: 5 },
  //     ],
  //     ratePaymentSettings: { status: false, zahlungRate: '', zahlungspans: [] },
  //     desiredDateSettings: {},
  //     cart: [
  //       {
  //         _id: ''
  //       }
  //     ],
  //     co2Compensation: [],
  //     tag: parseInt(selctedProduct.tag) || 1,
  //   };

  //   this.SmallPcService.setSessionData(this.sessionId, sessionData).then(() => {
  //     window.location.href = url;
  //   });
  // }


  // createNewSeqence() {
  //   let selctedProduct;
  //   let value;
  //   let params;
  //   let reqObj = {};

  //   if (this.primaryAdress && Object.keys(this.primaryAdress).length) {
  //     Object.assign(reqObj, { userData: this.primaryAdress, login: true })
  //   } else {
  //     Object.assign(reqObj, { login: false })
  //   };
  //   this.MybigpcService.createNewSeqence(reqObj).subscribe((data: any) => {
  //     if (data.success) {
  //       console.log("In");
  //       selctedProduct = {
  //         dynamicProductId: data.data[0].dynamicProductId,
  //         namePc: data.data[0].name,
  //         tag: data.data[0].webLinkSettings.tag
  //       }
  //       value = {
  //         liter: data.data[0].minAmount,
  //         abladestellen: 1
  //       }
  //       params = { value };
  //       if (data.message == "primary") {
  //         this.selectedPostalCodeId = data.data[0].primaryAdresss.plzId;
  //         this.city = data.data[0].primaryAdresss.city;
  //         this.postalcode = data.data[0].primaryAdresss.zipCode;
  //         this.suburb = data.data[0].primaryAdresss.suburb;
  //       }
  //       else if (data.message == "guest") {
  //         this.selectedPostalCodeId = data.data[0].postalCodes[0].plzId;
  //         this.city = data.data[0].postalCodes[0].placeName;
  //         this.postalcode = data.data[0].postalCodes[0].countryCode;
  //         this.suburb = data.data[0].postalCodes[0].suburb;
  //       } else {
  //         console.log("NO primary adress for logined user");
  //         // this.getProductDatabyUnter();
  //       }
  //       if (!this.env.testSite_or_thirdPartySites) {
  //         //  local users or our own sites is having this case
  //         this.dataLayer.push({
  //           'event': 'auprisCalculatePrice'
  //         });
  //         let url = `${this.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&page=Dashboard`;
  //         if (this.ratepaymentInQuery) {
  //           url = `${url}&ratepayment=true`
  //         }
  //         this.settingSIDData(params, selctedProduct, url)
  //       } else {
  //         this.dataLayer.push({
  //           'event': 'auprisCalculatePrice'
  //         });
  //         let url;
  //         //  this.env.testSite_or_thirdPartySites ? `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&page=Dashboard` :`${this.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&page=Dashboard`;
  //         if (this.env.testSite_or_thirdPartySites) {
  //           if (data.message == "guest") {
  //             url = `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&stage=one`
  //           } else {
  //             url = `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&page=Dashboard`
  //           }
  //         } else {
  //           url = `${this.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&page=Dashboard`
  //         }
  //         if (this.ratepaymentInQuery) {
  //           url = `${url}&ratepayment=true`
  //         }
  //         this.settingSIDData(data, selctedProduct, url)
  //       }
  //     } else {
  //       console.log("failed To Create A new Session");
  //     }
  //   });
  // };

  loader: Boolean;

  // getProductDatabyUnter() {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       this.loader = true;
  //       this.MybigpcService.unterProducts().subscribe((productsData: any) => {
  //         this.loader = false;
  //         if (productsData.success) {
  //           let dynamicPcDataArr = productsData.data.map(n => { return { ...n, dynamicProductId: n._id } });
  //           this.products = dynamicPcDataArr;
  //           this.smallpc_ttr_fr = true;
  //           this.dynamicPCData = this.products;
  //           if (!this.myForm) {
  //             this.myForm = this._fb.group({
  //               product: ['', [Validators.required]],
  //               lieferort: ['', Validators.required],
  //               liter: ['', [Validators.required]],
  //               abladestellen: ['1'],

  //             }, { validator: maxminValidations(this.pcMinMaxValues) });
  //           };
  //         };
  //         resolve(true);
  //       });
  //     }
  //     catch (err) {
  //       reject(err);
  //     }
  //   });
  // };
}
