import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'assetsUrl'
})
export class assetsUrl implements PipeTransform {
assetsUrlLocal: string = environment.assetsUrl;

  transform(value:any): string {
    return this.assetsUrlLocal+value;
  }
}
