import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { WindowRefService, ICustomWindow } from '../Services/window.service'
import { PortalAuthService } from "../login/auth.service"
import { LanguageService } from '../Services/language.service';
import { AdminService } from 'app/Services/admin.service';
import { EditComponent } from 'app/edit/edit.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { CommonService } from 'app/common.service';
import { LocalOptions, SessionData, SmallPcService } from '../Services/small-pc.service';
import { UploadImageComponent } from 'app/upload-image/upload-image.component';
import { DeleteOnlineaccountComponent } from '../popups/delete-onlineaccount/delete-onlineaccount.component';
import { environment } from '../../environments/environment';
import { UserInfo } from '../models/portalModels/userInfo.interface';
import { UserAddress } from '../models/portalModels/portalUserAddress.interface';
import { LocalstorgeService } from 'app/Services/localstorge.service';
import { BigpcService } from 'app/Services/bigpc.service';
import { SafePipe } from '../pipes/safe.pipe';

@Component({
  selector: 'app-einstellungen',
  templateUrl: './einstellungen.component.html',
  styleUrls: ['./einstellungen.component.css'],
  providers: [SafePipe]
})
export class EinstellungenComponent implements OnInit {

  pageName = "page=Einstellungen";
  cautionicon: Boolean = false;
  orginalEmail: string = '';
  editEmail: Boolean = false;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  lang: any = {};
  @Input() colors;
  @Output() userN = new EventEmitter();
  @Output() userName = new EventEmitter();
  @Output() pic = new EventEmitter();
  @ViewChild('google1') google1: any;
  @ViewChild('facebook1') facebook1: any;
  @Input() smallpclink;
  nam: string;

  onFileSelected_file: File;
  filename: any;
  editMode: any;
  editMode3: any;
  url: any;
  email: any;
  username: string;
  password: string;
  vendennummer: any;
  userinfo: UserInfo;
  urlname;
  userProfileImage: string;
  image: string;
  profile_image: any;
  profileinfo: any;
  google: boolean;
  facebook: boolean;
  domainname: string;
  private _window: ICustomWindow;
  hostname: string;
  user_exists = false;
  user_not_found = false;
  loading: boolean;
  loginErrorMessage: any;
  userCreatedMessage: string;
  showFullScreenLoading: boolean;
  getdata: any;
  snackbarMsg: string;
  showSnack: boolean;
  userInfo: UserInfo;
  authService: any;
  randomvariable: any;
  portaluser_data: any;
  portaluser_id: string;
  googlename: string;
  facebookname: string;
  googlephotourl: string;
  facebookphotourl: string;
  showForm: boolean;
  showFormFacebook: boolean;
  newName: string;
  newPhoto: string;
  newNamefb: string;
  newPhotofb: string;
  public emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  data: UserAddress[];
  kundennummer: any;
  photoUrllocal: string;
  Mode: boolean;
  Change_password: string;
  newpassword: string;
  re_password: FormControl;
  aupris_id: any;
  oldpassword: any;
  editPassword: any = false;
  editNewsletter: any = false;
  editBankdetails: any = false;
  changeBankdetails: boolean = false;
  checkEmailMarketingNewsletter: boolean;
  changeEmailMarketing: boolean = false;
  onlineId: any;
  Vorname: string;
  Name: string;
  sessionData: SessionData;
  localOptions: LocalOptions;
  kontoId: string;
  pricecalc: string = environment.pricecalc;

  subscribeText: boolean = false;
  unSubscribeText: boolean = false;
  KtoInhaber: String;
  IBAN: String;
  BIC: String;
  bank_name: String;
  account_number: String;
  bank_code: String;
  checkIbanStatus: any;
  msg: any;
  msgg: any;
  repeatEmailValidate: Boolean = false;
  emailValidate: Boolean = false;

  langData;
  loaderForScreen: boolean = false;


  myForm: FormGroup;
  myFormFacebook: FormGroup;
  resetPasswordForm: FormGroup;
  BankverbindungForm: FormGroup

  newEmail = new FormGroup({
    newEmail: new FormControl('', []),
    repeatNewEmail: new FormControl('', [])
  });
  updatedBankDetails: any;


  constructor(
    private langService: LanguageService,
    private admin: AdminService,
    private auth: PortalAuthService,
    private windowRef: WindowRefService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private smallPcService: SmallPcService,
    public fb: FormBuilder,
    public localstorage: LocalstorgeService,
    public bigPCService: BigpcService,
    private SafePipe: SafePipe,
  ) {
    this._window = this.windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;
    var params = new URLSearchParams(this.url);/*testcalc*/

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    };
  };


  ngOnInit() {

    this.myForm = this.fb.group({
      name: [false],
      photo: [false],
    });

    this.myFormFacebook = this.fb.group({
      name: [false],
      photo: [false],
    });
    this.BankverbindungForm = this.fb.group({
      "KtoInhaber": ['', [Validators.required]],
      "IBAN": ['', [Validators.required]],
      "BIC": [''],
      "bank_name": [''],
      "account_number": ['', [Validators.required]],
      "bank_code": ['', [Validators.required]],
    })

    this.resetPasswordForm = this.fb.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      re_password: ['', Validators.required],
    }, { validators: this.MatchValidator });

    this.langData = this.langService.getLocalLanguageData();
    this.languageData = this.langData['web-pc'] || {};
    this.lang = this.langData['unternehmen'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
      this.lang = data['unternehmen'] || {};
    });
    // this.profile();

    this.portaluser_data = JSON.parse(localStorage.getItem('portal_user'));
    this.portaluser_id = this.portaluser_data._id;
    this.kontoId = this.portaluser_data.kundenKontoId;
    this.onlineId = this.portaluser_data.portalOnlineId;

    var params = new URLSearchParams(this.url);
    this.domainname = decodeURIComponent(params.get("Domainname"));
    this.getaddresses();
    this.getCustomerNewsLetterStatus();
    // this.newEmail.get('newEmail').patchValue(this.email);

    this.url = this._window.location.href;
    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));
    };

    this.BankverbindungForm['controls']['BIC'].disable();
    this.BankverbindungForm['controls']['bank_name'].disable();

    if (this.url.includes("subPage=emilEdit")) {
      setTimeout(() => {
        this.editEmailHandler();
      }, 200);
    };

    if (this.url.includes("subPage=passwordEdit")) {
      setTimeout(() => {
        this.editPswordHandler();
      }, 200);
    };


    if (this.url.includes("subPage=bankingDetailsEdit")) {
      setTimeout(() => {
        this.editBankingHandler();
      }, 200);
    };


    if (this.url.includes("subPage=newsLetterEdit")) {
      setTimeout(() => {
        this.editNewsLetterSection();
      }, 200);
    };

    if (this.url.includes("subPage=addOrEditProfile")) {
      this.editProfileImage();
    };

    // myForm values check Start here
    // this.myForm.valueChanges.subscribe(val => {
    //   if (this.myForm.dirty) {
    //     Object.keys(this.myForm.controls).forEach(field => {
    //       this.myForm.value[`${field}`] = this.SafePipe.transform(this.myForm.value[`${field}`], 'html')
    //       console.log(this.myForm.value[`${field}`])
    //       if (this.myForm.value[`${field}`] == ' ' || this.myForm.value[`${field}`] == 'null') {
    //         this.myForm.value[`${field}`] = '';
    //       };
    //     });
    //   };
    // });
    // myForm values check End here

    // BankverbindungForm  values check Start here
    this.BankverbindungForm.valueChanges.subscribe(val => {
      if (this.BankverbindungForm.dirty) {
        Object.keys(this.BankverbindungForm.controls).forEach(field => {
          this.BankverbindungForm.value[`${field}`] = this.SafePipe.transform(this.BankverbindungForm.value[`${field}`], 'html')
          if (this.BankverbindungForm.value[`${field}`] == ' ' || this.BankverbindungForm.value[`${field}`] == 'null') {
            this.BankverbindungForm.value[`${field}`] = '';
          };
        });
      };
    });
    // BankverbindungForm  values check End here

    // newEmail form  values check Start here
    this.newEmail.valueChanges.subscribe(val => {
      if (this.newEmail.dirty) {
        Object.keys(this.newEmail.controls).forEach(field => {
          this.newEmail.value[`${field}`] = this.SafePipe.transform(this.newEmail.value[`${field}`], 'html')
          if (this.newEmail.value[`${field}`] == ' ' || this.newEmail.value[`${field}`] == 'null') {
            this.newEmail.value[`${field}`] = '';
          };
        });
      };
    });
    // newEmail form  values check End here

    // resetPasswordForm  values check Start here
    this.resetPasswordForm.valueChanges.subscribe(val => {
      if (this.resetPasswordForm.dirty) {
        Object.keys(this.resetPasswordForm.controls).forEach(field => {
          this.resetPasswordForm.value[`${field}`] = this.SafePipe.transform(this.resetPasswordForm.value[`${field}`], 'html')
          if (this.resetPasswordForm.value[`${field}`] == ' ' || this.resetPasswordForm.value[`${field}`] == 'null') {
            this.resetPasswordForm.value[`${field}`] = '';
          };
        });
      };
    });
    // resetPasswordForm  values check End here
  };


  MatchValidator = (group: FormGroup) => {
    let newpassword = group.get('newpassword').value
    let re_password = group.get('re_password').value
    return newpassword === re_password ? null : { matched: false }
  };

  getaddresses() {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.admin.getPortalnumber().subscribe(result => {
      this.loaderForScreen = false;
      if (result.data) {
        this.data = result.data;
        var kunden: any;
        kunden = result.data.filter(data => data.isPrimary == true);
        if (kunden.length > 0) {
          // this.kundennummer = kunden[0].Kundennummer;
        };
      };
    });
  };

  getCustomerNewsLetterStatus() {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.admin.getCustomerNewsLetter().subscribe((result: any) => {
      this.loaderForScreen = false;
      if (result.success) {
        this.checkEmailMarketingNewsletter = result.data.checkEmail
        this.kundennummer = result.data.kundennummer;
        this.userinfo = result.portalUserData;
        this.profile();
      };
    }, () => {
      this.loaderForScreen = false;
    });
  };

  profile() {
    // this.userinfo = JSON.parse(this.auth.getPortalUserInfo());
    this.Vorname = this.userinfo['Vorname'];
    this.Name = this.userinfo.Name;
    this.userN.emit(this.Vorname);
    this.userName.emit(this.Name);
    this.orginalEmail = this.userinfo.email;
    if (this.userinfo && this.userinfo['temp_email'] && (new Date(this.userinfo['tempEmailExpiry']) > new Date())) {
      this.email = this.userinfo['temp_email'];
      this.newEmail.get('newEmail').patchValue(this.email);
      this.cautionicon = true;
    } else {
      this.email = this.userinfo.email;
    };

    this.oldpassword = this.userinfo.oldpassword;
    this.newpassword = this.userinfo.newpassword;
    this.re_password = this.userinfo.re_password;

    this.profileinfo = this.userinfo.photoUrl;
    this.google = this.userinfo.google;
    this.facebook = this.userinfo.facebook;
    this.KtoInhaber = this.userinfo.KtoInhaber;
    this.IBAN = this.userinfo.IBAN;
    this.BIC = this.userinfo.BIC;
    this.bank_name = this.userinfo.bank_name;
    this.account_number = this.userinfo.account_number;
    this.bank_code = this.userinfo.bank_code;
    this.updatedBankDetails = {
      KtoInhaber: this.KtoInhaber,
      IBAN: this.IBAN,
      BIC: this.BIC,
      bank_name: this.bank_name,
      account_number: this.account_number,
      bank_code: this.bank_code
    }
    // this.BankverbindungForm.value.KtoInhaber = this.userinfo.KtoInhaber;
    // this.BankverbindungForm.value.IBAN = this.userinfo.IBAN;

    if (typeof this.profileinfo === 'string') {
      this.userProfileImage = this.profileinfo;
      this.pic.emit(this.userProfileImage);
    } else {
      if (this.userinfo?.photoUrl) {
        if (Object.keys(this.userinfo?.photoUrl).length) {
          this.getUserLogo(this.userinfo.photoUrl._id);
          this.pic.emit(this.userProfileImage);
        };
      };
    }
  };

  async getUserLogo(image_id) {
    let profile_image_b64 = await this.admin.getLogoImageById(image_id);
    if (profile_image_b64.success) {
      this.userProfileImage = `data:image/jpg;base64,${profile_image_b64.data.data}`;
      this.pic.emit(this.userProfileImage);
    }
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  save() {
    let profiledetails = new FormData();
    console.log(this.updatedBankDetails, 133)
    if (this.onFileSelected_file) {
      profiledetails.append('profile_image', this.onFileSelected_file);
    };

    if (this.Vorname) {
      profiledetails.append('Vorname', this.Vorname);
    };

    if (this.Name) {
      profiledetails.append('Name', this.Name);
      profiledetails.get('Name')
    };

    if (this.newpassword) {
      profiledetails.append('newpassword', this.newpassword);
    };

    if (this.BankverbindungForm.value.KtoInhaber) {
      profiledetails.append('KtoInhaber', this.BankverbindungForm.value.KtoInhaber);
    };

    if (this.BankverbindungForm.value.IBAN) {
      profiledetails.append('IBAN', this.BankverbindungForm.value.IBAN);
    };

    if (this.updatedBankDetails?.BIC) {
      profiledetails.append('BIC', this.updatedBankDetails?.BIC);
    };

    if (this.updatedBankDetails?.bank_name) {
      profiledetails.append('bank_name', this.updatedBankDetails?.bank_name);
    };

    if (this.updatedBankDetails?.account_number) {
      profiledetails.append('account_number', this.updatedBankDetails?.account_number);
    };

    if (this.updatedBankDetails?.bank_code) {
      profiledetails.append('bank_code', this.updatedBankDetails?.bank_code);
    };
     this.loaderForScreen = true;
     this.stopLoaderHere();
    this.admin.postData(profiledetails).subscribe((data: any) => {
      this.loaderForScreen = false;
      let newUserData = data.data[0];
      // if (Object.keys(newUserData.photoUrl).length ) {
      //   this.getUserLogo(newUserData.photoUrl._id);
      // }
      this.auth.changeUserDetails(newUserData);
      this.userinfo = newUserData;
      this.profile();
    })
  };

  emailMarketingChecked(event) {
    this.checkEmailMarketingNewsletter = event
    this.changeEmailMarketing = true
  };

  saveNewEmailMarketing() {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.admin.newsLetter({ status: this.checkEmailMarketingNewsletter, kontoId: this.kontoId }).subscribe((data: any) => {
      this.loaderForScreen = false;
      if (data.data == true) {
        this.subscribeText = true;
        this.unSubscribeText = false;
        this.getCustomerNewsLetterStatus();
        this.changeEmailMarketing = false;
        setTimeout(() => {
          this.subscribeText = false;
        }, 3000);
      }
    });
    this.subscribeText = false;
    this.unSubscribeText = true;
    setTimeout(() => {
      this.unSubscribeText = false;
    }, 3000);
  };

  editchangeBankdetails() {
    console.log(this.BankverbindungForm.controls.BIC.value, this.BankverbindungForm.controls.IBAN.value, this.BankverbindungForm.controls.KtoInhaber.value, this.BankverbindungForm.controls.bank_name.value);
    console.log(this.BankverbindungForm.controls.bank_code.value, this.BankverbindungForm.controls.account_number.value, this.BankverbindungForm.controls.KtoInhaber.value);

    if (this.BankverbindungForm.controls.BIC.value && this.BankverbindungForm.controls.IBAN.value && this.BankverbindungForm.controls.KtoInhaber.value && this.BankverbindungForm.controls.bank_name.value) {
      this.changeBankdetails = true;
    } else {
      this.changeBankdetails = false;
    };

    if (this.BankverbindungForm.controls.bank_code.value && this.BankverbindungForm.controls.account_number.value && this.BankverbindungForm.controls.KtoInhaber.value) {
      this.changeBankdetails = true;
    } else {
      this.changeBankdetails = false;
    };
  };

  saveNewBankdetails() {
    console.log(this.BankverbindungForm.controls.IBAN.value, this.BankverbindungForm.controls.IBAN.value !== "");
    if (this.BankverbindungForm.controls.IBAN.value && this.BankverbindungForm.controls.IBAN.value !== "") {
      this.editBankdetails = !this.editBankdetails;
      this.changeBankdetails = false;
      this.save();
      this.profile();
    } else {
      //this.profile();
    };
  };

  checkIban() {
    if (this.BankverbindungForm.value.IBAN?.length > 0) {
      let ibanValue = this.BankverbindungForm.value
      let params = {
        iban: ibanValue.IBAN
      };

      this.loaderForScreen = true;
      this.stopLoaderHere();
      this.bigPCService.checkIban(params).subscribe((resp) => {
        this.loaderForScreen = false;
        let sessionId = localStorage.getItem('sid');
        if (resp.success) {
          this.msg = '';
          this.msgg = '';
          this.checkIbanStatus = resp.data;
          this.BankverbindungForm.controls['BIC'].disable()
          this.BankverbindungForm.controls['BIC'].patchValue(this.checkIbanStatus.iban.bic);
          this.BankverbindungForm.controls['bank_code'].disable()
          this.BankverbindungForm.controls['bank_code'].patchValue(this.checkIbanStatus.iban.bank_code);
          this.BankverbindungForm.controls['account_number'].disable()
          this.BankverbindungForm.controls['account_number'].patchValue(this.checkIbanStatus.iban.account_number);
          this.BankverbindungForm.controls['bank_name'].disable()
          this.BankverbindungForm.controls['bank_name'].patchValue(this.checkIbanStatus.bankDetails.bank_code.bank_name);
          let bankDetails = this.BankverbindungForm.getRawValue();
          this.updatedBankDetails = bankDetails;
          this.smallPcService.setBankDetails(bankDetails, sessionId);
          console.log(12511);
          console.log(this.BankverbindungForm.controls.BIC.value, 5454);

          this.editchangeBankdetails();
        } else {
          this.BankverbindungForm.controls['BIC'].disable()
          this.BankverbindungForm.controls['BIC'].setValue('');
          this.BankverbindungForm.controls['bank_code'].enable()
          this.BankverbindungForm.controls['bank_code'].setValue('');
          this.BankverbindungForm.controls['account_number'].enable()
          this.BankverbindungForm.controls['account_number'].setValue('');
          this.BankverbindungForm.controls['bank_name'].disable()
          this.BankverbindungForm.controls['bank_name'].setValue('');
          let bankDetails = this.BankverbindungForm.getRawValue();
          this.smallPcService.setBankDetails(bankDetails, sessionId);
          this.msg = resp.data;
          this.msgg = '';
        }
      })
    }
  };

  ibanKeyUp(e) {
    if (e.target.value.length > 0) {
      this.BankverbindungForm.controls['account_number'].disable()
      this.BankverbindungForm.controls['bank_code'].disable()
    }
    else {
      this.BankverbindungForm.controls['account_number'].enable()
      this.BankverbindungForm.controls['account_number'].setValue('');
      this.BankverbindungForm.controls['bank_code'].enable()
      this.BankverbindungForm.controls['bank_code'].setValue('');
      this.BankverbindungForm.controls['BIC'].setValue('');
      this.BankverbindungForm.controls['bank_name'].setValue('');
    }

  };

  checkAccount() {
    if (this.BankverbindungForm.value.account_number != ''
      && this.BankverbindungForm.value.account_number != null
      && this.BankverbindungForm.value.bank_code != ''
      && this.BankverbindungForm.value.bank_code != null) {
      let account = this.BankverbindungForm.value
      let params = {
        account_number: account.account_number,
        bank_code: account.bank_code
      }
      this.bigPCService.getIbanByDetails(params).subscribe((resp) => {
        if (resp.success && resp.data.error != "Bankcode unknown") {
          this.msgg = '';
          this.msg = '';
          if (this.BankverbindungForm.value.account_number != ''
            && this.BankverbindungForm.value.account_number != null
            && this.BankverbindungForm.value.bank_code != ''
            && this.BankverbindungForm.value.bank_code != null) {
            this.BankverbindungForm.controls['IBAN'].disable()
            this.BankverbindungForm.controls['IBAN'].setValue(resp.data.iban);
            this.BankverbindungForm.controls['BIC'].disable()
            this.BankverbindungForm.controls['BIC'].setValue(resp.data.bic);
            this.BankverbindungForm.controls['bank_name'].disable()
            this.BankverbindungForm.controls['bank_name'].setValue(resp.data.bankDetails.bank_code.bank_name);
            let bankDetails = this.BankverbindungForm.getRawValue();
            this.updatedBankDetails = bankDetails;
          }
        }
        else {
          this.msgg = resp.data.error
          this.msg = '';
          this.BankverbindungForm.controls['IBAN'].enable()
          this.BankverbindungForm.controls['IBAN'].setValue('');
          this.BankverbindungForm.controls['BIC'].setValue('');
          this.BankverbindungForm.controls['bank_name'].setValue('');

        }
      })
    }
  };

  accountKeyUp(e) {
    if (e.target.value.length > 0 && e.target.value != '') {
      this.BankverbindungForm.controls['IBAN'].disable()
    } else {
      this.BankverbindungForm.controls['account_number'].setValue('');
      this.BankverbindungForm.controls['IBAN'].enable();
      this.BankverbindungForm.controls['IBAN'].setValue('');
      this.BankverbindungForm.controls['BIC'].setValue('');
      this.BankverbindungForm.controls['bank_name'].setValue('');
      this.BankverbindungForm.controls['bank_code'].setValue('');
    };
  };

  validate(e) {
    var regex = new RegExp("[a-zA-Z0-9]");
    var key = e.keyCode || e.which;
    key = String.fromCharCode(key);
    if (!regex.test(key)) {
      e.returnValue = false;
      if (e.preventDefault) {
        e.preventDefault();
      };
    };
  };

  submit(value) {
    this.showForm = false
    let checkboxname = value.value.name;
    let checkboxphoto = value.value.photo;
    let existeduserinfo = JSON.parse(this.auth.getPortalUserInfo());

    if (checkboxname == true) {
      this.newName = this.googlename
    } else {
      this.newName = existeduserinfo.name
    };

    if (checkboxphoto == true) {
      this.newPhoto = this.googlephotourl
    } else {
      this.newPhoto = existeduserinfo.photoUrl
    };


    let data = {
      name: this.newName,
      photoUrl: this.newPhoto,
      domainname: this.domainname,
      portaluser_id: this.portaluser_id,
    };

    this.auth.updatePoralDataGoogle(data).subscribe((data: any) => {
      if (data.success == true) {
        let updateddoc = data.data.value;
        let existeduserinfo = JSON.parse(this.auth.getPortalUserInfo());
        let newUserInfo = { ...existeduserinfo, name: updateddoc.name, photoUrl: updateddoc.photoUrl }
        this.auth.changeUserDetails(newUserInfo);
        this.profile();
      };
    });
  };

  submitfb(value) {
    this.showFormFacebook = false
    let checkboxname = value.value.name;
    let checkboxphoto = value.value.photo;
    let existeduserinfo = JSON.parse(this.auth.getPortalUserInfo());

    if (checkboxname == true) {
      this.newName = this.facebookname
    } else {
      this.newNamefb = existeduserinfo.name
    };

    if (checkboxphoto == true) {
      this.newPhoto = this.facebookphotourl
    } else {
      this.newPhotofb = existeduserinfo.photoUrl
    };

    let data = {
      name: this.newName,
      photoUrl: this.newPhoto,
      domainname: this.domainname,
      portaluser_id: this.portaluser_id,
    };

    this.auth.updatePoralDataFacebook(data).subscribe((data: any) => {
      if (data.success == true) {
        let updateddoc = data.data.value;
        let existeduserinfo = JSON.parse(this.auth.getPortalUserInfo());
        let newUserInfo = { ...existeduserinfo, name: updateddoc.name, photoUrl: updateddoc.photoUrl }
        this.auth.changeUserDetails(newUserInfo);
        this.profile();
      };
    });
  };

  cancel() {
    this.showForm = false;
  };

  cancelfb() {
    this.showFormFacebook = false;

  };

  openContainer() {
    this.showForm = true;
    let data = {
      domainname: this.domainname,
      portaluser_id: this.portaluser_id
    };

    this.auth.getGoogleUserDetails(data).subscribe((data: any) => {
      this.googlename = data.googleUserDetails[0].name;
      this.googlephotourl = data.googleUserDetails[0].photoUrl;
    });

  };

  openContainerfb() {
    this.showFormFacebook = true;
    let data = {
      domainname: this.domainname,
      portaluser_id: this.portaluser_id
    };

    this.auth.getFacebookUserDetails(data).subscribe((data: any) => {
      this.facebookname = data.facebookUserDetails[0].name;
      this.facebookphotourl = data.facebookUserDetails[0].photoUrl;
    });
  };

  closeContainer() {
    this.showForm = false;
  };

  closeContainerfb() {
    this.showFormFacebook = false;
  };

  onFileSelected(event) {
    this.onFileSelected_file = <File>event.target.files[0];
    this.filename = event.target.files[0].name;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url
    reader.onload = ((event: any) => { // called once readAsDataURL is completed
      this.userProfileImage = event.target.result;
    });
    this.save();
  };

  user(id) {
    const dialogRef = this.dialog.open(EditComponent,
      {
        width: '500px',
        disableClose: true,
        data: { id: id, colors: this.colors }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result.data == true) {
        this.save();
      } else {
        this.profile();
      };
    });
  };

  delelteonlineaccount() {
    const dialogRef = this.dialog.open(
      DeleteOnlineaccountComponent,
      {
        width: '500px',
        disableClose: true,
      }
    );
    dialogRef.componentInstance.colors = this.colors;
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'proceed') {
        this.loaderForScreen = true;
        this.stopLoaderHere();
        this.admin.deleteUserDetails({ _id: this.portaluser_id, kundenKontoId: this.kontoId, domain: this.hostname }).subscribe((data: any) => {
          // this.getPortalAccounts();
          this.loaderForScreen = false;
          if (data && data.success) {
            let sessionId = localStorage.getItem('sid');
            this.sessionData = this.localstorage.getSessionData(sessionId);
            if (!environment.testSite_or_thirdPartySites) {
              let url = environment.pricecalc;
              window.location.replace(url + "#&Domainname=" + this.hostname);
            } else {
              this.smallpclink = this.sessionData?.priceCalcUrl ? this.sessionData.priceCalcUrl : location.host + location.pathname;
              let url = `https://${this.smallpclink}`;
              window.location.replace(url);
            };
          };
        });
      };
    });
  };

  validateEmailUpdate1() {

    if (!this.newEmail.get('newEmail').value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ig)) {
      this.emailValidate = true;
      return
    } else {
      this.emailValidate = false;
    }
    if (this.newEmail.get('newEmail').value != this.newEmail.get('repeatNewEmail').value) {
      this.repeatEmailValidate = true;
    }
    this.newEmail.get("repeatNewEmail").valueChanges.subscribe((data) => {

      if (!this.newEmail.get('newEmail').touched && this.newEmail.get('newEmail').value != this.newEmail.get('repeatNewEmail').value) {
        this.repeatEmailValidate = true;
      } else {
        this.repeatEmailValidate = false;
      }
    })
    this.newEmail.get("newEmail").valueChanges.subscribe((data) => {
      if (!this.emailValidate && this.newEmail.get('newEmail').value && !this.newEmail.get('newEmail').value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ig)) {
        this.emailValidate = true;
        // this.newEmail.get("repeatNewEmail").invalid
      } else {
        this.emailValidate = false;
      }
    });
    return !this.emailValidate && !this.repeatEmailValidate ? true : false
  };

  validateEmailUpdate() {
    this.newEmail.get("repeatNewEmail").valueChanges.subscribe((data) => {
      this.repeatEmailValidate = false;
    });
    this.newEmail.get("newEmail").valueChanges.subscribe((data) => {
      this.emailValidate = false;
    });

    if (!this.newEmail.get('newEmail').value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ig)) {
      this.emailValidate = true;
      return
    };

    if (this.newEmail.get('newEmail').value != this.newEmail.get('repeatNewEmail').value) {
      this.repeatEmailValidate = true;
    };

    return !this.emailValidate && !this.repeatEmailValidate ? true : false
  };

  validateSecondEmailUpdate(value): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && value.repeatNewEmail != value.newEmail) {
        return { 'repeatEmail': true };
      }
      return null;
    };
  };

  editEmailHandler() {
    this.editEmail = !this.editEmail;
    let keyName = "&subPage=emilEdit";
    this.addKeyToUrl(this.pageName, keyName);


    if (this.userinfo && ('temp_email' in this.userinfo) && (new Date(this.userinfo['tempEmailExpiry']) > new Date())) {
      this.newEmail.get('newEmail').patchValue(this.userinfo['temp_email'])
      this.newEmail.get('repeatNewEmail').patchValue(this.userinfo['temp_email'])
    } else {
      this.newEmail.reset();
    }
  };

  editPswordHandler() {
    this.editPassword = !this.editPassword;
    let keyName = "&subPage=passwordEdit";
    this.addKeyToUrl(this.pageName, keyName);
  };

  editBankingHandler() {
    this.editBankdetails = !this.editBankdetails;
    let keyName = "&subPage=bankingDetailsEdit";
    this.addKeyToUrl(this.pageName, keyName);
  };

  editNewsLetterSection() {
    this.editNewsletter = !this.editNewsletter;

    let keyName = "&subPage=newsLetterEdit";
    this.addKeyToUrl(this.pageName, keyName)
  };

  cancelEmailBtn() {
    this.editEmail = !this.editEmail;
    let keyName = "&subPage=emilEdit";
    this.removeKeyFromUrl(this.pageName, keyName);
  };

  cancelPasswordSection() {
    let keyName = "&subPage=passwordEdit";

    this.removeKeyFromUrl(this.pageName, keyName);
    this.editPassword = !this.editPassword;
    this.resetPasswordForm.reset();
  };

  cancelBankingDetailsSection() {
    this.editBankdetails = !this.editBankdetails;
    this.profile();
    let keyName = "&subPage=bankingDetailsEdit";

    this.removeKeyFromUrl(this.pageName, keyName);

  };


  canceNewsletterBtn() {
    this.editNewsletter = !this.editNewsletter;
    let keyName = "&subPage=newsLetterEdit";
    this.getCustomerNewsLetterStatus();
    this.removeKeyFromUrl(this.pageName, keyName);
  };

  addKeyToUrl(page, key) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;

    if (newUrl.includes(page) && !url.includes(key)) {
      newUrl = `${newUrl}${key}`;
      window.history.replaceState('', document.title, newUrl);
    };
  }

  removeKeyFromUrl(page, key) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;

    if (newUrl.includes(page)) {
      newUrl = newUrl.replace(key, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  async saveEmail() {
    // let userData = JSON.parse(this.auth.getPortalUserInfo());
    Object.keys(this.newEmail.controls).forEach(field => {
      if (this.newEmail.value[`${field}`] == '' || this.newEmail.value[`${field}`] == undefined) {
        this.newEmail.controls[`${field}`].setValue('');
        this.newEmail.controls[`${field}`].markAsDirty();
      };
    });

    let dateCheck = true;
    if (this.userinfo['temp_email_verified'] === false) {
      dateCheck = (new Date(this.userinfo['tempEmailExpiry']) < new Date()) || (this.newEmail.get('newEmail').value != this.email)
    }
    if (this.validateEmailUpdate() && this.newEmail.valid && (this.newEmail.get('newEmail').value != this.orginalEmail) && dateCheck) {
      //  submit new email to verify current email
      this.url = this._window.location.href;/*testcalc*/
      var params = new URLSearchParams(this.url);
      let sessionId = localStorage.getItem('sid');
      this.sessionData = await this.smallPcService.getSessionData(sessionId);
      this.sessionData = this.localstorage.getSessionData(sessionId);

      this.localOptions = this.sessionData?.localOptions;
      let language = localStorage.getItem('language');
      if (!environment.testSite_or_thirdPartySites) {
        this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
      } else {
        this.hostname = this._window.location.hostname
      };

      let queryParams = {
        Lieferort: this.sessionData['postalCode']['plzId'],
        domainname: this.hostname,
        Liter: this.sessionData['quantity'],
        Product: this.sessionData['product']['_id'],
        view: 'bigpc',
        url: this.url
      };

      queryParams['domainLink'] = this.smallPcService.getMatchedDomainLink();
      this.loaderForScreen = true;
      this.stopLoaderHere();
      this.admin.updateEmail({ newEmail: this.newEmail.get('newEmail').value, queryParams: queryParams, language: language }).subscribe(result => {
        this.loaderForScreen = false;
        if (result.success) {
          this.cautionicon = true;
          this.email = this.newEmail.get('newEmail').value;
          this.editEmail = false;
          this.portaluser_data = result.portalUserData;
          this.portaluser_data['temp_email'] = this.newEmail.get('newEmail').value;
          this.userinfo = this.portaluser_data;
          localStorage.setItem('portal_user', JSON.stringify(this.portaluser_data));

          let keyName = "&subPage=passwordEdit";
          this.removeKeyFromUrl(this.pageName, keyName);
        };

        if (result.error == "mail exists") {
          this.editEmail = false;
          this.showSnackbar('Mail already exists');
        };
      }, error => {
        console.log(error);
      });
    };
  };

  savePassword() {
    Object.keys(this.resetPasswordForm.controls).forEach(field => {
      if (this.resetPasswordForm.value[`${field}`] == '' || this.resetPasswordForm.value[`${field}`] == undefined) {
        this.resetPasswordForm.controls[`${field}`].setValue('');
        this.resetPasswordForm.controls[`${field}`].markAsDirty();
      };
    });

    if (this.resetPasswordForm.valid) {
      let queryParams = this.commonService.getUrlParameters();
      let language = localStorage.getItem('language')
      queryParams['domainLink'] = this.smallPcService.getMatchedDomainLink();
      let localUserData = JSON.parse(localStorage.getItem('portal_user'));
      if (this.newEmail.value.newEmail == '' || localUserData.temp_email != this.orginalEmail) {
        this.newEmail.value.newEmail = localUserData.email;
      };

      // console.log("email from form group", this.newEmail.value);
      let passwordInfo = {
        newEmail: this.newEmail.value,
        resetPasswordForm: this.resetPasswordForm.value,
        queryParams: queryParams,
        language: language
      };
      this.loaderForScreen = true;
      this.stopLoaderHere();
      this.admin.updatePassword(passwordInfo).subscribe((result: any) => {
        this.loaderForScreen = false;
        if (result.success) {
          this.showSnackbar('Password Updated Successfully.');
          this.editPassword = false;
          this.resetPasswordForm.reset()
        } else {
          this.loginErrorMessage = result.error;
        };
        this.user_not_found = true;
        setTimeout(() => {
          this.user_not_found = false;
        }, 5000)
      });
    };
  };

  showSnackbar(msg: string) {
    this.snackbarMsg = msg;
    this.showSnack = true;
    setTimeout(() => {
      this.showSnack = false;
    }, 3000);
  };

  loaderTime: any = 10000;
  stopLoaderHere() {
    setTimeout(() => {
      this.loaderForScreen = false;
    }, this.loaderTime);
  };

  editProfileImage() {
    let logoImgB64 = '';
    if (this.userProfileImage) {
      logoImgB64 = this.userProfileImage;
    };
    this.addKeyInUrl('&subPage=addOrEditProfile');
    const dialog = this.dialog.open(
      UploadImageComponent,
      {
        panelClass: 'my-full-screen-dialog',
        width: 'max-content',
        disableClose: true,
        data: logoImgB64
      }
    );
    dialog.componentInstance.colors = this.colors;
    dialog.componentInstance.languageData = this.languageData;
    dialog.afterClosed().subscribe((data) => {
      this.removeKeyInUrl('&subPage=addOrEditProfile');
      if (data && data.delete) {
        // delete the profile image
        this.deleteProfileImage(this.userinfo.photoUrl._id);
        Object.assign(data, { image: "" });
        this.onFileSelected_file = data.image;
      } else if (data && data.image) {
        // upload image
        this.onFileSelected_file = data.image;
        this.save();
      };
    });
  };


  addKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    window.history.pushState("", document.title, url);
    if (!url.includes(subPageName)) {
      newUrl = `${newUrl}${subPageName}`;
      window.history.replaceState('', document.title, newUrl);
    };
  };


  removeKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    if (newUrl.includes(subPageName)) {
      newUrl = newUrl.replace(subPageName, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  deleteProfileImage(fileId) {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.admin.deleteprofileImage(fileId).subscribe((data => {
      this.loaderForScreen = false;
      if (data && data.success) {
        // get profile information 
        let existeduserinfo = JSON.parse(this.auth.getPortalUserInfo());
        let newUserInfo = { ...existeduserinfo };
        delete newUserInfo.photoUrl;
        this.auth.changeUserDetails(newUserInfo);
        this.userProfileImage = null;
        this.pic.emit(null);
      }
    }));
  };
};







