

import { Component, Inject, OnInit,} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';


@Component({
  selector: 'app-bestellen-popup',
  templateUrl: './bestellen-popup.component.html',
  styleUrls: ['./bestellen-popup.component.css']
})
export class BestellenPopupComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  
constructor(public dialog: MatDialog,private langService:LanguageService, public dialogRef: MatDialogRef<BestellenPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
      let langData = this.langService.getLocalLanguageData();
 
  this.languageData = langData['web-pc'] || {};
  this.langService.onLanguageChange.subscribe((data: any) => {
    this.languageData = data['web-pc'] || {};
  }); 
  }
  
  colors: any;
  offernames:any = [];
  
  ngOnInit() {
    this.offernames = this.data
  }

  contiuneu(){
    this.dialogRef.close('Bestellen');
  }
}


