import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';
import { SmallPcService } from '../../Services/small-pc.service';
import { InfopopupComponent } from 'app/infopopup/infopopup.component';
import { AlternativeTextInfoComponent } from 'app/alternative-text-info/alternative-text-info.component';

@Component({
  selector: 'app-pricedetails',
  templateUrl: './pricedetails.component.html',
  styleUrls: ['./pricedetails.component.css']
})
export class PricedetailsComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  volueme: any;
  weight: any;
  currency: any;
  currency_symbol: any;
  currency_short: any;
  volume_short: any;
  weight_short: any;
  vatSettings: any;
  alternativeMwst = 0;
  alternativeWarenpries = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private langService: LanguageService, public dialogRef: MatDialogRef<PricedetailsComponent>, private SmallPcService: SmallPcService) {

    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }
  // colors: any;

  offer: any;
  otherdata: any;
  hostname: any;

  ngOnInit() {
    this.calculateAlternative(this.offer);
    this.vatSettings = this.data.vatSettings;
  };

  calculateAlternative(offer) {
    this.alternativeMwst = 0;
    this.alternativeWarenpries = 0;
    if (offer.alternativePrices) {
      for (let item of offer.alternativePrices) {
        this.alternativeMwst = Number(this.alternativeMwst) + Number(item.tax);
        this.alternativeWarenpries = Number(this.alternativeWarenpries) + Number(item.amount);
      }
      if (offer.ratePaymentDetails) {
        this.alternativeWarenpries = Number(this.alternativeWarenpries) + Number(this.alternativeMwst) + Number(offer.ratePaymentDetails.GesamtpreisBrutto);
      } else {
        this.alternativeWarenpries = Number(this.alternativeWarenpries) + Number(this.alternativeMwst) + Number(offer.tax_for_finalprice);
      }
    }
  }

  specialtextpopup1(event) {
    // this.specialtextpopup1Event.emit(event)
    let dialogRef = this.dialog.open(InfopopupComponent, { panelClass: 'my-full-screen-dialog', width: '500px', });
    // dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.infotext = event;
  }

  alternativePopup(event) {
    let dialogRef = this.dialog.open(AlternativeTextInfoComponent, { panelClass: 'my-full-screen-dialog', width: '500px', });
    // dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.infotext = event;
  }

  onclick() {
    this.dialogRef.close();
  }
}
