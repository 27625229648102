<div class="aupris_main">
  <div class="card" >
    <div class="aurow">
      <div class="aucol-md-1"><img [src]="'assets/ok.svg' | assetsUrl"></div>
      <div class="aucol-md-11" style="text-align: left;">Ihre Bestellung war erfolgreich!</div>
    </div>
  </div>
  <div class="card" >
      <div class="aurow " > 
      <div class="aucol-md-1">      <img [src]="'assets/mail.svg' | assetsUrl"></div>
      <div class="aucol-md-11" style="text-align: left;">
        Die Bestellbestätigung wird Ihnen per Email zugestellt.Bitte überprufen Sie lhren Posteingang und gegebenenfalls auch lhren Spam Ordner. Sollten Sie innerhalb der nächsten 24 Stunden keine Email erhalten, melden Sie sich bitte bei unserem Team!</div>
    </div>
  </div>
</div>
