import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-kundconform-popup',
  templateUrl: './kundconform-popup.component.html',
  styleUrls: ['./kundconform-popup.component.css']
})
export class KundconformPopupComponent implements OnInit {
  colors: any;
  languageData: any ={};
  language: string = localStorage.getItem('appLanguage') || 'GEN';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any ,
    public dialog :MatDialogRef<KundconformPopupComponent>,private langService: LanguageService) {
    this.colors =data;
  
    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
   }

  ngOnInit() {
  }
  confirm(){
this.dialog.close({edit:true});
  }
  cancel(){
    this.dialog.close({edit:false});
  }
}
