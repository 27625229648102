<div class="aupris_main scrolltotopprogress tableResp stage">
  <div class="card" style="position: sticky; top: 1.6%; z-index: 1;padding-top: 0;margin-top: 0;" *ngIf="listData">
    <div style="padding: 13px 0px 6px 28px;box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);">
      <ul style="display: flex;padding-left:0;" class="aurow headers">
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1  hidden-xs">{{languageData['Order'].Var_time || 'Datum/Uhrzeit'}}
        </li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1 hidden-sm hidden-md  hidden-lg showCursor"
          [title]="languageData['Order'].Var_time || 'Datum/Uhrzeit'">
          {{languageData['Order'].Var_time |
          truncate:[3,'...'] || 'Datum/Uhrzeit'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1  hidden-xs">
          {{languageData['Order'].Var_Status || 'Status'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1 hidden-sm hidden-md  hidden-lg showCursor"
          [title]="languageData['Order'].Var_Status || 'Status'">
          {{languageData['Order'].Var_Status | truncate:[3,'...'] || 'Status'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1">ID</li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1  hidden-xs">{{languageData['Order'].Var_Produkt || 'Produkt'}}
        </li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1 hidden-sm hidden-md  hidden-lg showCursor"
          [title]="languageData['Order'].Var_Produkt || 'Produkt'">{{languageData['Order'].Var_Produkt |
          truncate:[3,'...'] || 'Produkt'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1  hidden-xs">{{languageData['Order'].Var_Menge || 'Menge'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1 hidden-sm hidden-md  hidden-lg showCursor"
          [title]="languageData['Order'].Var_Menge || 'Menge'">{{languageData['Order'].Var_Menge |
          truncate:[3,'...'] || 'Menge'}}</li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-2  hidden-xs">{{languageData['Order'].Var_Stuckpreis ||
          'Stückpreis'}}</li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-2  hidden-sm hidden-md  hidden-lg showCursor" [title]="languageData['Order'].Var_Stuckpreis ||
        'Stückpreis'">
          {{languageData['Order'].Var_Stuckpreis |
          truncate:[5,'...'] || 'Stückpreis'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-2  hidden-xs">{{languageData['Bestelloptionen'].Var_Gesamtpreis ||
          'Gesamtpreis'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-2  hidden-sm hidden-md  hidden-lg showCursor" [title]="languageData['Bestelloptionen'].Var_Gesamtpreis ||
        'Gesamtpreis'">{{languageData['Bestelloptionen'].Var_Gesamtpreis |
          truncate:[4,'...'] || 'Gesamtpreis'}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1"> </li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1"> </li>

      </ul>
    </div>
  </div>

  <div style="padding-top: 16px;margin-top: 1px;min-height: 540px;" class="card  list" *ngIf="listData">
    <ul class="aurow" *ngFor="let item of bestellung; let i = index;trackBy :onScroll;"
      style="padding-left: 0px;text-align:left">
      <div class="aucol-md-12 aucol-sm-12 aucol-xs-12">
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1" *ngIf="item.data[0].priceDetails">{{item.data[0].timeStamp |
          timezone:timeZoneFormat}}
        </li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1" *ngIf="!item.data[0].priceDetails">
          {{item.data[0].timeStamp | timezone:timeZoneFormat}}

        </li>

        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1"></li>

        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1  hidden-xs status" *ngIf="item.data[0].priceDetails">
          {{item.data[0].shotOrderId
          ||item.data[0].shortOrderId}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1  hidden-xs status" *ngIf="!item.data[0].priceDetails">
          {{item.data[0].id}}</li>

        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1  hidden-lg hidden-md hidden-sm showCursor"
          *ngIf="item.data[0].priceDetails" [title]="item.data[0].shotOrderId">
          {{item.data[0].shotOrderId |
          truncate:[4,'...']}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1  hidden-lg hidden-md hidden-sm showCursor"
          *ngIf="!item.data[0].priceDetails" [title]="item.data[0].id">
          {{item.data[0].id |
          truncate:[4,'...']}}</li>

        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1 hidden-xs" *ngIf="item.data[0].priceDetails">
          {{item.data[0].products[0].productName}}</li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1 hidden-xs" *ngIf="!item.data[0].priceDetails">
          {{item.data[0].productName}}</li>

        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1 hidden-lg hidden-md hidden-sm showCursor"
          [title]="item.data[0].priceDetails.name" *ngIf="item.data[0].priceDetails">
          {{item.data[0].products[0].productName |
          truncate:[5,'...'] }}</li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-1 hidden-lg hidden-md hidden-sm showCursor"
          [title]="item.data[0].productName" *ngIf="!item.data[0].priceDetails">{{item.data[0].productName |
          truncate:[5,'...'] }}</li>

        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1" *ngIf="item.data[0].priceDetails">
          {{item.data[0].products[0].orderQuantity |
          replaced}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1" *ngIf="!item.data[0].priceDetails">{{item.data[0].amount |
          replaced}}</li>

        <li class="aucol-md-2 aucol-sm-2 aucol-xs-2" *ngIf="item.data[0].priceDetails">
          {{item.data[0].products[0].productUnitPrice}}
          ({{item.data[0].currencySymbol}}/{{item.data[0].products[0].productPriceFactor |
          replaced}}{{item.data[0].products[0].productUnitName}})
        </li>
        <li class="aucol-md-2 aucol-sm-2 aucol-xs-2" *ngIf="!item.data[0].priceDetails">{{item.data[0].productUnitPrice
          | replace}}</li>

        <li class="aucol-md-1 aucol-sm-1 aucol-xs-2" *ngIf="item.data[0].priceDetails">{{item.data[0].priceDetails.gross
          |
          replace}}</li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-2" *ngIf="!item.data[0].priceDetails">{{item.data[0].netPrice |
          replace}}</li>

        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1" style="text-align: right;">
          <span (click)="invoice(item.data[0])" style="cursor: pointer;">
            <svg id="Layer_1" enable-background="new 0 0 480.013 480.013" height="18" width="18"
              viewBox="0 0 480.013 480.013" xmlns="http://www.w3.org/2000/svg">
              <path [ngStyle]="{'fill': (item?.data[0].invoicePdf?._id) ? ('var(--primaryColour)') : 'grey' }"
                d="m238.068 322.013c0 57.897 47.103 105 105 105s105-47.103 105-105c0-37.745-20.022-70.896-50-89.406v-131.594c0-2.61-1.256-4.921-3.19-6.382-99.397-99.397-92.966-93.788-96.367-94.462-1.189-.236 11.366-.156-234.566-.156-17.645 0-32 14.355-32 32v416c0 17.645 14.355 32 32 32h302c17.645 0 32-14.355 32-32 0-4.418-3.582-8-8-8s-8 3.582-8 8c0 8.822-7.178 16-16 16h-302c-8.822 0-16-7.178-16-16v-416c0-8.822 7.178-16 16-16h225v61c0 17.645 14.355 32 32 32h61.123v115.515c-13.043-5.236-27.215-7.882-42-7.469v-57.045c0-13.233-10.767-24-24-24h-202c-13.234 0-24 10.767-24 24v102c0 13.233 10.766 24 24 24h105c4.418 0 8-3.582 8-8s-3.582-8-8-8h-36v-51h72c4.418 0 8-3.582 8-8v-59h53c4.411 0 8 3.589 8 8v58.727c-48.858 8.966-86 51.861-86 103.272zm82.877-229c-8.822 0-16-7.178-16-16v-49.686l65.687 65.687h-49.687zm-206.877 59h53v51h-61v-43c0-4.411 3.589-8 8-8zm53 118h-53c-4.411 0-8-3.589-8-8v-43h61zm80-67h-64v-51h64zm185 119c0 49.075-39.925 89-89 89s-89-39.925-89-89 39.925-89 89-89 89 39.925 89 89zm-81 20v-3.237c0-3.518-2.256-6.586-5.614-7.636l-9.544-2.982c-10.074-3.148-16.842-12.354-16.842-22.907v-3.237c0-10.429 6.689-19.321 16-22.624v-3.376c0-4.418 3.582-8 8-8s8 3.582 8 8v3.376c9.311 3.303 16 12.195 16 22.624 0 4.418-3.582 8-8 8s-8-3.582-8-8c0-4.411-3.589-8-8-8s-8 3.589-8 8v3.237c0 3.518 2.256 6.586 5.614 7.636l9.544 2.982c10.074 3.148 16.842 12.354 16.842 22.907v3.237c0 11.519-8.159 21.165-19 23.472v2.528c0 4.418-3.582 8-8 8s-8-3.582-8-8v-4.68c-7.713-3.996-13-12.05-13-21.32 0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.411 3.589 8 8 8s8-3.589 8-8zm-261-12c0-4.418 3.582-8 8-8h78c4.418 0 8 3.582 8 8s-3.582 8-8 8h-78c-4.418 0-8-3.582-8-8zm0 60c0-4.418 3.582-8 8-8h98c4.418 0 8 3.582 8 8s-3.582 8-8 8h-98c-4.418 0-8-3.582-8-8z" />
            </svg>
          </span>
        </li>
        <li class="aucol-md-1 aucol-sm-1 aucol-xs-1">
          <span (click)="viewDetails(i)" style="cursor: pointer;" class="respscreens">
            <span [innerHTML]="search"></span>
          </span>
        </li>
      </div>
      <div class="aucol-md-12 aucol-sm-12 aucol-xs-12" *ngIf="showOrderDetails && selectedIndex == i" class="infoSec">
        <div class="aurow" style="padding-left: 20px;">
          <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-12" *ngIf="item.data[0].priceDetails">
            <div class="aurow mb25" *ngFor="let item1 of item.data; index as i">
              <div class="titlecss">{{languageData['Bestellubersicht'].Var_Lieferandressen || 'Lieferandressen'}} {{i +
                1}}:
              </div>
              <div>
                <span>{{item1?.deliveryAddress?.firstName}} {{item1?.deliveryAddress?.lastName}}</span>
              </div>
              <div *ngIf="item?.deliveryAddress?.companyName">
                <span>{{item1?.deliveryAddress?.companyName}}</span>
              </div>
              <div *ngIf="item?.deliveryAddress?.Addresszusatz">
                <span>{{item1?.deliveryAddress?.Addresszusatz}}</span>
              </div>
              <div>
                <span>{{item1?.deliveryAddress?.streetName}} {{item1?.deliveryAddress?.houseNo}}</span>
              </div>
              <div>
                <span>{{item1?.deliveryAddress?.zipCode}} {{item1?.deliveryAddress?.city}}</span>
              </div>
              <div>
                <span class="mr5">
                  <span [innerHTML]="phoone"></span>
                </span>
                <span>{{item1?.deliveryAddress?.phoneNumber}}</span>
              </div>
              <div>
                <span class="mr5">
                  <span [innerHTML]="ordermail"></span>
                </span>
                <span>{{item1?.deliveryAddress?.eMail}}</span>
              </div>
              <div class="mt10" *ngIf="item1?.deliveryAddress?.comments">
                <div style="text-decoration: underline;">
                  {{languageData['Bestellubersicht'].Var_Anmerkung || 'Anmerkung'}}:</div>
                <div>{{item1?.deliveryAddress?.comments}}</div>
              </div>
            </div>


          </div>
          <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-12">
            <div class="titlecss">
              {{languageData['Bestellubersicht'].Var_Rechnungsadresse || 'Rechnungsadresse'}}:
              <!-- {{languageData['Bestellubersicht'].Var_Lieferandressen || 'Lieferandressen'}}
              {{i+2}}: -->
            </div>
            <div>
              <span>{{item?.data[0].billingAddress?.firstName}} {{item?.data[0].billingAddress?.lastName}}</span>
            </div>
            <div>
              <span>{{item?.data[0].billingAddress?.companyName}}</span>
            </div>
            <div><span>{{item?.data[0].billingAddress?.eMail}}</span>
            </div>
            <div>
              <span>{{item?.data[0].billingAddress?.streetName}} {{item?.data[0].billingAddress?.houseNo}}</span>
            </div>
            <div>
              <span>{{item?.data[0].billingAddress?.zipCode}} {{item?.data[0].billingAddress?.city}}</span>
            </div>
            <div>
              <span class="mr5">
                <span [innerHTML]="phoone"></span>
              </span>
              <span>{{item?.data[0].billingAddress?.phoneNumber}}</span>
            </div>
          </div>

          <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-12" *ngIf="item.data[0].options">
            <div class="titlecss" style="margin-left:-16px">{{languageData['Bestellubersicht'].Var_bestelldetails ||
              'Bestelldetails'}}:</div>
            <div class="aurow" *ngFor="let option of item.data[0].options">
              <div>
                <span>{{option?.optionSetName}}</span>: <span> {{option?.optionName}}</span>
                <div *ngIf="option?.optionSetName == 'Liefertermin' && option?.optionName !== 'Wunschtermin'">
                  <span class=" control1 mfont12">{{languageData['Bestelloptionen'].Var_firstDeliveryDate_text ||
                    'Frühester Liefertermin'}}: </span>
                  <span class="mfont mfont12">{{item?.data[0].earliestDeliveryDate | date:"dd.MM.yyyy"}}</span>
                </div>
                <div *ngIf="option?.optionSetName == 'Liefertermin' && option?.optionName !== 'Wunschtermin'">
                  <span class=" control1 mfont12">{{languageData['Bestelloptionen'].Var_lastDeliveryDate_text ||
                    'Spätester Liefertermin'}}: </span>
                  <span class="mfont mfont12">{{item.data[0].latestDeliveryDate | date:"dd.MM.yyyy"}}</span>
                </div>
                <div *ngIf="option?.optionSetName == 'Liefertermin' && option?.optionName == 'Wunschtermin'">
                  <span class=" control1 mfont12">{{languageData['Bestelloptionen'].Var_firstDeliveryDate_text ||
                    'Frühester Liefertermin'}}: </span>
                  <span class="mfont mfont12">{{item.data[0].wunschterminFrom | date:"dd.MM.yyyy"}}</span>
                </div>
                <div *ngIf="option?.optionSetName == 'Liefertermin' && option?.optionName == 'Wunschtermin'">
                  <span class=" control1 mfont12">{{languageData['Bestelloptionen'].Var_lastDeliveryDate_text ||
                    'Spätester Liefertermin'}}: </span>
                  <span class="mfont mfont12">{{item.data[0].wunschterminTo | date:"dd.MM.yyyy"}}</span>
                </div>
              </div>
            </div>
            <div class="aurow mt40">
              <div class="titlecss">{{languageData['Bestelloptionen'].Var_file_attachments || 'Datei-Anhänge'}}:</div>
              <div *ngFor="let data of item?.data[0].termsAndConditions">
                <span [innerHTML]="pdfmodel"></span>
                <a *ngIf="!data.isLink" (click)="downloadFile(data.refCentralizedFileId)" style="cursor:pointer">
                  {{data?.textarea1}} {{data.name}} {{data?.textarea2}}</a>
                <a *ngIf="data.isLink" style="cursor:pointer" [href]="data?.link | securitycheck" target="_blank">
                  {{data?.textarea1}} {{data?.name}} {{data?.textarea2}}</a>
              </div>
            </div>
          </div>
          <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12" *ngIf="!item.data[0].priceDetails">
            <div class="aurow">
              <div class="titlecss">{{languageData['Bestellubersicht'].Var_bestelldetails || 'Bestelldetails'}}:</div>
              <div>
                <span>{{languageData['Bestelloptionen'].Var_Liefertermin || 'Liefertermin'}}:
                </span>
                <span>
                  {{item?.data[0].termsOfDelivery }}</span>
              </div>

              <div>
                <span>
                  {{languageData['Bestelloptionen'].Var_Lieferzeit || 'Lieferzeit'}}:</span>
                <span>
                  {{ item?.data[0].strictTimeSlot }}</span>
              </div>

            </div>

          </div>
          <div style="float:right;padding-right: 10px;">
            <!-- <i style="font-size: 30px" class="material-icons" style="cursor:pointer;" (click)="closeOrderDetails()">
              keyboard_arrow_up
            </i> -->
            <svg (click)="closeOrderDetails(selectedIndex)" style="cursor:pointer;" id="Layer_1" data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
              <path
                d="M23.76,16.45,12.55,5.24a.75.75,0,0,0-1.1,0L.24,16.45A.75.75,0,0,0,0,17a.77.77,0,0,0,.24.56l1.2,1.2a.76.76,0,0,0,1.11,0L12,9.31l9.45,9.45a.76.76,0,0,0,1.11,0l1.2-1.2A.77.77,0,0,0,24,17,.75.75,0,0,0,23.76,16.45Z"
                fill="gray" />
            </svg>
          </div>
        </div>
        <!-- <div class="aurow pb10" style="float:right">
          <i style="font-size: 30px" class="material-icons" style="cursor:pointer;" (click)="closeOrderDetails()">
            keyboard_arrow_up
          </i>
        </div> -->
      </div>
    </ul>

    <div class="loaderposition">
      <div class="lds-roller" *ngIf="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

  <div *ngIf="!listData">
    <div class="card  list" style="text-align: center;padding-top: 0;margin-top: 0;">
      <h4 style="display: table;margin: 0 auto;padding-top: 20px;">

      </h4>
      <br>
      <div *ngIf="order">
        <!-- new UI start -->
        <app-accordion>
          <app-accordion-item>
            <div class="accordion-item-header">
              <b>Info</b>
            </div>
            <div class="accordion-item-content">
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Datum'] || 'Datum'}}:
                <span aucol-md-8><b>{{order.timeStamp | date: 'dd.MM.yyyy'}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Uhrzeit'] || 'Uhrzeit'}}:
                <span aucol-md-8><b>{{order.timeStamp | date: 'HH:mm'}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Auftragsnummer'] || 'Auftragsnummer'}}:
                <span aucol-md-8><b>{{order.id}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Qualitat'] || 'Qualität'}}:
                <span aucol-md-8><b>{{order.productName}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Menge'] || 'Menge'}}:
                <span aucol-md-8><b>{{order.quantity || order.orderQuantity | replace}}<span>&nbsp; {{order.productUnitName}}
                    </span></b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Preis_100l _(netto)'] || 'Preis 100l (netto)'}}:
                <span aucol-md-8><b>{{order.netPrice | replace}} EUR</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Liefertermin'] || 'Liefertermin'}}:
                <span aucol-md-8><b>{{order.termsOfDelivery}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Lieferzeit'] || 'Lieferzeit'}}:
                <span aucol-md-8><b>{{order.strictTimeSlot}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Tankwagen'] || 'Tankwagen'}}:
                <span aucol-md-8><b>{{order.truckSize}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Schlauchlange'] || 'Schlauchlänge'}}:
                <span aucol-md-8><b>{{order.tubeLength}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Zahlungsart'] || 'Zahlungsart'}}:
                <span aucol-md-8><b>{{order.termsOfPayment}}</b></span>
              </div>
              <div aucol-md-4 *ngIf="order.voucherCode != ''">
                {{languageData['Order_info']['Var_Gutschein'] || 'Gutschein'}}:
                <span aucol-md-8><b>{{ order.voucherCode}}</b></span>
              </div>
              <div aucol-md-4 *ngIf="order.voucherNetPrice != ''">
                {{languageData['Order_info']['Var_Gutschein_(netto)'] || 'Voucher (net)'}}:
                <span aucol-md-8><b>{{ order.voucherNetPrice}}</b></span>
              </div>
            </div>
          </app-accordion-item>

          <app-accordion-item *ngIf="billingAddress">
            <div class="accordion-item-header">
              <b>{{languageData['Order_info']['Var_Rechnungsadresse'] || 'Rechnungsadresse'}}</b>
            </div>
            <div class="accordion-item-content">
              <div aucol-md-4>
                Adresstyp :
                <span aucol-md-8><b>{{billingAddress.addressType}}</b></span>
              </div>
              <div aucol-md-4>
                type :
                <span aucol-md-8><b>{{ billingAddress.type}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Stadt'] || 'Stadt'}}:
                <span aucol-md-8><b>{{ billingAddress.city}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Firma'] || 'Firma'}}:
                <span aucol-md-8><b>{{ billingAddress.companyName}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info'].Var_Gender || 'Gender'}} :
                <span aucol-md-8><b>{{ billingAddress.currentTitle1}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info'].Var_Menge || 'Menge'}} :
                <span aucol-md-8><b>{{ billingAddress.deliveryAmount}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Vorname'] || 'Vorname'}} :
                <span aucol-md-8><b>{{ billingAddress.Vorname}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Nachname'] || 'Nachname'}} :
                <span aucol-md-8><b>{{ billingAddress.Name}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Auftragsnummer'] || 'Auftragsnummer'}} :
                <span aucol-md-8><b>{{ billingAddress.orderId}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Telefonnummer'] || 'Telefonnummer'}} :
                <span aucol-md-8><b>{{ billingAddress.phoneNumber}}</b></span>
              </div>
              <div aucol-md-4>
                station :
                <span aucol-md-8><b>{{ billingAddress.station}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_Strabe'] || 'Straße'}} :
                <span aucol-md-8><b>{{ billingAddress.streetName}}</b></span>
              </div>
              <div aucol-md-4>
                {{languageData['Order_info']['Var_PLZ'] || 'PLZ'}} :
                <span aucol-md-8><b>{{ billingAddress.zipCode}}</b></span>
              </div>
            </div>
          </app-accordion-item>

          <app-accordion-item *ngIf="deliveryAddresses">
            <div class="accordion-item-header">
              <b>{{languageData['Order_info']['Var_Lieferadresse'] || 'Lieferadresse'}}</b>
            </div>
            <div class="accordion-item-content">
              <div *ngFor="let del of order.addresses; let i=index">
                <!-- <div>Addiional {{ i +1 }}</div> -->
                <div class="mb20">
                  <div aucol-md-4>
                    addressType :
                    <span aucol-md-8><b>{{del.addressType}}</b></span>
                  </div>
                  <div aucol-md-4>
                    type :
                    <span aucol-md-8><b>{{ del.type}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_Stadt'] || 'Stadt'}}:
                    <span aucol-md-8><b>{{ del.city}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_Firma'] || 'Firma'}}:
                    <span aucol-md-8><b>{{ del.companyName}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info'].Var_Gender || 'Gender'}} :
                    <span aucol-md-8><b>{{ del.currentTitle1}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info'].Var_Menge || 'Menge'}} :
                    <span aucol-md-8><b>{{ del.deliveryAmount}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_Vorname'] || 'Vorname'}} :
                    <span aucol-md-8><b>{{ del.Vorname}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_Nachname'] || 'Nachname'}} :
                    <span aucol-md-8><b>{{ del.Name}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_Auftragsnummer'] || 'Auftragsnummer'}} :
                    <span aucol-md-8><b>{{ del.orderId}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_Telefonnummer'] || 'Telefonnummer'}} :
                    <span aucol-md-8><b>{{ del.phoneNumber}}</b></span>
                  </div>
                  <div aucol-md-4>
                    station :
                    <span aucol-md-8><b>{{ del.station}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_Strabe'] || 'Straße'}} :
                    <span aucol-md-8><b>{{ del.streetName}}</b></span>
                  </div>
                  <div aucol-md-4>
                    {{languageData['Order_info']['Var_PLZ'] || 'PLZ'}} :
                    <span aucol-md-8><b>{{ del.zipCode}}</b></span>
                  </div>
                </div>
              </div>
            </div>
          </app-accordion-item>

        </app-accordion>
        <!-- new UI end -->


        <br><br>
        <br><br>

      </div>
    </div>
  </div>
</div>

<div>
  <loader [full-screen]="true" *ngIf="loaderForScreen" [color]="'var(--primaryColour)'"></loader>
</div>