<div class="aupris_main" style="margin:auto">
  <div class="au-container">
    <div class="aurow " style="margin-right: 0px;margin-left: 0px;">
      <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12 ">
        <div>
          <div class="card cardd">
            <b class="Headerr">
              {{languageData['Edit'].Var_head_newsletterCancel || 'Abmeldung vom Newsletter erfolgreich'}}
            </b>
            <div class="text-center">
              <p class="msgg">
                {{languageData['Edit'].Var_body_newsletterCancel || 'Sie haben sich erfolgreich von unserem Newsletter
                abgemeldet. Sollten Sie unsere Newsletter in Zukunft wieder empfangen wollen, können Sie diese Funktion
                in Ihrem Kundenkonto aktivieren.'}}
              </p>
              <button type="button" class="au-btn loginBtn" (click)="toUser()"
                [ngStyle]="{'background-color': colors?.Grundfarbe}">
                {{languageData['Edit'].Var_btn_newsletterCancel||'Zum Kundenkonto'}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>