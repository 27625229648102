import { Component, OnInit } from '@angular/core';
import { BigpcService } from '../Services/bigpc.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popups',
  templateUrl: './popups.component.html',
  styleUrls: ['./popups.component.css']
})
export class PopupsComponent implements OnInit {

  constructor(private bigpc: BigpcService) { }
  hostname: any;
  name_local: any;
  colors: any;
  Nutzervereinbarung: any;
  Datenschutzerklarung: any;
  busy: Subscription;
  // showFullScreenLoading: boolean = true;
  ngOnInit() {
    // this.showFullScreenLoading = false;
    this.busy = this.bigpc.pretext_event(this.hostname).subscribe((data: any) => {
      if (data.success) {
        this.Nutzervereinbarung = data.data.Nutzervereinbarung;
        this.Datenschutzerklarung = data.data.Datenschutzerklarung;
      }
    });
  }
}
