<div class="aupris_main stage">
    <!-- < THEME - 1> -->
    <div class="aurow aucol-md-12" class="progressCss" *ngIf="theme == 'Theme1'">
        <ul class="progressbar_fs">
            <li class="active_fs"
                [ngClass]="clickedoption=='setprice' || clickedoption=='notification'  ? 'activated':''"
                (click)="page('Page1')">
                <h5>{{languageData['Levels'].Var_selectproduct || 'Produktauswahl'}}</h5>
            </li>
            <li [ngClass]="{'active_fs':clickedoption=='setprice', 'activated': clickedoption=='notification'}"
                (click)="page('Page2')">
                <h5>{{languageData['Levels'].Var_setprice || 'Preis festsetzen'}}</h5>
            </li>
            <li [ngClass]="clickedoption=='notification' ? 'active_fs': ''" (click)="page('Page3')">
                <h5>{{languageData['Levels'].Var_notification || 'Benachrichtigungen'}}</h5>
            </li>
        </ul>
    </div>
    <!-- < THEME - 1> -->

    <!-- < THEME - 2> -->
    <div class="aurow headerRow theme2" style="cursor:pointer" *ngIf="theme == 'Theme2'">
        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
        <div class="proColumn">
            <div (click)="page('Page1')" class="card"
                [style.background]="(clickedoption=='selection' || clickedoption=='setprice' || clickedoption=='notification') ?'var(--primaryColour)':'white'"
                [style.color]="(clickedoption=='selection' || clickedoption=='setprice' || clickedoption=='notification') ?'white':'black'">
                <h5 class="h19" *ngIf="!(clickedoption=='setprice' || clickedoption=='notification' )">
                    1</h5>
                <svg class="tickimg" *ngIf="(clickedoption=='setprice' || clickedoption=='notification')"
                    xmlns="http://www.w3.org/2000/svg" width="14.909" height="14.909" viewBox="0 0 14.909 14.909">
                    <path id="ic_check_circle_24px"
                        d="M9.455,2a7.455,7.455,0,1,0,7.455,7.455A7.457,7.457,0,0,0,9.455,2ZM7.964,13.182,4.236,9.455,5.287,8.4l2.676,2.669,5.658-5.658,1.051,1.059Z"
                        transform="translate(-2 -2)" fill="#fff" />
                </svg>
                <h5 class="fontt">{{languageData['Levels'].Var_selectproduct || 'Produktauswahl'}}</h5>
            </div>
        </div>

        <div class="proColumn secondBar">
            <div (click)="page('Page2')" class="card"
                [style.background]="(clickedoption=='setprice' || clickedoption=='notification') ?'var(--primaryColour)':'white'"
                [style.color]="(clickedoption=='setprice' || clickedoption=='notification') ?'white':'black'">
                <h5 class="h19" *ngIf="!(clickedoption=='notification')">
                    2</h5>
                <svg class="tickimg" *ngIf="(clickedoption=='notification')" xmlns="http://www.w3.org/2000/svg"
                    width="14.909" height="14.909" viewBox="0 0 14.909 14.909">
                    <path id="ic_check_circle_24px"
                        d="M9.455,2a7.455,7.455,0,1,0,7.455,7.455A7.457,7.457,0,0,0,9.455,2ZM7.964,13.182,4.236,9.455,5.287,8.4l2.676,2.669,5.658-5.658,1.051,1.059Z"
                        transform="translate(-2 -2)" fill="#fff" />
                </svg>
                <h5 class="fontt">{{languageData['Levels'].Var_setprice || 'Preis festsetzen'}}</h5>
            </div>
        </div>

        <div class="proColumn thirdBar">
            <div (click)="page('Page3')" class="card mr-0"
                [style.background]="(clickedoption=='notification') ?'var(--primaryColour)':'white'"
                [style.color]="(clickedoption=='notification') ?'white':'black'">
                <h5 class="h19"
                    *ngIf="(clickedoption=='setprice' || clickedoption=='selection'  || clickedoption=='notification')">
                    3</h5>
                <h5 class="fontt">{{languageData['Levels'].Var_notification || 'Benachrichtigungen'}}</h5>
            </div>
        </div>
        </div>
    </div>
    <!-- < THEME - 2> -->
</div>