import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Builder } from 'protractor';
import { LanguageService } from '../../Services/language.service';
// import { ValidateAddressPipe } from 'app/pipes/validate-address.pipe';
// import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-geburtstag-selecton-popup',
  templateUrl: './geburtstag-selecton-popup.component.html',
  styleUrls: ['./geburtstag-selecton-popup.component.css']
})
export class GeburtstagSelectonPopupComponent implements OnInit {

  colors: any;
  languageData: any;
  startDate: Date;
  datePatternRegex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
  today = new Date();
  showError: Boolean = false;
  saveGeburtstag: Boolean = false;
  @ViewChild('lGeburtstag') lGeburtstag: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      colors: any
    },
    private fb: FormBuilder, public dialog: MatDialog,
    private langService: LanguageService,
    public dialogRef: MatDialogRef<GeburtstagSelectonPopupComponent>) {

    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  };

  public geburtstagForm: FormGroup;

  ngOnInit() {
    this.startDate = new Date(new Date().getFullYear() - 35, 0, 1);
    this.today.setDate(this.today.getDate() + 0);
    this.geburtstagForm = this.fb.group({
      Geburtstag: ['', Validators.required]
    });
    this.colors = this.data.colors;
    // console.log(this.colors)
  };

  dateChange(event) {
    let date = this.datePatternRegex.test(event);
    if (date) {
      let dateSplit = event.split(".");
      if (parseInt(dateSplit[1]) > 12) {
        this.LieferGeburtstagValidateEvent({ status: false, value: null });
      } else {
        let date = dateSplit[2] + '-' + ('0' + dateSplit[1]).slice(-2) + '-' + ('0' + dateSplit[0]).slice(-2);
        this.LieferGeburtstagValidateEvent({ status: true, value: date });
      }

    } else {
      this.LieferGeburtstagValidateEvent({ status: false, value: null });
    }
  };


  checkValue(value: any) {
    this.saveGeburtstag = value.checked;
  };

  cancelPopup() {
    this.geburtstagForm.controls['Geburtstag'].setValue('');
    this.dialogRef.close({ Geburtstag: '', saveGeburtstag: false });
  };

  close() {
    if (!this.geburtstagForm.valid) {
      this.showError = true;
      return false;
    }

    if (this.geburtstagForm.valid) {
      let gebDate = this.lGeburtstag.nativeElement.value;
      let dateCheck = this.datePatternRegex.test(gebDate);
      if (dateCheck) {
        let dateSplit = gebDate.split(".");
        if (parseInt(dateSplit[1]) > 12) {
          this.LieferGeburtstagValidateEvent({ status: false, value: null });
        } else {
          let date = dateSplit[2] + '-' + ('0' + dateSplit[1]).slice(-2) + '-' + ('0' + dateSplit[0]).slice(-2);
          let valErr = this.LieferGeburtstagValidateEvent({ status: true, value: date });
          if (valErr) {
            this.dialogRef.close({ Geburtstag: date, saveGeburtstag: this.saveGeburtstag });
          };
        };
      } else {
        this.LieferGeburtstagValidateEvent({ status: false, value: null });
        this.dialogRef.close({ Geburtstag: '', saveGeburtstag: false });
      };
    };
  }



  LieferGeburtstagValidateEvent(event) {
    if (!event.status) {
      this.showError = true;
      this.geburtstagForm.controls['Geburtstag'].setValue('');
      return false;
    } else {
      this.showError = false;
      return true;
    }
  };

  // LieferGeburtstagValidateEvent(event) {
  //   if (!event.status) {
  //     this.geburtstagForm.controls['Geburtstag'].setValue('');
  //   } else {
  //     this.geburtstagForm.controls['Geburtstag'].setValue(event.value);
  //   }
  // }

  // close() {
  //   if (!this.geburtstagForm.valid) {
  //     this.showError = true;
  //     return false;
  //   }
  //   console.log(this.lGeburtstag.nativeElement.value)
  //   if (this.lGeburtstag.nativeElement.value) {
  //     let gebDate = this.lGeburtstag.nativeElement.value;
  //     console.log(gebDate, 98888);
  //     let date = this.datePatternRegex.test(gebDate);
  //     console.log(date, 1000000);
  //     if (date) {
  //       let dateSplit = gebDate.split(".");
  //       if (parseInt(dateSplit[1]) > 12) {
  //         this.LieferGeburtstagValidateEvent({ status: false, value: null });
  //       } else {
  //         let date = dateSplit[2] + '-' + ('0' + dateSplit[1]).slice(-2) + '-' + ('0' + dateSplit[0]).slice(-2);
  //         console.log(date, 955555);
  //         let valErr = this.LieferGeburtstagValidateEvent({ status: true, value: date });
  //         console.log(valErr, 966);
  //         if (valErr) {
  //           this.dialogRef.close({ Geburtstag: date, saveGeburtstag: this.saveGeburtstag });
  //         }
  //       }

  //     } else {
  //       this.LieferGeburtstagValidateEvent({ status: false, value: null });
  //     };
  //     this.dialogRef.close({ Geburtstag: date, saveGeburtstag: this.saveGeburtstag });
  //   } else {
  //     this.dialogRef.close({ Geburtstag: this.geburtstagForm.value.Geburtstag, saveGeburtstag: this.saveGeburtstag });
  //   }
  // };


  // LieferGeburtstagValidateEvent(event) {
  //   // var datePipe = new DatePipe("de-DE");
  //   if (!event.status) {
  //     this.showError = true;
  //     this.geburtstagForm.controls['Geburtstag'].setValue('');
  //     return false;

  //   } else {
  //     this.showError = false;
  //     return true;
  //   }
  // };

  // dateChange(event) {
  //   let date = this.datePatternRegex.test(event);
  //   if (date) {
  //     let dateSplit = event.split(".");
  //     if (parseInt(dateSplit[1]) > 12) {
  //       this.LieferGeburtstagValidateEvent({ status: false, value: null });
  //     } else {
  //       let date = dateSplit[2] + '-' + ('0' + dateSplit[1]).slice(-2) + '-' + ('0' + dateSplit[0]).slice(-2);
  //       this.LieferGeburtstagValidateEvent({ status: true, value: date });
  //     }

  //   } else {
  //     this.LieferGeburtstagValidateEvent({ status: false, value: null });
  //   }
  // };

}
