import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../Services/language.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {
  id: any;
  color: any;
  languageData: any;
  
  constructor( public dialogRef: MatDialogRef<DataComponent>,private langService: LanguageService,
     @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.id=data.id;
    this.color =data.colors;
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit() {
    
  }
  onclick() {
    this.dialogRef.close();
  }
}
