import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlternativeTextInfoComponent } from 'app/alternative-text-info/alternative-text-info.component';
import { LanguageService } from '../../Services/language.service';
import { SmallPcService } from 'app/Services/small-pc.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pricedetail-popup',
  templateUrl: './pricedetail-popup.component.html',
  styleUrls: ['./pricedetail-popup.component.css']
})
export class PricedetailPopupComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  public smallPcService: SmallPcService;
  languageData: any;
  alternativeMwst = 0;
  alternativeWarenpries = 0;
  timeDepandentSurcharges: any;
  url: any;
  sessionId: any
  sessionData: any;
  symbol: string;
  infoSvg: any;
  priceFactor: any;
  languageVar: any;




  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private langService: LanguageService, public dialogRef: MatDialogRef<PricedetailPopupComponent>, private SmallPcService: SmallPcService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,) {

    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    //getting session data stored in localstorage
    this.SmallPcService.getNewSessionData(this.sessionId).then(async (data: any) => {
      this.sessionData = data;
    });

  }
  // colors: any;
  priceData: any;

  ngOnInit() {
    this.getImageData();
    // console.log(this.data)
    this.priceData = this.data;
    this.timeDepandentSurcharges = this.priceData.timeDepandentSurcharges;
  }


  // calculateAlternative(priceData) {
  //   this.alternativeMwst = 0;
  //   this.alternativeWarenpries = 0;
  //   if (priceData.timeDepandentSurcharges) {
  //     for (let item of priceData.timeDepandentSurcharges) {
  //       this.alternativeMwst = Number(this.alternativeMwst) + Number(item.tax);
  //       this.alternativeWarenpries = Number(this.alternativeWarenpries) + Number(item.amount);
  //     }
  //     if (priceData.ratePaymentDetails) {
  //       this.alternativeWarenpries = Number(this.alternativeWarenpries) + Number(this.alternativeMwst) + Number(priceData.ratePaymentDetails.GesamtpreisBrutto);
  //     } else {
  //       this.alternativeWarenpries = Number(this.alternativeWarenpries) + Number(this.alternativeMwst) + Number(priceData.tax_for_finalprice);
  //     }
  //   }
  // }
  alternativePopup(event) {
    let dialogRef = this.dialog.open(AlternativeTextInfoComponent, { panelClass: 'my-full-screen-dialog', width: '500px' });
    // dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.infotext = event;
  }
  getImageData() {
    let infopath = '../assets/infoSign.svg'
    this.httpClient.get(infopath, { responseType: 'text' })
      .subscribe(logo => {
        logo = logo.replace('#333', 'var(--primaryColour)');
        this.infoSvg = this.sanitizer.bypassSecurityTrustHtml(logo);
      })
  }
  onclick() {
    this.dialogRef.close({ close: true });
  }

}
