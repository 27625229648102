<div class="au-container" style="height: 80vh;overflow-y: scroll;">
    <div style="margin-bottom:15px;">
    1. Informationen zur vertragsbedingten Verarbeitung meiner personenbezogenen Daten durch die TeamBank AG, Auskunfteien und die Risk.Ident GmbH
     Die TeamBank AG verarbeitet personenbezogene Daten im Einklang mit den Bestimmungen der Europäischen Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Nähere Informationen können online unter www.teambank.de/datenschutz eingesehen werden.
    </div>
    <div style="margin-bottom:15px;">
    2. Durchführung des mTAN-Verfahrens mTAN steht für „mobileTAN“ und wird oft auch „SMS-TAN“ oder „mobil TAN“ genannt. Die Erhebung und Verarbeitung personenbezogener Daten im Zusammenhang mit dem mTAN-Verfahren dient dem Schutz vor typischen Gefahren im Online-Bereich wie etwa Identitätsdiebstahl. Dieses Verfahren wird beispielsweise auch im Online-Banking von vielen Kreditinstituten verwendet. Es funktioniert einfach über ein mobiles Endgerät mit SMS-Funktion. mTAN hat den Vorteil, dass die TANs zeitlich nur begrenzt gelten und nur einmalig für die angegebene Transaktion genutzt werden können.
</div>
<div style="margin-bottom:15px;">
    3. Aufklärung/Hinweis über die erforderliche Datenübermittlung an Auskunfteien und der Befreiung vom Bankgeheimnis
    Die Teambank AG übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung und zur Verifizierung Ihrer Adresse an folgende Auskunfteien:
    SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden
    Infoscore Consumer DATA GmbH, Rheinstr. 99, 76532 Baden-Baden
    Creditreform Boniversum, Hellersbergstr. 11, 41460 Neuss
    Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und Artikel 6 Absatz 1 Buchstabe f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DS-GVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der TeamBank AG oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. Der Datenaustausch mit den Auskunfteien dient insbesondere der Erfüllung gesetzlicher Pflichten zur Durchführung von Kreditwürdigkeitsprüfungen von Kunden (§ 505a des Bürgerlichen Gesetzbuches, § 18a des Kreditwesengesetzes).
    Die Auskunfteien verarbeiten die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren im Europäischen Wirtschaftsraum (EWR) und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der hier aufgeführten Auskunfteien können Sie unter
    www.schufa.de/datenschutz
  <a  target="_blank"href= "https://finance.arvato.com/content/dam/arvato/documents/financial-solutions/Arvato_Financial_Soultions_Art._14_EUDSGVO.pdf" target="_blank">
    https://finance.arvato.com/content/dam/arvato/documents/financial-solutions/Arvato_Financial_Soultions_Art._14_EUDSGVO.pdf</a>
   <a href ="https://www.boniversum.de/eu-dsgvo/informationen-nach-eu-dsgvo-fuer-verbraucher/" target="_blank">
    https://www.boniversum.de/eu-dsgvo/informationen-nach-eu-dsgvo-fuer-verbraucher</a>
   einsehen.Als Kunde befreien Sie die TeamBank AG zudem vom Bankgeheimnis.
    </div>
    <div style="margin-bottom:15px;">
   4. Erhebung, Speicherung und Übermittlung von Daten durch die TeamBank AG und ihre Kooperationspartner zur Risikobewertung
    Zur Vermeidung von Betrugsfällen im Rahmen eines Ratenkaufs greift die TeamBank AG beim Betrieb dieser Website auf die Dienste von folgenden Unternehmen (im Folgenden „Kooperationspartnern zur Risikobewertung“) zurück:
    Risk.Ident GmbH, Am Sandtorkai 50, 20457 Hamburg (Deutschland)
    BioCatch Ltd, 132 Derech Menachem Begin, Tel Aviv-Yafo (Israel)
    
    (Israel ist aufgrund eines gültigen Angemessenheitsbeschlusses der Europäischen Kommission ein sog. „sicheres Drittland“ und weist ein angemessenes Datenschutzniveau auf.)
    <div style="margin-bottom:10px;margin-top:10px">
    a) Erhebung und Speicherung von Daten durch die Risk.Ident GmbH
    Die Risk.Ident GmbH erhebt, verarbeitet und nutzt mit Hilfe von Cookies und anderen Tracking-Technologien Daten zur Ermittlung des vom Besucher dieser Website verwendeten Endgeräts (Browserdaten, wie z.B. Browserversion, Auflösung etc.) und weiterer Daten über die Nutzung der Website (wie z.B. Namen bzw. Domain der Website, Durchführung einer Bestellung etc.). Soweit durch den Kooperationspartner IP- Adressen erhoben werden, werden diese Adressen vor der Verarbeitung und Nutzung gekürzt, sodass kein Personenbezug der IP-Adressen mehr hergestellt werden kann. Die Daten werden vom Kooperationspartner in einer Datenbank zur Betrugsprävention hinterlegt. In diese Datenbank werden auch von der TeamBank AG übermittelte Daten zu Endgeräten gespeichert, unter deren Verwendung es bereits zu betrügerischem Verhalten gekommen ist. Nach 12 Monaten werden die Daten in der Datenbank gelöscht.
    Im Rahmen eines Anmelde- bzw. Bestellprozesses auf dieser Website ruft die TeamBank AG aus dieser Datenbank eine Risikobewertung zum Endgerät des Nutzers ab. Diese Risikobewertung zur Wahrscheinlichkeit eines Betrugsversuchs berücksichtigt u.a., ob das Endgerät sich über verschiedene Service-Provider eingewählt hat, ob das Endgerät eine häufig wechselnde Geo-Referenz aufweist, wie viele Transaktionen über das Endgerät getätigt wurden und ob eine Proxy-Verbindung genutzt wird.
    Nähere Informationen zu der hier beschriebenen Tätigkeit finden Sie auch unter:
   <a href="https://riskident.com/de/" target="_blank">https://riskident.com/de</a> 
  </div>
  <div style="margin-bottom:10px">
    b) Erhebung und Speicherung von Daten durch BioCatch Ltd.
    Zur Vermeidung von Betrugsfällen im Rahmen eines Ratenkaufs greift die TeamBank AG beim Betrieb dieser Website auf die Dienste der BioCatch Ltd. (im folgenden „Kooperationspartner“) zurück.
     Der Kooperationspartner erhebt, verarbeitet und nutzt u.a. mit Hilfe von Cookies und anderen Tracking-Technologien Daten zur Ermittlung des Verhalten des Besuchers der Website (z.B. Tippverhalten und Mausbewegungen). Die Daten werden von dem Kooperationspartner ausgewertet und hinsichtlich der Vertrautheit der Eingaben zur Verhinderung von Identitätsdiebstahl bewertet.
    Im Rahmen eines Anmelde- bzw. Bestellprozesses auf dieser Website ruft die TeamBank AG diese Informationen vom Kooperationspartner ab. Diese Risikobewertung wird von der TeamBank AG bei der Berechnung der zur Wahrscheinlichkeit eines Identitätsdiebstahls berücksichtigt, indem z.B. geprüft wird, ob u.a. Inhalte bei der Eingabe in das Eingabefeld kopiert wurden oder automatisiert bzw. maschinell eingegeben wurden.
    Nähere Informationen zu der hier beschriebenen Tätigkeit finden Sie auch unter:
    <a href="https://www.biocatch.com/" target="_blank">https://www.biocatch.com</a>
  </div>
    <div style="margin-bottom:10px">
    c) Erhebung und Verarbeitung von Daten zur Betrugsprävention durch die TeamBank AG
    Zur Vermeidung und Aufklärung von Betrugsfällen bzw. versuchen im Rahmen eines Ratenkaufs werden die von den „Kooperationspartnern zur Risikobewertung“ gemäß den vorstehenden Bedingungen erhobenen und gespeicherten Daten (z.B. Standortdaten und Daten des verwendeten Endgeräts, wie Browserdaten und Bildschirmauflösung, Verhaltensinformationen) in eine Datenbank der TeamBank AG übermittelt. Diese Daten werden dort zusammen mit weiteren Daten wie Bestell und Kontaktdaten gespeichert.
    Im Rahmen des Ratenkauf-Bestellprozesses ruft die TeamBank AG aus dieser Datenbank Daten ab, um eine Risikobewertung zu jedem anfragenden Nutzer durchführen zu können. Die Bewertung erfolgt anhand individueller Risikofaktoren (z.B. Art des Eingabeverhaltens, Geo-Referenz oder Entfernung Adresse/Lieferort) und durch einen Abgleich mit früheren Bestellungen. Bei dem Abgleich werden mögliche Verbindungen zu anderen Nutzerdaten bewertet (z.B. Identitätsdiebstahl oder Übereinstimmung mit erkannten Betrugsfällen). Die Bewertung beruht nicht auf einzelnen Daten, sondern auf einem in der Ausprägung der einzelnen Kriterien variablen Punktesystem.
    Als Vertragspartner der TeamBank AG können Sie Auskunft über die Sie betreffenden gespeicherten Daten erhalten und erfahren, aufgrund welcher Daten ein Ratenkauf bewilligt oder abgelehnt wurde. Die betreffenden Daten der Datenbank werden regelmäßig in einem 3-Jahres-Turnus überprüft. Nicht mehr erforderliche Daten werden zu diesem Zeitpunkt gelöscht.
  </div>
  </div>
    <div style="margin-bottom:15px;">
    5. Hinweis zur Teilnahme am Return-debit Prevention Pool der infoscore Consumer Data GmbH
    Im Rahmen des Antragsverfahrens gleicht die TeamBank AG Ihre Bankverbindungsdaten (nur IBAN und BIC, keine zur Person) mit dem Return-debit Prevention Pool (RPP) der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden ab. Der RPP hat die Funktion einer Sperrdatei. Darüber hinaus wird die TeamBank AG im Falle der Nichteinlösung der Lastschrift, soweit zulässig, Ihre Bankverbindung (nur IBAN und BIC, keine Angaben zur Person) in den RPP der infoscore Consumer Data GmbH einmelden, die diese Sperrung anderen Unternehmen, die am Auskunftsverfahren beteiligt sind, auf Anfrage übermittelt. Nach Bezahlung der Rücklastschrift wird die TeamBank AG diese Erledigung in den RPP melden.
    </div>
    <div style="margin-bottom:15px;">
    6. Hinweise zum Scoring Für die Erstellung eines Teilzahlungsangebots verwendet die TeamBank AG eine automatisierte Kreditentscheidung. Hierbei nutzt die TeamBank AG zur Bewertung Ihrer Bonität einen von der Schufa gelieferten Scorewert. Zudem fließen Auskünfte Dritter (Daten, die mit Ihrer Zustimmung von Auskunfteien übermittelt werden) sowie gegebenenfalls eigene Erfahrungen, die die TeamBank mit Ihnen als Kunde gemacht hat, ein.
    Die TeamBank AG nutzt in diesem Zusammenhang auch bonitätsrelevante Informationen (Verifizierung der Namensangaben, der E-Mail-Adresse und der Adresse inklusive Prüfung der Zustellbarkeit) zum bisherigen Zahlungsverhalten sowie Informationen zur Beurteilung des Zahlungsausfallrisikos auf Basis mathematisch-statistischer Verfahren unter Verwendung von Anschriftendaten von der infoscore Consumer Data GmbH.
    Das Verfahren ist auf vergangenheitsbezogenen Werten konzipiert. Die Entscheidung beruht somit nicht auf einem einzelnen Merkmal, sondern auf einem in der Ausprägung der einzelnen Kriterien variablen Punktesystem.
    Sie haben die Möglichkeit, sich mit Ihren Einwände gegen das Ergebnis der Entscheidung direkt an die TeamBank AG zu wenden. Sie erreichen die TeamBank AG unter folgender Adresse:
    TeamBank AG, Beschwerdemanagement, Beuthener Straße 25, 90471 Nürnberg, E-Mail bdsg@teambank.de.
</div>

<button class="au-btn btn-default" [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="onclick()"
style="float:right;margin-top:5px">
{{languageData['Popups']['Var_Schlieben']|| 'Schließen' }}
</button>
</div>
