import { AbstractControl } from '@angular/forms';
export function maxminValidations(maxmindata,) {
   return (AC: AbstractControl) => {
      let EnteredValue = AC.get('liter').value; // to get value in input tag

      if (maxmindata.maxAmount >= EnteredValue && maxmindata.minAmount <= EnteredValue) {
         AC.get('liter').setErrors(null);
      }

      else {
         AC.get('liter').setErrors({ errormaxmin: true });
      }
   };

}