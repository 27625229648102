<div class="aupris_main my-full-screen-dialog">
    <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">Geburtstag</div>
    <p>{{zahlungsartdata}}
        <!-- Näheres dazu finden Sie in unseren <b> -->
            <!-- <a [ngStyle]="{color: 'var(--primaryColour)'}" (click)="downloadFile('AGB')">AGB's,</a>
            <a [ngStyle]="{color: 'var(--primaryColour)'}" (click)="downloadFile('Widerrufsbelehrung')"> Widerrufsbelehrung</a> -->
          <!-- </b> und -->
          <!-- <b>
            <a [ngStyle]="{color: 'var(--primaryColour)'}" (click)="downloadFile('Datenschutzerklärung')"> Datenschutzerklärung </a>
          </b> -->
    </p>
    <button class="au-btn btn-default" [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="onclick()"
      style="float:right;margin-top:5px">Schließen</button>
  </div>