import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import 'hammerjs';

if (environment.production) {
  let uri = window.location.href || '';
  enableProdMode();

  ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml',
    'exception', 'group', 'groupCollapsed', 'groupEnd',  'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ].forEach((method) => {
    if (uri.match(/localhost/ig)) {
      //if url is local host it will print consoles
      return
    }
    window.console[method] = () => { }
  })
}

platformBrowserDynamic().bootstrapModule(AppModule);
