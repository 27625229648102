<div class="aupris_main dd" (mouseleave)="someDiv.state = false ">
  <div class="ddlabel" (click)="someDiv.state = !someDiv.state">
    <span *ngIf="currentSelection?.Name && _disabled">{{currentSelection?.Name}}</span>
    <span *ngIf="!currentSelection?.Name || !_disabled">Select</span>
  </div>
  <div>
    <ul #someDiv [@slideInOut]="!someDiv.state">
      <div *ngIf="_disabled">
        <li *ngFor="let option of _options; let first = first; let last = last"
          [ngClass]="{ 'pt15': first, 'pb15':last, 'tick':option.class == 'tick', 'untick': option.class == 'untick' }"
          (click)="someDiv.state = !someDiv.state; setCurrentSelection(option)"> {{option?.Name}}</li>
        <li class="border-dashed font19 text-center" (click)="someDiv.state = !someDiv.state; "
          (click)="addNew.emit(true)"><img [src]="'assets/add.svg' | assetsUrl"> Add
          new</li>
          
      </div>
    </ul>
  </div>
</div>