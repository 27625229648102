<div class="aupris_main my-full-screen-dialog">
  <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">
    {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</div>
  <table>
    <tr>
      <td>
        {{languageData['Bestelloptionen'].Var_voucherText || 'Folgender Gutschein wurde erfasst'}}:
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        <span>{{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}
          ({{languageData['Bestelloptionen'].Var_netto || 'netto'}})</span>
      </td>
      <td>
        <span>
          <span>{{voucherData?.currencySymbol}}</span>
          <!-- {{voucherNett | replace}} -->
          {{voucherNett | langReplacer : languageVar }} 
        </span>
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td>
        <span>+ 
          {{voucherData?.voucherInfo?.productTax | langReplacer : languageVar }}
          % {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
      </td>
      <td>
        **
        <span>
          <span>{{voucherData?.currencySymbol}}</span> 
          {{voucherData?.voucherNettMwst | langReplacer : languageVar }} 
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <b>
          <span>= {{languageData['Bestelloptionen'].Var_totalValueVoucher || 'Gesamtwert Gutschein'}}
            ({{languageData['Bestelloptionen'].Var_brutto || 'brutto'}})
          </span>
        </b>
      </td>
      <td>
        <b>
          <span>{{voucherData.currencySymbol}}</span> 
          {{voucherData?.voucherInfo?.voucherGross | langReplacer : languageVar }} 
        </b>
      </td>
    </tr>
  </table>
  <br>
  <div class="inline-block">
    <span class="dblock">
      {{languageData['Bestelloptionen'].Var_voucherText2 || 'Produkte, auf die der Gutschein
      angewendet werden kann, werden entsprechend gekennzeichnet. Der Gutschein wird bei der Preisberechnung direkt in
      Abzug gebracht.' 
    }}
    </span>
  </div>
  <br>
  <div class="text-right">
    <button class="au-btn btn-default" [ngStyle]="{background: colors.primaryColour,color: colors.primaryTextColour}"
      (click)="onclick()" style="margin-top:5px">{{languageData['Popups'].Var_Schlieben || 'Schließen'}}</button>
  </div>
  <br />
  <div class="inline-block" *ngIf="data?.productTax?.status">
    <span class="dblock">**{{languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis'}}</span>
    <span class="dblock">{{data?.productTax?.infoText}}</span>
  </div>
</div>