import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableInputDirective } from './directives/disable-input.directive';
import { AppComponent } from './app.component';
import { aReplacePipe } from "./directives/ThousandDivider";
import { ReplacePipe } from "./pipes/custom_dotcomma";
import { assetsUrl } from "./pipes/assetsUrl.pipe";
import { AppComponent2 } from './bigpc/app.component2';
import { OnlyNumberWithOutDot } from './directives/onlyNumber.directive';
import { InlinesvgDirective } from './inlinesvg.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { PopupsComponent } from './popups/popups.component';
import { TelefonPopupComponent } from './telefon-popup/telefon-popup.component';;
import { AppScrolltoFirstDirective } from './directives/app-scrollto-first.directive';
import { enableProdMode } from '@angular/core';
import { InfopopupComponent } from './infopopup/infopopup.component';
import { PricedetailsComponent } from './popups/pricedetails/pricedetails.component';
import { GeburtstagInfoComponent } from './popups/geburtstag-info/geburtstag-info.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeAt from '@angular/common/locales/de-AT';
import localeEs from '@angular/common/locales/es';
import { BestellenPopupComponent } from './popups/bestellen-popup/bestellen-popup.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { FirststageComponent } from './firststage/firststage.component';
import { DatePickerComponent } from './popups/date-picker/date-picker.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { BigpcNewComponent } from './bigpc-new/bigpc-new.component';
import { VerificationNewComponent } from './verification-new/verification-new.component';
import { DataSecurityFormNewComponent } from './data-security-form-new/data-security-form-new.component';
import { ForthStageComponent } from './forth-stage/forth-stage.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VerificationComponent } from './verification/verification.component';
import { PcManualAddressComponent } from './pc-manual-address/pc-manual-address.component';
import { BestellungComponent } from './bestellung/bestellung.component';
import { EinstellungenComponent } from './einstellungen/einstellungen.component';
import { PcCreateAddressComponent } from './pc-create-address/pc-create-address.component';
import { GetLogoPipe } from './pipes/get-logo.pipe';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { EditComponent } from './edit/edit.component';
import { CharAndNumDirective } from './directives/char-and-num.directive';
import { DeleteComponent } from './popups/delete/delete.component';
import { EmailVarificationComponent } from './verification/email-varification/email-varification.component';
import { PwVerificationComponent } from './verification/pw-verification/pw-verification.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { EmailFbComponent } from './popups/email-fb/email-fb.component';
import { KundconformPopupComponent } from './popups/kundconform-popup/kundconform-popup.component';
import { UpdateEmailVerificationComponent } from './verification/update-email-verification/update-email-verification.component';
import { ValidateAddressPipe } from './pipes/validate-address.pipe';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { StagetwoComponent } from './stages/stagetwo/stagetwo.component';
import { StagefourComponent } from './stagefour/stagefour.component';
import { VerificationlinkComponent } from './verificationlink/verificationlink.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AddressSelectionComponent } from './address-selection/address-selection.component';
import { SteuerhinweisPopupComponent } from './popups/steuerhinweis-popup/steuerhinweis-popup.component';
import { PricealarmComponent } from './pricealarm/pricealarm.component';
import { ProgressalarmComponent } from './progressalarm/progressalarm.component';
import { AlarmPricesComponent } from './alarm-prices/alarm-prices.component';
import { AlarmStageoneComponent } from './alarm-stageone/alarm-stageone.component';
import { AlarmStagetwoComponent } from './alarm-stagetwo/alarm-stagetwo.component';
import { AlarmStageThreeComponent } from './alarm-stage-three/alarm-stage-three.component';

import { Ng2CompleterModule } from './ng2Completer/ng2-completer';
import { DataComponent } from './data/data.component';
import { RatePaymentRejectedInfoComponent } from './popups/rate-payment-rejected-info/rate-payment-rejected-info.component';
import { RatePaymentSelectionPopupComponent } from './popups/rate-payment-selection-popup/rate-payment-selection-popup.component';
import { GermanDateAdapter } from './DateAdapter';
import { AlternativeTextInfoComponent } from './alternative-text-info/alternative-text-info.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InvoiceComponent } from './invoice/invoice.component';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { VoucherNoticeComponent } from './popups/voucher-notice/voucher-notice.component';
import { EmergencyHotlineComponent } from './emergency-hotline/emergency-hotline.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeleteOnlineaccountComponent } from './popups/delete-onlineaccount/delete-onlineaccount.component';
import { SelectCalculatorComponent } from './popups/select-calculator/select-calculator.component';
import { DeleteAlarmComponent } from './delete-alarm/delete-alarm.component';
import { removeAutocompleteDirective } from './directives/removeAutocomplete.directive';
import { TimezonePipe } from './pipes/timezone.pipe';
import { EmailMarketingComponent } from './email-marketing/email-marketing.component';
import { NewsletterCancellationComponent } from './newsletter-cancellation/newsletter-cancellation.component';
import { GeburtstagSelectonPopupComponent } from './popups/geburtstag-selecton-popup/geburtstag-selecton-popup.component';
import { OnlyNumberWithDotDirective } from './directives/only-number-with-dot.directive';
import { SecuritycheckPipe } from './pipes/securitycheck.pipe';
import { AuprisKleinerPreisrechner1Component } from './tags/aupris-kleiner-preisrechner1/aupris-kleiner-preisrechner1.component';
import { AuprisKleinerPreisrechner2Component } from './tags/aupris-kleiner-preisrechner2/aupris-kleiner-preisrechner2.component';
import { AuprisKleinerPreisrechner3Component } from './tags/aupris-kleiner-preisrechner3/aupris-kleiner-preisrechner3.component';
import { AuprisKleinerPreisrechner4Component } from './tags/aupris-kleiner-preisrechner4/aupris-kleiner-preisrechner4.component';
import { AuprisKleinerPreisrechner5Component } from './tags/aupris-kleiner-preisrechner5/aupris-kleiner-preisrechner5.component';
import { AuprisKleinerPreisrechner6Component } from './tags/aupris-kleiner-preisrechner6/aupris-kleiner-preisrechner6.component';
import { AuprisKleinerPreisrechner7Component } from './tags/aupris-kleiner-preisrechner7/aupris-kleiner-preisrechner7.component';
import { AuprisKleinerPreisrechner8Component } from './tags/aupris-kleiner-preisrechner8/aupris-kleiner-preisrechner8.component';
import { AuprisKleinerPreisrechner9Component } from './tags/aupris-kleiner-preisrechner9/aupris-kleiner-preisrechner9.component';
import { AuprisKleinerPreisrechner10Component } from './tags/aupris-kleiner-preisrechner10/aupris-kleiner-preisrechner10.component';
import { PriceCalculaorCommonComponent } from './tags/price-calculaor-common/price-calculaor-common.component';
import { TrackingVerificationComponent } from './tracking-verification/tracking-verification.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TrackingInfoPopupComponent } from './popups/tracking-info-popup/tracking-info-popup.component';
import { DecimalpipePipe } from './pipes/decimalpipe.pipe';
import { OneComponent } from './stages/one/one.component';
import { InfoPasswordPopupComponent } from './info-password-popup/info-password-popup.component';
import { HttpRequestHandler } from './interceptors/http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonServiceLatest } from "./Services/common.service";
import { BigpcService } from "./Services/bigpc.service";
import { CommonService } from './common.service';
import { LanguageService } from './Services/language.service';
import { SmallPcService } from './Services/small-pc.service';
import { WindowRefService } from './Services/window.service';
import { PortalAuthService } from './login/auth.service';
import { SocialService } from './login/social.service';
import { PricedetailPopupComponent } from './popups/pricedetail-popup/pricedetail-popup.component';
import { ThreeComponent } from './stages/three/three.component';
import { LangReplacerPipe } from './lang-replacer.pipe';
import { AuprisKleinerPreisrechnerComponent } from './tags/aupris-kleiner-preisrechner/aupris-kleiner-preisrechner.component';
import { AuprisKleinerPreisrechnerPelletsComponent } from './tags/aupris-kleiner-preisrechner-pellets/aupris-kleiner-preisrechner-pellets.component';
import { AuprisKleinerPreisrechnerHeizolComponent } from './tags/aupris-kleiner-preisrechner-heizol/aupris-kleiner-preisrechner-heizol.component';
import { SmallPcComponent } from './stages/small-pc/small-pc.component';
import { SafePipe } from './pipes/safe.pipe';
import { PaymentErrorComponent } from './popups/payment-error/payment-error.component';


registerLocaleData(localeDe);     // de-DE
registerLocaleData(localeDeAt);   // de-AT
registerLocaleData(localeEs);     // es-ES
enableProdMode();
@NgModule({
  declarations: [

    AppComponent,
    DisableInputDirective,
    AppComponent2,
    OnlyNumberWithOutDot,
    aReplacePipe,
    ReplacePipe,
    assetsUrl,
    PopupsComponent,
    TelefonPopupComponent,
    AppScrolltoFirstDirective,
    InfopopupComponent,
    PricedetailsComponent,
    GeburtstagInfoComponent,
    BestellenPopupComponent,
    LoaderComponent,
    LoginComponent,
    FirststageComponent,
    DatePickerComponent,
    BigpcNewComponent,
    VerificationNewComponent,
    DataSecurityFormNewComponent,
    ForthStageComponent,
    SidebarComponent,
    VerificationComponent,
    PcManualAddressComponent,
    BestellungComponent,
    EinstellungenComponent,
    PcCreateAddressComponent,
    GetLogoPipe,
    CustomDropdownComponent,
    EditComponent,
    CharAndNumDirective,
    DeleteComponent,
    EmailVarificationComponent,
    PwVerificationComponent,
    TruncatePipe,
    EmailFbComponent,
    KundconformPopupComponent,
    UpdateEmailVerificationComponent,
    ValidateAddressPipe,
    ProgressBarComponent,
    StagetwoComponent,
    StagefourComponent,
    VerificationlinkComponent,
    AddressSelectionComponent,
    SteuerhinweisPopupComponent,
    PricealarmComponent,
    ProgressalarmComponent,
    AlarmPricesComponent,
    AlarmStageoneComponent,
    AlarmStageThreeComponent,
    AlarmStagetwoComponent,
    DataComponent,
    RatePaymentRejectedInfoComponent,
    RatePaymentSelectionPopupComponent,
    AlternativeTextInfoComponent,
    UploadImageComponent,
    InvoiceComponent,
    NameInitialsPipe,
    VoucherNoticeComponent,
    EmergencyHotlineComponent,
    DashboardComponent,
    DeleteOnlineaccountComponent,
    SelectCalculatorComponent,
    DeleteAlarmComponent,
    removeAutocompleteDirective,
    TimezonePipe,
    EmailMarketingComponent,
    NewsletterCancellationComponent,
    GeburtstagSelectonPopupComponent,
    OnlyNumberWithDotDirective,
    SecuritycheckPipe,
    AuprisKleinerPreisrechner1Component,
    AuprisKleinerPreisrechner2Component,
    AuprisKleinerPreisrechner3Component,
    AuprisKleinerPreisrechner4Component,
    AuprisKleinerPreisrechner5Component,
    AuprisKleinerPreisrechner6Component,
    AuprisKleinerPreisrechner7Component,
    AuprisKleinerPreisrechner8Component,
    AuprisKleinerPreisrechner9Component,
    AuprisKleinerPreisrechner10Component,
    PriceCalculaorCommonComponent,
    TrackingVerificationComponent,
    TrackingInfoPopupComponent,
    DecimalpipePipe,
    OneComponent,
    InfoPasswordPopupComponent,
    InlinesvgDirective,
    PricedetailPopupComponent,
    ThreeComponent,
    LangReplacerPipe,
    AuprisKleinerPreisrechnerComponent,
    AuprisKleinerPreisrechnerPelletsComponent,
    AuprisKleinerPreisrechnerHeizolComponent,
    SmallPcComponent,
    SafePipe,
    PaymentErrorComponent,
  ],

  entryComponents: [
    PopupsComponent,
    TelefonPopupComponent,
    InfopopupComponent,
    AlternativeTextInfoComponent,
    BestellenPopupComponent,
    PricedetailsComponent,
    GeburtstagInfoComponent,
    DatePickerComponent,
    EditComponent,
    DeleteComponent,
    EmailFbComponent,
    KundconformPopupComponent,
    AddressSelectionComponent,
    SteuerhinweisPopupComponent,
    DataComponent,
    RatePaymentRejectedInfoComponent,
    RatePaymentSelectionPopupComponent,
    UploadImageComponent,
    InvoiceComponent,
    VoucherNoticeComponent,
    EmergencyHotlineComponent,
    DeleteOnlineaccountComponent,
    SelectCalculatorComponent,
    DeleteAlarmComponent,
    GeburtstagSelectonPopupComponent,
    TrackingInfoPopupComponent,
    VerificationNewComponent,
    PricedetailPopupComponent,
    SmallPcComponent
  ],

  exports: [
    GetLogoPipe,
    TruncatePipe,
    DecimalpipePipe,
    TimezonePipe,
    NameInitialsPipe,
    ValidateAddressPipe,
    DisableInputDirective,
    OnlyNumberWithOutDot,
    AppScrolltoFirstDirective,
    removeAutocompleteDirective,
    CharAndNumDirective
  ],

  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2CompleterModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    AngularMyDatePickerModule,
    AutocompleteLibModule,
    HttpClientModule,
    ImageCropperModule,
    LeafletModule,
  ],
  providers: [
    SmallPcService,
    BigpcService,
    WindowRefService,
    LanguageService,
    PortalAuthService,
    SocialService,
    CommonService,
    CommonServiceLatest,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestHandler,
      multi: true
    },
    {
      provide: DateAdapter, useClass: GermanDateAdapter,
    },

  ],

  bootstrap: [
    // AppComponent,
    // AppComponent_pellets,
    // PriceCalculaorCommonComponent,
    // AppComponent_heizol,
    AppComponent2,
    AuprisKleinerPreisrechner1Component,
    AuprisKleinerPreisrechner2Component,
    AuprisKleinerPreisrechner3Component,
    AuprisKleinerPreisrechner4Component,
    AuprisKleinerPreisrechner5Component,
    AuprisKleinerPreisrechner6Component,
    AuprisKleinerPreisrechner7Component,
    AuprisKleinerPreisrechner8Component,
    AuprisKleinerPreisrechner9Component,
    AuprisKleinerPreisrechner10Component,
    AuprisKleinerPreisrechnerComponent,
    AuprisKleinerPreisrechnerHeizolComponent,
    AuprisKleinerPreisrechnerPelletsComponent
  ]
})
export class AppModule { }
