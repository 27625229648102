import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appInlinesvg]'
})
export class InlinesvgDirective {
  @Input('appInlinesvg') inlineSvg;
  logoHtml: HTMLElement;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer
  ) { }
  ngOnInit() {
    this.appendSvg();
  }
  appendSvg() {

    this.httpClient.get(this.inlineSvg, { responseType: 'text' })
      .subscribe(logo => {
        let logoSecurity: any = this.sanitizer.bypassSecurityTrustHtml(logo);
        this.logoHtml = logoSecurity.changingThisBreaksApplicationSecurity ? logoSecurity.changingThisBreaksApplicationSecurity : logoSecurity;
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.logoHtml);
      }, error => {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');
      },
        () => {
          this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.logoHtml);
        });
  }
}
