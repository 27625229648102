import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-progressalarm',
  templateUrl: './progressalarm.component.html',
  styleUrls: ['./progressalarm.component.css']
})
export class ProgressalarmComponent implements OnInit {

  constructor() { }
  @Input() clickedoption: any;
  @Input() clickedPage: any;
  @Input() colors: any;
  @Input() theme: any;
  @Input() languageData: any;
  @Input() showFullScreenLoading: boolean;
  @Output() selectedPage = new EventEmitter<object>();

  ngOnInit() {
  }

  page(page) {
    if (!this.showFullScreenLoading) {
      if (this.clickedPage != 'page3') {
        this.selectedPage.emit(page)
      }

    }

  }

}
