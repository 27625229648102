

  <!-- <h4 style="text-align: center;" >Please submit your Email</h4> -->
  <div class="aupris_main" style="overflow-x:hidden ;">
  <h4 >{{languageData['login'].Var_BittesendenSieIhreEMail || '
      Bitte senden Sie Ihre E-Mail'}}</h4>
  <div class="auform-group">
    
   <div  style="text-align: center;" > <input type="text" placeholder="Email" [(ngModel)]="value" #email='ngModel'[pattern]="emailregex"  required> </div>
    <div class="alert-danger" style="margin:auto;margin-top:6px" *ngIf="email.invalid && email.touched">{{languageData['login'].Var_EMailistobligatorisch || '
        E-Mail ist obligatorisch'}}</div>
  </div>
  <!-- <div  style="display: block" style="padding-top:20px;">
  <a  (click)="abbort()">{{languageData['Edit'].Var_Abb || '
    Abbrechen'}}</a>
    <div> -->
      <!-- <div class= "aurow" > -->
        <!-- <span class="aucol-1"> -->
  <button  mat-raised-button class="au-btn"  mat-dialog-close style="float:left;margin-left: 34px;font-size: larger;font-weight: bold;background-color: #e4e1e1;">{{languageData['Edit'].Var_Abb || '
      Abbrechen'}}</button>
    
  <button mat-raised-button  class="au-btn" style="float:right;margin-right: 25px;font-size: larger;font-weight: bold;"  mat-dialog-close="proceed" appBackgroundcolor
    appActiveAhover="true"  [ngStyle]="{background: color.Grundfarbe,color: color.Schriftfarbe_Buttons}"  [disabled]="email.invalid"(click)="submit(email)">{{languageData['login'].Var_einreichen || '
    Einreichen'}}</button>
  </div>



