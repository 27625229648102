<div class="aupris_main scrolltotopprogress">
    <div style="width:100%; float:right">

        <app-progressalarm [languageData]="languageData" [colors]="colors" [theme]="theme"
            [clickedoption]="clickedoption" [showFullScreenLoading]="showFullScreenLoading" [clickedPage]="clickedPage"
            (selectedPage)="selectedPage($event)">
        </app-progressalarm>

        <app-alarm-stageone *ngIf="clickedPage=='Page1'" [clickedPage]="clickedPage" [priceAlarm]="priceAlarm"
            [_productInfoForWebPC]="productInfoForWebPC" [colors]="colors" [theme]="theme" [page_2]="page_2"
            [page_3]="page_3" [languageData]="languageData" (getPostalCodeEvent)="getPostalCodeEvent($event)"
            (selectPlzEvent)="selectPlzEvent($event)" (changeProductEvent)="changeProductEvent($event)"
            [plzOptions]="plzOptions" [qualityArray]="qualityArray" (submitValueEvent)="submitValueEvent()"
            (pricealarmCancel)="change()" [localoptions]="localoptions" [msg]="msg"
            [showFullScreenLoading]="showFullScreenLoading" [_products]="products">
        </app-alarm-stageone>

        <app-alarm-stagetwo *ngIf="clickedPage =='Page2'" [clickedPage]="clickedPage" [priceAlarm]="priceAlarm"  
            [SecoendSatgeData]="SecoendSatgeData" [Lieferort]="Lieferort" [pricesForm]="pricesForm" [colors]="colors"
            (page1Event)="page1Event($event)" (getpriceCalculatorEvent)="getpriceCalculatorEvent()"
            (pricedetailsEvent)="pricedetailsEvent($event)" [colors]="colors" [theme]="theme" [minMaxData]="minMaxData"
            [languageData]="languageData" [volume]="volume" [weight]="weight" [volume_short]="volume_short"
            [weight_short]="weight_short" [noData]="noData" [labels]="labels" [isPriceFormValid]="isPriceFormValid"
            [currency_symbol]="currency_symbol" [final_PC]="final_PC" [_offer]="offer" [localoptions]="localoptions"
            (stageThreeEvent)="stageThreeEventHandler()" [desiredPriceLimit]="desiredPriceLimit"
            (steuerhinweisPopupEvent)="steuerhinweisPopupEvent()"
            (specialtextpopup1Event)="specialtextpopup1Event($event)" [viewsnack2]="viewsnack2"
            [viewsnack1]="viewsnack1" [showFullScreenLoading]="showFullScreenLoading" [_langVariable]="langVariable">
        </app-alarm-stagetwo>


        <app-alarm-stage-three *ngIf="clickedPage =='Page3'" [clickedPage]="clickedPage" [colors]="colors"
            [theme]="theme" [notificationForm]="notificationForm" [languageData]="languageData"
            (page2Event)="page2Event($event)" (page3Event)="page3Event()" (gotoPage2Event)="gotoPage2Event()"
            [showFullScreenLoading]="showFullScreenLoading">
        </app-alarm-stage-three>
    </div>
</div>


<!-- <div [ngClass]="{snackbar:true,show:viewsnack}">
    Please agree to the given information
    <button mat-button class="close-icon" (click)="viewsnack = false">
        <mat-icon>close</mat-icon>
    </button>
</div> -->

<!-- <div [ngClass]="{snackbar:true,show:viewsnack1}">
    {{languageData['Bestelloptionen'].Var_WunschpreisError || 'Preis muss kleiner als aktueller Preis sein.'}}
    <button mat-button class="close-icon" (click)="viewsnack1 = false">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div [ngClass]="{snackbar:true,show:viewsnack2}">
    {{languageData['Bestelloptionen'].Var_PreisalarmError || 'Preis muss größer als aktueller Preis sein.'}}


    <button mat-button class="close-icon" (click)="viewsnack2 = false">
        <mat-icon>close</mat-icon>
    </button>
</div> -->