<div class="aupris_main">
  <div class="au-container">
    <div class="aucol-md-6 aucol-md-offset-3 aucol-sm-6 aucol-sm-offset-3 aucol-lg-6 aucol-lg-offset-3">
      <div class="panel panel-primary">
        <div class="panel-heading text-center" [ngStyle]="{background: colors.Grundfarbe}">
          <h4 [ngStyle]="{color:colors.Schriftfarbe_Buttons}">Data Security</h4>
        </div>
        <div class="panel-body-aupris">
          <div>
            <pre
              style="border:none;background-color:white;font-family: inherit;font-size: inherit;">{{Datenschutzerklarung}}</pre>
          </div>
          <form [formGroup]="dataSecurityForm" (ngSubmit)="dataSecurityEvent()">
            <div class="auform-group">
              <label for="eMail">EMail:</label>
              <input type="text" autocomplete="eMail" class="auform-control" formControlName="eMail">
              <div class="alert-danger"
                *ngIf="(!dataSecurityForm.controls.eMail.valid && dataSecurityForm.controls.eMail.touched) || DataSecurity_Man_check && dataSecurityForm.controls.eMail.status == 'INVALID'">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!</div>
            </div>
            <div class="auform-group">
              <label for=" Vorname"> {{languageData['Adresseingabe'].Var_Vorname || 'Vorname'}}</label>
              <input type="text" autocomplete="Vorname" class="auform-control" formControlName="Vorname">
              <div class="alert-danger"
                *ngIf="!dataSecurityForm.controls.Vorname.valid && dataSecurityForm.controls.Vorname.touched || DataSecurity_Man_check && dataSecurityForm.controls.Vorname.status == 'INVALID'">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!</div>
            </div>
            <div class="auform-group">
              <label for=" Nachname"> {{languageData['Adresseingabe'].Var_Nach || 'Nachname'}}:</label>
              <input type="text" autocomplete="Nachname" class="auform-control" formControlName="Nachname">
              <div class="alert-danger"
                *ngIf="!dataSecurityForm.controls.Nachname.valid && dataSecurityForm.controls.Nachname.touched || DataSecurity_Man_check && dataSecurityForm.controls.Nachname.status == 'INVALID'">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!</div>
            </div>
            <div class="aurow" style="margin:5px 0px 10px 5px;">
              <div class="form-check">
                <input autocomplete="foo" class="form-check-input" type="checkbox" formControlName="checkbox"> Ich
                {{languageData['Adresseingabe'].Var_widerrufe || 'widerrufe meine Zustimmung zur Datenschutzvereinbarung im Onlinekauf.'}}
              </div>
              <div class="alert-danger"
                *ngIf="!dataSecurityForm.controls.checkbox.valid && dataSecurityForm.controls.checkbox.touched || DataSecurity_Man_check && dataSecurityForm.controls.checkbox.status == 'INVALID'">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!</div>
              <div class="alert-danger" *ngIf="emaildoesnotexist">{{languageData['Adresseingabe'].Var_Unbekannte || 'Unbekannte Email Adresse!'}}</div>
              <p style="margin-left:45%;padding:2%"></p>
              <button class="au-btn btn-default futuresbutton" type="submit"
                [ngStyle]="{background: colors.Grundfarbe,color: colors.Schriftfarbe_Buttons}"
                style="float:right">{{languageData['Adresseingabe'].Var_Abschicken || 'Abschicken'}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="{snackbar:true,show:viewsnack7}">{{languageData['Bestatigung'].Var_Die_Preisanderung_muss_vor_Bestellung || 'Die Preisänderung muss vor Bestellung akzeptiert werden!'}}</div>