import { Component, OnInit } from '@angular/core';
import { SmallPcService } from '../../Services/small-pc.service';
import { WindowRefService, ICustomWindow } from '../../Services/window.service'
import { DeviceDetectionService } from 'app/Services/device-detection.service';

@Component({
  selector: 'aupris-kleiner-preisrechner-1',
  templateUrl: './aupris-kleiner-preisrechner1.component.html',
  styleUrls: ['./aupris-kleiner-preisrechner1.component.css']
})
export class AuprisKleinerPreisrechner1Component implements OnInit {
  tag = 1;
  tagData = [];

  private _window: ICustomWindow;
  tracking: Boolean = false;
  constructor(
    public smallPc: SmallPcService,
    private windowRef: WindowRefService,
    public deviceDetetect: DeviceDetectionService
  ) { }

  ngOnInit(): void {
        this.smallPc.getWebTags('1').subscribe((webLinkData) => {
      if (webLinkData.success) {
        this.tagData = webLinkData.data;
      }
    });
    
    //for liveTracking
    this._window = this.windowRef.nativeWindow;
    let url = this._window.location.href;
    let params = new URLSearchParams(url);
    if (decodeURIComponent(params.get("view")) == 'tracking') this.tracking = true;
  }

}
