import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})

export class ReplacePipe implements PipeTransform {
    transform(value: any): any {
        var regex = new RegExp("^[0-9,.]");
        if (value && regex.test(value)) {
            value = value.toString();
            if (value.includes(',') && value.includes('.')) {
                value = value.replace('.', '');
            }
            // worked with sai and dinesh
            // if (value.includes('.')) {
            //     let vsp = value.split('.');
            //     if (vsp[0].toString().length < 2) {
            //         value = value.replace('.', '');
            //     }
            // }
            if (value.includes(',')) {
                value = value.replace(',', '.');
            }
            let v = value.split('.');
            if (v.length > 2) {
                value = v[0] + '' + v[1] + '.' + v[2];
            }
            let n = new Number(value);
            let val = n.toLocaleString("de-DE", { minimumFractionDigits: 2 });
            return val;
        } else {
            return value;
        }

    }
}