import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { AdminService } from 'app/Services/admin.service';
import { LanguageService } from '../Services/language.service';


@Component({
  selector: 'app-newsletter-cancellation',
  templateUrl: './newsletter-cancellation.component.html',
  styleUrls: ['./newsletter-cancellation.component.css']
})
export class NewsletterCancellationComponent implements OnInit {

  languageData: any;
  @Input() colors;
  @Output() gotoUser = new EventEmitter<boolean>();

 
  constructor(
    private langService: LanguageService,
    // private admin: AdminService,
    ) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }
  toUser() {
     this.gotoUser.emit()
  }
  ngOnInit(): void {
  }

}
