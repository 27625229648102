<div class="aupris_main">
    <h2 *ngIf="name_local=='Datenschutzerklarung'" md-dialog-title style="text-align:center;">Datenschutzerklärung</h2>
    <h2 *ngIf="name_local=='Nutzungsbedingungen'" md-dialog-title style="text-align:center;">Nutzungsbedingungen</h2>
    <!-- <div [cgBusy]="busy" [cgBusyConfig]="{message:'Bitte warten...'}"></div> -->
    <div *ngIf="name_local=='Datenschutzerklarung'" style="width:100%; height:300px;">
        <pre style="border:none;background-color:white;font-family: inherit;font-size: inherit;">{{Datenschutzerklarung}}</pre>
    </div>
    <div *ngIf="name_local=='Nutzungsbedingungen'" style="width:100%; height:300px;">
        <pre style="border:none;background-color:white;font-family: inherit;font-size: inherit;">{{Nutzervereinbarung}}</pre>
    </div>
    <button class="au-btn btn-default futuresbutton" mat-dialog-close [ngStyle]="{background: colors.Grundfarbe,color: colors.Schriftfarbe_Buttons}"
        style="float:right">Schließen</button>
</div>
<!-- <loader [full-screen]="true" *ngIf="showFullScreenLoading">  </loader>    -->