import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LanguageService } from '../Services/language.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-verificationlink',
  templateUrl: './verificationlink.component.html',
  styleUrls: ['./verificationlink.component.css']
})
export class VerificationlinkComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  @Input() register;
  @Input() forgotPassword;
  @Output('registered') registered = new EventEmitter();
  svgs = [
    environment.assetsUrl+'assets/svgs/mailVerification.svg',

  ];
  langData: any;
  constructor(private langService: LanguageService ,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer, ) { }

   ngOnInit() {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
      this.langData = data['login'] || {};
    });
   this.getDynamicSVG(this.svgs)

   }

   getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
      this.httpClient.get(item, { responseType: 'text' })
        .subscribe((logo) => {
          logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
          logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
          logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
          logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
          this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
        })
    });
  };
}
