import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SmallPcService } from './Services/small-pc.service';
import { maxminValidations } from './Validations/maxmin.validators';
import { WindowRefService, ICustomWindow } from './Services/window.service'
import { LanguageService } from './Services/language.service';
import { environment } from './../environments/environment';
import { CompleterData, CompleterService } from './ng2Completer/ng2-completer';

@Component({
  selector: 'Aupris-Kleiner-Preisrechner',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  hostname: any;
  url: any;
  // label: string = 'Menge';
  label: string = 'Liter';
  dataService: CompleterData;
  searchData: any;
  public products: any = [];
  selectedValue: any = ''; // when giving a predefined link make this value to "Heizöl" or "Pellets"
  public myForm: FormGroup;
  minmaxdata: any;
  Lieferort: any;
  bigpclink: any;
  colors: any;
  private _window: ICustomWindow;
  siteKey: string = '6Lf66HoUAAAAAI5ipILiQCkJWZRIf4LhmjM71jZs';
  ActivateDeactivatePc = {
    'Heizol': false,
    'HeizolText': "",
    'Pellets': false,
    'PelletsText': ""
  };
  units: any;
  volueme: any;
  weight: any;
  currency: any;
  errMsg = '';
  pricecalc: string = environment.pricecalc;
  pcOff_Pbbackend: boolean = false;
  abladestelle_pc_heatoil: boolean = false;
  abladestelle_pc_pellets: boolean = false;
  showAbladestelle: boolean = false;
  inLimitHouseHold: boolean = false;
  public abladestellen: any[] = [
    { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }, { value: 13 },
    { value: 14 }, { value: 15 }, { value: 16 }, { value: 17 }, { value: 18 }, { value: 19 }, { value: 20 }
  ];
  public loadingStations: any[] = [
    { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 },
    { value: 11 }, { value: 12 }, { value: 13 }, { value: 14 }, { value: 15 }, { value: 16 }, { value: 17 }, { value: 18 }, { value: 19 }, { value: 20 },
    { value: 21 }, { value: 22 }, { value: 23 }, { value: 24 }, { value: 25 }, { value: 26 }, { value: 27 }, { value: 28 }, { value: 29 }, { value: 30 },
    { value: 31 }, { value: 32 }, { value: 33 }, { value: 34 }, { value: 35 }, { value: 36 }, { value: 37 }, { value: 38 }, { value: 39 }, { value: 40 },
    { value: 41 }, { value: 42 }, { value: 43 }, { value: 44 }, { value: 45 }, { value: 46 }, { value: 47 }, { value: 48 }, { value: 49 }, { value: 50 },
    { value: 51 }, { value: 52 }, { value: 53 }, { value: 54 }, { value: 55 }, { value: 56 }, { value: 57 }, { value: 58 }, { value: 59 }, { value: 60 },
    { value: 61 }, { value: 62 }, { value: 63 }, { value: 64 }, { value: 65 }, { value: 66 }, { value: 67 }, { value: 68 }, { value: 69 }, { value: 70 },
    { value: 71 }, { value: 72 }, { value: 73 }, { value: 74 }, { value: 75 }, { value: 76 }, { value: 77 }, { value: 78 }, { value: 79 }, { value: 80 },
    { value: 81 }, { value: 82 }, { value: 83 }, { value: 84 }, { value: 85 }, { value: 86 }, { value: 87 }, { value: 88 }, { value: 89 }, { value: 90 },
    { value: 91 }, { value: 92 }, { value: 93 }, { value: 94 }, { value: 95 }, { value: 96 }, { value: 97 }, { value: 98 }, { value: 99 },
  ]

  abladestellenCount: any;
  min_pellets: number;
  min_heizol: number;
  invalidLieferort: boolean;
  sessionId: string;
  traderRedirect: boolean = false;
  ratepaymentInQuery: boolean;
  plzOptCheck: boolean;
  plzOptions: any;
  msg: string;
  errorMsg: boolean = false;
  tabErrorMsg: boolean = false;
  dynamicProductId: any;

  showremainingValues: boolean = false;
  dynamicPCData: any;
  colorsPc: any;
  captcha: any;
  productVal: any;
  minimumAmount: any;
  maximumAmount: any;
  minAmount: boolean = false;
  maxAmount: boolean = false;
  customersLiveTracking: Boolean = false;
  constructor(
    private langService: LanguageService,
    private windowRef: WindowRefService,
    private _fb: FormBuilder,
    public completerService: CompleterService,
    private SmallPcService: SmallPcService,
  ) {

    this._window = this.windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;

    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  smallpc_ttr_fr: boolean = true;
  productdup: string = '';
  dataLayer = window['dataLayer'] || [];

  ngOnInit() {
    this.url = this._window.location.href;
    let params = new URLSearchParams(this.url);
    if (!params.get('sid')) {
      // alert("side not avaiable")
      let sessionId = this.SmallPcService.genereateNewSessionId();
      this.sessionId = sessionId;
      console.log("session id", sessionId)
      // window.history.replaceState('', document.title, `${this.url}&sid=${sessionId}`)
      // window.location.href = `${this.url}&sid=${sessionId}`;
    } else {
      this.sessionId = params.get('sid');
    }
    // this.SmallPcService.getWebTags().subscribe((data) => {
    // if (data.success) {
    //   this.dynamicPCData = data.data;
    //   this.colorsPc = data.data[0];
    //   this.captcha = data.data[0].captcha;
    // }
    // if (!this.myForm) {
    //   this.myForm = this._fb.group({
    //     product: ['', [Validators.required]],
    //     Lieferort: [undefined, Validators.required],
    //     Liter: ['', [Validators.required]],
    //     abladestellen: ['1'],
    //     recaptchaReactive: [null, [Validators.required]]
    //   }, { validator: maxminValidations(this.minmaxdata) });
    // } else {
    //   this.myForm = this._fb.group({
    //     product: [this.myForm?.value.product, [Validators.required]],
    //     Lieferort: [this.myForm?.value.Lieferort, Validators.required],
    //     Liter: [this.myForm?.value.Liter, [Validators.required]],
    //     abladestellen: [this.myForm?.value.abladestellen],
    //     recaptchaReactive: [this.myForm?.value.recaptchaReactive, [Validators.required]]
    //   }, { validator: maxminValidations(this.minmaxdata) });
    // }
    // if (this.Captcha) {
    //   this.myForm.controls['recaptchaReactive'].setValidators([Validators.required]);
    //   this.myForm.controls['recaptchaReactive'].updateValueAndValidity();
    // } else {
    //   this.myForm.controls['recaptchaReactive'].setValidators([]);
    //   this.myForm.controls['recaptchaReactive'].updateValueAndValidity();
    // }
    // })
    if (params.get("traderRedirect")) {
      this.traderRedirect = true;
    } else {
      this.traderRedirect = false;
    }
    if (params.get("ratepayment") === 'true') {
      this.ratepaymentInQuery = true;
    } else {
      this.ratepaymentInQuery = false;
    }
    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));    /*test calc*/
    }

    if ((decodeURIComponent(params.get("view")) == 'bigpc') ||
      (decodeURIComponent(params.get("view")) == 'verification') ||
      (decodeURIComponent(params.get("view")) == 'login') ||
      (decodeURIComponent(params.get("view")) == 'data_security') ||
      (decodeURIComponent(params.get("view")) == 'dataSecurityForm') ||
      (decodeURIComponent(params.get("view")) == 'verified') ||
      (decodeURIComponent(params.get("view")) == 'updateemail') ||
      (decodeURIComponent(params.get("view")) == 'pwverified') ||
      (decodeURIComponent(params.get("view")) == 'emailMarketing') ||
      (decodeURIComponent(params.get("view")) == 'newsLetterCancellation')
    ) {
      this.smallpc_ttr_fr = false;
    }
    else {
      this.smallpc_ttr_fr = true;
    };
  }


  getDynamicPcList() {
    this.SmallPcService.dynamicprodcuts().subscribe((data: any) => {

      if (data.success) {
        this.dynamicPCData = data.data;
        this.colorsPc = data.data[0];
      }
      if (!this.myForm) {
        this.myForm = this._fb.group({
          product: ['', [Validators.required]],
          Lieferort: [undefined, Validators.required],
          Liter: ['', [Validators.required]],
          abladestellen: ['1'],
          // recaptchaReactive: [null, [Validators.required]]
        }, { validator: maxminValidations(this.minmaxdata) });
      } else {
        this.myForm = this._fb.group({
          product: [this.myForm?.value.product, [Validators.required]],
          Lieferort: [this.myForm?.value.Lieferort, Validators.required],
          Liter: [this.myForm?.value.Liter, [Validators.required]],
          abladestellen: [this.myForm?.value.abladestellen],
          // recaptchaReactive: [this.myForm?.value.recaptchaReactive, [Validators.required]]
        }, { validator: maxminValidations(this.minmaxdata) });
      }
      // if (this.captcha) {
      //   this.myForm.controls['recaptchaReactive'].setValidators([Validators.required]);
      //   this.myForm.controls['recaptchaReactive'].updateValueAndValidity();
      // } else {
      //   this.myForm.controls['recaptchaReactive'].setValidators([]);
      //   this.myForm.controls['recaptchaReactive'].updateValueAndValidity();
      // }
    });
  }
  // getDynamicPcList() {
  //   this.SmallPcService.dynamicprodcuts().subscribe((data:any) => {


  //   });
  // }

  save(data, valid) {
    if (valid) {
      // if (!localStorage.getItem('aupris_token')) {
      //   this._window.location.href = `http://localhost:4900/#&Domainname=${this.hostname}&view=login`
      //   // 'https://' + this.bigpclink + '#&Lieferort=' + this.Lieferort + '&Liter=' + data.value.Liter + '&Product=' + data.value.product + '&view=bigpc';
      //   return
      // }
      if (!this.sessionId) {
        this.sessionId = this.SmallPcService.genereateNewSessionId();
      }
      if (this.traderRedirect) {
        let url;
        //if (!environment.testSite_or_thirdPartySites) {
        //  url = `${this.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&Lieferort=${this.Lieferort}&Liter=${data.value.Liter}&abladestellen=${data.value.abladestellen}&Product=${data.value.product}&stage=one&traderRedirect=true&page=${data.value.product}`;
        //} else {
        url = `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&Lieferort=${this.Lieferort}&Liter=${data.value.Liter}&abladestellen=${data.value.abladestellen}&Product=${data.value.product}&stage=one&traderRedirect=true&page=${data.value.product}`;
        //}
        if (this.ratepaymentInQuery) {
          url = `${url}&ratepayment=true`
        }
        // window.top.location.href = url;
        window.open(url)
        // window.top.location.href = `http://${this.bigpclink}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&Lieferort=${this.Lieferort}&Liter=${data.value.Liter}&abladestellen=${data.value.abladestellen}&Product=${data.value.product}&stage=one&traderRedirect=true`;
        // window.parent.location.href =  `${this.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&stage=one`;
        return false;
      }

      if (!this.Lieferort) {
        return
      }
      if (!environment.testSite_or_thirdPartySites) {
        this.dataLayer.push({
          'event': 'auprisCalculatePrice'
        });

        // let url = `${this.pricecalc}#&Domainname=${this.hostname}&Lieferort=${this.Lieferort}&Liter=${data.value.Liter}&abladestellen=${data.value.abladestellen}&Product=${data.value.product}&view=bigpc&sid=${sessionId}`;
        let url = `${this.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&stage=one`;
        if (this.ratepaymentInQuery) {
          url = `${url}&ratepayment=true`
        }
        let sessionData = {
          priceCalcUrl: this.pricecalc,
          domainName: this.hostname,
          lieferort: this.Lieferort,
          liter: data.value.Liter,
          abladestellen: data.value.abladestellen,
          product: data.value.product,
          localOptions: {
            Lieferort: { _id: this.Lieferort },
            Liter: { value: data.value.Liter },
            Abladestellen: { number: Number(data.value.abladestellen) },
            Produkt: { name: data.value.product }
          }
        }
        this.SmallPcService.setSessionData(this.sessionId, sessionData).then(() => {
          window.location.href = url;
        })
      } else {
        this.dataLayer.push({
          'event': 'auprisCalculatePrice'
        });
        // let url = `https://${this.bigpclink}#&Lieferort=${this.Lieferort}&Liter=${data.value.Liter}&abladestellen=${data.value.abladestellen}&Product=${data.value.product}&view=bigpc`;
        let url = `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&stage=one`;
        if (this.ratepaymentInQuery) {
          url = `${url}&ratepayment=true`
        }
        let sessionData = {
          priceCalcUrl: this.bigpclink,
          domainName: this.hostname,
          lieferort: this.Lieferort,
          liter: data.value.Liter,
          abladestellen: data.value.abladestellen,
          product: data.value.product,
          localOptions: {
            Lieferort: { _id: this.Lieferort },
            Liter: { value: data.value.Liter },
            Abladestellen: { number: Number(data.value.abladestellen) },
            Produkt: { name: data.value.product }
          }
        }
        this.SmallPcService.setSessionData(this.sessionId, sessionData).then(() => {
          this._window.location.href = url;
        })
      }


      // window.open('https://' + this.bigpclink + '#&Domainname=' + this.hostname + '&Lieferort=' + this.Lieferort + '&Liter=' + data.value.Liter + '&Product=' + data.value.product + '&view=bigpc', "_blank");
      // }
    }
  }


  conversionchange(event) {
    let productData = this.dynamicPCData.find(({ dynamicProductId }) => dynamicProductId == event.value)
    if (productData.dynamicProductId) {
      this.showremainingValues = true;
      this.showAbladestelle = true;
    }
    this.minimumAmount = productData.minAmount,
      this.maximumAmount = productData.maxAmount
    this.loadingStations.length = productData.additionalStationsCount;
  }

  // <<<<<<< HEAD
  // validateMenge() {
  //   this.min_heizol = this.minmaxdata.min.min_heatoil_value * this.myForm.controls['abladestellen'].value;
  //   this.min_pellets = this.minmaxdata.min.min_pellets_value * this.myForm.controls['abladestellen'].value;
  //   let liters = Number(this.myForm.controls['Liter'].value);
  //   if (this.productdup == 'Heizol') {
  //     if (liters < this.min_heizol) {
  //       this.inLimitHouseHold = true;
  //       this.label = 'Liter';

  //     } else {
  //       this.inLimitHouseHold = false;
  //       this.label = 'Liter';

  //     }
  //   }
  //   if (this.productdup == 'Pellets') {
  //     if (liters < this.min_pellets) {
  //       this.inLimitHouseHold = true;
  //       this.label = 'Kg';

  //     } else {
  //       this.inLimitHouseHold = false;
  //       this.label = 'Kg';

  //     }
  // =======
  validateMenge(e) {
    if (e < this.minimumAmount) {
      this.minAmount = true
    }
    else {
      this.minAmount = false;

    }
    if (e > this.maximumAmount) {
      this.maxAmount = true
    }
    else {
      this.maxAmount = false;
    }
    // this.min_heizol = this.minmaxdata.min.min_heatoil_value * this.myForm.controls['abladestellen'].value;
    // this.min_pellets = this.minmaxdata.min.min_pellets_value * this.myForm.controls['abladestellen'].value;
    // let liters = Number(this.myForm.controls['Liter'].value);
    // if (this.productdup == 'Heizol') {
    //   if (liters < this.min_heizol) {
    //     this.inLimitHouseHold = true;
    //     this.label = 'Liter';

    //   } else {
    //     this.inLimitHouseHold = false;
    //     this.label = 'Liter';

    //   }
    // }
    // if (this.productdup == 'Pellets') {
    //   if (liters < this.min_pellets) {
    //     this.inLimitHouseHold = true;
    //     this.label = 'Kg';

    //   } else {
    //     this.inLimitHouseHold = false;
    //     this.label = 'Kg';

    //   }
    // }

  }

  validate(e) {
    var regex = new RegExp("[a-zA-Z0-9äöüÄÖÜß ]");
    var key = e.keyCode || e.which;
    key = String.fromCharCode(key);

    if (!regex.test(key)) {
      e.returnValue = false;
      if (e.preventDefault) {
        e.preventDefault();
      }
    }
  }

  selectPlz(event) {
    if (event) {
      this.tabErrorMsg = false;
      this.Lieferort = event._id;
    }
    if (!event || !event._id) {
      this.myForm.setErrors({ Lieferort: false })
    } else {
      this.myForm.setErrors(null)
    }

  }
  onKeyy(e) {
    if (e.key == "Tab") {
      this.tabErrorMsg = this.Lieferort ? false : true
    }
    else if (e.key == "Backspace") {
      this.Lieferort = undefined;
      if (this.Lieferort == undefined) {
        this.tabErrorMsg = true;
      }
    }
  };


  // getPostalCode(e) {
  //   if (e !== undefined && e.length >= 4) {
  //     let objectname = { event: e, product: this.myForm.value.product, dynamicProductId: this.dynamicProductId };
  //     this.SmallPcService.getPostalcode(objectname, this.hostname).subscribe((data: any) => {
  //       if (data.success) {
  //         this.plzOptCheck = true;
  //         this.plzOptions = data.data;
  //         let Options = data.data;
  //         this.plzOptions = Options.sort((a, b) => {
  //           if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
  //           if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
  //         });
  //         this.msg = "";
  //         this.errorMsg = false;
  //       }
  //       else {
  //         this.plzOptCheck = false;
  //         this.errorMsg = true;
  //         this.plzOptions.length = 0;
  //         this.msg = "Nur Auswahl aus dem Dropdown Menü zulässig!"
  //       }
  //     })
  //   } else {
  //     this.plzOptions = [];
  //     this.errorMsg = false;

  //   }
  // }


  onKey(Event: any) {
    if (Event.length >= 3) {
      Event = Event.trim()
      // let event = Event.split(' ').pop();
      // let objectname = {
      //   event: event,
      //   product: this.myForm.value.product
      // };
      if (this.searchData) {
        this.searchData = this.searchData.reduce((prev, postalcode) => {
          let prevIndex = prev.findIndex(p => postalcode._id == p._id)
          if (postalcode.produkts.indexOf(this.myForm.controls['product'].value) >= 0 && prevIndex < 0) {
            prev = [...prev, postalcode]
          }
          return prev
        }, [])
        this.dataService = this.completerService.local(this.searchData.concat([]),
          'country_code,place_name,suburb',
          'place_name,country_code,suburb');
      }
    }
    // });

  }

  onNg2Blur() {
    if (this.myForm.controls['Lieferort'].invalid) {
      this.invalidLieferort = true;
    } else {
      this.invalidLieferort = false
    }
  }

  isvalid() {
    if (this.myForm.get('product').valid && this.Lieferort && this.myForm.get('Lieferort').valid && this.myForm.get('Liter').valid && this.myForm.get('abladestellen').valid) {
      return false;
    } else {
      return true
    }
  };

}
