import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LanguageService } from '../Services/language.service';
import { AdminService, } from 'app/Services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from 'app/popups/delete/delete.component';

import { UserAddress } from '../models/portalModels/portalUserAddress.interface';
import { CommonService } from 'app/common.service';
@Component({
  selector: 'app-pc-manual-address',
  templateUrl: './pc-manual-address.component.html',
  styleUrls: ['./pc-manual-address.component.css']
})
export class PcManualAddressComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  @Input('createAddress') createAddress: boolean = false;
  @Input('minMaxData') minMaxData;
  @Input('colors') colors;
  userAddresses: UserAddress[];
  editableAddress: UserAddress;
  editAdr: boolean;
  viewsnack: boolean = false;
  Pellets: any;
  Heizöl: any;
  sessionId: string;
  @Output() showPricecalcfromAddress = new EventEmitter();
  url: any;
  editDocId: any;
  // private _window: ICustomWindow;
  loaderForScreen: any = false;
  loaderTime: any = 10000;
  constructor(
    private langService: LanguageService,
    private adminService: AdminService,
    public dialog: MatDialog,
    public commonService: CommonService,
  ) { }

  ngOnInit() {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this.userAddresses = this.adminService.userAdresses;
    this.getAddresses();
    this.url = window.location.href;

    if (this.url.includes("subPage=addAdressen")) {
      this.openCreateAddress();
    };

    if (this.url.includes("subPage=deleteAddress")) {
      var params = new URLSearchParams(this.url);
      this.editDocId = decodeURIComponent(params.get("_id"));
      this.commonService.setEditAddressId(this.editDocId);
      setTimeout(() => {
        let findEditDoc = (this.userAddresses || []).find((doc) => doc._id == this.editDocId);
        this.deleteAddress(findEditDoc);
      }, 700);
    };


    if (this.url.includes("subPage=editAdressen")) {
      var params = new URLSearchParams(this.url);
      this.editDocId = decodeURIComponent(params.get("_id"));
      this.commonService.setEditAddressId(this.editDocId);
      setTimeout(() => {
        let findEditDoc = (this.userAddresses || []).find((doc) => doc._id == this.editDocId);
        this.editAddress(findEditDoc);
      }, 700);
    };

  }

  async getAddresses() {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.adminService.getPortaladdresses().subscribe((result: any) => {
      if (result.success) {
        this.userAddresses = result.data;
        this.loaderForScreen = false;
        this.userAddresses = this.userAddresses.sort((a) => {
          if (a.isPrimary == true) {
            return -1
          } else {
            return 1
          };
        });
      }
    }, () => {
      this.loaderForScreen = false;
    });
  };

  stopLoaderHere() {
    setTimeout(() => {
      this.loaderForScreen = false;
    }, this.loaderTime);
  };

  setprimary(id, kundenKontoId) {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.adminService.setprimary({ id: id, kundenKontoId: kundenKontoId }).subscribe(result => {
      this.loaderForScreen = false;
      if (result.success) {
        this.getAddresses();
      };
    });
  };

  editAddress = (address: UserAddress) => {
    this.addKeyInUrl(`&subPage=editAdressen&_id=${address._id}`);
    this.createAddress = true;
    this.editAdr = true;
    this.editableAddress = address;
  };

  cancelEdit = () => {
    this.editableAddress = <UserAddress>{};
    this.createAddress = false;
    this.editAdr = false;
    this.getAddresses();
    this.url = window.location.href; /*testcal */
    var params = new URLSearchParams(this.url);
    let subPageName = decodeURIComponent(params.get("subPage"));
    let removeSubpage;
    if (this.url.includes("page=Adressen") && subPageName == 'addAdressen') {
      removeSubpage = "&subPage=addAdressen"
      this.removeKeyInUrl(removeSubpage);
    } else if (this.url.includes("page=Adressen") && subPageName == 'editAdressen') {
      this.editDocId = decodeURIComponent(params.get("_id"));
      this.removeKeyInUrl(`&subPage=editAdressen&_id=${this.editDocId}`);
    };
  };

  openCreateAddress = () => {
    this.addKeyInUrl("&subPage=addAdressen");
    this.createAddress = true;
    this.editAdr = false;
    this.editableAddress = <UserAddress>{};
  };

  addKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    window.history.pushState("", document.title, url);
    if (!url.includes(subPageName)) {
      newUrl = `${newUrl}${subPageName}`;
      window.history.replaceState('', document.title, newUrl);
    };
  };


  removeKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    if (newUrl.includes(subPageName)) {
      newUrl = newUrl.replace(subPageName, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  deleteAddress = (item) => {
    if (item.isPrimary) {
      this.viewsnack = true;
      setTimeout(() => {
        this.viewsnack = false;
      }, 4500);
    } else {
      this.addKeyInUrl(`&subPage=deleteAddress&_id=${item._id}`);
      const dialogRef = this.dialog.open(
        DeleteComponent,
        {
          width: '500px',
          disableClose: true,
          data: this.colors
        }
      )
      dialogRef.afterClosed().subscribe((data: any) => {
        this.removeKeyInUrl(`&subPage=deleteAddress&_id=${item._id}`);
        if (data.delete == true) {
          this.loaderForScreen = true;
          this.stopLoaderHere();
          this.adminService.deleteAddress({ _id: item._id }).subscribe((data: any) => {
            this.loaderForScreen = false;
            this.getAddresses();
          }, error => {
            console.log(error);
          });
        };
      });
    };
  };

  calculateprice(address) {
    this.showPricecalcfromAddress.emit(address);
  };

}

