import { Injectable } from '@angular/core';
//import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
//import { AuthService } from "angularx-social-login";
// import { PortalAuthService } from './auth.service';
// import { WindowRefService, ICustomWindow } from '../Services/window.service'
// import { addressmodel } from 'app/bigpc/addressmodel';
// import { fbdetails } from './login.component';
// import { googledetails } from './login.component'
// import { async, reject } from 'q';
// import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  googleData: any;
  facebookData: any;
  url: any;
  // private _window: ICustomWindow;
  hostname: string;
  domainname: string;

  constructor(
    //private authServicefacegoogle: AuthService, 
    // private auth:PortalAuthService, 
    // private windowRef: WindowRefService,
  ) { }

  // googleLogin(): Promise<googledetails> {
  //   return new Promise (async (resolve, reject)  => {
  //     try {
  //       this.authServicefacegoogle.signIn(GoogleLoginProvider.PROVIDER_ID).then((data: any) => {
  //         this.googleData = data;
  //         resolve(data);
  //       })
  //     } catch (err) {
  //       reject(err);
  //     }
  //   })
  // }

  // fbLogin(): Promise<fbdetails> {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       this.authServicefacegoogle.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: any) => {
  //         this.facebookData = data;
  //         resolve(data);
  //       });
  //     } catch (err) {
  //       reject(err);
  //     }

  //   });
  // }
}
