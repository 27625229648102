import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { WindowRefService, } from '../Services/window.service';
import { UserTokenDetails } from '../models/portalModels/userTokenDetails.interface';
import { UserInfo } from 'app/models/portalModels/userInfo.interface';
import { FbDetails } from 'app/models/thirdPartSignInModels/fbDetails.interface';
import { GoogleDetails } from 'app/models/thirdPartSignInModels/google.interface';


@Injectable({
  providedIn: 'root'
})


export class PortalAuthService {
  domainname: string;
  baseUrl: string = environment.baseUrl;
  userdetails: string;
  isLoggedIn: boolean;
  isLoggedInSubject: Subject<{ userLogin: boolean, guestLogin: boolean }> = new Subject();
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  userDetailsChangedSubject: Subject<UserInfo> = new Subject();
  detailsChanged$ = this.userDetailsChangedSubject.asObservable();
  googleData: any;
  constructor(
    private http: HttpClient,
    private windowRef: WindowRefService
  ) {
    var _window = this.windowRef.nativeWindow;
    var url = _window.location.href;
    var params = new URLSearchParams(url);
    this.domainname = decodeURIComponent(params.get("Domainname"));
  }

  newUserRegistration = (userData): Observable<any> => {
    let language = localStorage.getItem('language')
    let body = Object.assign({}, userData, { language: language })
    return this.http.post(`${this.baseUrl}/authentication/userregestraion`, body).pipe(
      map(res => res),
      catchError(err => throwError(err))
    )
  };


  userLogin = (userDetails: { email: string, password: string, domainname: string }): Observable<any> => {
    let body = Object.assign({}, userDetails);
    return this.http.post(`${this.baseUrl}/authentication/authenticate`, body).pipe(
      map((res: UserTokenDetails) => {
        if (res.success && !res.user_details['tempPassword']) {
          this.storeToken(res);
        };
        return res;
      }),
      catchError(err => throwError(err))
    )
  };


  forgotpassword(data) {
    data = { ...data, domainname: this.domainname }
    return this.http.post(this.baseUrl + '/authentication/forgotpassword', data);
  };

  // resetpassword(data) {
  //   var _window = this.windowRef.nativeWindow;
  //   var url = _window.location.href;
  //   var params = new URLSearchParams(url);
  //   var verification_userid = decodeURIComponent(params.get("resetpassword_user"));
  //   let headers = new HttpHeaders(
  //     { 'domainname': this.domainname }
  //   );
  //   data = { ...data, domainname: this.domainname, id: verification_userid }
  //   return this.http.post(this.baseUrl + '/authentication/resetpassword', data, { headers: headers })
  // };

  resetpassword(data) {
    data = { ...data, domainname: this.domainname }
    return this.http.post(this.baseUrl + '/authentication/resetpassword', data)
  };


  logout(data) {
    data = { ...data, domainname: this.domainname }
    this.http.post(this.baseUrl + '/authentication/logout', data).subscribe(() => {
    })
  }
  verifyUser = (userDetails: { domainname: string, id: string }): Observable<any> => {

    let body = Object.assign({}, userDetails);
    return this.http.post<any>(`${this.baseUrl}/authentication/verify`, body).pipe(
      map(res => {
        if (res.success) {
          this.storeToken(res)
        }
        return res
      }),
      catchError(err => throwError(err))
    )
  };

  storeGuestSession = () => {
    localStorage.removeItem('aupris_token');
    localStorage.removeItem('portal_user');
    sessionStorage.setItem('aupris_user_type', 'guest');
    this.isLoggedInSubject.next({ guestLogin: true, userLogin: false });
  };

  logoutUser = () => {
    localStorage.removeItem('aupris_token');
    localStorage.removeItem('portal_user');
    localStorage.removeItem("priceAlarmInfo");
    this.isLoggedInSubject.next({ guestLogin: false, userLogin: false });
  };

  storeToken = (userToken: UserTokenDetails) => {
    if (userToken.success) {
      localStorage.setItem('aupris_token', userToken.token);
      localStorage.setItem('portal_user', JSON.stringify(userToken.user_details));
      sessionStorage.removeItem('aupris_user_type');
      this.isLoggedInSubject.next({ guestLogin: false, userLogin: true });
    }
  };

  updateEmailToken = (userToken: UserTokenDetails) => {
    if (userToken.success) {
      localStorage.setItem('aupris_token', userToken.token);
      localStorage.setItem('portal_user', JSON.stringify(userToken.user_details));
      sessionStorage.removeItem('aupris_user_type');
      this.isLoggedInSubject.next({ guestLogin: false, userLogin: false });
    }
  };

  getPortalUserInfo = (): any => {
    this.userdetails = localStorage.getItem('portal_user') || '';
    return this.userdetails;
  };

  isUserLoggedIn = (): boolean => {
    this.isLoggedIn = this.getPortalUserInfo() == '' ? false : true
    this.manageSession();
    return this.isLoggedIn;
  };

  async manageSession() {
    if (this.isLoggedIn) {
      // check if session storage available
      let isNewVisit = sessionStorage.getItem('isNewVisit');
      if (!isNewVisit) {
        sessionStorage.setItem('isNewVisit', 'yes');
        return this.updateSession();
      };
    };
  };


  async updateSession() {
    return await this.http.post(`${this.baseUrl}/authentication/insertSession`, {}).toPromise();
  };

  isGuestUser = () => {
    return sessionStorage.getItem('aupris_user_type') == 'guest'
  };

  getAuprisToken = (): string => {
    return localStorage.getItem('aupris_token') || ''
  };


  changeUserDetails = (userDetails: UserInfo) => {
    localStorage.setItem('portal_user', '');
    localStorage.setItem('portal_user', JSON.stringify(userDetails));
    this.userDetailsChangedSubject.next(userDetails);
  };


  insertGoogleDetails = (googleData: GoogleDetails): Observable<any> => {
    let body = Object.assign({}, googleData)
    return this.http.post(`${this.baseUrl}/authentication/googleRegister`, body).pipe(
      map((res: UserTokenDetails) => {
        if (res.success) {
          this.storeToken(res);
        }
        return res
      }),
      catchError(err => throwError(err))
    )
  };


  insertFacebookDetails = (fbData: FbDetails): Observable<any> => {
    let body = Object.assign({}, fbData);
    return this.http.post(`${this.baseUrl}/authentication/facebookRegister`, body).pipe(
      map((res: UserTokenDetails) => {
        if (res.success) {
          this.storeToken(res);
        }
        return res
      }),
      catchError(err => throwError(err))
    )
  };

  verifyFbUser(data) {
    return this.http.post(`${this.baseUrl}/authentication/verifyFbUser`, { ...data, language: localStorage.getItem('language') });
  };



  updatePoralDataGoogle(data) {
    return this.http.post(`${this.baseUrl}/authentication/updatePoralDataGoogle`, data);
  };


  updatePoralDataFacebook(data) {
    return this.http.post(`${this.baseUrl}/authentication/updatePoralDataFacebook`, data);
  };



  deletingGoogleUserDetails(data) {
    return this.http.post(`${this.baseUrl}/authentication/deletingGoogleUserDetails`, data);
  };



  deletingFacebookUserDetails(data) {
    return this.http.post(`${this.baseUrl}/authentication/deletingFacebookUserDetails`, data);
  };


  getGoogleUserDetails(data) {
    return this.http.post(`${this.baseUrl}/authentication/getGoogleUserDetails`, data);
  };


  getFacebookUserDetails(data) {
    return this.http.post(`${this.baseUrl}/authentication/getFacebookUserDetails`, data);
  };


  pwverify(token) {
    return this.http.post(`${this.baseUrl}/authentication/pwverify`, token);
  }

};

export interface IUserInfo {
  newpassword: any;
  password: string;
  oldpassword: any;
  re_password: any;
  kunden: any;
  kundennummer: any;
  Kundennummer: any;
  MyMasterId: string,
  domain_id: string,
  domain_name: string,
  email: string,
  Vorname: string,
  Name: string,
  name: string,
  passcode: string,
  number: string,
  unternehmen_id: string,
  verifed: true,
  _id: string,
  photoUrl: any
  facebook: boolean;
  google: boolean;
  chunkSize: 261120
  KtoInhaber: String,
  IBAN: String,
  BIC: String,
  bank_name: String,
  account_number: String,
  bank_code: String,
  verified: boolean,
  tempPassword: boolean,
  temp_email: string
}
