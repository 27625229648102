import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-telefon-popup',
  templateUrl: './telefon-popup.component.html',
  styleUrls: ['./telefon-popup.component.css']
})
export class TelefonPopupComponent implements OnInit {

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<TelefonPopupComponent>) { }
  colors: any;

  ngOnInit() {
  }


  onclick(buttonName) {
    this.dialogRef.close(buttonName);
  }
}
