import { Directive, ElementRef, Input, OnInit, Renderer2,  AfterViewChecked } from '@angular/core';

@Directive({
    selector: '[removeAutoComplete]'
})

export class removeAutocompleteDirective implements OnInit, AfterViewChecked {
    @Input() attributes: any;
    constructor(private renderer: Renderer2, private el: ElementRef) { }

    ngOnInit() {
    }

    ngAfterViewChecked() {
        this.addAttributes();
    }

    addAttributes() {
        this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'new-password');
        if (this.el.nativeElement.querySelector('input')) {
            this.renderer.setAttribute(this.el.nativeElement.querySelector('input'), 'autocomplete', 'new-password');
        }
    }
}