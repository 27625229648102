import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, HostListener, ElementRef, ViewChild, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';

import io from 'socket.io-client';
import maplibregl from 'maplibre-gl';
import polyUtil from 'polyline-encoded';

import { environment } from '../../environments/environment';

import { AdminService } from '../Services/admin.service';
import { BigpcService } from '../Services/bigpc.service';
import { LanguageService } from '../Services/language.service';
import { ICustomWindow, WindowRefService } from '../Services/window.service';

import { TrackingInfoPopupComponent } from '../popups/tracking-info-popup/tracking-info-popup.component';

@Component({
  selector: 'app-tracking-verification',
  templateUrl: './tracking-verification.component.html',
  styleUrls: ['./tracking-verification.component.css']
})

export class TrackingVerificationComponent implements OnInit, OnDestroy {
  @ViewChild("mapHome")
  private mapContainer!: ElementRef<HTMLElement>;
  languageData: any = {};
  orderData: any = {};
  orderDataAfterVerification: any = {};
  url: any;
  trackingIdFromUrl: any;
  liveTrackingAccess: any;
  trackingPinSvg: any;
  noTrackingIdFound: Boolean = false;

  options: any;
  map: any;
  fitBounds: L.LatLngBounds;
  markerClusterData: any = [];
  autoCenter: Boolean = false;

  verificationFail: Boolean = false;
  showFullScreenLoading: Boolean = false;

  // driverImage: any = 'assets/account_circle-black-18dp.svg';
  driverImage: any = environment.assetsUrl + 'assets/account_circle-black-18dp.svg';
  basicForm: FormGroup;
  MinutesBeforeArrivalDropDown: any = [
    '- -', 20, 30, 40, 50, 60, 70, 80, 90,
  ];
  messageInfoForm: FormGroup;
  showSMSError: Boolean = false;
  showeMailError: Boolean = false;
  smsEditMode: Boolean = false;
  eMailEditMode: Boolean = false;

  largeScreen = false;
  mediumScreen = false;
  screenHeight: number;
  screenWidth: number;
  smallScreen: boolean;
  window: ICustomWindow;
  mapshow: boolean = false;
  response: any;
  mobile: boolean = false;
  screenSize = "largeScreen"
  //Socket Variables
  socketUrl: string = environment.socketUrl;


  socket: any;
  customerTracking: boolean = false;
  hostname: string;
  format: boolean;

  // variables related to map
  mapStyles: any;
  tilesGl = null;
  drawPolyLine = [];
  showAuto = false;
  openClick = "";
  deliverdAmount: any;
  inputTrackingId: string = '';
  blurMap: boolean = true;
  sMap: boolean = false;
  mMap: boolean = false;
  lMap: boolean = false;
  pcColours: any;


  svgs = [
    environment.assetsUrl + 'assets/Lock.svg',
    environment.assetsUrl + 'assets/svgs/order.svg',
    environment.assetsUrl + 'assets/GpsFixed.svg'

  ];
  constructor(
    private el: ElementRef,
    public dialog: MatDialog,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private windowRef: WindowRefService,
    private langService: LanguageService,
    private newBigPcService: BigpcService,
    private ref: ChangeDetectorRef
  ) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData.administration || {};
    this.langService.getJSON().subscribe(res => {
      this.mapStyles = res
    })
    this.newBigPcService.pcColours$.subscribe(res => {
      this.pcColours = res;
    })

    this.languageData['customersLiveTracking'] = this.languageData?.customersLiveTracking || {};

    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data.administration || {};
      this.languageData['customersLiveTracking'] = this.languageData?.customersLiveTracking || {};
    });

    this.window = windowRef.nativeWindow;
    this.url = this.window.location.href;
  }


  @HostListener('window:resize', ['$event'])
  async getScreenSize(event?) {
    if (!this.response) this.response = ""
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth >= 995) {
      this.mediumScreen = false;
      this.largeScreen = true;
      this.smallScreen = false;
      this.screenSize = "largeScreen"
      this.mobile = false;
      this.mapshow = this.mapshow;
      this.mMap = false;
      this.sMap = false;
      if (this.screenSize == "largeScreen" && !this.lMap) {
        this.ref.detectChanges();
        this.startMap(this.response);
        this.lMap = true;
      }
    } else if (this.screenWidth > 770 && this.screenWidth < 995) {
      this.largeScreen = false;
      this.mediumScreen = true;
      this.screenSize = "mediumScreen"
      this.smallScreen = false;
      this.mobile = false;
      this.mapshow = this.mapshow;
      this.lMap = false;
      this.sMap = false;
      if (this.screenSize == "mediumScreen" && !this.mMap) {
        this.ref.detectChanges();
        this.startMap(this.response);
        this.mMap = true;
      }
    } else {
      this.largeScreen = false;
      this.screenSize = "smallScreen"
      this.mediumScreen = false;
      this.smallScreen = true;
      if (this.response) {
        this.mobile = true
      } else {
        this.mobile = false
      }
      //   this.mapshow = this.mapshow
      this.mapshow = this.mapshow;
      this.lMap = false;
      this.mMap = false;
      if (this.screenSize == "smallScreen" && !this.sMap) {
        this.ref.detectChanges();
        this.startMap(this.response);
        this.sMap = true;
      }
    }
  }

  async openMap() {
    this.mobile = true;
    this.openClick = "clicked"
    this.mapshow = !this.mapshow;
    this.smallScreen = true;
    this.ref.detectChanges();
    this.screenSize = "smallScreen";
    await this.delay(500)
    this.startMap(this.response)
  }

  ngOnInit(): void {
    var params = new URLSearchParams(this.url);
    this.getDynamicSVG(this.svgs);
    this.hostname = this.window.location.hostname;
    if (!environment.testSite_or_thirdPartySites) this.hostname = decodeURIComponent(params.get("Domainname"));

    this.newBigPcService.getDriverAppLicenses(this.hostname, '').then((data: any) => {
      if (data.success) {
        let res = data?.data || {};
        this.liveTrackingAccess = res?.package == '2' || res?.package == 2 ? true : false;
      };
      this.getImageData();
    }).catch(err => {
      this.liveTrackingAccess = false;
      this.getImageData();
    });

    this.basicForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      lastName: [''],
    });

    this.messageInfoForm = this.formBuilder.group({
      minBeforeArrival: [''],
      mobileNo: [''],
      eMail: [''],
      mobileNoToggle: [false],
      eMailToggle: [false]
    })
    this.getScreenSize()
    if (decodeURIComponent(params.get('trackingId')) && (decodeURIComponent(params.get('trackingId'))?.toUpperCase()?.includes('ATR'))) {
      this.trackingIdFromUrl = decodeURIComponent(params.get('trackingId'));
      let fName = decodeURIComponent(params.get('name'));
      this.basicForm.patchValue({
        name: fName && !['null', null].includes(fName) ? fName : '',
      });
      this.getTrackingDetails(this.trackingIdFromUrl);
    } else {
      this.trackingIdFromUrl = false;
    }

    window.onbeforeunload = () => {
      this.closeSocket()
    }
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
      this.httpClient.get(item, { responseType: 'text' })
        .subscribe((logo) => {
          logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
          logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
          logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
          logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
          this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
        })
    });
  };

  getImageData() {
    let path = environment.assetsUrl + "assets/trackingPin.svg";
    this.httpClient.get(path, { responseType: 'text' })
      .subscribe(logo => {
        logo = logo.replace('#39baea', 'var(--primaryColour)');
        this.trackingPinSvg = this.sanitizer.bypassSecurityTrustHtml(logo);
      });
  }

  searchTracking() {
    this.url = this.window.location.href;
    let urlCopy = this.url.split("&track");
    let newUrl = urlCopy[0];
    window.history.replaceState('', document.title, `${newUrl}&trackingId=${this.inputTrackingId}`);
    this.basicForm.controls['name'].setValue('');
    this.getTrackingDetails(this.inputTrackingId);
  }

  getTrackingDetails(trackingId) {
    this.showFullScreenLoading = true;
    this.inputTrackingId = trackingId;
    this.adminService.getTrackingDetails({ trackingId: trackingId }).subscribe(async res => {
      if (res.success && res.data) {
        this.orderData = res.data;
        this.orderData = { ... this.orderData };
        this.orderData.productArray.forEach((p) => {
          p.deliverdAmount = p.deliveredAmount == '' ? p.amount : p.deliveredAmount;
        });
        this.orderData?.productArray.sort((a, b) => +a?.p_pos_no - +b?.p_pos_no);
        this.orderData.StatusCopy = +this.orderData.Status >= 5 ? '4' : this.orderData.Status;
        this.adminService.notifyOther(this.orderData.StatusCopy);
        this.trackingIdFromUrl = trackingId;
        this.url = this.window.location.href;
        let urlCopy = this.url.split("&track");
        let newUrl = urlCopy[0];
        this.format = this.orderData?.settingsData?.timeMode == '12h' ? true : false;
        if (this.basicForm.value.name) this.verifyUser();
        else {
          window.history.replaceState('', document.title, `${newUrl}&trackingId=${trackingId}`);
          await this.delay(1000)
          this.startMap("");
        }
      }
      else if (res.noTrackingIdFound) {
        this.trackingIdFromUrl = false;
        this.noTrackingIdFound = true;
      }
      this.showFullScreenLoading = false;
    }, (err) => {
      this.showFullScreenLoading = false;
      this.trackingIdFromUrl = false;
      this.noTrackingIdFound = true;
      this.inputTrackingId = '';
      this.url = this.window.location.href;
      let urlCopy = this.url.split("&track");
      window.history.replaceState('', document.title, urlCopy[0]);
    })
  }

  async startMap(res) {
    await this.removeMap();
    this.markerClusterData = [];
    if (this.mapContainer?.nativeElement) {
      this.tilesGl = new maplibregl.Map({
        container: this.mapContainer.nativeElement,
        style: this.mapStyles,
        center: [12.486418, 49.353778],
        zoom: 4,
        pitchWithRotate: false,
        attributionControl: false,
      });
      this.tilesGl.setMaxBounds([[-178.753133, -84.828612], [178.784309, 84.716508]])
      this.tilesGl.setRenderWorldCopies(false)
      var nav = new maplibregl.NavigationControl({ showCompass: false });
      if (!this.blurMap) this.tilesGl.addControl(nav, 'top-left');
      let cont = '<a href="https://www.maptiler.com/copyright/" class="attrib" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" class="attrib" target="_blank">© openStreetMap contributors</a> <a href="https://maplibre.org/" class="attrib" target="_blank">© MapLibre contributors</a>';
      this.tilesGl.addControl(new maplibregl.AttributionControl({ compact: false, customAttribution: cont }));
      this.tilesGl.touchZoomRotate.disableRotation();
      this.tilesGl.on('dragstart', this.dragstart.bind(this));
      this.tilesGl.on('dragend', this.dragend.bind(this));
      setTimeout(() => { this.tilesGl.on('zoomstart', this.zoomstart.bind(this)) }, 4000);
      this.tilesGl.on("load", async () => {
        if (res) {
          let del = res?.data?.deliveryAddress || {};
          if (del && del.l_geo_lat && del.l_geo_long) await this.addLocationMArker(res);
          this.addTruckMarker(res);
          // this.tilesGl.resize();
        }
      })
    } else {
      this.ref.detectChanges();
      await this.delay(1000);
      this.startMap(this.response)
    }
  }

  removeMap() {
    if (this.tilesGl != null) {
      if (this.tilesGl.getLayer("routeLayer")) {
        this.tilesGl.removeLayer("routeLayer");
      }
      if (this.tilesGl.getLayer("arrowId")) {
        this.tilesGl.removeLayer("arrowId");
        this.tilesGl.removeImage("arrow");
      }
      if (this.tilesGl.getSource("route")) {
        this.tilesGl.removeSource("route");
      }
      this.tilesGl.remove();
      this.tilesGl = null;
    }
    return;
  }

  async dragstart(e) {
    this.showAuto = true;
    this.ref.detectChanges();
  }

  async dragend(e) {
    this.showAuto = true;
    this.ref.detectChanges();
  }

  async zoomstart(e) {
    this.showAuto = true;
    this.ref.detectChanges();
  }

  async addLocationMArker(res) {
    var orderMarker = document.createElement('div');
    let tempColor = await this.hex2rgb(this.pcColours?.primaryColour) || 'rgba(118,153,195)'
    orderMarker.style.backgroundImage = `url("data:image/svg+xml; utf8,%3Csvg width='40' height='40' viewBox='0 0 512 512' fill='${tempColor}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M256 0C161.896 0 85.333 76.563 85.333 170.667C85.333 198.917 92.396 226.927 105.823 251.771L246.667 506.5C248.542 509.896 252.115 512 256 512C259.885 512 263.458 509.896 265.333 506.5L406.229 251.687C419.604 226.927 426.667 198.916 426.667 170.666C426.667 76.563 350.104 0 256 0Z' fill='${tempColor}'/%3E%3Cg clip-path='url(%23clip0_1912_982)'%3E%3Cpath d='M323.17 143.763C323.167 143.76 323.164 143.757 323.161 143.753L265.236 85.8308C262.767 83.3607 259.484 82 255.993 82C252.501 82 249.218 83.3596 246.748 85.8297L188.854 143.723C188.834 143.743 188.815 143.763 188.795 143.783C183.725 148.882 183.734 157.156 188.82 162.242C191.144 164.567 194.213 165.914 197.495 166.055C197.628 166.068 197.762 166.074 197.898 166.074H200.206V208.702C200.206 217.137 207.07 224 215.507 224H238.169C240.466 224 242.329 222.138 242.329 219.84V186.42C242.329 182.571 245.46 179.44 249.309 179.44H262.676C266.525 179.44 269.656 182.571 269.656 186.42V219.84C269.656 222.138 271.518 224 273.816 224H296.478C304.916 224 311.779 217.137 311.779 208.702V166.074H313.92C317.41 166.074 320.693 164.715 323.164 162.244C328.256 157.149 328.258 148.862 323.17 143.763V143.763Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1912_982'%3E%3Crect width='142' height='142' fill='white' transform='translate(185 82)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")`;
    orderMarker.classList.add("orderMarkerIcon");
    let delAdd = res?.data?.deliveryAddress || {};
    let marker = new maplibregl.Marker(orderMarker, { draggable: false, anchor: "bottom" }).setLngLat([delAdd.l_geo_long, delAdd.l_geo_lat]).addTo(this.tilesGl);
    marker['type'] = 'order';
    this.markerClusterData.push(marker);
    return;
  }

  async addTruckMarker(res) {
    let { liveLocationData = {}, truckData = {}, Status = false, o_del_stat = '', deliveryAddress = {} } = { ...res?.data };
    let finalETA = this.orderData?.LiveETA || this.orderData?.ETA;
    let diff = getDiffBwnTwoDates(new Date(finalETA), new Date());
    console.log({ diff });
    if (liveLocationData?.location?.coordinates?.length && (Status == '3' || Status == 3) && diff <= 90 && o_del_stat == '') {
      let htmlSvg = await this.getTruckMarker(truckData?.topViewIcon, truckData?.colorpicker || 'var(--primaryColour)');
      let temp = await this.encodeSVG(htmlSvg);
      let truckSvg = `data:image/svg+xml; utf8,${temp}`;
      let orderMarker = document.createElement('div');
      orderMarker.classList.add("ordertruckIcon");
      orderMarker.style.backgroundImage = `url("${truckSvg}")`;
      let truckMarker = new maplibregl.Marker(orderMarker, { draggable: false }).setLngLat([liveLocationData?.location?.coordinates[1], liveLocationData?.location?.coordinates[0]]).addTo(this.tilesGl);
      truckMarker['type'] = 'truck';
      this.markerClusterData.push(truckMarker);
      if (liveLocationData?.route?.roundFound && liveLocationData?.route?.polyline.length > 0) {
        let latlngs = [], finalLatLangs = [];
        for (let polyline of liveLocationData?.route?.polyline) {
          latlngs.push(polyUtil.decode(polyline, 6));
        }
        latlngs[0].forEach(ele => { finalLatLangs.push(ele.reverse()) })
        this.polyLineAdd(finalLatLangs);
      }
      else {
        let latlngs = [];
        latlngs.push([liveLocationData?.location?.coordinates[1], liveLocationData?.location?.coordinates[0]])
        latlngs.push([deliveryAddress.l_geo_long, deliveryAddress.l_geo_lat])
        this.polyLineAdd(latlngs);
      }
      // await this.delay(1000);
      this.autoAdjustMap();
      this.enableSocket();
    }
    else {
      this.autoAdjustMap()
    }
  }


  async getTruckMarker(id, color) {
    let logoFile = await this.adminService.getLogoImageById(id);
    if (logoFile && logoFile.success) {
      let resp = atob(`${logoFile.data.data}`);
      var htmlSvg = resp.replace(/fill="#6E6E6E"/g, `fill="${color}"`);

    } else {
      var htmlSvg = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 55 30">
      <path d="M30.42,16.74H7.11V2.14H26.42a4,4,0,0,1,4,4Z" fill="#fff"/>
      <path fill="${color ? color : '#757575'}" d="M10.62,5.62V7.5H8.75V5.62Zm0-3.74V3.75H8.75V1.88Zm37,3.74a1.11,1.11,0,0,1,
      .66.22l.13.12L52,9.61a1,1,0,0,1,.3.6l0,.17v7.18h.33a1.12,1.12,0,0,1,1.11.93v5.26a1.12,1.12,0,0,1-.94,1.1h-3a5.2,5.2,0,0,
      1-10.39.31v-.31H29.82a5.2,5.2,0,0,1-10.38.31v-.31h-.06A5.2,5.2,0,0,1,9,25.17v-.31H6.12A1.12,1.12,0,0,1,5,23.93V21.39a1.11,
      1.11,0,0,1,.94-1.09h.52l28.06,0h2.34V6.73a1.11,1.11,0,0,1,.94-1.09l.18,0h9.6ZM27.47,0a.75.75,0,0,1,.77.71.72.72,0,0,
      1-.61.69l-.16,0H27.4v.72h2.16c3,0,5.44,3.3,5.44,7.36s-2.27,7.15-5.14,7.36l-.3,0H6.69c-3,0-5.44-3.31-5.44-7.37S3.52,
      2.36,6.39,2.15h.72V9.43a.85.85,0,0,0,.88.82.86.86,0,0,0,.87-.67l0-.15V8.65H12v.78a.89.89,0,0,0,1.75.15l0-.15V2.14H22V1.42h-.07a.75.75,
      0,0,1-.77-.71A.72.72,0,0,1,21.76,0l.15,0Z"/>
      <path d="M14.17,23A1.82,1.82,0,1,0,16,24.86,1.83,1.83,0,0,0,14.17,23Zm10.45,0a1.82,1.82,0,1,0,1.85,1.82A1.83,1.83,0,0,0,
      24.62,23Zm20,0a1.82,1.82,0,1,0,1.84,1.82A1.83,1.83,0,0,0,44.58,23ZM46.37,8.82H40.78a.48.48,0,0,0-.47.37l0,.12v5a.48.48,0,
      0,0,.38.47h7.71a.48.48,0,0,0,.47-.37l0-.11v-3a.39.39,0,0,0-.07-.23l-.07-.1-2-2a.52.52,0,0,0-.35-.14Zm-22-2.93c-.12.12-2.95,
      2.93-2.95,4.46a3.1,3.1,0,0,0,6.18,0c0-1.53-2.82-4.34-2.94-4.46a.21.21,0,0,0-.29,0Z" fill="#fff"/>
    </svg>`
    }
    return htmlSvg

  }

  encodeSVG(data) {
    let symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;
    data = data.replace(/"/g, `'`);
    data = data.replace(/>\s{1,}</g, `><`);
    data = data.replace(/\s{2,}/g, ` `);
    return data.replace(symbols, encodeURIComponent);
  }

  hex2rgb(hex) {
    if (hex.includes("rgba") || hex.includes("rbg")) return hex;
    else {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return `rgba(${r},${g},${b})`
    }
  }

  async polyLineAdd(latlngs) {
    if (this.tilesGl.getLayer("arrowId")) {
      await this.tilesGl.removeImage("arrow");
      await this.tilesGl.removeLayer("arrowId");
    }
    if (this.tilesGl.getLayer('routeCopy')) await this.tilesGl.removeLayer('routeCopy');
    if (this.tilesGl.getSource('route')) await this.tilesGl.removeSource('route');

    this.tilesGl.addSource(`route`, { type: 'geojson', data: { type: 'Feature', properties: {}, geometry: { 'type': 'LineString', 'coordinates': latlngs } } });
    this.tilesGl.addLayer({
      'id': `routeCopy`,
      'type': 'line',
      'source': `route`,
      'layout': { 'line-join': 'round', 'line-cap': 'round' },
      'paint': { 'line-color': '#cb182f', 'line-width': 4 },
    });
    const url = environment.assetsUrl + 'assets/rightArrowColor.png'
    this.tilesGl.loadImage(url, (err, image) => {
      if (err) { return; }
      if (this.tilesGl.getLayer("arrowId")) {
        this.tilesGl.removeLayer("arrowId");
        this.tilesGl.removeImage("arrow");
      }
      this.tilesGl.addImage('arrow', image);
      this.tilesGl.addLayer({
        'id': 'arrowId',
        'type': 'symbol',
        'source': 'route',
        'layout': {
          'symbol-placement': 'line',
          'symbol-spacing': 75,
          'icon-allow-overlap': true,
          'icon-image': 'arrow',
          'icon-size': 0.6,
          'visibility': 'visible'
        }
      });
    });

  }

  autoAdjustMap() {
    if (this.markerClusterData.length) {
      let bounds = new maplibregl.LngLatBounds();
      this.markerClusterData.forEach(element => {
        let latLng = element?._lngLat || {};
        bounds.extend([latLng.lng, latLng.lat]);
      });
      this.tilesGl.fitBounds(bounds, { padding: { top: 100, bottom: 100, left: 50, right: 50 }, maxZoom: 14 });
      this.showAuto = false;
    }
  }




  onMapReady(map) {
    map.setZoom(5);
    map.setMinZoom(0);
    map.setMaxZoom(18);
    this.map = map;
    this.map.addEventListener("dragend", this.dragstart.bind(this));
    this.map.addEventListener("zoomend", this.dragstart.bind(this));
    this.map.dragging.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
  }

  verifyUser() {
    if (this.basicForm.valid) {
      this.showFullScreenLoading = true;
      this.adminService.verifyUserInTracking({ name: this.basicForm.value.name || '', trackingId: this.trackingIdFromUrl }).subscribe(res => {
        if (this.smallScreen) this.mapshow = true;
        if (res?.success && res?.data) {
          this.blurMap = false;
          this.showFullScreenLoading = false;
          this.url = this.window.location.href;
          let urlCopy = this.url.split("&name");
          let newUrl = urlCopy[0];
          window.history.replaceState('', document.title, `${newUrl}&name=${this.basicForm.value.name}`)
          this.orderDataAfterVerification = res?.data;
          this.orderDataAfterVerification.Status = this.orderDataAfterVerification.Status.toString();
          if (this.orderDataAfterVerification?.trackingInfo?.eMail || this.orderDataAfterVerification?.trackingInfo?.mobileNo || this.orderDataAfterVerification?.trackingInfo?.minBeforeArrival) {
            this.messageInfoForm.patchValue({
              mobileNo: this.orderDataAfterVerification?.trackingInfo?.mobileNo,
              eMail: this.orderDataAfterVerification?.trackingInfo?.eMail,
              minBeforeArrival: this.orderDataAfterVerification?.trackingInfo?.minBeforeArrival,
              mobileNoToggle: this.orderDataAfterVerification?.trackingInfo?.mobileNoToggle,
              eMailToggle: this.orderDataAfterVerification?.trackingInfo?.eMailToggle,
            });
          } else {
            this.messageInfoForm.patchValue({
              mobileNo: this.orderDataAfterVerification?.deliveryAddress?.l_phone1,
              eMail: this.orderDataAfterVerification?.deliveryAddress?.l_email,
              minBeforeArrival: '- -',
            });
          }
          if (this.orderDataAfterVerification.Status == '0' || this.orderDataAfterVerification.Status == '1') {
            this.customerTracking = true;
          } else if (this.orderDataAfterVerification?.truckData?.customerTracking && (this.orderDataAfterVerification.Status == '2' || this.orderDataAfterVerification.Status == '3')) {
            this.customerTracking = true;
          } else {
            this.customerTracking = false;
          }
          if (this.orderDataAfterVerification?.tourData?.driverLogo) {
            this.getUserLogo(this.orderDataAfterVerification?.tourData?.driverLogo);
          }
          this.verificationFail = true;
          this.response = res;
          if (!this.smallScreen) {
            this.startMap(res)
          } else {
            this.showFullScreenLoading = false;
          }
        } else {
          this.blurMap = true;
          this.startMap(res)
          this.showFullScreenLoading = false;
        }
      }, (err) => {
        this.showFullScreenLoading = false;
        if (err?.error?.verificationFail) {
          this.mapshow = false;
          this.blurMap = true;
          this.verificationFail = true;
        }
      })
    } else {
      this.basicForm.markAllAsTouched();
      this.blurMap = true;
    }
  }

  getSvg(color) {
    color = color || 'rgba(118,153,195)';
    let svg = `<svg width="40" height="40" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M256 0C161.896 0 85.333 76.563 85.333 170.667C85.333 198.917 92.396 226.927 105.823 251.771L246.667 506.5C248.542 
    509.896 252.115 512 256 512C259.885 512 263.458 509.896 265.333 506.5L406.229 251.687C419.604 226.927 426.667 198.916 426.667 
    170.666C426.667 76.563 350.104 0 256 0Z" fill="${color}"/>
    <g clip-path="url(#clip0_1912_982)">
    <path d="M323.17 143.763C323.167 143.76 323.164 143.757 323.161 143.753L265.236 85.8308C262.767 83.3607 259.484 82 255.993 
    82C252.501 82 249.218 83.3596 246.748 85.8297L188.854 143.723C188.834 143.743 188.815 143.763 188.795 143.783C183.725 148.882 
    183.734 157.156 188.82 162.242C191.144 164.567 194.213 165.914 197.495 166.055C197.628 166.068 197.762 166.074 197.898 166.074H200.206V208.702C200.206 
    217.137 207.07 224 215.507 224H238.169C240.466 224 242.329 222.138 242.329 219.84V186.42C242.329 182.571 245.46 179.44 249.309 179.44H262.676C266.525 
    179.44 269.656 182.571 269.656 186.42V219.84C269.656 222.138 271.518 224 273.816 224H296.478C304.916 224 311.779 217.137 311.779 208.702V166.074H313.92C317.41 
    166.074 320.693 164.715 323.164 162.244C328.256 157.149 328.258 148.862 323.17 143.763V143.763Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_1912_982">
    <rect width="142" height="142" fill="white" transform="translate(185 82)"/>
    </clipPath>
    </defs>
    </svg>
    `
    return svg;
  }

  openLockPopup() {
    let popup = this.dialog.open(TrackingInfoPopupComponent, { panelClass: 'my-full-screen-dialog', width: '500px', });
    popup.afterClosed().subscribe((res) => { })
  }

  changeToggle(event, key) {
    let value = this.messageInfoForm.value[`${key}Toggle`];
    if (!this.messageInfoForm.value.mobileNoToggle || !this.messageInfoForm.value.eMailToggle) {
      if (!this.messageInfoForm.value.mobileNoToggle) {
        this.showSMSError = false;
      }
      if (!this.messageInfoForm.value.eMailToggle) {
        this.showeMailError = false;
      };
    };
  };

  checkFocusOutInput() {
    if ((this.messageInfoForm.value.mobileNo || this.messageInfoForm.value.mobileNo == '') && (this.messageInfoForm.value.eMail || this.messageInfoForm.value.eMail == '')) {
      let number = this.messageInfoForm.value['mobileNo'];
      let eMail = this.messageInfoForm.value['eMail'];
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
      if ((!number.startsWith("01") || number.length < 10) && !(regex.test(eMail)) && this.messageInfoForm.value.eMailToggle && this.messageInfoForm.value.mobileNoToggle) {
        this.showSMSError = this.languageData['customersLiveTracking']['VarPageCustomerTracking12'] || 'Keine gültige Handynummer!';
        this.showeMailError = this.languageData['customersLiveTracking']['VarValidationMessageInvalidEmailFormat'] || 'Ungültiges Email Format';
        return false;
      } else if ((!number.startsWith("01") || number.length < 10) && (regex.test(eMail) || !(regex.test(eMail))) && this.messageInfoForm.value.mobileNoToggle) {
        this.showSMSError = this.languageData['customersLiveTracking']['VarPageCustomerTracking12'] || 'Keine gültige Handynummer!';
        this.showeMailError = false;
        return false;
      } else if ((number.startsWith("01") || !(number.length < 10) || this.messageInfoForm.value.mobileNo == '') && !(regex.test(eMail)) && this.messageInfoForm.value.eMailToggle) {
        this.showeMailError = this.languageData['customersLiveTracking']['VarValidationMessageInvalidEmailFormat'] || 'Ungültiges Email Format';
        this.showSMSError = false;
        return false;
      }
      else {
        this.showSMSError = false;
        this.showeMailError = false;
      }
      this.updateTrackingValue();
    } else { }
  }

  editInput(dynamicVar) {
    this.smsEditMode = false;
    this.eMailEditMode = false;
    if (this.smsEditMode || this.eMailEditMode) {
      console.log('i need to check once api')
    }
    this[dynamicVar] = true;
  }

  updateTrackingValue() {
    this.showFullScreenLoading = true;
    this.url = this.window.location.href;
    let urlCopy = this.url.split("&name");
    let longUrl = urlCopy[0];
    var params = new URLSearchParams(this.url);
    let trackingId = decodeURIComponent(params.get('trackingId')) || false;
    this.adminService.updateTrackingInfo({ trackingId: trackingId, longUrl: longUrl, ...this.messageInfoForm.value }).subscribe((res: any) => {
      this.showFullScreenLoading = false;
      this.smsEditMode = false;
      this.eMailEditMode = false;
    }, err => {
      console.log(err);
      this.showFullScreenLoading = false;
    })
  }


  enableSocket() {
    if (this.socket) this.closeSocket();
    let options = {
      path: '/driverApp',
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      rejectUnauthorized: false,
      reconnectionAttempts: 3,
      transports: ['websocket'],
      query: {
        trackingId: this.orderDataAfterVerification?.trackingId,
        truckId: this.orderDataAfterVerification?.truckData?._id,
        recordOId: this.orderDataAfterVerification?.recordOId,
      }
    };
    this.socket = io(`${this.socketUrl}/customerTracking`, options);
    this.socket.on('success', (res) => {
      console.log(res);
    });

    this.socket.on("connect_error", (error) => {
      console.log("socket conection failed", error);
    });

    this.socket.on(`${options.query.recordOId}_customerTracking`, async (res: any) => {
      if (res.recordOId == options.query.recordOId) {
        let { LiveETA, ETA, route = {}, coordinates = [], status, o_del_stat }: any = { ...res };
        if ((status == '3' || status == 3) && o_del_stat == '') {
          console.log('im in')
          this.orderData.LiveETA = LiveETA ? LiveETA : this.orderData.LiveETA;
          this.orderData.ETA = ETA ? ETA : this.orderData.ETA;
          this.markerClusterData.filter(async (marker, i) => {
            if (marker.type == 'truck') {
              let that = this;
              let duration = 2000;
              let startLngLat = marker.getLngLat();
              let startTime = Date.now();
              function animate() {
                let elapsed = Date.now() - startTime;
                let progress = elapsed / duration;
                if (progress < 1) {
                  let lng = startLngLat.lng + (coordinates[1] - startLngLat.lng) * progress;
                  let lat = startLngLat.lat + (coordinates[0] - startLngLat.lat) * progress;
                  let headingCal = bearing(startLngLat.lat, startLngLat.lng, lat, lng);
                  marker.setLngLat([lng, lat]);
                  marker.setRotation(headingCal || 0);
                  requestAnimationFrame(animate);
                } else {
                  let threshold = 0.0001;
                  let lngDiff = Math.abs(marker.getLngLat().lng - coordinates[1]);
                  let latDiff = Math.abs(marker.getLngLat().lat - coordinates[0]);
                  if (lngDiff < threshold && latDiff < threshold) {
                    marker.setLngLat([coordinates[1], coordinates[0]]);
                  }
                  else requestAnimationFrame(animate);
                }
              }
              animate();
              let latlngs = [];
              if (route?.roundFound && route?.polyline.length > 0) {
                let latlngsCopy = [];
                for (let polyline of route?.polyline) {
                  latlngsCopy.push(polyUtil.decode(polyline, 6));
                }
                latlngsCopy[0].forEach(ele => { latlngs.push(ele.reverse()) })
              }
              else {
                let orderLatalng = this.orderDataAfterVerification?.deliveryAddress || {}
                latlngs.push([coordinates[1], coordinates[0]])
                latlngs.push([orderLatalng.l_geo_long, orderLatalng.l_geo_lat])
              }
              const routeSource = this.tilesGl.getSource('route');
              if (routeSource) {
                routeSource.setData({
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates: latlngs
                  }
                });
              };
            };
          });
        } else {
          console.log('im out')
          for (let marker of this.markerClusterData) marker.remove();
          this.markerClusterData = [];
          if (this.tilesGl.getLayer("arrowId")) {
            await this.tilesGl.removeImage("arrow");
            await this.tilesGl.removeLayer("arrowId");
          };

          if (this.tilesGl.getLayer('routeCopy')) await this.tilesGl.removeLayer('routeCopy');
          if (this.tilesGl.getSource('route')) await this.tilesGl.removeSource('route');
          if (this.socket) {
            this.socket.emit('disConnectCustomerSocket');
            this.socket.disconnect();
          };
          this.addLocationMArker(this.response);
        };
      };
    });
  };

  closeSocket() {
    if (this.socket) {
      this.socket.emit('disConnectCustomerSocket');
      this.socket.disconnect();
    };
  };

  ngOnDestroy() {
    window.onbeforeunload = null;
  };

  // replacement for settimeout function
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  async getUserLogo(image_id) {
    let profile_image_b64 = await this.adminService.getLogoImageById(image_id);
    if (profile_image_b64.success) {
      this.driverImage = `data:image/jpg;base64,${profile_image_b64.data.data}`;
    };
  };
}

// Converts from degrees to radians.
function toRadians(degrees) {
  return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
function toDegrees(radians) {
  return radians * 180 / Math.PI;
}

function bearing(startLat, startLng, destLat, destLng) {
  let startLatt = toRadians(startLat);
  let startLngg = toRadians(startLng);
  let destLatt = toRadians(destLat);
  let destLngg = toRadians(destLng);

  let y = Math.sin(destLngg - startLngg) * Math.cos(destLatt);
  let x = Math.cos(startLatt) * Math.sin(destLatt) -
    Math.sin(startLatt) * Math.cos(destLatt) * Math.cos(destLngg - startLngg);
  let brng = Math.atan2(y, x);
  brng = toDegrees(brng);
  return (brng + 360) % 360;
}

function getDiffBwnTwoDates(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}