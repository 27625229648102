<div class="aupris_main">
    <p class="headerText" style="color:'var(--primaryColour)'">
        {{languageData['Popups']['Var_emergency']|| 'Notfall Hotline' }}
    </p>
    <div>
        <span style="font-size: 14px;">{{dashboardData?.hotlineInfoText}}
        </span>
    </div>
    <div class="hotlineNumber" style="color:'var(--primaryColour)'">
        <span>{{dashboardData?.hotline}}</span>
    </div>
    <div>
        <button class="au-btn btn-default borderCss" type="button"
        style="background: 'var(--primaryColour)';color: 'var(--primaryTextColour)'"
            (click)="onclick()">{{languageData['Popups']['Var_schlieben']||
            'Schließen'}}</button>
    </div>
</div>