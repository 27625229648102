import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberWithDot]'
})
export class OnlyNumberWithDotDirective {

  constructor() { }

  @Input() inputValue: any;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;

    if ((this.inputValue == undefined || this.inputValue.length == 0)
      && (e.keyCode == 189 || e.keyCode == 109)) {
      return;
    }
    if ([46, 8, 9, 27, 13, 110, 190, 188, 109].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
}
