import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { ICustomWindow, WindowRefService } from '../Services/window.service';

@Component({
  selector: 'app-stagefour',
  templateUrl: './stagefour.component.html',
  styleUrls: ['./stagefour.component.css']
})
export class StagefourComponent implements OnInit {
  interval: any;
  showcount: number;
  pricecalc: string = environment.pricecalc;
  hostname: string;
  url: any;
  private _window: ICustomWindow;
  constructor(
    private windowRef: WindowRefService,
  ) {
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }

    this._window = this.windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;
  }

  @Input() clickedPage;
  @Input() options;
  @Input() localoptions;
  @Input() languageData;
  @Input() page_2: boolean;
  @Input() page_3: boolean;
  @Input() page_4: boolean;
  @Input() colors;
  @Input() blockeduser;
  @Input() isLoggedIn;
  @Input() isGuestUser;
  // @Input() isGuestUser;
  @Input() smallpclink;
  @Output() pageEmit = new EventEmitter();


  ngOnInit() {
    this.url = this._window.location.href;
    let params = new URLSearchParams(this.url);
    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));
    }
    // var counter = 5;
    // this.interval = setInterval(() => {
    //   // console.log(counter);
    //   this.showcount = counter;
    //   counter--;
    //   if (counter < 0) {
    //     clearInterval(this.interval);
    //     this.pageEmit.emit(true)
    //   };
    // }, 1000)
    // clear previous address
    localStorage.removeItem('recentAddresses');

  }
  // ngOnDestroy() {
  //   if (this.interval) {
  //     clearInterval(this.interval);
  //   }
  // }
  redirect() {
    // this.pageEmit.emit(true);
    //window.location.replace(this.pricecalc + "#&Domainname=" + this.hostname);
    if (!environment.testSite_or_thirdPartySites) {
      let url = environment.pricecalc;
      window.location.replace(url + "#&Domainname=" + this.hostname);
    } else {
      let url = `https://${this.smallpclink}`;
      window.location.replace(url)
    }
  }

}
