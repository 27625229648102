import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-select-calculator',
  templateUrl: './select-calculator.component.html',
  styleUrls: ['./select-calculator.component.css']
})
export class SelectCalculatorComponent implements OnInit {
  languageData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, private langService: LanguageService,
    public dialogRef: MatDialogRef<SelectCalculatorComponent>) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }
  colors;
  ngOnInit() {
  }
  openCalculator() {
    this.dialogRef.close(true)
  }
  onclick(){
    this.dialogRef.close()
  }
}
