import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../Services/language.service';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  id: any;
  color: any;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};

  constructor(private  langService: LanguageService,public dialog: MatDialogRef<EditComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.id=data.id;
    this.color =data.colors;
  }

  ngOnInit() {
    let langData = this.langService.getLocalLanguageData();
 
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
     });
  }

  abbrechen(){
   var savedetails =false;
this.dialog.close({data:savedetails});
  }
  bestellen(){
    var savedetails =true;
    this.dialog.close({data:savedetails});
  }
}
