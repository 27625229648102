import { Component, OnInit } from '@angular/core';
import { SmallPcService } from '../../Services/small-pc.service';
import { WindowRefService, ICustomWindow } from '../../Services/window.service'

@Component({
  selector: 'aupris-kleiner-preisrechner-6',
  templateUrl: './aupris-kleiner-preisrechner6.component.html',
  styleUrls: ['./aupris-kleiner-preisrechner6.component.css']
})
export class AuprisKleinerPreisrechner6Component implements OnInit {
  tag = 6;
  tagData = [];
  private _window: ICustomWindow;
  tracking: Boolean = false;

  constructor(
    public smallPc: SmallPcService,
    private windowRef: WindowRefService,
  ) { }

  ngOnInit(): void {
    this.smallPc.getWebTags('6').subscribe((webLinkData) => {
      if (webLinkData.success) {
        this.tagData = webLinkData.data;
      }
    });

    //for liveTracking
    this._window = this.windowRef.nativeWindow;
    let url = this._window.location.href;
    let params = new URLSearchParams(url);
    if (decodeURIComponent(params.get("view")) == 'tracking') this.tracking = true;
  }
}
