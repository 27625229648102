<div class="aupris_main stage progressb" *ngIf="!typeDriverApp">

  <!-- theme1 -->
  <div class="aurow aucol-md-12" class="progressCss progresbar probar" *ngIf="theme == 'Theme1'">
    <ul class="progressbar_fs flex-progress-bar">
      <li class="active_fs"
        [ngClass]="clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung' ? 'activated':''"
        (click)="page('page1')">{{languageData['Levels'].Var_Bestelloptionen || 'Bestelloptionen'}}</li>
      <li
        [ngClass]="{'active_fs':clickedoption=='Adresseeingebe', 'activated': clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung'}"
        (click)="page('page2')">{{languageData['Levels'].Var_Adresseingabe || 'Adresseingabe'}}</li>
      <li [ngClass]="{'active_fs':clickedoption=='Bestellübersicht', 'activated': clickedoption=='Bestätigung'}"
        (click)="page('page3')">{{languageData['Levels'].Var_Bestellubersicht || 'Bestellübersicht'}}</li>
      <li [ngClass]="clickedoption=='Bestätigung' ? 'activated': ''" (click)="page('page4')">
        {{languageData['Levels'].Var_Bestatigung || 'Bestätigung'}}</li>
    </ul>
  </div>
  <div class="custom-border" style="border-bottom: 2px solid;margin-bottom: 15px;"
    [ngStyle]="{'color': 'var(--secondaryColour)'}" *ngIf="theme == 'Theme1'"></div>

  <!-- theme 2 -->
  <div class="aurow headerRow progresbar1 probar" style="cursor:pointer" *ngIf="theme == 'Theme2'">
    <div class="proColumn">
      <div (click)="page('page1')" class="card"
        [style.background]="(clickedoption=='Bestelloptionen' || clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung') ?'var(--primaryColour)':'white'"
        [style.color]="(clickedoption=='Bestelloptionen' || clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung') ?'white':'black'">
        <h5 class="h19"
          [ngClass]="clickedoption=='Bestelloptionen' || clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung' ? 'checkedclass':'uncheckedclass'"
          *ngIf="!(clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung')">
          1</h5>
        <!-- <img class="tickimg "
          *ngIf="(clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung')"
          src="../../assets/ic_check_circle_24px.svg"> -->
        <svg class="tickimg"
          *ngIf="(clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung')"
          xmlns="http://www.w3.org/2000/svg" width="14.909" height="14.909" viewBox="0 0 14.909 14.909">
          <path id="ic_check_circle_24px"
            d="M9.455,2a7.455,7.455,0,1,0,7.455,7.455A7.457,7.457,0,0,0,9.455,2ZM7.964,13.182,4.236,9.455,5.287,8.4l2.676,2.669,5.658-5.658,1.051,1.059Z"
            transform="translate(-2 -2)" fill="#fff" />
        </svg>

        <h6>{{languageData['Levels'].Var_Bestelloptionen || 'Bestelloptionen'}}</h6>
      </div>
    </div>

    <div class="proColumn">
      <div (click)="page('page2')" class="card"
        [style.background]="(clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung') ?'var(--primaryColour)':'white'">
        <h5 class="h19" *ngIf="!(clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung')"
          [ngClass]="clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung' ? 'checkedclass':'uncheckedclass'">
          2</h5>
        <svg class="tickimg" *ngIf="(clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung')"
          xmlns="http://www.w3.org/2000/svg" width="14.909" height="14.909" viewBox="0 0 14.909 14.909">
          <path id="ic_check_circle_24px"
            d="M9.455,2a7.455,7.455,0,1,0,7.455,7.455A7.457,7.457,0,0,0,9.455,2ZM7.964,13.182,4.236,9.455,5.287,8.4l2.676,2.669,5.658-5.658,1.051,1.059Z"
            transform="translate(-2 -2)" fill="#fff" />
        </svg>
        <h6
          [ngClass]="(clickedoption=='Adresseeingebe' || clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung') ?'checkedclass':'uncheckedclass'">
          {{languageData['Levels'].Var_Adresseingabe || 'Adresseingabe'}}</h6>
      </div>
    </div>

    <div class="proColumn">
      <div (click)="page('page3')" class="card"
        [style.background]="(clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung') ?'var(--primaryColour)':'white'"
        [style.color]="(clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung') ?'white':'black'">
        <h5 class="h19" *ngIf="!(clickedoption=='Bestätigung')"
          [ngClass]="clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung'? 'checkedclass':'uncheckedclass'">
          3</h5>
        <svg class="tickimg" *ngIf="(clickedoption=='Bestätigung')" xmlns="http://www.w3.org/2000/svg" width="14.909"
          height="14.909" viewBox="0 0 14.909 14.909">
          <path id="ic_check_circle_24px"
            d="M9.455,2a7.455,7.455,0,1,0,7.455,7.455A7.457,7.457,0,0,0,9.455,2ZM7.964,13.182,4.236,9.455,5.287,8.4l2.676,2.669,5.658-5.658,1.051,1.059Z"
            transform="translate(-2 -2)" fill="#fff" />
        </svg>
        <h6
          [ngClass]="(clickedoption=='Bestellübersicht' || clickedoption=='Bestätigung') ?'checkedclass':'uncheckedclass'">
          {{languageData['Levels'].Var_Bestellubersicht || 'Bestellübersicht'}}</h6>
      </div>
    </div>

    <div class="proColumn">
      <div (click)="page('page4')" class="card"
        [style.background]="(clickedoption=='Bestätigung') ?'var(--primaryColour)':'white'"
        [style.color]="(clickedoption=='Bestätigung') ?'white':'black'">
        <h5 class="h19" *ngIf="!(clickedoption=='Bestätigung')"
          [ngClass]="(clickedoption=='Bestätigung') ?'checkedclass':'uncheckedclass'">4</h5>
        <svg class="tickimg" *ngIf="(clickedoption=='Bestätigung')" xmlns="http://www.w3.org/2000/svg" width="14.909"
          height="14.909" viewBox="0 0 14.909 14.909">
          <path id="ic_check_circle_24px"
            d="M9.455,2a7.455,7.455,0,1,0,7.455,7.455A7.457,7.457,0,0,0,9.455,2ZM7.964,13.182,4.236,9.455,5.287,8.4l2.676,2.669,5.658-5.658,1.051,1.059Z"
            transform="translate(-2 -2)" fill="#fff" />
        </svg>
        <h6 [ngClass]="(clickedoption=='Bestätigung') ?'checkedclass':'uncheckedclass'">
          {{languageData['Levels'].Var_Bestatigung || 'Bestätigung'}}</h6>
      </div>
    </div>
  </div>
</div>

<!------------------------------------------driver app tracking----------------------------------->

<div class="aupris_main" style="overflow:initial" *ngIf="typeDriverApp">
  <!-- <div class="aurow aucol-md-12" class="progressCss">
    <ul class="progresswidth progressbar_fs">
      <li class="active_fss ddd" 
        [ngClass]="statusChange=='' || statusChange=='0' || statusChange=='1' || statusChange=='2' || statusChange=='3' || statusChange=='4' ? 'statusactivated':''">{{languageData['Verkauf'].Var_finalOrderStatus0 || 'Bestellung ausstehend'}} </li>
      <li  class="active_fss ddd"
        [ngClass]="{'active_fss':statusChange=='0','statusactivated': statusChange=='1' || statusChange=='2' || statusChange=='3' || statusChange=='4'}">{{languageData['Verkauf'].Var_finalOrderStatus1 || 'Bestellung bestätigt'}}</li>
      <li  class="active_fss ddd" [ngClass]="{'active_fss':statusChange=='1','statusactivated': statusChange=='2' || statusChange=='3' || statusChange=='4'}">{{languageData['Verkauf'].Var_finalOrderStatus2 || 'Lieferung geplant'}}</li>
      <li  class="active_fss ddd" [ngClass]="{'active_fss':statusChange=='2','statusactivated': statusChange=='3' || statusChange=='4'}">{{languageData['Verkauf'].Var_finalOrderStatus3 || 'Lieferung unterwegs'}}</li>
      <li  class="active_fss ddd" [ngClass]="{'active_fss':statusChange=='3', 'statusactivated': statusChange=='4'}">{{languageData['Verkauf'].Var_finalOrderStatus4 || 'Lieferung abgeschlossen'}}</li>
    </ul>
  </div> -->
  <!-- <div class="custom-border" style="border-bottom: 2px solid; margin: 82px 15px 15px 15px;"
    [ngStyle]="{'color': colors?.secondaryColour}" ></div> -->
  <div class="aupris_main" *ngIf="!screenType">
    <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12 mobileviews"
      style=" padding-left:3px;padding-right:0px">
      <div class="progressbar-wrapper bubble">
        <div class="line"></div>
        <div class="percent"
        [ngStyle]="{'width': statusChange ==0 ? '10%' : statusChange==1 ? '33%' : statusChange==2 ? '55%' : statusChange==3 ? '80%' : statusChange == 4 ? '90%' : ''}">
        </div>
        <ul class="progressbar lgs textTransform" id="list">
          <li class="active_fss ddd"><span class="first">{{languageData['Verkauf'].Var_finalOrderStatus0 || 'Bestellung
              ausstehend'}}</span> </li>
          <li class="active_fss ddd">{{languageData['Verkauf'].Var_finalOrderStatus1 || 'Bestellung bestätigt'}}</li>
          <li class="active_fss ddd">{{languageData['Verkauf'].Var_finalOrderStatus2 || 'Lieferung geplant'}}</li>
          <li class="active_fss ddd">{{languageData['Verkauf'].Var_finalOrderStatus3 || 'Lieferung unterwegs'}}</li>
          <li class="active_fss ddd"><span class="last">{{languageData['Verkauf'].Var_finalOrderStatus4 || 'Lieferung
              abgeschlossen'}}</span></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="progressbar-wrapper bubble" *ngIf="screenType">
    <div class="line"></div>
    <div class="percent"
      [ngStyle]="{'width': statusChange ==0 ? '10%' : statusChange==1 ? '33%' : statusChange==2 ? '55%' : statusChange==3 ? '80%' : statusChange == 4 ? '90%' : ''}">
    </div>
    <ul class="progressbar md textTransform" id="list">
      <li class="active_fss ddd sm" style="margin-left: -12px !important;"><span *ngIf="statusChange==0"
          class="firststatus">{{languageData['Verkauf'].Var_finalOrderStatus0 || 'Bestellung ausstehend'}} </span></li>
      <li class="active_fss ddd sm"><span *ngIf="statusChange==1">{{languageData['Verkauf'].Var_finalOrderStatus1 ||
          'Bestellung bestätigt'}}</span></li>
      <li class="active_fss ddd sm"><span *ngIf="statusChange==2">{{languageData['Verkauf'].Var_finalOrderStatus2 ||
          'Lieferung geplant'}}</span></li>
      <li class="active_fss ddd sm"><span *ngIf="statusChange==3">{{languageData['Verkauf'].Var_finalOrderStatus3 ||
          'Lieferung unterwegs'}}</span></li>
      <li class="active_fss ddd sm"><span *ngIf="statusChange==4"
          class="laststatus">{{languageData['Verkauf'].Var_finalOrderStatus4 || 'Lieferung abgeschlossen'}}</span></li>
    </ul>
  </div> <br />

  <div id='progress-bar'>
    <div id='progress-now'></div>
  </div>
</div>