import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Builder } from 'protractor';
import { LanguageService } from '../../Services/language.service';
// import { ValidateAddressPipe } from 'app/pipes/validate-address.pipe';

@Component({
  selector: 'app-rate-payment-selection-popup',
  templateUrl: './rate-payment-selection-popup.component.html',
  styleUrls: ['./rate-payment-selection-popup.component.css']
})
export class RatePaymentSelectionPopupComponent implements OnInit {
  colors: any;
  languageData: any;
  ZahlungSpans: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder, public dialog: MatDialog,
    private langService: LanguageService,
    public dialogRef: MatDialogRef<RatePaymentSelectionPopupComponent>) {

    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

  }
  public ratenzahlungForm: FormGroup;
  ngOnInit() {
    this.ratenzahlungForm = this.fb.group({
      span: ['',Validators.required]
    })
    this.ZahlungSpans = this.data
  }

  close() {
    console.log(this.ratenzahlungForm.value.span,399);
    this.dialogRef.close(this.ratenzahlungForm.value.span)
  }

  redirectionToStore(){
    this.dialogRef.close();
  }


}