import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../Services/language.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  color: any;
  languageData: any;
  constructor(public dialogRef: MatDialogRef<InvoiceComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private langService: LanguageService,) {
    this.color = data.colors;
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit() {
  }
  onclick() {
    this.dialogRef.close();
  }
}
