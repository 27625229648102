<div *ngIf="!pcOff_Pbbackend && !customersLiveTracking">
    <app-verification-new *ngIf="colors && verification"></app-verification-new>

    <app-data-security-new *ngIf="colors && datasecurity_verification"></app-data-security-new>

    <app-data-security-form-new *ngIf="colors && dataSecurity_View"></app-data-security-form-new>

    <app-login
        *ngIf="(loginPage && !(isLoggedIn || isGuestUser) && registration_at_order != 2 && activation && !testmode) || (forceShowLogin && activation && !testmode)"
        [colors]="colors" [_portalTermsAndConditions]="portalTermsAndConditions"
        [registration_at_order]="registration_at_order" [_allowRegistration]="allowRegistration"
        [consentNewsletter]="consentNewsletter" [productInfoForWebPC]="productInfoForWebPC">
    </app-login>

    <app-firststage *ngIf="bigpc" [languageData]="languageData" (logoutsmallpc)="logout()" [_isGuestUser]="isGuestUser"
        (userLoggedIn)="userLoggedIn($event)" [productInfoForWebPC]="productInfoForWebPC"
        [portalTermsAndConditions]="portalTermsAndConditions" [alarmSettings]="alarmSettings"
        [thridStagePdfs]="thridStagePdfs">
    </app-firststage>


    <app-verification *ngIf="colors && userVerification" [_minMaxData]="minMaxData">

    </app-verification>


    <app-pw-verification *ngIf="ftpassword" [ftpassword]="ftpassword" [tokenForVerification]="tokenForVerification"
        (resetPass)="navigateToLogin($event)">
    </app-pw-verification>

    <app-update-email-verification *ngIf="updateEmailVerification"
        (showloginPage)="showLoginAfterEmailVerification($event)">

    </app-update-email-verification>

    <app-email-marketing *ngIf="colors && emailMarketing" (cancelNewsletter)="toUseraccount($event)" [colors]="colors">

    </app-email-marketing>

    <app-newsletter-cancellation *ngIf="colors && newsLetterCancellation" (gotoUser)="gotoUser($event)"
        [colors]="colors">
    </app-newsletter-cancellation>
</div>


<div *ngIf="customersLiveTracking">
    <app-tracking-verification></app-tracking-verification>
</div>


<div *ngIf="pcOff_Pbbackend && bigpc && !customersLiveTracking">
    {{languageData?.Loading?.Var_PC_Off_From_PbBackend || 'Unsre Preisrechner befindet sich im Wartungsmodus!'}}
</div>