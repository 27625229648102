import { Component, OnInit, Output, EventEmitter, DoCheck, Input } from '@angular/core';
import { PortalAuthService } from 'app/login/auth.service';
// import { MybigpcService } from 'app/Services/mybigpc.service';
import { WindowRefService } from '../../Services/window.service';
import { LanguageService } from '../../Services/language.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-email-varification',
  templateUrl: './email-varification.component.html',
  styleUrls: ['./email-varification.component.css']
})
export class EmailVarificationComponent implements OnInit, DoCheck {
  @Output() ftpassword = new EventEmitter<boolean>();
  env;
  email: any;
  verify: boolean = false;
  wrongemail: boolean = false;
  colors: any;
  value: any;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  minMaxData: any;
  smallpclink: any;
  bigpclink: any;
  sessionId: string;
  sessionData: any;
  Liter: any;
  Lieferort: any;
  Product: any;
  abladestellen: any;
  private _window: import("../../Services/window.service").ICustomWindow;
  url: string;
  params: URLSearchParams;
  showFullScreenLoading: boolean;
  thirdparty: boolean = false;
  forgotPassword: boolean = false;

  @Input() passwordReset;
  // private _window;
  // @Input() set _minMaxData(minmaxdata) {
  //   this.minMaxData = minmaxdata;
  //   this.setMinMaxData(minmaxdata)
  // }
  // get _minMaxData() {
  //   return this.minMaxData
  // }
  constructor(private authService: PortalAuthService, private windowRef: WindowRefService,
     private langService: LanguageService) { }
  public emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  async ngOnInit() {
    this.env = environment;
    // let data: any = await this.MybigpcService.getMinMaxData();
    // this.colors = data.colors;
    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
    this._window = this.windowRef.nativeWindow;
    this.url = this._window.location.href;
    this.params = new URLSearchParams(this.url);
    // this.setSessionData(this.params);
  }
  ngDoCheck() { }
  abbrechen() {
    this.ftpassword.emit(false)
  }
  passwordverification(email) {
    // var _window = this.windowRef.nativeWindow;
    // var url = _window.location.href;
    // var params = new URLSearchParams(url);
    // var product = decodeURIComponent(params.get("Product"));
    // var product_quantity = decodeURIComponent(params.get("Liter"));
    // var Lieferort = decodeURIComponent(params.get("Lieferort"));
    //var domainlink = this.smallpcservice.getMatchedDomainLink();
    var domainlink = window.location.protocol + "//" + window.location.host;
    const object = { language: localStorage.getItem("language") };
    this.showFullScreenLoading = true;

    if (!this.env.testSite_or_thirdPartySites) {
       this.thirdparty = false
    }else{
      this.thirdparty = true
    }
    this.authService.forgotpassword({ thirdparty:this.thirdparty,email: email.control.value, product: this.Product, liter: this.Liter, Lieferort: this.Lieferort, domainlink: domainlink, object: object ,colors:this.colors}).subscribe(data => {
      this.showFullScreenLoading = false;
      var result: any = {};
      result = data;
      if (result.success == true) {
        this.verify = true;
        this.forgotPassword = true;
        this.passwordReset = false ;
        setTimeout(() => {  }, 2000)
      }
      else {
        this.wrongemail = true;
      }
      // setTimeout(() => { this.verify = false; this.wrongemail = false; }, 8000)
    } , (err) => {
      this.showFullScreenLoading = false;
      this.wrongemail = true;
      // setTimeout(() => { this.verify = false; this.wrongemail = false; }, 8000)
    });

  }
  registeredHandler(){
    this.ftpassword.emit(false)
  }
  
}
// this.ftpassword.emit(false)
// old code
// async setSessionData(params: URLSearchParams) {
  //   let sessionId = params.get('sid');
  //   if (sessionId) {
  //     this.sessionId = sessionId;
  //     this.sessionData = await this.smallpcservice.getSessionData(this.sessionId);

  //     this.bigpclink = this.minMaxData.matcheddomainlink;
  //     this.smallpclink = this.minMaxData.domain;
  //     if (!this.sessionData) {
  //       // this.showFullScreenLoading = false;
  //       // this.sessionExpire = true;
  //       setTimeout(() => {
  //         // this.sessionExpire = false;
  //         // if (!environment.testSite_or_thirdPartySites) {
  //         //   let url = environment.;
  //         //   let domainname = decodeURIComponent(params.get("Domainname"));
  //         //   window.location.replace(url + "#&Domainname=" + domainname);
  //         // } else {
  //         //   let url = `https://${this.smallpclink}`;
  //         //   window.location.replace(url)
  //         // }
  //       }, 3000);
  //     }
  //     // if (!this.sessionData.liter || !this.sessionData.lieferort || !this.sessionData.product) {
  //     //   // send back to small pc
  //     // }
  //     this.Liter = this.sessionData.liter;
  //     this.Lieferort = this.sessionData.lieferort;
  //     this.Product = this.sessionData.product;
  //     this.abladestellen = this.sessionData.abladestellen;
  //   } else {
  //     setTimeout(() => {
  //       // this.sessionExpire = false;
  //       // if (!environment.testSite_or_thirdPartySites) {
  //       //   let url = environment.api.pricecalc();
  //       //   let domainname = decodeURIComponent(params.get("Domainname"));
  //       //   window.location.replace(url + "#&Domainname=" + domainname);
  //       // } else {
  //       //   let url = `https://${this.smallpclink}`;
  //       //   window.location.replace(url)
  //       // }
  //     }, 3000);
  //   }
  // }

  // setMinMaxData(minmaxdata) {
  //   this.minMaxData = minmaxdata;
  //   this.bigpclink = this.minMaxData.matcheddomainlink;
  //   this.smallpclink = this.minMaxData.domain;
  // }