import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ICustomWindow, WindowRefService } from '../../Services/window.service';
import { PortalAuthService } from 'app/login/auth.service';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../Services/language.service';
import { SmallPcService } from '../../Services/small-pc.service';
import { InfoPasswordPopupComponent } from 'app/info-password-popup/info-password-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalstorgeService } from 'app/Services/localstorge.service';
import { BigpcService } from 'app/Services/bigpc.service';



@Component({
  selector: 'app-pw-verification',
  templateUrl: './pw-verification.component.html',
  styleUrls: ['./pw-verification.component.css']
})
export class PwVerificationComponent implements OnInit {
  passwordVisibility: boolean = false;
  colors;
  url;
  hostname;
  pwreseted: boolean = false;
  verifiedeMail: boolean = false;
  verification: boolean = false;
  private _window: ICustomWindow;
  bigpclink: any;
  resetPasswordFrom: FormGroup;
  Liter: string;
  Product: string;
  view: string;
  Lieferort: string;
  params: URLSearchParams;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  smallpclink: any;
  loading: boolean;
  href: string;
  sessionId: string;
  abladestellen: any;
  public pswdregex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,20}$/;
  @Input() tokenForVerification;
  @Input() ftpassword;
  @Output() resetPass = new EventEmitter<boolean>();
  sessionData: any;

  screenLoaderSet: boolean = false;
  loaderTime: any = 10000;
  languageDataCheck: any;
  constructor(
    private windowRef: WindowRefService,
    private authService: PortalAuthService,
    public fb: FormBuilder,
    public localStorageService: LocalstorgeService,
    private newBigPcService: BigpcService,
    public smallPcService: SmallPcService,
    private langService: LanguageService,
    private dialog: MatDialog,
  ) {
    this._window = this.windowRef.nativeWindow;
  };


  async ngOnInit() {
    this.resetPasswordFrom = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pswdregex)]],
      re_password: ['', Validators.required],
    }, { validators: this.MatchValidator });

    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    this.params = new URLSearchParams(this.url);/*testcalc*/
    this.Product = this.params.get('')
    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(this.params.get("Domainname"));/*testcalc*/
    }
    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
    this.languageDataCheck = langData.kundeportal || {};
    localStorage.setItem("EnteredToverify", "true");

  }

  MatchValidator = (group: FormGroup) => {
    let password = group.get('password').value
    let re_password = group.get('re_password').value
    return password === re_password ? null : { matched: false }
  };

  openDialog() {
    const dialogRef = this.dialog.open(InfoPasswordPopupComponent, {
      width: '445px',
      height: '267px',
      disableClose: true,
    });
    dialogRef.componentInstance.colors = this.colors;
  };

  stopLoaderHere() {
    setTimeout(() => {
      this.screenLoaderSet = false;
    }, this.loaderTime);
  };

  resetpassword() {
    // if (!this.resetPasswordFrom.valid) {
    //   this.openDialog();
    // };
    if (this.resetPasswordFrom.valid) {
      this.loading = true;
      this.screenLoaderSet = true;
      this.stopLoaderHere();
      this.authService.resetpassword({ confirmPassword: this.resetPasswordFrom.value.re_password, password: this.resetPasswordFrom.value.re_password, id: this.tokenForVerification }).subscribe(async (res: any) => {
        this.loading = false;
        this.screenLoaderSet = false;
        if (res.success) {
          this.authService.storeToken(res.data);
          let obj = { primaryAdress: "", loginUser: true, selectedProduct: {} };
          this.newBigPcService.createNewSession(obj)
          this.resetPass.emit(true);
          return;
        } else {
          this.resetPass.emit(false);
        };
      }, (error: any) => { throw error });
    };
  };

  abbrechen() {
    this.resetPass.emit(false);
  };

  checkValidity(value, type) {
    if (value) {
      let patternValues = {}
      type == 'uppercase' && (patternValues['pattern'] = /.*[A-Z].*/);
      type == 'lowercase' && (patternValues['pattern'] = /.*[a-z].*/);
      type == 'numeric' && (patternValues['pattern'] = /.*\d.*/);
      type == 'specialchar' && ((patternValues['pattern'] = /.*\W|_.*/))
      return patternValues['pattern'].test(value);
    }
  }

}
