<app-alarm-prices *ngIf="createOrEditAlarm" [labels]='labels' (check)="pricealarm=$event; callTheExit($event)"
    (showOverview)="showOverview()">
</app-alarm-prices>


<div *ngIf="!showPriceData && !createOrEditAlarm" class="aupris_main scrolltotopprogress"
    style="text-align: center;margin-top:10rem">
    <span style="margin-bottom: 3rem;">
        <span [innerHTML]="sidePricealaram"></span>
    </span>
    <h5 style="font-size: 15px;">
        {{languageData['Hamburger'].Var_notification || 'Erhalten Sie Benachrichtigungen über fallende oder steigende
        Preise.'}}
    </h5>
    <h5 style="margin-bottom: 35px !important;font-size: 15px;">
        {{languageData['Hamburger'].Var_pricealarm || ' Sie haben noch keine Preisalarme eingestellt.'}}
    </h5>
    <button class="au-btn" appBackgroundcolor appActiveAhover="true"
        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
        (click)="pricealarm = true; openPriceAlaram()">
        {{languageData['Hamburger'].Var_startalarm || 'Starten Sie den Preisalarm-Assistenten'}}
    </button>
</div>

<div *ngIf="showPriceData && !createOrEditAlarm" class="aupris_main scrolltotopprogress">
    <div style="width:100%; float:right;">
        <div class="aurow priceView">
            <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                <p>
                    {{languageData['Bestelloptionen'].Var_headtext || ' Der folgende Preisalarm ist aktiviert'}}
                </p>
            </div>

            <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 prodDetails">
                <div>
                    <span class="plabel">
                        {{languageData['Bestelloptionen'].Var_Produkt || 'Produkt'}}:

                    </span> <span class="pvalue">{{priceAlarmDetails?.product}}</span>
                </div>
                <div>
                    <span class="plabel">
                        {{languageData['Bestelloptionen'].Var_quality || 'Qualitat'}}:
                    </span> <span class="pvalue">{{priceAlarmDetails?.qualityName}}</span>
                </div>
                <div>
                    <span class="plabel">

                        {{languageData['Bestelloptionen'].Var_quantity || 'Menge'}}:
                    </span> <span class="pvalue">{{priceAlarmDetails?.quantity}}</span>
                </div>
                <div>
                    <span class="plabel">
                        {{languageData['Bestelloptionen'].Var_Lieferort || 'Lieferort'}}:
                    </span> <span class="pvalue">{{priceAlarmDetails?.plzOrt}}</span>
                </div>
            </div>

            <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 priceLimit">
                <div class="aurow">
                    <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12"
                        *ngIf="priceAlarmDetails?.desiredPriceLimit">
                        <p>
                            {{languageData['Bestelloptionen'].Var_desiredprice || 'wunschpreis'}}:

                        </p>
                        <div>
                            <span class="mr20">{{textIdentifer('wunchPrice')}}:</span>
                            <span class="amountSec"><span [innerHTML]="priceAlarmDetails.currency_symbol"></span>
                                {{priceAlarmDetails?.desiredPriceLimit| langReplacer : languageVar}}</span>
                        </div>
                    </div>

                    <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12" *ngIf="priceAlarmDetails?.priceLimit">
                        <p>
                            {{languageData['Bestelloptionen'].Var_pricelimit || 'Preisalarm'}}:
                        </p>
                        <div>
                            <span class="mr20"> {{textIdentifer('priceLimit')}}:</span>
                            <span class="amountSec"><span [innerHTML]="priceAlarmDetails.currency_symbol"></span>
                                {{priceAlarmDetails?.priceLimit | langReplacer : languageVar}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 addressSec">
                <p>
                    {{languageData['Bestelloptionen'].Var_goto || 'Alarme gehen an'}}:
                </p>

                <div class="aurow mailSec" *ngIf="priceAlarmDetails?.Email">
                    <div class="aucol-lg-1 aucol-md-1 aucol-sm-2 aucol-xs-2" style="width: 50px;">
                        <span [innerHTML]="alam"></span>
                    </div>
                    <div class="aucol-lg-11 aucol-md-11 aucol-sm-10 aucol-xs-10">
                        <span>{{priceAlarmDetails?.Email}}</span>
                    </div>

                </div>
                <div class="aurow phoneSec" *ngIf="priceAlarmDetails?.sms">
                    <div class="aucol-lg-1 aucol-md-1 aucol-sm-2 aucol-xs-2" style="width: 50px;">
                        <span [innerHTML]="phone"></span>

                    </div>
                    <div class="aucol-lg-11 aucol-md-11 aucol-sm-10 aucol-xs-10">
                        <span> +49</span> <span style="padding-left: 2px;" *ngIf="enableZero">(0)</span><span
                            style="padding-left: 2px;">{{priceAlarmDetails?.sms}}</span>
                    </div>
                </div>
            </div>

            <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 mr0 ml0 pl10 pr10 pb20 sectioncontainers"
                style="margin-top:30px">
                <div class="aurow">
                    <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12 delSec">
                        <button type="button" class="cnlBtn font20 smalBtn" style="background: #f6f7f9; color:black"
                            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                            (click)="deletePriceAlarm()">
                            {{languageData['Bestelloptionen'].Var_deletealarm || 'Preiswecker löschen'}}
                        </button>
                    </div>
                    <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12 contSec">
                        <button type="button" class="nxtBtn font20 smalBtn" (click)="pricealarm=true; openPriceAlaram()"
                            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                            [style.background]="'var(--primaryColour)'">
                            {{languageData['Bestelloptionen'].Var_setnewalarm || 'Neuen Preiswecker stellen'}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<loader [full-screen]="true" *ngIf="loader" [color]="'var(--primaryColour)'"></loader>