import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-voucher-notice',
  templateUrl: './voucher-notice.component.html',
  styleUrls: ['./voucher-notice.component.css']
})
export class VoucherNoticeComponent implements OnInit {
  hostname: any;
  languageData: any;
  offer: any;
  colors: any;
  voucherData: any;
  voucherNett: any;
  voucherTotalMwst: any;
  voucherTotalMwstVal: any;
  languageVar: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<VoucherNoticeComponent>,  private langService: LanguageService,) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit() {
    this.voucherData = this.data  
    this.voucherNett =  Math.abs(this.voucherData.voucherNett)
  }
  // rounding_to_two_decimal = (value) => {
  //   return (Math.round(value * 100) / 100).toFixed(2)
  // }
  onclick() {
    this.dialogRef.close();
  }
}
