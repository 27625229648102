<div class="aupris_main scrolltotopprogress main_body">
  <div *ngIf="!blockeduser" class="aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12 section-container">
    <div class="fourthcard">
      <div class="aurow">
        <div class="aucol-md-1"><svg xmlns="http://www.w3.org/2000/svg" width="27.158" height="24.689"
            viewBox="0 0 27.158 24.689">
            <path id="ic_thumb_up_24px"
              d="M1,25.689H5.938V10.876H1ZM28.158,12.11a2.476,2.476,0,0,0-2.469-2.469H17.9L19.073,4l.037-.4A1.858,1.858,0,0,0,18.566,2.3L17.258,1,9.135,9.135a2.414,2.414,0,0,0-.728,1.741V23.22a2.476,2.476,0,0,0,2.469,2.469h11.11a2.452,2.452,0,0,0,2.271-1.506l3.728-8.7a2.439,2.439,0,0,0,.173-.9V12.221l-.012-.012Z"
              transform="translate(-1 -1)" [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg></div>
        <div class="aucol-md-11" style="text-align: left;">
          {{languageData['Bestatigung'].Var_Ihre_Bestellung_war_erfolgreich || 'Ihre Bestellung war erfolgreich!'}}
        </div>
      </div>
    </div>
    <div class="fourthcard">
      <div class="aurow ">
        <div class="aucol-md-1"><svg xmlns="http://www.w3.org/2000/svg" width="27.158" height="21.727"
            viewBox="0 0 27.158 21.727">
            <path id="ic_markunread_24px"
              d="M26.442,4H4.716a2.712,2.712,0,0,0-2.7,2.716L2,23.011a2.724,2.724,0,0,0,2.716,2.716H26.442a2.724,2.724,0,0,0,2.716-2.716V6.716A2.724,2.724,0,0,0,26.442,4Zm0,5.432-10.863,6.79L4.716,9.432V6.716l10.863,6.79,10.863-6.79Z"
              transform="translate(-2 -4)" [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg>
        </div>
        <div class="aucol-md-11" style="text-align: left;">{{languageData['Bestatigung'].Var_confirmation_Text || 'Die
          Bestellbestätigung wird Ihnen per Email zugestellt.Bitte überprufen Sie lhren Posteingang und
          gegebenenfalls auch lhren Spam Ordner. Sollten Sie innerhalb der nächsten 24 Stunden keine Email erhalten,
          melden Sie sich bitte bei unserem Team!'}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="blockeduser && !isGuestUser">
    <div class="aucol-md-12" style="text-align: center;font-style: 14px;">
      {{languageData['Bestatigung'].Var_order_canceled ||'Bestellvorgang abgebrochen'}} </div>
    <div class="fourthcard">
      <div>
        <div class=" aucol-12 text-center"><svg style="width: 40px;position: relative;
          top: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <defs>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="32" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="21.727" height="21.727" x="8" y="8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <path d="m0 706.47h1490.93v-706.47h-1490.93v706.47" />
              </clipPath>
              <clipPath>
                <path d="m22.2 686.12h1447.73v-667.19h-1447.73v667.19" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.72721.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
            </defs>
            <path
              d="m562.2 609.8c-4.917 0-8.917 4-8.917 8.917 0 4.917 4 8.917 8.917 8.917 4.917 0 8.917-4 8.917-8.917 0-4.917-4-8.917-8.917-8.917m0 3.01c1.082 0 2.096.293 2.969.803l-8.07 8.07c-.51-.873-.803-1.887-.803-2.969 0-3.255 2.648-5.903 5.903-5.903m0 11.806c-1.082 0-2.096-.293-2.969-.803l8.07-8.07c.51.873.803 1.887.803 2.969 0 3.255-2.648 5.903-5.903 5.903"
              fill="#4d4d4d" transform="matrix(-.89713 0 0 .89713 515.36-544.07)"
              [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg>
          {{languageData['Bestatigung'].Var_orderthrough_this_account_canceled||'Die Bestellung über diesen Account
          wurde abgelehnt.'}}
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;" *ngIf="showcount">
      <div class="numcircle3" [ngStyle]="{background: 'var(--primaryColour)'}">
        <div class="numcircle2" [ngStyle]="{background: 'var(--primaryColour)'}">
          <div class='numberCircle' [ngStyle]="{background: 'var(--primaryColour)'}">{{showcount}}</div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="blockeduser && isGuestUser ">
    <div class="aucol-md-11" style="text-align: center;">{{languageData['Bestatigung'].Var_order_canceled
      ||'Bestellvorgang abgebrochen'}} </div>
    <div class="fourthcard">
      <div>
        <div class=" aucol-12 text-center"><svg style="width: 40px;position: relative;
          top: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <defs>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="32" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="21.727" height="21.727" x="8" y="8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <path d="m0 706.47h1490.93v-706.47h-1490.93v706.47" />
              </clipPath>
              <clipPath>
                <path d="m22.2 686.12h1447.73v-667.19h-1447.73v667.19" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.72721.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
            </defs>
            <path
              d="m562.2 609.8c-4.917 0-8.917 4-8.917 8.917 0 4.917 4 8.917 8.917 8.917 4.917 0 8.917-4 8.917-8.917 0-4.917-4-8.917-8.917-8.917m0 3.01c1.082 0 2.096.293 2.969.803l-8.07 8.07c-.51-.873-.803-1.887-.803-2.969 0-3.255 2.648-5.903 5.903-5.903m0 11.806c-1.082 0-2.096-.293-2.969-.803l8.07-8.07c.51.873.803 1.887.803 2.969 0 3.255-2.648 5.903-5.903 5.903"
              fill="#4d4d4d" transform="matrix(-.89713 0 0 .89713 515.36-544.07)"
              [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg>
          {{languageData['Bestatigung'].Var_order_has_not_been_processed_denied_by_system ||'Die Bestellung konnte nicht
          durchgeführt werden. Sie wurde von unserem System abgelehnt.'}}
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="aupris_main scrolltotopprogress main_body">
  <div *ngIf="!blockeduser" class="aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12 section-container">
    <div class="fourthcard">
      <div class="aurow">
        <div class="aucol-md-1"><svg xmlns="http://www.w3.org/2000/svg" width="27.158" height="24.689"
            viewBox="0 0 27.158 24.689">
            <path id="ic_thumb_up_24px"
              d="M1,25.689H5.938V10.876H1ZM28.158,12.11a2.476,2.476,0,0,0-2.469-2.469H17.9L19.073,4l.037-.4A1.858,1.858,0,0,0,18.566,2.3L17.258,1,9.135,9.135a2.414,2.414,0,0,0-.728,1.741V23.22a2.476,2.476,0,0,0,2.469,2.469h11.11a2.452,2.452,0,0,0,2.271-1.506l3.728-8.7a2.439,2.439,0,0,0,.173-.9V12.221l-.012-.012Z"
              transform="translate(-1 -1)" [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg></div>
        <div class="aucol-md-11" style="text-align: left;">
          {{languageData['Bestatigung'].Var_Ihre_Bestellung_war_erfolgreich || 'Ihre Bestellung war erfolgreich!'}}
        </div>
      </div>
    </div>
    <div class="fourthcard">
      <div class="aurow ">
        <div class="aucol-md-1"><svg xmlns="http://www.w3.org/2000/svg" width="27.158" height="21.727"
            viewBox="0 0 27.158 21.727">
            <path id="ic_markunread_24px"
              d="M26.442,4H4.716a2.712,2.712,0,0,0-2.7,2.716L2,23.011a2.724,2.724,0,0,0,2.716,2.716H26.442a2.724,2.724,0,0,0,2.716-2.716V6.716A2.724,2.724,0,0,0,26.442,4Zm0,5.432-10.863,6.79L4.716,9.432V6.716l10.863,6.79,10.863-6.79Z"
              transform="translate(-2 -4)" [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg>
        </div>
        <div class="aucol-md-11" style="text-align: left;">{{languageData['Bestatigung'].Var_confirmation_Text || 'Die
          Bestellbestätigung wird Ihnen per Email zugestellt.Bitte überprufen Sie lhren Posteingang und
          gegebenenfalls auch lhren Spam Ordner. Sollten Sie innerhalb der nächsten 24 Stunden keine Email erhalten,
          melden Sie sich bitte bei unserem Team!'}}
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;" *ngIf="showcount">
      <div class="numcircle3" [ngStyle]="{background: 'var(--primaryColour)'}">
        <div class="numcircle2" [ngStyle]="{background: 'var(--primaryColour)'}">
          <div class='numberCircle' [ngStyle]="{background: 'var(--primaryColour)'}">{{showcount}}</div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;" *ngIf="showcount">
      <div class="numcircle3" [ngStyle]="{background: 'var(--primaryColour)'}">
        <div class="numcircle2" style="background-color: transparent;">
          <div class='numberCircle' style="background-color: transparent;">{{showcount}}</div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;">
      <button class="nxtBtn font20" [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
        [style.background]="'var(--primaryColour)'" (click)="redirect()">
        {{languageData['Bestatigung'].Var_back || 'Geh zurück'}}
      </button>
    </div>
  </div>
  <div *ngIf="blockeduser && !isGuestUser">
    <div class="aucol-md-12" style="text-align: center;font-style: 14px;">
      {{languageData['Bestatigung'].Var_order_canceled ||'Bestellvorgang abgebrochen'}} </div>
    <div class="fourthcard">
      <div>
        <div class=" aucol-12 text-center"><svg style="width: 40px;position: relative;
        top: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <defs>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="32" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="21.727" height="21.727" x="8" y="8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <path d="m0 706.47h1490.93v-706.47h-1490.93v706.47" />
              </clipPath>
              <clipPath>
                <path d="m22.2 686.12h1447.73v-667.19h-1447.73v667.19" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.72721.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
            </defs>
            <path
              d="m562.2 609.8c-4.917 0-8.917 4-8.917 8.917 0 4.917 4 8.917 8.917 8.917 4.917 0 8.917-4 8.917-8.917 0-4.917-4-8.917-8.917-8.917m0 3.01c1.082 0 2.096.293 2.969.803l-8.07 8.07c-.51-.873-.803-1.887-.803-2.969 0-3.255 2.648-5.903 5.903-5.903m0 11.806c-1.082 0-2.096-.293-2.969-.803l8.07-8.07c.51.873.803 1.887.803 2.969 0 3.255-2.648 5.903-5.903 5.903"
              fill="#4d4d4d" transform="matrix(-.89713 0 0 .89713 515.36-544.07)"
              [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg>
          {{languageData['Bestatigung'].Var_orderthrough_this_account_canceled||'Die Bestellung über diesen Account
          wurde abgelehnt.'}}
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;" *ngIf="showcount">
      <div class="numcircle3" [ngStyle]="{background: 'var(--primaryColour)'}">
        <div class="numcircle2" [ngStyle]="{background: 'var(--primaryColour)'}">
          <div class='numberCircle' [ngStyle]="{background: 'var(--primaryColour)'}">{{showcount}}</div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="blockeduser && isGuestUser ">
    <div class="aucol-md-11" style="text-align: center;">{{languageData['Bestatigung'].Var_order_canceled
      ||'Bestellvorgang abgebrochen'}} </div>
    <div class="fourthcard">
      <div>
        <div class=" aucol-12 text-center"><svg style="width: 40px;position: relative;
        top: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <defs>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="32" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="21.727" height="21.727" x="8" y="8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect width="27.158" height="21.727" x="392.57" y="507.8" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <path d="m0 706.47h1490.93v-706.47h-1490.93v706.47" />
              </clipPath>
              <clipPath>
                <path d="m22.2 686.12h1447.73v-667.19h-1447.73v667.19" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="32" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.72721.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="507.8" x="392.57" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
              <clipPath>
                <rect y="8" x="8" height="21.727" width="27.158" fill="none" rx="16" />
              </clipPath>
            </defs>
            <path
              d="m562.2 609.8c-4.917 0-8.917 4-8.917 8.917 0 4.917 4 8.917 8.917 8.917 4.917 0 8.917-4 8.917-8.917 0-4.917-4-8.917-8.917-8.917m0 3.01c1.082 0 2.096.293 2.969.803l-8.07 8.07c-.51-.873-.803-1.887-.803-2.969 0-3.255 2.648-5.903 5.903-5.903m0 11.806c-1.082 0-2.096-.293-2.969-.803l8.07-8.07c.51.873.803 1.887.803 2.969 0 3.255-2.648 5.903-5.903 5.903"
              fill="#4d4d4d" transform="matrix(-.89713 0 0 .89713 515.36-544.07)"
              [ngStyle]="{fill:'var(--primaryColour)'}" />
          </svg>
          {{languageData['Bestatigung'].Var_order_has_not_been_processed_denied_by_system ||'Die Bestellung konnte nicht
          durchgeführt werden. Sie wurde von unserem System abgelehnt.'}}
        </div>
      </div>
    </div>
  </div>
</div>  -->