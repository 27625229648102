<div class="aupris_main">
  <div class="tagss" style="text-align: center;font-size: 20px;
font-weight: bold;font-family: sans-serif;">Wunschtermin</div>
  <div>
    <p class="fontt" style="width: 500px;font-size: 14px;font-family: sans-serif;">
      {{languageData?.placeholder?.Var_infoText|| 'Bitte wählen Sie für Ihre Wunschlieferung ein Anfangs- und ein
      Enddatum aus. Achten Sie dabei bitte darauf, dass an allen ausgegrauten Kalendertagen keine Lieferungen
      stattfinden und folglich auch nicht zur Auswahl stehen.'}}

    </p>
  </div>

  <div class="input-group" style="margin-top: 25px;">
    <input class="input-box" readonly angular-mydatepicker [options]=" myOptions" type="hidden"
      #dp="angular-mydatepicker" (click)="dp.toggleCalendar()" autocomplete="off" id="selectdate"
      (rangeDateSelection)="onRangeDateSelection($event)" (calendarViewChanged)="onCalendarViewChanged();"
      [defaultMonth]="defaultMonth" [(ngModel)]="model">
  </div>

  <div style="margin-top:59%">
    <button mat-raised-button class="abbrechen" appActiveAhover="true"
      [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
      (click)="pretext()">{{languageData?.placeholder?.Var_cancel || 'Abbrechen'}}</button>
    <button mat-raised-button class="bestatigen" style="float:right;display: inline-block;" appActiveAhover="true"
      [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="wunster()"
      [disabled]="!model.dateRange?.beginDate.day && !model.dateRange?.endDate.day">
      {{languageData?.placeholder?.Var_confirm || 'Bestätigen'}}</button>
  </div>
</div>