<div class="aupris_main mainContent" *ngIf="trackingIdFromUrl && liveTrackingAccess">

    <div class="mainContent" [ngStyle]="{'padding': smallScreen==true? '0px 6px 0px 0px' : ' ' }"
        [ngClass]="(smallScreen==true && mapshow==true) ? 'blurbackgroundss' : (mobile==true && mapshow==false) ? 'blurbackgroundss' : smallScreen==true ? 'blurbackground' : 'expectmobile'">
        <div class="nospace">
            <div class="head">
                {{ languageData['customersLiveTracking']['VarPageCustomerTracking1'] || 'Lieferung verfolgen' }}
            </div>
            <div class="subhead">
                <span *ngIf="orderData?.Status != '4'">{{
                    languageData['customersLiveTracking']['VarPageCustomerTracking2'] || 'Voraussichtliche Ankunftszeit'
                    }}</span>
                <span *ngIf="orderData?.Status == '4'">{{
                    languageData['customersLiveTracking']['VarPageCustomerTracking19'] || 'Ware geliefert!'
                    }}</span>
            </div>
            <div class="time" *ngIf="orderData.localOrderStatus=='InProgress'">
                <div *ngIf="orderData?.LiveETA">
                    {{orderData?.LiveETA | date:'dd.MM.yyyy'}}
                    {{ languageData['customersLiveTracking']['VarPageCustomerTracking5'] || 'um' }}
                    <span *ngIf="format"> {{orderData?.LiveETA | date:'hh:mm a'}}</span> <span
                        *ngIf="!format">{{orderData?.LiveETA | date:'HH:mm'}}</span>
                </div>
                <div *ngIf="!orderData?.LiveETA && orderData?.ETA">
                    {{orderData?.ETA | date:'dd.MM.yyyy'}}
                    {{ languageData['customersLiveTracking']['VarPageCustomerTracking5'] || 'um' }}
                    <span *ngIf="format"> {{orderData?.ETA | date:'hh:mm a'}}</span> <span
                        *ngIf="!format">{{orderData?.ETA | date:'HH:mm'}}</span>
                </div>

                <div *ngIf="!orderData?.LiveETA && !orderData?.ETA">
                    --:--
                </div>
            </div>

            <div class="time" *ngIf="orderData.localOrderStatus !='InProgress'">
                <div class="time" *ngIf="orderData?.Status != '4'">
                    <div *ngIf="orderData?.LiveETA">
                        {{orderData?.liveLocationArrival | date:'dd.MM.yyyy'}}
                        {{ languageData['customersLiveTracking']['VarPageCustomerTracking5'] || 'um' }}
                        <span *ngIf="format"> {{orderData?.liveLocationArrival | date:'hh:mm a'}} -
                            {{orderData?.liveLocationDeparture | date:'hh:mm a'}}</span>
                        <span *ngIf="!format">{{orderData?.liveLocationArrival | date:'HH:mm'}} -
                            {{orderData?.liveLocationDeparture | date:'HH:mm'}}</span>

                    </div>
                    <div *ngIf="!orderData?.LiveETA && orderData?.ETA">
                        {{orderData?.ETAdeviationArrival | date:'dd.MM.yyyy'}}
                        {{ languageData['customersLiveTracking']['VarPageCustomerTracking5'] || 'um' }}
                        <span *ngIf="format"> {{orderData?.ETAdeviationArrival | date:'hh:mm a'}} -
                            {{orderData?.ETAdeviationDeparture | date:'hh:mm a'}}</span>
                        <span *ngIf="!format"> {{orderData?.ETAdeviationArrival | date:'HH:mm'}} -
                            {{orderData?.ETAdeviationDeparture | date:'HH:mm'}}</span>

                    </div>
                    <div *ngIf="!orderData?.LiveETA && !orderData?.ETA">
                        --:--
                    </div>
                </div>
                <div class="time" *ngIf="orderData?.Status == '4'">
                    <div *ngIf="orderData?.LiveETA">
                        <span *ngIf="format"> {{orderData?.LiveETA | date:'hh:mm a'}}</span> <span
                            *ngIf="!format">{{orderData?.LiveETA | date:'HH:mm'}}</span>
                    </div>
                    <div *ngIf="!orderData?.LiveETA && orderData?.ETA">
                        <span *ngIf="format"> {{orderData?.ETA | date:'hh:mm a'}}</span> <span
                            *ngIf="!format">{{orderData?.ETA | date:'HH:mm'}}</span>
                    </div>
                    <div *ngIf="!orderData?.LiveETA && !orderData?.ETA">
                        --:--
                    </div>
                </div>
            </div>

        </div>

        <div class="aupris_main" *ngIf="!screenType">
            <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12 mobileviews"
                style=" padding-left:6px;padding-right:0px">
                <app-progress-bar [statusChange]="orderData?.StatusCopy" [languageData]="languageData"
                    [screenType]="smallScreen" [typeDriverApp]="orderData?.trackingId ? true : false"></app-progress-bar>
            </div>
        </div>

        <!-- showing map for medium and largescreens -->
        <div class="aupris_main">
            <div class="aurows " style="margin-top: 7vh;">
                <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12 mobileviews"
                    style=" padding-left:3px;padding-right:0px">
                    <div class="aucol-xs-12 aucol-sm-12 aucol-md-4 aucol-lg-4 mobileview" style=" padding-left:0px;padding-right: 20px;">
                        <div style="text-align: center;">
                            <button class="au-btn btn-default futuresbutton"
                                *ngIf="smallScreen && openClick != 'clicked'" (click)="openMap()"
                                [ngClass]="blurMap? 'mapButtonShow':''">
                                {{languageData['customersLiveTracking']['VarPageTracking12'] || 'Auf Karte zeigen'}}
                            </button>
                        </div>

                        <div class="column3 smallscreen" *ngIf="mobile && openClick == 'clicked'" style="margin-bottom: 15px;">

                            <!-- showing map -->
                            <div #mapHome [ngClass]="blurMap? 'mapalignment':'noBlurMap'"
                                [ngStyle]="{'pointer-events':blurMap? 'none': 'auto'}" class="smallmMap">
                                <button (click)="autoAdjustMap()" class="refreshButton" *ngIf="showAuto">
                                    <span [innerHTML]="GpsFixed" *ngIf="GpsFixed"></span>
                                </button>
                            </div>

                            <!-- autocenter all icons in MAP -->
                            <button id="autoCenterCSS" (click)="autoCenterMAP()" *ngIf="autoCenter">
                                <img src="https://s3.eu-central-1.amazonaws.com/weblinks.aupris.com/small_pc/assets/mapAutoCenter.svg"
                                    style=" cursor: pointer;margin-top: -18px;">                      
                            </button>

                        </div>

                        <div class="column2 smallscreen" *ngIf="mediumScreen" style="margin-bottom: 15px;">

                            <!-- showing popup on map -->
                            <div class="popupClassSmall" style="border-color: var(--primaryColour);"
                                *ngIf="!orderDataAfterVerification?._id">
                                <div class="header">
                                    {{ languageData['customersLiveTracking']['VarPageCustomerTracking7'] ||
                                    'Namenskontrolle' }}
                                </div>

                                <form [formGroup]="basicForm">
                                    <div class="form-group" style="margin-bottom: 10px;display:flex !important">
                                        <label class="labels">{{
                                            languageData['customersLiveTracking']['VarFirstName']
                                            || 'Vorname' }} {{languageData['customersLiveTracking']['VarLastName']
                                            || 'Nachname' }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <input type="text" class="width " formControlName="name"
                                            (keyup.enter)="verifyUser()">
                                    </div>
                                    <div>
                                        <div class="alertErr"
                                            *ngIf="basicForm?.get('name').invalid && basicForm?.get('name').touched">
                                            {{ languageData['customersLiveTracking']['VarPageCustomerTracking8'] ||
                                            'Pflichtfeld!' }}
                                        </div>
                                    </div>
                                    <div *ngIf="verificationFail" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-20"
                                        style="margin-bottom: 10px;color:red ;font-size: 14px;">
                                        <p class="margin">{{
                                            languageData['customersLiveTracking']['VarPageCustomerTracking14'] ||
                                            'Eingabe stimmt nicht mit unserer Datenbank überein!' }}</p>
                                        <p>{{ languageData['customersLiveTracking']['VarPageCustomerTracking16'] ||
                                            'Geben Sie Vor- und Nachname getrennt durch ein Leerzeichen ein.
                                            Nutzen Sie dabei die gleichen Namen wie in Ihrer Bestellbestätigung.
                                            Bei Unternehmen kann es auch der Firmenname sein!' }}</p>
                                    </div>
                                </form>

                                <div style="justify-content:space-between;display: flex;margin-top: 16px;">
                                    <button class="save" (click)="verifyUser()"
                                        style="background-color: var(--primaryColour);color: var(--primaryTextColour);margin-top:0px">
                                        {{ languageData['customersLiveTracking']['VarSend'] || 'Senden!' }}
                                    </button>
                                </div>
                            </div>

                            <!-- showing map -->
                            <div #mapHome [ngClass]="blurMap? 'mapalignment':'noBlurMap'"
                                [ngStyle]="{'pointer-events':blurMap? 'none': 'auto'}" class="mediumMap">
                                <button (click)="autoAdjustMap()" class="refreshButton" *ngIf="showAuto">
                                    <span [innerHTML]="GpsFixed" *ngIf="GpsFixed"></span>
                                </button>
                            </div>

                            <!-- autocenter all icons in MAP -->
                            <button id="autoCenterCSS" (click)="autoCenterMAP()" *ngIf="autoCenter">
                                <img src="https://s3.eu-central-1.amazonaws.com/weblinks.aupris.com/small_pc/assets/mapAutoCenter.svg"
                                    style=" cursor: pointer;margin-top: -18px;">
                            </button>

                        </div>

                        <div class="aucol-md-12 aucol-sm-6 aucol-lg-12 aucol-xs-12 nopadding alignment1">
                            <div class="headercolor">
                                <label class="head1">
                                    {{ languageData['customersLiveTracking']['VarOrder'] ||'Bestellung' }}
                                </label>
                            </div>
                            <div class="Geliefert">
                                <div style="margin-top: 0px;text-align: initial;"
                                    [ngStyle]="{'overflow': orderData?.productArray?.length > 3 ? 'auto' : ' ' }"
                                    [ngClass]="!orderDataAfterVerification?._id && smallScreen==true? 'notVerify':''">
                                    <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 smallMobiles"
                                        style="margin-top: 7px;display: flex;justify-content: space-between;align-items: end;width: 100%;"
                                        *ngFor="let data of orderData?.productArray">
                                        <div style="width: 81%;">
                                            {{data.label}}
                                        </div>
                                        <div style="text-align: end;">
                                            {{data.amount | decimalpipe : 'gen'}}&nbsp;{{data.unitsSymbol == 'p'? 'St' :
                                            data.unitsSymbol}}
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="orderData?.Status == '4'">
                                    <!-- [ngStyle]="orderData.Status == '4' ? {'visibility': 'visible'} : {'visibility':'hidden'}" -->
                                    <div class="textUnderline">
                                        {{languageData['customersLiveTracking']['VarPageTracking3'] ||'Geliefert'}}:
                                    </div>
                                    <div style="margin-top: -8px;text-align: initial;"
                                        [ngStyle]="{'height': blurMap? '50px' : '' }">
                                        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 smallMobiles" style="margin-top: 5px;display: flex;justify-content: space-between;align-items: end;width: 100%;"
                                            *ngFor="let data of orderData?.productArray">
                                            <div style="font-size: 14px !important;font-family: Arial, Helvetica sans-serif !important; width: 81%;">
                                                {{data.label}}
                                            </div>
                                            <div style="text-align: end;font-size: 14px !important;font-family: Arial, Helvetica sans-serif !important">
                                                {{data.deliverdAmount | decimalpipe : 'gen'}}&nbsp;{{data.unitsSymbol ==
                                                'p'? 'St' : data.unitsSymbol}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- message info start -->
                        <div class="aucol-md-12 aucol-sm-6 aucol-lg-12 aucol-xs-12 nopadding alignment2">
                            <div class="headercolor">
                                <label class="head1">
                                    {{ languageData['customersLiveTracking']['VarPageCustomerTracking6']
                                    ||'Nachrichten' }}
                                </label>
                                <span
                                    [appInlinesvg]="'https://s3.eu-central-1.amazonaws.com/weblinks.aupris.com/small_pc/assets/editPencil.svg'"
                                    class="dynamicSvg inputEdit"
                                    *ngIf="!smsEditMode && orderDataAfterVerification?._id && customerTracking"
                                    (click)="editInput('smsEditMode')"
                                    style="float: right; margin: 0px 15px 0px 0px;"></span>

                                <button type="submit" class="dynamicButton"
                                    *ngIf="smsEditMode && orderDataAfterVerification?._id && customerTracking"
                                    (click)="checkFocusOutInput()">
                                    {{ languageData['UserAddresses']['Var_Speichern'] ||'Speichern' }}
                                </button>
                            </div>

                            <form [formGroup]="messageInfoForm"
                                style="background-color: white;margin-left: 15px;margin-top: 10px;font-size: 14px !important;font-family: Arial, Helvetica sans-serif !important;"
                                *ngIf="orderDataAfterVerification?._id && customerTracking">
                                <div class="dropdown-container" style="padding:0px" *ngIf="smsEditMode">
                                    <div class=""
                                        style="padding:0px; flex-grow: 1;font-size: 14px !important;font-family: Arial, Helvetica sans-serif !important;">
                                        <mat-select [disableOptionCentering]="true" formControlName="minBeforeArrival"
                                            style="border: 1px solid black;">
                                            <mat-option *ngFor="let min of MinutesBeforeArrivalDropDown" [value]="min">
                                                {{min}}
                                            </mat-option>
                                        </mat-select>
                                        <span style="margin-left: 10px">
                                            {{ languageData['customersLiveTracking']['VarPageCustomerTracking10']
                                            ||'Minuten vor Ankunft' }}
                                        </span>
                                    </div>
                                </div>
                                <div style="padding:0px" *ngIf="!smsEditMode">
                                    <div style="width:25px !important;display: inline-block;">
                                        {{this.messageInfoForm.value?.minBeforeArrival}}
                                    </div>
                                    <span>
                                        {{ languageData['customersLiveTracking']['VarPageCustomerTracking10']
                                        ||'Minuten vor Ankunft' }}
                                    </span>
                                </div>
                                <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12" style="padding: 0px;">
                                    <div
                                        style="margin: 10px 0px;font-weight: normal;font-size: 14px !important;font-family: Arial, Helvetica sans-serif !important;">
                                        {{ languageData['customersLiveTracking']['VarPageCustomerTracking11']
                                        ||'Senden per' }}
                                    </div>
                                </div>
                                <div class="aucol-md-12 aucol-sm-12 aucol-lg-12 aucol-xs-12"
                                    style="padding-left: 0px;padding-right: 0px;font-size: 14px !important; font-family: Arial, Helvetica sans-serif !important;">
                                    <div class="aucol-xs-1 aucol-sm-1 aucol-md-1 aucol-lg-1"
                                        style="padding-left: 0px;padding-right: 0px;" [ngStyle]="{'pointer-events': smsEditMode ? 'auto' : 'none'}">
                                        <label class="switch">
                                            <input type="checkbox" formControlName="mobileNoToggle"
                                                (change)="changeToggle($event,'mobileNo')">
                                            <span class="round"  [ngClass]=" messageInfoForm.value.mobileNoToggle ? 'sliders' : 'slider' "></span>
                                        </label>
                                    </div>
                                    <div class="aucol-xs-1 aucol-sm-1 aucol-md-1 aucol-lg-1"
                                        style="padding-left: 0px;padding-right: 0px;">
                                        <img src="https://s3.eu-central-1.amazonaws.com/weblinks.aupris.com/small_pc/assets/trackingSMSIcon.svg"
                                            style="margin: 0px 10px 0px 10px;height:25px;width:25px" alt="SMS">
                                    </div>
                                    <div class="aucol-xs-10 aucol-sm-10 aucol-md-10 aucol-lg-10"
                                        style="margin-top: -3.3px;">

                                        <div>
                                            <span [ngClass]=" messageInfoForm.value.mobileNoToggle ? 'enablecolor' : 'diablecolor' ">
                                                <input type="text" formControlName="mobileNo" *ngIf="smsEditMode"
                                                    style="width:100% ;height:27px;padding: 2px !important;" OnlyNumberwithoutdot>
                                                <input type="text" formControlName="mobileNo" *ngIf="!smsEditMode"
                                                    style="width:100% ;height:27px ;border: none;pointer-events: none" OnlyNumberwithoutdot>
                                            </span>
                                            <div class="errorAlert" [ngStyle]="{'visibility': showSMSError ? 'visible' : 'hidden'}">
                                                {{showSMSError}}</div>
                                        </div>

                                    </div>
                                </div>

                                <div class="aucol-md-12 aucol-sm-12 aucol-lg-12 aucol-xs-12"
                                    style="padding-left: 0px;padding-right: 0px;font-size: 14px !important; font-family: Arial, Helvetica sans-serif !important">
                                    <div class="aucol-xs-1 aucol-sm-1 aucol-md-1 aucol-lg-1"
                                        style="padding-left: 0px;padding-right: 0px;" [ngStyle]="{'pointer-events': smsEditMode ? 'auto' : 'none'}">
                                        <label class="switch">
                                            <input type="checkbox" formControlName="eMailToggle"
                                                (change)="changeToggle($event,'eMail')">
                                            <span class="round" [ngClass]=" messageInfoForm.value.eMailToggle ? 'sliders' : 'slider' " ></span>
                                        </label>
                                    </div>
                                    <div class="aucol-xs-1 aucol-sm-1 aucol-md-1 aucol-lg-1"
                                        style="padding-left: 0px;padding-right: 0px;">
                                        <img src="https://s3.eu-central-1.amazonaws.com/weblinks.aupris.com/small_pc/assets/trackingEmailIcon.svg"
                                            style="margin: -2px 10px 0px 10px;height:25px;width:25px" alt="email">
                                    </div>
                                    <div class="aucol-xs-10 aucol-sm-10 aucol-md-10 aucol-lg-10"
                                        style="margin-top: -3.5px;">

                                        <div style="padding-right: 0px !important; padding-left: 0px !important">
                                            <span [ngClass]=" messageInfoForm.value.eMailToggle ? 'enablecolor' : 'diablecolor' ">
                                                <input type="email" formControlName="eMail" *ngIf="smsEditMode"
                                                    style="width:100% ;height:27px;padding: 2px !important;"
                                                    [ngStyle]="{'pointer-events': eMailEditMode ? 'auto' : '','cursor': eMailEditMode ? 'auto' : ''}">
                                                <input type="email" formControlName="eMail" *ngIf="!smsEditMode"
                                                    style="width:100% ;height:27px ;border: none;pointer-events : none">
                                            </span>
                                            <div class="errorAlert" [ngStyle]="{'visibility': showeMailError ? 'visible' : 'hidden'}">
                                                {{showeMailError}}</div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                            <div class="emptyspace" *ngIf="!orderDataAfterVerification?._id"
                                [ngStyle]="{'height': blurMap? '82px' : '' }">
                                <span [innerHTML]="Lock" class="dynamicSvg" *ngIf="Lock"
                                    (click)="openLockPopup()"></span>
                                <!-- <span [src]="'assets/Lock.svg' | assetsUrl" class="dynamicSvg"
                                    (click)="openLockPopup()"></span> -->
                            </div>
                            <div class="emptyspace" *ngIf="orderDataAfterVerification?._id && !customerTracking"
                                [ngStyle]="{'height': blurMap? '82px' : '' }">
                                {{ languageData['customersLiveTracking']['VarPageTracking13'] ||
                                'Funktion nicht verfügbar!' }}
                            </div>
                        </div>
                        <!-- message info end -->

                        <div class="aucol-md-12 aucol-sm-6 aucol-lg-12 aucol-xs-12 nopadding alignment3">
                            <div class="headercolor">
                                <label class="head1">
                                    {{ languageData['customersLiveTracking']['VarDeliveryAddress']||
                                    'Lieferadresse'}}
                                </label>
                            </div>
                            <div *ngIf="orderDataAfterVerification?._id" style="background-color: white;margin-left: 15px;margin-top: 7px;min-height: 70px;font-size: 14px !important;font-family: Arial, Helvetica sans-serif !important;
                                max-height: 100px;margin-bottom: 7px;">
                                <div>
                                    {{orderDataAfterVerification?.deliveryAddress?.l_name01}}
                                    {{orderDataAfterVerification?.deliveryAddress?.l_name02}}
                                </div>
                                <div>
                                    {{orderDataAfterVerification?.deliveryAddress?.l_name03}}
                                </div>
                                <div>
                                    {{orderDataAfterVerification?.deliveryAddress?.l_supplmt}}
                                </div>
                                <div>
                                    {{orderDataAfterVerification?.deliveryAddress?.l_street}}
                                    {{orderDataAfterVerification?.deliveryAddress?.l_streetno}}
                                </div>
                                <div>
                                    {{orderDataAfterVerification?.deliveryAddress?.l_zipcode}}
                                    {{orderDataAfterVerification?.deliveryAddress?.l_city}}
                                </div>
                            </div>

                            <div class="emptyspace" *ngIf="!orderDataAfterVerification?._id"
                                [ngStyle]="{'height': blurMap? '82px' : '' }">
                                <span [innerHTML]="Lock" class="dynamicSvg" (click)="openLockPopup()"></span>
                            </div>
                        </div>
                        <div class="aucol-md-12 aucol-sm-6 aucol-lg-12 aucol-xs-12 nopadding alignment4">
                            <div>
                                <div class="headercolor">
                                    <label class="head1">
                                        {{ languageData['customersLiveTracking']['VarDriver'] || 'Fahrer' }}
                                    </label>
                                </div>
                                <div style="background-color: white;margin-top: 7px;">
                                    <div *ngIf="orderDataAfterVerification?._id" style="text-align: center;">
                                        <img [src]="driverImage"
                                            style="width: 120px;height: 120px;border-radius: 72px;">
                                    </div>
                                    <div class="drivername" *ngIf="orderDataAfterVerification">
                                        {{orderDataAfterVerification?.tourData?.driverName}}
                                    </div>
                                </div>
                                <div class="emptyspace" *ngIf="!orderDataAfterVerification?._id"
                                    [ngStyle]="{'height': blurMap? '82px' : '' }">
                                    <span [innerHTML]="Lock" class="dynamicSvg" (click)="openLockPopup()"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="aucol-xs-12 aucol-sm-12 aucol-md-8 aucol-lg-8" style=" padding-left:0px">
                        <div class="column2" *ngIf="largeScreen"
                            [ngStyle]="{ 'height': orderDataAfterVerification._id ? '662px': '722px'}">

                            <!-- showing popup on map -->
                            <div class="popupClass" style="border-color: var(--primaryColour)"
                                *ngIf="!orderDataAfterVerification?._id">
                                <div class="header">
                                    {{ languageData['customersLiveTracking']['VarPageCustomerTracking7'] ||
                                    'Namenskontrolle' }}
                                </div>
                                <form [formGroup]="basicForm">
                                    <div class="form-group" style="margin-bottom: 10px;display:flex !important;">
                                        <label class="labels">{{
                                            languageData['customersLiveTracking']['VarFirstName']
                                            || 'Vorname' }} {{languageData['customersLiveTracking']['VarLastName']
                                            || 'Nachname' }}</label>
                                    </div>
                                    <div style="display: flex;">
                                        <input type="text" class="width " formControlName="name"
                                            (keyup.enter)="verifyUser()">
                                    </div>
                                    <div class="alertErr" style="height: 15px;"
                                        *ngIf="basicForm?.get('name').invalid && basicForm?.get('name').touched">
                                        {{ languageData['customersLiveTracking']['VarPageCustomerTracking8'] ||
                                        'Pflichtfeld!' }}
                                    </div>
                                    <div *ngIf="verificationFail" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-20"
                                        style="color:red;font-size: 14px;">
                                        <p class="margin">{{
                                            languageData['customersLiveTracking']['VarPageCustomerTracking14'] ||
                                            'Eingabe stimmt nicht mit unserer Datenbank überein!' }}</p>
                                        <p>{{ languageData['customersLiveTracking']['VarPageCustomerTracking16'] ||
                                            'Geben Sie Vor- und Nachname getrennt durch ein Leerzeichen ein.
                                            Nutzen Sie dabei die gleichen Namen wie in Ihrer Bestellbestätigung.
                                            Bei Unternehmen kann es auch der Firmenname sein!' }}</p>
                                    </div>
                                </form>
                                <div style="justify-content:space-between;display: flex;margin-top: 16px;">
                                    <button class="save" (click)="verifyUser()"
                                        style="background-color: var(--primaryColour);color: var(--primaryTextColour);margin-top:0px">
                                        {{languageData['customersLiveTracking']['VarSend'] || 'Senden!' }}
                                    </button>
                                </div>
                            </div>

                            <!-- showing map -->
                            <div #mapHome
                                [ngClass]=" (blurMap && orderData?.Status != '4') ? 'mapalignment' : blurMap? 'mapalignment' : 'noBlurMap'"
                                [ngStyle]="{'pointer-events':blurMap? 'none': 'auto'}" class="mapHome">
                                <button (click)="autoAdjustMap()" class="refreshButton" *ngIf="showAuto">
                                    <span [innerHTML]="GpsFixed" *ngIf="GpsFixed"></span>
                                </button>
                            </div>

                            <!-- autocenter all icons in MAP -->
                            <button id="autoCenterCSS" (click)="autoCenterMAP()" *ngIf="autoCenter">
                                <img src="https://s3.eu-central-1.amazonaws.com/weblinks.aupris.com/small_pc/assets/mapAutoCenter.svg"
                                    style=" cursor: pointer;margin-top: -18px;">
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- showing popup without on map for small screens -->
        <div class="column2" *ngIf="smallScreen">
            <div class="popupClassSmall smallScreenPopup" style="border-color: var(--primaryColour);"
                *ngIf="!orderDataAfterVerification?._id && smallScreen">
                <div class="header">
                    {{ languageData['customersLiveTracking']['VarPageCustomerTracking7'] ||
                    'Namenskontrolle' }}
                </div>
                <form [formGroup]="basicForm">
                    <div class="form-group" style="margin-bottom: 10px;display:flex !important">
                        <label class="labels">{{
                            languageData['customersLiveTracking']['VarFirstName']
                            || 'Vorname' }} {{languageData['customersLiveTracking']['VarLastName']
                            || 'Nachname' }}</label>
                    </div>
                    <div style="display: flex;">
                        <input type="text" class="width " formControlName="name" (keyup.enter)="verifyUser()">
                    </div>
                    <div class="alertErrs" *ngIf="basicForm?.get('name').invalid && basicForm?.get('name').touched">
                        {{ languageData['customersLiveTracking']['VarPageCustomerTracking8'] ||
                        'Pflichtfeld!' }}
                    </div>
                    <div *ngIf="verificationFail" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-20"
                        style="margin-bottom: 10px;color:red;font-size: 14px;">
                        <p class="margin">{{ languageData['customersLiveTracking']['VarPageCustomerTracking14'] ||
                            'Eingabe stimmt nicht mit unserer Datenbank überein!' }}</p>
                        <p>{{ languageData['customersLiveTracking']['VarPageCustomerTracking16'] || 'Geben Sie Vor- und
                            Nachname getrennt durch ein Leerzeichen ein.
                            Nutzen Sie dabei die gleichen Namen wie in Ihrer Bestellbestätigung.
                            Bei Unternehmen kann es auch der Firmenname sein!' }}</p>
                    </div>
                </form>
                <div style="justify-content:space-between;display: flex;margin-top: 16px;">
                    <button class="save" (click)="verifyUser()"
                        style="background-color: var(--primaryColour);color: var(--primaryTextColour);margin-top:0px">
                        {{
                        languageData['customersLiveTracking']['VarSend'] || 'Senden!' }}
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- If tracking id not found , show tracking input -->
<div class="aupris_main" *ngIf="!trackingIdFromUrl || liveTrackingAccess == false"
    style="display: table;position: absolute;top: 0;left: 0;height: 100%;width: 100%;font-family: Arial, Helvetica, sans-serif;font-size:14px"
    [ngStyle]="{'position': window?.location?.hostname == 'localhost' ? 'absolute' : 'unset'}">

    <div style=" display: table-cell; vertical-align: middle;">
        <div style=" margin-left: auto;   margin-right: auto;text-align: center;max-width: 620px;padding: 100px 30px;">
            <div style="color: red !important;margin-bottom: 10px;" *ngIf="noTrackingIdFound">
                {{ languageData['customersLiveTracking']['VarPageTracking11'] ||
                'Tracking ID nicht gefunden. GebenSie eine gültige Tracking ID beginnend mit ATR ein.' }}
            </div>

            <div style="text-align: start; display: inline-flex; width: 100%;" class="locationPinSVG">
                <div class="image">
                    <span *ngIf="trackingPinSvg" [innerHTML]="trackingPinSvg"
                        style="margin-left: -10px;" [ngClass]="smallScreen==true ? 'nomargin' : 'margin'" class="locationSVG"></span>
                    <!-- <img *ngIf="!trackingPinSvg" src="../../assets/trackingPinCopy.svg" alt="location"
                        style="margin-left: -10px;margin-right: 20px;height: 105px;width: 105px;"> -->
                    <img *ngIf="!trackingPinSvg"
                        src="https://s3.eu-central-1.amazonaws.com/weblinks.aupris.com/small_pc/assets/trackingPinCopy.svg"
                        alt="location" style="margin-left: -10px;margin-right: 20px;height: 105px;width: 105px;">
                </div>

                <div style="width: 100%;margin-bottom: 10px;">

                    <div
                        style="margin-left: 10px;position: relative;background-color: #fff;display:table;margin-bottom: -12px">
                        {{ languageData['customersLiveTracking']['VarPageTracking9'] || 'Tracking ID'}}:
                    </div>

                    <input type="text" (keyup)="noTrackingIdFound = false" (keyup.enter)="searchTracking()"
                        [(ngModel)]="inputTrackingId"
                        style="height: 40px;max-width: 470px;min-width: 270px; border-radius: 4px; width: 100%;"
                        [ngStyle]="{'pointer-events': liveTrackingAccess ? 'auto' : 'none','background-color': liveTrackingAccess ? '#FFF' : '#CAC9CD'}">

                    <input type="button" style="cursor: pointer; background-color: var(--primaryColour);color: var(--primaryTextColour); margin-top: 15px !important; border: none !important; line-height: 40px;height: 40px;
                        max-width: 470px;  min-width: 270px;border-radius: 4px;  width: 100%;  padding: 0;"
                        [value]="languageData['customersLiveTracking']['VarContinue'] || 'Fortfahren' "
                        (click)="searchTracking()" [disabled]="liveTrackingAccess == false">
                </div>

            </div>

            <div style="text-align: start;" *ngIf="liveTrackingAccess">
                {{ languageData['customersLiveTracking']['VarPageTracking10'] ||
                'Die Tracking ID beginnt mit „ATR“. Sollten Sie keine Tracking ID erhalten haben, wenden Sie sich an
                unseren Support.' }}
            </div>

            <div style="text-align: center;color:red;margin-left: 25%;" *ngIf="liveTrackingAccess == false"
                class="noTrackingAcess">
                {{ languageData['customersLiveTracking']['VarPageCustomerTracking15'] || 'Tracking ist nicht aktiviert!'
                }}
            </div>
        </div>
    </div>

</div>

<loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="colors?.Grundfarbe"></loader>