<div class="aupris_main">
<div class="full-screen" *ngIf="fullscreen" style="z-index: 9999;">
  <div class='au-container'>
    <div class='loader'>
      <div class='loader--dot' [ngStyle]="{'background-color' : color}"></div>
      <div class='loader--dot' [ngStyle]="{'background-color' : color}"></div>
      <div class='loader--dot' [ngStyle]="{'background-color' : color}"></div>
      <div class='loader--dot' [ngStyle]="{'background-color' : color}"></div>
      <div class='loader--dot' [ngStyle]="{'background-color' : color}"></div>
      <div class='loader--dot' [ngStyle]="{'background-color' : color}"></div>
      <div class='loader--text'></div>
    </div>
  </div>
</div>

<div class="loading-container" *ngIf="!fullscreen">
  <div class="loading-wrapper">
    <div class="bars" *ngIf="loaderStyle == 'bars'">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>

    <div class="bubbles" *ngIf="loaderStyle == 'bubbles'">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

    <div class="sk-fading-circle" *ngIf="loaderStyle == 'fading-circle'">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>

    <div class="lds-roller" *ngIf="loaderStyle == 'bubble-circle'">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div class="circle" *ngIf="loaderStyle == 'circle'">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div> 
  </div> 
</div>
</div>
