import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials'
})
export class NameInitialsPipe implements PipeTransform {

  transform(name: string, ...args: string[]): any {
    if(!name){
      return ''
    }
    let nameArr = name.split(' ');
    let displayChar = '';
    if (args && args[0] && args[0].length > 0) {
      displayChar = (name.charAt(0) + args[0].charAt(0))
    } else if (nameArr.length > 1) {
      displayChar = (nameArr[0].charAt(0) + nameArr[1].charAt(0))
    } else {
      displayChar = (name.charAt(0) + name.charAt(1));
    }
    return displayChar.toLocaleUpperCase();
  }

}
