import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from './../../environments/environment';
import { ICustomWindow, WindowRefService } from '../Services/window.service';
@Component({
  selector: 'app-alarm-stageone',
  templateUrl: './alarm-stageone.component.html',
  styleUrls: ['./alarm-stageone.component.css']
})
export class AlarmStageoneComponent implements OnInit {
  @Output() getPostalCodeEvent = new EventEmitter<any>();
  @Output() selectPlzEvent = new EventEmitter<any>();
  @Output() changeProductEvent = new EventEmitter<any>();
  @Output() submitValueEvent = new EventEmitter<any>();
  @Output() checkMinMaxValues = new EventEmitter<any>();
  @Output() pricealarmCancel = new EventEmitter<boolean>();

  private _window: ICustomWindow;
 
  public Produkt: any = [];
  hostname: string;
  url: string;
  selectedItem: any;
  @Input() priceAlarm: FormGroup;
  @Input() plzOptions;
  @Input() qualityArray;
  @Input() colors;
  @Input() theme: any;
  @Input() clickedPage: any;
  @Input() page_3: boolean;
  @Input() page_2: boolean;
  @Input() page_4: boolean;
  @Input() languageData;
  @Input() localoptions;
  @Input() msg;
  @Input() plzOptCheck;
  @Input() showFullScreenLoading: boolean;
  productsBySPC: any;
  productsByUnter: any;
  showErrorMsg: any;
  errorText: string;
  @Input()
  set _products(products) {
    this.Produkt = products
  };
  productInfoForWebPC: any;
  @Input() set _productInfoForWebPC(productInfoForWebPC) {
    this.productInfoForWebPC = productInfoForWebPC;

  };
  get _productInfoForWebPC() {
    return this.productInfoForWebPC;
  }


  submitted = false;

  clickedoption: string = 'selection';

  constructor(
    private windowRef: WindowRefService,
  ) {
    this._window = this.windowRef.nativeWindow
  };

  ngOnInit() {
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(this.url);/*testcalc*/

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    }
  };

  getPostalCode(e) {
    this.getPostalCodeEvent.emit(e);
  };

  selectPlz(e) {
    this.selectPlzEvent.emit(e);
  };

  changeProduct(product) {
    this.priceAlarm.get('quality').reset();
    this.changeProductEvent.emit(product);
  };

  get func() {
    return this.priceAlarm.controls;
  };

  submitValue() {
    this.submitted = true;
    !this.showErrorMsg && this.submitValueEvent.emit(this.priceAlarm.value);
  };

  abbrechen() {
    this.pricealarmCancel.next(false);
  };

  checkMinMaxValue() {
    let min: number = this.productInfoForWebPC.minAmount;
    let max: number = this.productInfoForWebPC.maxAmount;
    let inputValue = this.priceAlarm.value.menge;
    inputValue = parseInt(inputValue);
    if (inputValue <= max && inputValue >= min) {
      this.showErrorMsg = false;
    } else {
      this.showErrorMsg = true;
      this.errorText = ' Bestellmenge ' + '' + this.productInfoForWebPC.minAmount + ' ' + 'bis' + ' ' + this.productInfoForWebPC.maxAmount + ' ' + this.productInfoForWebPC.unitsName;
    }
  };

};
