<div class=" aupris_main scrolltotopprogress">
  <div class="aurow  mainn middleContent" *ngIf="register">
    <div class="aucol-md-4 aucol-sm-6 aucol-xs-10 aucol-md-offset-4 aucol-sm-offset-3 aucol-xs-offset-1 formM">
      <div class="card cardd align-items-center justify-content-center"
        style="width:500px;height: 650px;margin: 50px;top: 100px;">
        <div class="svgReg">
          <span [innerHTML]="mailVerification" *ngIf="mailVerification"></span>
        </div>
        <b class="Headerr">
          {{languageData?.login?.VarWeb-PcLogin10 || 'Danke fürs Anmelden!'}}
        </b>
        <div class="text-center">
          <p class="msgg">
            {{languageData?.login?.VarWeb-PcLogin9 || 'Unsere Datenbank hat Ihre Daten gespeichert. Ein temporäres
            Passwort wurde an Ihre registrierte E-Mailadresse verschickt. Bitte verwenden Sie dieses Passwort für Ihre
            erste Anmeldung in Ihr Konto.'}}
          </p>
          <button type="button" class="au-btn loginBtn" (click)="registered.emit(true)"
            style="background-color: var(--primaryColour);">
            {{languageData?.Edit?.Var_login ||'zurück zur Anmeldung'}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="aurow  mainn middleContent" *ngIf="forgotPassword">
    <div class="aucol-md-4 aucol-sm-6 aucol-xs-10 aucol-md-offset-4 aucol-sm-offset-3 aucol-xs-offset-1 formM">
      <div class="card cardd align-items-center justify-content-center"
        style="width:500px;height: 650px;margin: 50px;top: 100px;">
        <div class="svgReg">
          <span [innerHTML]="mailVerification" *ngIf="mailVerification"></span>
        </div>
        <b class="Headerr">
          {{langData?.Loginpage?.Var_snack_data || 'Passwort zurückgesetzt!'}}
        </b>
        <div class="text-center">
          <p class="msgg">
            {{langData?.Loginpage?.Var_snack_data || 'Passwort wurde zurückgesetzt! Überprüfen Sie Ihren Email
            Posteingang und loggen Sie sich erneut ein.'}}
          </p>
          <button type="button" class="au-btn loginBtn" (click)="registered.emit(true)"
            style="background-color: var(--primaryColour);">
            {{languageData['Edit'].Var_login ||'zurück zur Anmeldung'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>