import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';


@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {
  colors: any;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,public dialog :MatDialogRef<DeleteComponent>,private langService: LanguageService) {
    this.colors =data;
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['einstellungenpopup'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['einstellungenpopup'] || {};
    });
   }

  ngOnInit() {
  
  }
  confirm(){
this.dialog.close({delete:true});
  }
  cancel(){
    this.dialog.close({delete:false});
  }
}
