import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { SmallPcService } from '../Services/small-pc.service';
// import { ICustomWindow } from '../Services/window.service';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {
  timeZone: any;
  timeMode: any;

  constructor(protected smallpcService: SmallPcService) { }

  transform(value: any, timeData?: any): any {
    this.timeMode = timeData.timeMode;
    this.timeZone = timeData.timeZone;
    let format = moment(value).tz(this.timeZone || 'Europe/Berlin').format('DD.MM.YYYY HH:mm');
    if (this.timeMode == '12h') {
      format = moment(value).tz(this.timeZone || 'Europe/Berlin').format('DD.MM.YYYY hh:mm a');
    }
    return format;
  }
}