import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, group, animate } from '@angular/animations';
import { UserAddress } from 'app/models/portalModels/portalUserAddress.interface';

// import { IUserDetails } from 'app/login/login.component';

@Component({
  selector: 'custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.css'],
  animations: [
    trigger('slideInOut', [
      state('0', style({
        'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
      })),
      state('1', style({
        'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
      })),
      transition('0 => 1', [group([
        animate('400ms ease-in-out', style({
          'opacity': '0'
        })),
        animate('600ms ease-in-out', style({
          'max-height': '0px'
        })),
        animate('700ms ease-in-out', style({
          'visibility': 'hidden'
        }))
      ]
      )]),
      transition('1 => 0', [group([
        animate('1ms ease-in-out', style({
          'visibility': 'visible'
        })),
        animate('600ms ease-in-out', style({
          'max-height': '500px'
        })),
        animate('800ms ease-in-out', style({
          'opacity': '1'
        }))
      ]
      )])
    ])
  ]
})
export class CustomDropdownComponent implements OnInit {
  newOption: any;
  emptyDataSelection = {
    name: 'Select'
  };
  _options: UserAddress[]
  @Input('form') form: string;
  @Input('disableInput') disableInput: any;
  _disabled = true;
  @Input('options')
  set options(options: UserAddress[]) {
    this._options = options;
  }
  @Output() currentSelectionChange = new EventEmitter<object>();
  _currentSelection: any;
  get currentSelection() {
    return this._currentSelection;
  }
  @Input()
  set currentSelection(value) {
    this._currentSelection =
      value === '' || value === null || value === undefined
        ? this.emptyDataSelection
        : value;
  }
  @Output() addNew = new EventEmitter();

  @Input('defaultOption')
  set defaultOption(option: string) {
    if (this._options) {
      let currentSelection = this._options.find(opts => opts._id === option);
      if (currentSelection && Object.keys(currentSelection).length >= 0) {
        this.currentSelection = currentSelection;
      }
    }
  }

  @Input('disabled')
  set disabled(option) {
    this._disabled = option;
  }
  constructor() {
    this.newOption = '';
  }

  ngOnInit() {
  }

  setCurrentSelection(option: UserAddress) {
    if (option.class == 'untick') {
      return false
    }
    option.class = 'untick';
    this.currentSelection = option;

    this.currentSelectionChange.emit(option);
  }
}
