import { Pipe, PipeTransform } from '@angular/core';
// import { PortalAuthService } from '../login/auth.service';
import { AdminService } from '../Services/admin.service';

@Pipe({
  name: 'getLogo'
})
export class GetLogoPipe implements PipeTransform {
  constructor(private adminService: AdminService) { }
  async transform(fileId: string) {
    const logoFile = await this.adminService.getLogoImageById(fileId);
    if (!logoFile.success) {
      return
    }
    return `data:image/jpg;base64,${logoFile.data.data}`;
  }

}
