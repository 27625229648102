import { Component, OnInit } from '@angular/core';
import { BigpcService } from 'app/Services/bigpc.service';
import { WindowRefService, ICustomWindow } from '../Services/window.service';
import { environment } from '../../environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LanguageService } from '../Services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-security-form-new',
  templateUrl: './data-security-form-new.component.html',
  styleUrls: ['./data-security-form-new.component.css']
})
export class DataSecurityFormNewComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  busy: Subscription;
  colors;
  url;
  hostname;
  bigpclink;
  DataSecurity_Man_check: boolean = false;
  viewsnack7: boolean = false;
  emaildoesnotexist: boolean = false;
  private _window: ICustomWindow;
  public dataSecurityForm: FormGroup;
  Datenschutzerklarung;
  Nutzervereinbarung;
  viewsnack6: any;
  expiryRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor(
    private newBigPc: BigpcService,
    private windowRef: WindowRefService,
    private _fb: FormBuilder,
    private langService: LanguageService) {
    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this._window = this.windowRef.nativeWindow;
  }

  async ngOnInit() {
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(this.url);/*testcalc*/

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    }

    // let data: any = await this.MybigpcService.getMinMaxData();
    // this.colors = data.colors;
    // this.bigpclink = data.matcheddomainlink;

    this.busy = this.newBigPc.pretext_event(this.hostname).subscribe((data: any) => {
      // this.showFullScreenLoading = false;
      if (data.success) {
        this.Nutzervereinbarung = data.data.Nutzervereinbarung;
        this.Datenschutzerklarung = data.data.Datenschutzerklarung;
      }
    });

    this.dataSecurityForm = this._fb.group({
      eMail: ['', [Validators.required, Validators.pattern(this.expiryRegex)]],
      Vorname: ['', [Validators.required]],
      Nachname: ['', [Validators.required]],
      checkbox: [false, [Validators.pattern('true')]]
    });
  }

  dataSecurityEvent() {
    this.DataSecurity_Man_check = true;
    if (this.dataSecurityForm.valid) {
      let object = this.dataSecurityForm.value;
      this.newBigPc.data_security_delete(object, this.hostname).subscribe((data: any) => {
        if (data.success) {
          this.viewsnack7 = true;
          setTimeout(() => {
            this.viewsnack7 = false;
          }, 4500);
        } else {
          if (data.msg == "login") {
            this._window.location.href = 'http://' + this.bigpclink + '#&view=login';
          } else if (data.msg == "emailDoesnotExist") {
            //something went wrong!
            this.emaildoesnotexist = true;
            setTimeout(() => {
              this.emaildoesnotexist = false;
            }, 4500);

          }
        }
      });
    }
  }

}
