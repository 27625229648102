import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forth-stage',
  templateUrl: './forth-stage.component.html',
  styleUrls: ['./forth-stage.component.css']
})
export class ForthStageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
