import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../Services//language.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  colors: any;
  save:any;
  languageData: any;
  // save:any;
  constructor(
    public langService: LanguageService, public dialog: MatDialog, public dialogRef: MatDialogRef<UploadImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data && this.data != '') {
      let imageFile = this.dataURLtoFile(this.data, 'profileImg.png');
      let list = new DataTransfer();
      list.items.add(imageFile)
      this.imageChangedEvent = { target: list };
      let langData = this.langService.getLocalLanguageData();

      this.languageData = langData['web-pc'] || {};
      this.langService.onLanguageChange.subscribe((data: any) => {
        this.languageData = data['web-pc'] || {};
      });
    }
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  saveImage() {
    let imageFile = this.dataURLtoFile(this.croppedImage, 'profileImg.png');
    this.dialogRef.close({ image: imageFile });
  }

  cancel() {
    this.dialogRef.close();
  }

  deleteImage() {
    this.dialogRef.close({ delete: true })
  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

}
