import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserAddress } from '../models/portalModels/portalUserAddress.interface';


@Component({
  selector: 'app-address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.css']
})
export class AddressSelectionComponent implements OnInit {
  addresses: UserAddress[];
  lieferort: any;
  addressType: any;
  languageData: any;
  colors: any;
  lieferIndex: any;
  selectedIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      addresses: UserAddress[],
      addressType: {
        addressType: string,
        lieferIndex: any,
        addressData: any
      },
      liferrort: {
        title: string,
        _id: string
      },
      languageData: any,
      colors: any
    },

    public dialog: MatDialogRef<AddressSelectionComponent>,
  ) { }

  ngOnInit() {
    this.addressType = this.data.addressType.addressType;
    let addressCopy = this.data.addresses && this.data.addresses.map(e => {
      e.class = e._id == this.data?.addressType?.addressData?._id ? "tick" : "untick";
      return e;
    });
    this.addresses = addressCopy;
    this.selectedIndex = 0;
    this.lieferort = this.data.liferrort;
    this.lieferIndex = this.data.addressType.lieferIndex;
    this.languageData = this.data.languageData;
    this.colors = this.data.colors;
  };

  selectAddress(address, index) {
    this.selectedIndex = index;
    this.addresses.map(addr => addr.class = "untick")
    this.addresses[index].class = "tick";
    this.dialog.close({ address, addressType: this.data.addressType, addNew: false, index: index });
  };

  addAddress() {
    this.dialog.close({ addressType: this.data.addressType, addNew: true, lieferIndex: this.lieferIndex });
  };
}
