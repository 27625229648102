import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-alarm-stage-three',
  templateUrl: './alarm-stage-three.component.html',
  styleUrls: ['./alarm-stage-three.component.css']
})
export class AlarmStageThreeComponent implements OnInit {
  @Input() colors;
  @Input() theme: any;
  @Input() clickedPage: any;
  @Input() languageData: any;
  @Input() notificationForm: FormGroup;
  @Input() showFullScreenLoading: boolean;
  
  @Output() page3Event = new EventEmitter<any>();
  @Output() gotoPage2Event = new EventEmitter<any>();
  months = [3, 6];
  EmailError: boolean = false;
  // SmsError: boolean = false;
  viewsnackErr: boolean = false;
  viewsnackErr1: boolean = false;
  agreeCheckErr: boolean = false;
  monthSelectErr: boolean = false;
  msgError: boolean = false;;
  constructor() { }

  ngOnInit() {
    this.notificationForm['controls']['months'].setValue(this.months[0]);
  }

  submit() {
    // validate empty inputs if checked
    this.EmailError = false;
    this.msgError = false;
    this.agreeCheckErr = false;
    if (this.notificationForm.value.sms.charAt(0) == 1) {
    } else if (this.notificationForm.value.sms.slice(0, 2) == "01") {
    } else if (this.notificationForm.value.sms == '') {
    } else {
      this.msgError = true;
    };

    if ((!this.notificationForm.value.emailCheck && this.notificationForm.value.Email == "") && (!this.notificationForm.value.smsCheck && this.notificationForm.value.sms == "")) {
      this.EmailError = true;
      this.msgError = true;
    };

    if ((this.notificationForm.value.emailCheck && this.notificationForm.value.Email == "") || (!this.notificationForm.value.emailCheck && this.notificationForm.value.Email != "")) {
      //this.showError();
      this.EmailError = true;
    };

    if ((this.notificationForm.value.smsCheck && this.notificationForm.value.sms == "") ||
      (!this.notificationForm.value.smsCheck && this.notificationForm.value.sms != "") ||
      ((this.notificationForm.value.sms != "") && this.notificationForm.controls.sms.errors)) {
      //this.showError();
      this.msgError = true;
    };

    if (this.notificationForm.value.agreeCheck != true) {
      this.agreeCheckErr = true;
    };

    if (this.notificationForm.value.months == "") {
      this.monthSelectErr = true;
    } else {
      this.monthSelectErr = false;
    };

    if (!this.notificationForm.value.emailCheck && !this.notificationForm.value.smsCheck) {
      //this.showError1();
      return false;
    };

    if (!this.notificationForm.valid || this.EmailError || this.msgError) {
      return false;
    };

    this.page3Event.emit();
  }

  showError() {
    this.viewsnackErr = true;
    setTimeout(() => {
      this.viewsnackErr = false;
    }, 4500)
  }

  showError1() {
    this.viewsnackErr1 = true;
    setTimeout(() => {
      this.viewsnackErr1 = false;
    }, 4500)
  }

  abbrechen() {
    this.gotoPage2Event.emit()
  }
}
