import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../Services/language.service';

@Component({
  selector: 'app-alternative-text-info',
  templateUrl: './alternative-text-info.component.html',
  styleUrls: ['./alternative-text-info.component.css']
})
export class AlternativeTextInfoComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  // colors: any;
  infotext:any;

  constructor(public dialog: MatDialog, private  langService: LanguageService,public dialogRef: MatDialogRef<AlternativeTextInfoComponent>) { 
    let langData = this.langService.getLocalLanguageData();
 
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
     });
  }

  ngOnInit() {
  }

  onclick() {
    this.dialogRef.close();
  }

}
