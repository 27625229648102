import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AdminService } from '../../Services/admin.service';
import { LanguageService } from '../../Services/language.service';
import { ICustomWindow, WindowRefService } from '../../Services/window.service';
import { SessionData, SmallPcService } from '../../Services/small-pc.service';
import { CommonServiceLatest } from 'app/Services/common.service';
@Component({
  selector: 'app-update-email-verification',
  templateUrl: './update-email-verification.component.html',
  styleUrls: ['./update-email-verification.component.css']
})
export class UpdateEmailVerificationComponent implements OnInit {
  colors;
  url;
  hostname
  verifiedeMail: boolean = false;
  emailVerficationRequest: boolean = false;
  verification: boolean = false;
  // emailVerficationRequest: boolean = false;
  private _window: ICustomWindow;
  bigpclink: any;
  Liter: string;
  view: string;
  Product: string;
  Lieferort: string;
  verified: boolean = false;
  href: string;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  kundapanPortalUpdate: any = {};
  emailLangVar: any = {};
  params: URLSearchParams;
  sessionId: string;
  abladestellen: any;
  @Output() showloginPage = new EventEmitter<boolean>();
  constructor(
    private windowRef: WindowRefService,
    private adminService: AdminService,
    private langService: LanguageService,
    private smallPcService: SmallPcService,
    public commonService: CommonServiceLatest
  ) {
    this._window = this.windowRef.nativeWindow;
  }

  async ngOnInit() {
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    this.params = new URLSearchParams(this.url);/*testcalc*/

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(this.params.get("Domainname"));/*testcalc*/
    };
    if (decodeURIComponent(this.params.get("user")).match(/^[0-9a-fA-F]{24}$/)) {
      this.Liter = decodeURIComponent(this.params.get('Liter')) || null;
      this.Product = decodeURIComponent(this.params.get('Product')) || null;
      this.Lieferort = decodeURIComponent(this.params.get('Lieferort')) || null;
      let sendInfo = {
        domainname: this.hostname,
        id: decodeURIComponent(this.params.get('user')),
        "token": decodeURIComponent(this.params.get('token'))
      };
      this.adminService.verifyUpdateEmail(sendInfo).subscribe((result: any) => {
        if (result.success) {
          this.verifiedeMail = true;
          this.emailVerficationRequest = true
          localStorage.clear();
          sessionStorage.clear();
        } else {
          this.verifiedeMail = false;
        };
      }
      );
    };
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.kundapanPortalUpdate = langData['kundeportal'];
    this.emailLangVar = langData['email'];
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
      this.emailLangVar = langData['email'];
      this.kundapanPortalUpdate = langData['kundeportal'] || {};
    });
  };

  showlogin(){
    this.showloginPage.emit(true);
   }


  // login = () => {
  //   this.prepareSessionData();
  // };

  // async prepareSessionData() {
  //   this.sessionId = this.params.get('sid');
  //   if (!this.sessionId) {
  //     this.sessionId = this.smallPcService.getSidFromlocalStorage();
  //   };

  //   if (!this.sessionId) {
  //     this.sessionId = this.smallPcService.genereateNewSessionId();
  //   };
  //   // get session data from localstorage
  //   let currentSessionData = await this.smallPcService.getSessionData(this.sessionId);
  //   let newSession = <SessionData>{};

  //   newSession = {
  //     ...newSession,
  //     liter: this.Liter,
  //     lieferort: this.Lieferort,
  //     product: this.Product,
  //     abladestellen: this.abladestellen,
  //   };

  //   if (!currentSessionData) {
  //     currentSessionData = <SessionData>{};
  //     currentSessionData = { ...newSession };
  //   } else {
  //     currentSessionData = { ...currentSessionData, ...newSession };
  //   };

  //   this.smallPcService.setSessionData(this.sessionId, currentSessionData);
  //   if (!environment.testSite_or_thirdPartySites) {
  //     let url = environment.pricecalc;
  //     let domainname = decodeURIComponent(this.params.get("Domainname"));
  //     this.href = `${url}#&Domainname=${domainname}&sid=${this.sessionId}&view=bigpc`;
  //   } else {
  //     this.href = `https://${this.bigpclink}#&sid=${this.sessionId}&view=bigpc`;
  //   };
  //   this._window.location.href = this.href
  // };

  async login() {
    // this.params = new URLSearchParams(this.url);
    // this.Liter = this.params.get('Liter');
    // this.Product = this.params.get('Product');
    // this.Lieferort = this.params.get('Lieferort');
    // this.abladestellen = this.params.get('abladestellen') || '1';
    // this.sessionId = this.params.get('sid');
    // if (!this.sessionId) {
    //   this.sessionId = this.smallPcService.getSidFromlocalStorage();
    // }
    // if (!this.sessionId) {
    //   this.sessionId = this.smallPcService.genereateNewSessionId();
    // }
    // // get session data from localstorage
    // let currentSessionData = await this.smallPcService.getSessionData(this.sessionId);
    // let newSession = <SessionData>{};
    // newSession = {
    //   ...newSession,
    //   liter: this.Liter,
    //   lieferort: this.Lieferort,
    //   product: this.Product,
    //   abladestellen: this.abladestellen,
    // };
    // if (!currentSessionData) {
    //   currentSessionData = <SessionData>{};
    //   currentSessionData = { ...newSession };
    // } else {
    //   currentSessionData = { ...currentSessionData, ...newSession };
    // }
    // this.smallPcService.setSessionData(this.sessionId, currentSessionData);
    // if (!environment.testSite_or_thirdPartySites) {
    //   let url = environment.pricecalc;
    //   let domainname = decodeURIComponent(this.params.get("Domainname"));
    //   this.href = `${url}#&Domainname=${domainname}&sid=${this.sessionId}&view=bigpc&page=Dashboard`;
    // } else {
    //   this.href = `https://${this.bigpclink}#&sid=${this.sessionId}&view=bigpc&page=Dashboard`;
    // };

    // this._window.location.href = this.href;
  };
}
