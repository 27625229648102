import { Directive, Input, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

@Directive({
  selector: '[appAppScrolltoFirst]'
})
export class AppScrolltoFirstDirective {
  @Input('form') form: NgForm;

  constructor(
    // private el: ElementRef
    ) {

  }

  @HostListener('click', ['$event'])
  onClick(event) {
    event.preventDefault();
    if (!this.form.valid) {
      let target;
      for (var i in this.form.controls.Liefer_address['controls']) {
        if (!this.form.controls.Liefer_address['controls'].valid) {
          target = this.form.controls;
          break;
        }
      };

      if (target) {
        $('html,body').animate({ scrollTop: $(target).offset().top }, 'slow');
        target.focus();
      }
    }
  }

}
