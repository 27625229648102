import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from '../../Services/language.service';
import { maxminValidations } from 'app/Validations/maxmin.validators';
import { CompleterData, } from 'app/ng2Completer/services/completer-data';
import { CompleterService } from 'app/ng2Completer/services/completer-service';
import { ICustomWindow, WindowRefService } from '../../Services//window.service';
import { BigpcService } from '../../Services/bigpc.service';
import { SmallPcService } from '../../Services/small-pc.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { LocalstorgeService } from 'app/Services/localstorge.service';
import { CommonServiceLatest } from 'app/Services/common.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-price-calculaor-common',
  templateUrl: './price-calculaor-common.component.html',
  styleUrls: ['./price-calculaor-common.component.css']
})
export class PriceCalculaorCommonComponent implements OnInit {
  env;
  @Input('tagSelecter') tagSelecter: string;
  @Input('tagData') tagData: any;
  tagInfo = {};
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  hostname: any;
  url: any;
  label: string = 'Liter';
  dataService: CompleterData;
  searchData: any;
  public products: any = [];

  pcMinMaxValues: any;
  minimumAmount: any;
  maximumAmount: any;
  minAmount: boolean = false;
  maxAmount: boolean = false;
  minMumValue: number;
  productSelected = {};


  // Pc Info
  dynamicPCData: any;
  colorsPc: any;
  pricecalc: string = environment.pricecalc;
  pcOffPbbackend: boolean = false;

  // ratePayment
  ratepaymentInQuery: boolean;


  plzOptions: any;
  invalidLieferort: boolean;
  sessionId: string;
  traderRedirect: boolean = false;
  bigpclink: any;
  private _window: ICustomWindow;
  ActivateDeactivatePc = {
    'activatePc': false,
    'productText': ''
  };
  units: any;
  volueme: any;
  weight: any;
  currency: any;
  showAbladestelle: boolean = false;
  inLimitHouseHold: boolean = false;
  public abladestellen: any[] = [
    { value: 1 }
  ];


  selectedPostalCodeId: any;
  city: any = '';
  postalcode: any = '';
  suburb: any = '';
  postalLat: any = '';
  postalLng: any = '';

  //Eroor messages
  // msg: string;
  // errorMsg: boolean = false;
  // tabErrorMsg: boolean = false;
  tabErrorMsg: boolean = false;
  postalCodeErrorMsg: boolean = false;
  // errorMsg: boolean = false;

  createNewPc: boolean = false;
  showRemainingValues: boolean = false;
  webLinks = true;
  webLinksData = [];
  unitsSymbol = 'l';
  unitName = 'liter';
  loadingStations = [];
  public myForm: FormGroup;
  primaryAdress: any;
  showDeliveryStations: boolean = false;

  constructor(
    private langService: LanguageService,
    private windowRef: WindowRefService,
    private _fb: FormBuilder,
    public completerService: CompleterService,
    private SmallPcService: SmallPcService,
    private newBigPcService: BigpcService,
    public dialog: MatDialog,
    public localstorage: LocalstorgeService,
    public commonFunctions: CommonServiceLatest
  ) {
    this._window = this.windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;

    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  };

  smallpc_ttr_fr: boolean = true;
  productdup: string = '';
  dataLayer = window['dataLayer'] || [];
  singleProductSelect = {};
  tradingSize: any;

  ngOnInit() {
    this.ActivateDeactivatePc['activatePc'] = true;
    this.env = environment;
    this.webLinksData = this.tagData;
    this.url = this._window.location.href;
    let params = new URLSearchParams(this.url);

    // if(isSafari()) {
    // let checkSessionId = !params.get('sid');
    //  localStorage.setItem("intialStep" , JSON.stringify(checkSessionId))};
    if (!params.get('sid')) {
      let sessionId = this.SmallPcService.genereateNewSessionId();
      this.sessionId = sessionId;
      // if(isSafari()) {localStorage.setItem("step1sessionId", this.sessionId)};
    } else {
      this.sessionId = params.get('sid')
      if (isSafari()) {
        let getData: any = sessionStorage.getItem('aupris_ipaddress')
        getData = JSON.parse(getData)
        if (getData && Object.keys(getData).length) {
          this.sessionId = getData.sessionId;
          // localStorage.setItem("step2sessionId", this.sessionId)
        };
      }
    };

    if (params.get("traderRedirect")) {
      this.traderRedirect = true;
    } else {
      this.traderRedirect = false;
    };

    if (params.get("ratepayment") === 'true') {
      this.ratepaymentInQuery = true;
    } else {
      this.ratepaymentInQuery = false;
    };

    if (!this.env.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));    /*test calc*/
    };

    if ((decodeURIComponent(params.get("view")) == 'bigpc') ||
      (decodeURIComponent(params.get("view")) == 'verification') ||
      (decodeURIComponent(params.get("view")) == 'login') ||
      (decodeURIComponent(params.get("view")) == 'data_security') ||
      (decodeURIComponent(params.get("view")) == 'dataSecurityForm') ||
      (decodeURIComponent(params.get("view")) == 'verified') ||
      (decodeURIComponent(params.get("view")) == 'updateemail') ||
      (decodeURIComponent(params.get("view")) == 'pwverified') ||
      (decodeURIComponent(params.get("view")) == 'emailMarketing') ||
      (decodeURIComponent(params.get("view")) == 'newsLetterCancellation') ||
      (decodeURIComponent(params.get("sid")) == localStorage.getItem('sid'))
    ) {
      this.smallpc_ttr_fr = false;
    } else {
      this.smallpc_ttr_fr = true;
    };

    this.SmallPcService.getIP().subscribe((data) => {
      // console.log(data)
    }, (error) => { console.log(error) });

    this.products = [];
    this.bigpclink = this.tagData && this.tagData.length ? this.tagData[0]['pcLink'] : '';
    if (this.tagData && this.tagData.length) {
      this.tagData[0].products.forEach(element => {
        this.tagInfo = {};
        this.tagInfo = element;
        this.products.push(element);
        this.colorsPc = element;
        this.label = element.unitsLangVariable
        this.pcMinMaxValues = element;

        if (!this.myForm) {
          this.myForm = this._fb.group({
            product: ['', [Validators.required]],
            lieferort: ['', Validators.required],
            liter: ['', [Validators.required]],
            abladestellen: ['1'],

          }, { validator: maxminValidations(this.pcMinMaxValues) });
        };
      });
    }
    this.dynamicPCData = this.products;
    if (this.dynamicPCData && this.dynamicPCData.length < 2) {
      let stations = 0;
      this.abladestellen = [];
      this.loadingStations = [];
      this.singleProductSelect = this.dynamicPCData[0];
      // console.log(this.singleProductSelect, 220);
      if (this.singleProductSelect) {
        this.showRemainingValues = true;
        this.showAbladestelle = true;
        this.showDeliveryStations = this.singleProductSelect?.['priceRqForDeliveryStation'];
        this.minimumAmount = this.singleProductSelect['minAmount'];
        this.maximumAmount = this.singleProductSelect['maxAmount'];
        this.ActivateDeactivatePc['activatePc'] = this.singleProductSelect['activatePc'];
        this.ActivateDeactivatePc['productText'] = this.singleProductSelect['pcText'];
        this.label = this.singleProductSelect['unitsLangVariable'];
        this.unitsSymbol = this.singleProductSelect['unitsSymbolForPc'];
        this.unitName = this.singleProductSelect['unitsName'];
        stations = this.singleProductSelect['additionalStationsCount'];

        for (let i = 0; i < stations; i++) {
          this.loadingStations.push({ value: i + 1 });
        };
        this.abladestellen = this.loadingStations;
      };


      this.myForm = this._fb.group({
        product: [this.singleProductSelect['dynamicProductId']],
        lieferort: [undefined, Validators.required],
        liter: [, [Validators.required]],
        abladestellen: ["1"],
      }, { validator: maxminValidations(this.singleProductSelect) });
    };
  };

  async routeToPriceCalculator(data, valid) {
    let selctedProduct = this.dynamicPCData.find(i => i.dynamicProductId == data.value.product);
    if (valid) {
      if (!this.sessionId) {
        this.sessionId = await this.SmallPcService.genereateNewSessionId();
        // if(isSafari()) {localStorage.setItem("step3sessionId", this.sessionId)};
      };

      if (this.traderRedirect) {
        let url;
        if (this.ratepaymentInQuery) {
          url = `${url}&ratepayment=true`
        };
        window.open(url);
        return false;
      };

      if (!this.selectedPostalCodeId) {
        return;
      };

      if (!this.env.testSite_or_thirdPartySites) {

        //  local users or our own sites is having this case
        this.dataLayer.push({
          'event': 'auprisCalculatePrice'
        });

        let url = `${this.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&stage=one`;
        if (this.ratepaymentInQuery) {
          url = `${url}&ratepayment=true`;
        }
        this.settingSIDData(data, selctedProduct, url);
      } else {
        this.dataLayer.push({
          'event': 'auprisCalculatePrice'
        });

        let url = `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&stage=one`;
        if (this.ratepaymentInQuery) {
          url = `${url}&ratepayment=true`;
        };
        // if(isSafari()) {localStorage.setItem("finallyCreatedUrl", url)};
        this.settingSIDData(data, selctedProduct, url);
      };
    };
  };

  productSelectionChange(event) {
    let stations = 0;
    this.abladestellen = [];
    this.loadingStations = [];
    let productData = this.dynamicPCData.find(({ dynamicProductId }) => dynamicProductId == event.value);
    if (productData) {
      this.tradingSize = productData.tradingSize;
      this.showDeliveryStations = productData.priceRqForDeliveryStation;
    };
    this.productSelected = {
      name: productData.namePc,
      dynamicProductId: productData.dynamicProductId
    };

    this.myForm.get('liter').reset();
    this.myForm.get('lieferort').reset();

    if (productData.dynamicProductId) {
      this.showRemainingValues = true;
      this.showAbladestelle = true;
    };

    this.pcMinMaxValues = productData;
    this.minimumAmount = productData.minAmount;
    this.maximumAmount = productData.maxAmount;
    this.label = productData.unitsLangVariable;
    this.unitsSymbol = productData.unitsSymbolForPc;
    stations = productData.additionalStationsCount;

    for (let i = 0; i < stations; i++) {
      this.loadingStations.push({ value: i + 1 });
    };

    this.abladestellen = this.loadingStations;
    this.unitName = productData.unitsName;
    this.ActivateDeactivatePc['activatePc'] = productData.activatePc;
    this.ActivateDeactivatePc['productText'] = productData.pcText;
    this.myForm = this._fb.group({
      product: [productData.dynamicProductId, [Validators.required]],
      lieferort: [this.myForm?.value.lieferort, Validators.required],
      liter: [this.myForm?.value.liter, [Validators.required]],
      abladestellen: [this.myForm?.value.abladestellen],
    }, { validator: maxminValidations(productData) });

  };

  validateMenge(event?) {
    let literValueStream = this.myForm.get('liter');
    literValueStream.valueChanges.pipe(distinctUntilChanged(), debounceTime(1000),).subscribe((value) => {
      let event = this.commonFunctions.tradingSizeValidate(parseInt(this.myForm.controls['liter'].value), this.tradingSize);
      this.minMumValue = this.minimumAmount * this.myForm.controls['abladestellen'].value;
      if (value !== event && !isNaN(event)) {
        this.label = 'Liter';
        this.myForm.controls['liter'].setValue(event);
      }
      if (event < this.minMumValue && !isNaN(event)) {
        this.inLimitHouseHold = true;
        this.label = 'Liter';
      } else {
        this.inLimitHouseHold = false;
        this.label = 'Liter';
      }
    }, err => {
      console.log(err)
    });
  };

  validate(e) {
    var regex = new RegExp("[a-zA-Z0-9äöüÄÖÜß ]");
    var key = e.keyCode || e.which;
    key = String.fromCharCode(key);
    if (!regex.test(key)) {
      e.returnValue = false;
      if (e.preventDefault) {
        e.preventDefault();
      }
    }
  };

  selectPlz(event) {
    if (event) {
      this.tabErrorMsg = false;
      this.selectedPostalCodeId = event._id;
      this.city = event.place_name;
      this.postalcode = event.country_code;
      this.suburb = event.suburb;
      this.postalLat = event.latitude;
      this.postalLng = event.longitude;
    } else if (!event || !event._id) {
      this.myForm.setErrors({ lieferort: false });
      this.tabErrorMsg = true;
    } else {
      this.myForm.setErrors(null);
    };
  };

  onKeyy(e) {
    // console.log(e, e.key == "Tab", e.key == "Backspace")
    if (e.key == "Tab") {
      this.tabErrorMsg = this.selectedPostalCodeId ? false : true
    } else if (e.key == "Backspace") {
      this.selectedPostalCodeId = undefined;
      if (this.selectedPostalCodeId == undefined) {
        this.tabErrorMsg = true;
      };
    };
  }

  getPostalCode(e) {
    if (e !== null && e !== undefined && e.length >= 4) {
      let objectname = {
        event: e,
        dynamicProductId: this.myForm.value.product
      };

      this.newBigPcService.postalCode(objectname).subscribe((data: any) => {
        if (data.success) {
          this.plzOptions = data.data;
          let Options = data.data
          this.plzOptions = Options.sort((a, b) => {
            if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
            if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
          })
          this.postalCodeErrorMsg = false;
          let postelAddress = objectname.event;
          let findPostalCode = this.plzOptions.find(item => (item.ortPlz).trim() == postelAddress.trim());
          if (typeof findPostalCode != 'object') {
            this.postalCodeErrorMsg = true;
          };
        } else {
          this.postalCodeErrorMsg = true;
          this.plzOptions.length = 0;
        };
      });
    } else {
      this.plzOptions = [];
      this.postalCodeErrorMsg = false;
    };
  };

  onKey(Event: any) {
    if (Event.length >= 3) {
      Event = Event.trim();
      if (this.searchData) {
        this.searchData = this.searchData.reduce((prev, postalcode) => {
          let prevIndex = prev.findIndex(p => postalcode._id == p._id)
          if (postalcode.produkts.indexOf(this.myForm.controls['product'].value) >= 0 && prevIndex < 0) {
            prev = [...prev, postalcode]
          }
          return prev
        }, [])
        this.dataService = this.completerService.local(this.searchData.concat([]),
          'country_code,place_name,suburb',
          'place_name,country_code,suburb');
      };
    };
  };

  onNg2Blur() {
    if (this.myForm.controls['lieferort'].invalid) {
      this.invalidLieferort = true;
    } else {
      this.invalidLieferort = false;
    };
  };

  isvalid() {
    if (this.myForm.get('product').valid && this.selectedPostalCodeId && this.myForm.get('lieferort').valid && this.myForm.get('liter').valid && this.myForm.get('abladestellen').valid) {
      return false;
    } else {
      return true;
    }
  };


  settingSIDData(data, selctedProduct, url) {
    let sessionData = {
      priceCalcUrl: this.bigpclink, // need to check for third party and local users
      domainName: this.hostname,
      quantity: data?.value?.liter ? parseInt(data?.value?.liter) : 3000,
      stations: data?.value?.abladestellen ? parseInt(data?.value?.abladestellen) : 1,
      product: {
        "_id": selctedProduct.dynamicProductId,
        "name": selctedProduct.namePc
      },
      postalCode: {
        plzId: this.selectedPostalCodeId,
        city: this.city,
        postalcode: this.postalcode,
        suburb: this.suburb,
        latitude: this.postalLat,
        longitude: this.postalLng,
      },
      localOptions: [
        { optionSetId: '', optionId: '', sort: 1 },
        { optionSetId: '', optionId: '', sort: 2 },
        { optionSetId: '', optionId: '', sort: 3 },
        { optionSetId: '', optionId: '', sort: 4 },
        { optionSetId: '', optionId: '', sort: 5 },
      ],
      ratePaymentSettings: { status: false, zahlungRate: '', zahlungspans: [] },
      desiredDateSettings: {},
      cart: [
        {
          _id: ''
        }
      ],
      co2Compensation: [],
      tag: selctedProduct.tag,
    };

    this.SmallPcService.setSessionData(this.sessionId, sessionData).then(() => {
      if (isSafari()) {
        localStorage.setItem("bigpcurl", url);
        window.location.assign(url);
        checkUpdateurl(url, this._window.location.href)
      } else {
        window.location.href = url;
      }
    });
  };
};

function isSafari(): boolean {
  const userAgent = window.navigator.userAgent;
  return /Safari/i.test(userAgent) && !/Chrome|CriOS|FxiOS|OPiOS/i.test(userAgent);
}

function checkUpdateurl(originalUrl, currentUrl) {
  // localStorage.setItem('urls' , JSON.stringify({originalUrl:originalUrl , currentUrl:currentUrl }))
  let currentSession = new URLSearchParams(currentUrl);
  let currentSessionId = currentSession.get('sid');
  let mainsession = new URLSearchParams(originalUrl);
  let mainsessionId = mainsession.get('sid')
  if (currentSessionId != mainsessionId) {
    window.history.pushState('', document.title, originalUrl);
    window.location.reload();
  }
}