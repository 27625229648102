import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { _throw } from 'rxjs/observable/throw';
import { environment } from './../../environments/environment';

import { WindowRefService, ICustomWindow } from './window.service';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { SessionDataNew } from 'app/models/newSession.interface';
import { selectedOffer } from 'app/models/selectedOffer.interface';
import { VorgangData } from 'app/models/vorgangData.interface';
import { BankDetails } from 'app/models/bankDetails.interface';
import { LocalstorgeService } from './localstorge.service';


@Injectable()
export class SmallPcService {
  webLinkInitlize: Boolean = true;
  public ipaddress: any;
  currency_symobl: any;
  selectOptn: any;
  matchedDomain: string;
  sessionData: any;
  private _window: ICustomWindow;
  url: string;
  webLinks: boolean = true;
  webLinksLocalData = []
  // $webLinksData: Subject<any> = new Subject();

  webLinkData = new Subject<any>();
  $webLinksData = this.webLinkData.asObservable();

  // public get webLinkData(): any {
  //   return this.$webLinksData.asObservable()
  // }
  private httpClientBackend: HttpClient;
  ;
  constructor(
    httpBackend: HttpBackend,

    private httpClient: HttpClient,
    private windowRef: WindowRefService,
    public lsService: LocalstorgeService
  ) {
    this.httpClientBackend = new HttpClient(httpBackend);
  }

  baseUrl: string = environment.baseUrl;
  showStageOne = new Subject<any>();
  showStageOne$ = this.showStageOne.asObservable();
  getIP(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/getIp`)
      .pipe(map((response) => {
        let getData = sessionStorage.getItem('aupris_ipaddress')
        getData = JSON.parse(getData)
        if (getData) {
          sessionStorage.setItem('aupris_ipaddress', JSON.stringify(getData));
          return false
        } else {
          if (response && Object.keys(response).length) {
            let prepareData = {
              latitude: response['latitude'],
              longitude: response['longitude'],
              city: response['city'],
              postalCode: response['postal'],
              IPv4: response['IPv4'],
              sessionId: this.genereateNewSessionId()
            }
            sessionStorage.setItem('aupris_ipaddress', JSON.stringify(prepareData));
            return prepareData;
          } else {
            let prepareData = {
              latitude: "",
              longitude: "",
              city: "",
              postalCode: "",
              IPv4: "",
              sessionId: this.genereateNewSessionId()
            }
            sessionStorage.setItem('aupris_ipaddress', JSON.stringify(prepareData));
            return prepareData;
          }


        }
      }), catchError(e => {
        if (e.status === 401) {
          this.ipaddress = 'wrongip';
          return _throw('Unauthorized');
        }
        return _throw('Unknown Error');
      }),
      )
  };
  updateIPRealSessionId(sessionId: any) {
    let getData: any = sessionStorage.getItem('aupris_ipaddress')
    getData = JSON.parse(getData)
    if (getData && Object.keys(getData).length) {
      getData = { ...getData, sessionId: sessionId };
      sessionStorage.setItem('aupris_ipaddress', JSON.stringify(getData))
    }
  }

  unterProducts(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/webLinks/getAllDynamicPcs`);
  }
  setValueOption(val) {
    this.selectOptn = val;
  };

  getValueOption() {
    return this.selectOptn;
  };

  getMatchedDomainLink() {
    return this.matchedDomain;
  };

  genereateNewSessionId(): string {
    let getData: any = sessionStorage.getItem('aupris_ipaddress');
    if (getData == null || getData == 'null' || getData == 'undefined' || getData == undefined || getData == '') {
      let sessionId = `SID${new Date().getTime()}`;
      return sessionId;
    }
    getData = JSON.parse(getData)
    if (getData && Object.keys(getData).length) {
      return getData.sessionId
    }
  };

  setSessionData(sessionId: string, data: any) {
    return new Promise((resolve, reject) => {
      //clear old sessionId data
      try {
        let sid = localStorage.getItem('sid');
        if (sid != sessionId) {
          localStorage.removeItem(sid);
          localStorage.removeItem('sid');
        };
        //insert new sessionId data
        this.sessionData = data;
        let stringifyData = JSON.stringify(data)
        localStorage.setItem(sessionId, stringifyData);
        localStorage.setItem('sid', sessionId);
        resolve(true);
      } catch (error) {
        reject(false);
      };
    });
  };

  getSessionData(sessionId): Promise<SessionData> {
    //unwanted-code
    return new Promise((resolve, reject) => {
      try {
        if (sessionId == 'SID1423AU123' && !environment.testSite_or_thirdPartySites) {
          this._window = this.windowRef.nativeWindow;
          let url = this._window.location.href;
          var params = new URLSearchParams(url);
          let domainName = params.get("Domainname");
          let lieferort = params.get("lieferort");
          this.sessionData = {
            priceCalcUrl: this._window.location.hostname,
            domainName: domainName,
            lieferort: lieferort,
            liter: 1000,
            abladestellen: 1,
            product: 'Heizöl',
            localOptions: {
              Lieferort: { _id: lieferort },
              Liter: { value: 1000 },
              Abladestellen: { number: 1 },
              Produkt: { name: 'Heizöl' }
            }
          }
        } else {
          let data = localStorage.getItem(sessionId);
          this.sessionData = JSON.parse(data);
        }
        resolve(this.sessionData);
      } catch (error) {
        reject(false);
      }
    });
  }

  getNewSessionData(sessionId): Promise<SessionDataNew> {

    return new Promise((resolve, reject) => {
      try {
        this._window = this.windowRef.nativeWindow;
        let url = this._window.location.href;
        let params = new URLSearchParams(url);
        let domainName = params.get("Domainname");
        if (sessionId == null || sessionId == "null" || sessionId == 'undefined' || sessionId == undefined || sessionId == '') {
          sessionId = this.lsService.getSessionId();

        }

        if (sessionId == 'SID1423AU123' && !environment.testSite_or_thirdPartySites && !decodeURIComponent(params.get("tempOrderId"))) {

          this.sessionData = {
            priceCalcUrl: this._window.location.hostname,
            domainName: domainName,
            quantity: 1000,
            stations: 1,
            product: {
              "_id": "61d421c387bf6a786895f826",
              "name": "Heizöl"
            },
            postalCode: { plzId: '', city: '', postalcode: '', suburb: '' },
            localOptions: [
              { optionSetId: '', optionId: '', sort: 1 },
              { optionSetId: '', optionId: '', sort: 2 },
              { optionSetId: '', optionId: '', sort: 3 },
              { optionSetId: '', optionId: '', sort: 4 },
              { optionSetId: '', optionId: '', sort: 5 }
            ],
            ratePaymentSettings: { status: false },
            desiredDateSettings: {},
            cart: [
              { _id: '' }
            ],
            co2Compensation: [],
          };
        } else {

          let OrderId = decodeURIComponent(params.get("tempOrderId"));
          let localSid = localStorage.getItem('sid');

          if (localSid && localSid == 'undefined') {
            let encodedUrl = this.lsService.SearchURLParams();
            let localSidCopy = decodeURIComponent(encodedUrl.get("sid"))

            if (localSid && localSid == 'undefined') {
              localStorage.setItem('sid', localSidCopy);
            }

          };



          if (localStorage.getItem('prefetch') && localStorage.getItem('prefetch') == '1' && localStorage.getItem(decodeURIComponent(params.get('sid'))) != null) {


            return resolve(JSON.parse(localStorage.getItem(decodeURIComponent(params.get('sid')))));
          };

          if (((OrderId && OrderId != null && OrderId != "null"))) {

            this.getTempOrderData({ tempSaleId: OrderId }).subscribe(async (saleTempInfo: any) => {
              if (saleTempInfo.success) {
                if (sessionId == null || sessionId == "null" || sessionId == 'undefined' || sessionId == undefined || sessionId == '') {
                  if (saleTempInfo.data && saleTempInfo.data.hasOwnProperty('sessionId')) {
                    sessionId = saleTempInfo.data.sessionId;
                  }
                }
                localStorage.setItem('prefetch', '1');
                let tempInfo = saleTempInfo.data;
                let prepareAsLikeLocal: any = {
                  localOptions: tempInfo.options,
                  orderAddresses: tempInfo.addresses,
                  domainName: tempInfo.bigPcUrls.domainName,
                  co2Compensation: tempInfo.co2Compensation,
                  postalCode: tempInfo.address,
                  stations: tempInfo.stations,
                  quantity: tempInfo.quantity,
                  ratePaymentSettings: tempInfo.ratePaymentDetails,
                  product: {
                    name: tempInfo.orderInfo.product,
                    _id: tempInfo.dynamicProductId
                  },
                  cart: tempInfo.cart,
                  priceCalcUrl: tempInfo.priceCalcUrl,
                  desiredDateSettings: tempInfo.desiredDate,
                  tag: tempInfo.tag,
                  bankDetails: tempInfo.bankAccountDetails
                };
                if (tempInfo?.voucherCode && tempInfo?.voucherId) {
                  prepareAsLikeLocal = {
                    ...prepareAsLikeLocal,
                    'voucher': {
                      "valid": true,
                      "voucherCode": tempInfo.voucherCode,
                      "error": false,
                      "voucherId": tempInfo.voucherId
                    }
                  }
                }

                let sessionIdUrl = decodeURIComponent(params.get('sid'));


                // localStorage.removeItem("sid");
                // localStorage.removeItem(sessionIdUrl);

                let setData = await this.setSessionData(sessionId, prepareAsLikeLocal);

                let getData = await localStorage.getItem(sessionId);
                this.sessionData = JSON.parse(getData);
                let sessionStorageIpData = JSON.parse(sessionStorage.getItem('aupris_ipaddress'))
                if (sessionStorageIpData && sessionStorageIpData != null && sessionStorageIpData != 'null' && sessionStorageIpData && Object.keys(sessionStorageIpData).length) {
                  sessionStorageIpData['sessionId'] = sessionId;
                  sessionStorage.setItem('aupris_ipaddress', JSON.stringify(sessionStorageIpData))
                } else {
                  let data = {
                    sessionId: sessionId, latitude: '',
                    longitude: '',
                    city: '',
                    postalCode: '',
                    IPv4: '',
                  }
                  sessionStorage.setItem('aupris_ipaddress', JSON.stringify(data))
                }

                resolve(this.sessionData);
              } else {

              };
            });
          } else {
            let getData = localStorage.getItem(sessionId);
            this.sessionData = JSON.parse(getData);
            resolve(this.sessionData);
          };
        };
      } catch (error) {
        reject(false);
      };
    });
  };


  getTempOrderData(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/order/getTempOrderdData', data);
  };

  async setRecentAddress(addresses, sessionId) {
    // let jsonAddress = JSON.stringify(addresses);
    let currentSessionData = await this.getSessionData(sessionId);
    if (currentSessionData) {
      currentSessionData = {
        ...currentSessionData,
        addresses: addresses
      }
    }
    localStorage.setItem(sessionId, JSON.stringify(currentSessionData));
  };

  async setBankDetails(bankDetails, sessionId) {
    let currentSessionData = await this.getSessionData(sessionId);
    if (currentSessionData) {
      currentSessionData = {
        ...currentSessionData,
        bankDetails: bankDetails
      }
    }
    localStorage.setItem(sessionId, JSON.stringify(currentSessionData));
  };

  getRecentAddress(sessionId) {
    return new Promise(async (resolve, reject) => {
      try {
        let sessionData = await this.getSessionData(sessionId)
        // let sesss = localStorage.getItem(sessionId);
        if (sessionData && sessionData.addresses && sessionData.addresses != '') {
          resolve(sessionData.addresses);
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(false);
      }
    });
  };

  getSessionBankDetails(sessionId) {
    return new Promise(async (resolve, reject) => {
      try {
        let sessionData = await this.getSessionData(sessionId);
        if (sessionData && sessionData?.bankDetails && !sessionData.bankDetails.iban) {
          resolve(sessionData.bankDetails);
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(false);
      }
    });
  };



  // showFirstStage(data, data2) {
  //   let sendingData = { booleanValue: data, s_data: data2 }
  //   this.showStageOne.next(sendingData)
  // };

  showFirstStage(event) {
    this.showStageOne.next({ show: event })
  };

  getSidFromlocalStorage() {
    let sid = localStorage.getItem('sid');
    return sid;
  };

  getDomainName() {
    if (!environment.testSite_or_thirdPartySites) {
      this.url = window.location.href;
      let params = new URLSearchParams(this.url);
      let domainname = decodeURIComponent(params.get("Domainname"));
      return domainname;
    } else {
      return false
    };
  };


  validateSessionData(sessionId) {
    return new Promise(async (resolve, reject) => {
      try {
        this.sessionData = await this.getSessionData(sessionId);
        if (sessionId && this.sessionData && this.sessionData.localOptions) {
          let lieferOrt = this.sessionData.postalCode && this.sessionData.postalCode.plzId ? this.sessionData.postalCode.plzId : null;
          let product = this.sessionData.product && this.sessionData.product.name ? this.sessionData.product.name : null;
          let Liter = this.sessionData.quantity ? this.sessionData.quantity : null;
          if (lieferOrt && product && Liter || product && Liter) {
            resolve(true);
          } else {
            resolve(false);
          };
        } else {
          resolve(false);
        };
      } catch (error) {
        reject(false);
      };
    });
  };

  dynamicprodcuts() {
    return this.httpClient.get(this.baseUrl + '/getDynamicPcList');
  };

  products(data): Observable<any> {
    let authHeader = new HttpHeaders();
    authHeader = authHeader.append('Content-Type', 'application/json');
    authHeader = authHeader.append('tag', data.tag.toString());
    authHeader = authHeader.append('dynamiceProductId', data.dynamicProductId);
    authHeader = authHeader.append('domainname', 'localhost');
    return this.httpClient.get(this.baseUrl + '/webLinks/products', { headers: authHeader });
  };

  dynamicProductInfo(data) {
    let authHeader = new HttpHeaders();
    this.appendHeadersData(data);
    return this.httpClient.get(this.baseUrl + '/productInfo', { headers: authHeader });
  };

  appendHeadersData(data) {

  };

  // dynamicOptions(data: any) {
  //   let authHeader = new HttpHeaders();
  //   authHeader = authHeader.append('Content-Type', 'application/json');
  //   let domainName = this.getDomainName();
  //   let dynamicProductId = data.dynamicProductId
  //   let plzId = data.plzId || "597f43b48324563a64de6610"
  //   if (domainName && dynamicProductId && plzId) {
  //     authHeader = authHeader.append('domainname', domainName);
  //     authHeader = authHeader.append('dynamicProductId', dynamicProductId);
  //     authHeader = authHeader.append('plzid', plzId);
  //   }
  //   return this.httpClient.get(this.baseUrl + '/bigpc/options', { headers: authHeader });
  // };

  dynamicOptions(data: any) {
    return this.httpClient.get(this.baseUrl + '/bigpc/options');
  };

  dynamicPcCalculation(data) {
    this.appendHeadersData(data);
    return this.httpClient.get(this.baseUrl + '/calculation');
  };



  getWebTags(tag): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/webLinks', { tag: tag });
  };

  updateIpAdress(data): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/authentication/updateIpAdress`, data)
  };


}
export interface SessionData {
  abladestellen: any,
  domainName: string,
  lieferort: any,
  liter: string,
  priceCalcUrl: string,
  product: string,
  localOptions: LocalOptions | any,
  mengeno?: boolean,
  remainingMenge: Number,
  mig?: string,
  selectedOffer: selectedOffer,
  co2CompensateValue: any,
  page1: boolean,
  page2: boolean,
  page3: boolean,
  page4: boolean,
  options?: any,
  prufen: any,
  rechnung1: any,
  addresses: any,
  rechnungOption: any;
  ratePaymentStatus: boolean;
  ratePayment: boolean,
  ausgewaehlterRatenplan: {
    zahlungsplan: {
      anzahlRaten?: any;
      betragLetzteRate?: any;
      betragRate?: any;
      terminErsteRate?: any;
      terminLetzteRate?: any;
    },
    zinsen: { effektivzins?: any, nominalzins?: any, anfallendeZinsen?: any },
    tbVorgangskennung?: any,
    fachlicheVorgangskennung?: any,
    deviceIdentToken?: any,
    mTanRequired?: any,
    tilgungsplanText?: any,
    urlVorvertraglicheInformationen?: any
  },
  vorgangData?: VorgangData,
  bankDetails?: BankDetails
}

export interface LocalOptions {
  Abladestellen: { number: Number },
  Gutschein: string,
  Lieferort: { _id: string, title: string },
  title: string,
  _id: string,
  Liefertermin: { _id: string, name: string }
  Lieferzeiten: { _id: string, name: string }
  Liter: { value: string }
  Produkt: { name: string }
  Schlauchlange: { _id: string, name: string }
  Schlauchlange_pellets: { _id: string, name: string }
  Tankwagen: { _id: string, name: string }
  Wunschtermin: {
    _id: string, name: string, head: string, colapse: string, preText: string, specialText: string,
    specialText_show: string,
  }[],
  Zahlungsart: {
    _id: string,
    name: string,
    refPayment: any,
    centralizedRefId: string,
  },
  earliestDate: string,
  fromDate: string,
  latestDate: string,
  toDate: string,
  zahlungspans: { zahlungRate: number }[]
}