import { Directive, Input, HostListener, } from '@angular/core';

@Directive({
  selector: '[allowCharAndNum]'
})
export class CharAndNumDirective {
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  constructor() { }
  @Input() inputValue: any;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    if (e.altKey == true && (e.keyCode == 65 || e.keyCode == 79 || e.keyCode == 83 || e.keyCode == 85)) {
      e.preventDefault();
    }
    if (e.altKey == false) {
      // if(e.shiftKey)
      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Allow Backspace, tab, end, and home keys
      if (this.specialKeys.indexOf(event.key) !== -1) {

        return;
      }

      // Ensure that it is a number and stop the keypress
      if ((((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 86 || e.keyCode > 105) && !(e.keyCode > 64 && e.keyCode < 90)))) {
        // console.log(e.shiftKey, 9)
        e.preventDefault();
      }

    }
  }

}
