import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LocalstorgeService {

  constructor() { }

  // getSessionId() {
  //   let url = window.location.href;
  //   let params = new URLSearchParams(url);
  //   let sessionId = decodeURIComponent(params.get("sid"));
  //   return sessionId;
  // };

  getSessionData(id) {
    let currentStoredData = localStorage.getItem(id);
    let sessionData = JSON.parse(currentStoredData);
    return sessionData;
  };

  setLocalOptionSessionData(obj) {
    return new Promise(async (resolve, reject) => {
      try {
        let currentSessionData = this.getSessionData(this.getSessionId());
        await currentSessionData['localOptions'].map((item) => {
          if (item.optionSetId == obj.refCentralizedOptionSetId) {
            item.optionId = obj.refCentralizedOptionId;
            item.sort = obj.sortOrder;
            item.name = obj.name;
            item.DOB = obj.centralizedOptions.DOBStatus;
            item.name = obj.centralizedOptions.name;
            return item;
          }
        });
        localStorage.setItem(this.getSessionId(), JSON.stringify(currentSessionData))
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  setSingleKeySessionData(key, data) {
    let currentSessionData = this.getSessionData(this.getSessionId());
    currentSessionData[key] = data;
    localStorage.setItem(this.getSessionId(), JSON.stringify(currentSessionData))
  };

  getSingleSessionData(key) {
    let currentSessionData = this.getSessionData(this.getSessionId());
    let resultData = currentSessionData[key];
    return resultData;
  };

  sessionValidate() {
    if (!this.getSessionId()) {
      return false;
    }

    return true;
  };

  setSessionData(currentSessionData) {
    localStorage.setItem(this.getSessionId(), JSON.stringify(currentSessionData));
  };

  setPriceAlarm(alarmData) {
    localStorage.setItem("priceAlarmInfo", JSON.stringify(alarmData));
  };

  getAlarmData() {
    let currentAlarmStoredData = localStorage.getItem("priceAlarmInfo");
    let alarmData = JSON.parse(currentAlarmStoredData);
    return alarmData;
  };

  removeAlarmFromLocal() {
    let removeAlarmStoredData = localStorage.removeItem("priceAlarmInfo");
    return removeAlarmStoredData;
  };
  SearchURLParams() {
    let url = window.location.href;
    return new URLSearchParams(url);
  }
  decodeURLParams(urlParam, id) {
    console.log(decodeURIComponent(urlParam.get(id)), 'decodeURIComponent(urlParam.get(id))');
    return decodeURIComponent(urlParam.get(id));
  }

  getSessionId() {
    let params = this.SearchURLParams();
    let sessionId: any = decodeURIComponent(params.get("sid"));
    if (sessionId != null || sessionId !== "null" || sessionId !== undefined || sessionId !== "undefined" || sessionId !== '') {
      return sessionId;
    }

  }
}
