import { Component, OnInit } from '@angular/core';;
import { WindowRefService, ICustomWindow } from '../Services/window.service';
import { environment } from '../../environments/environment';
import { BigpcService } from '../Services/bigpc.service';
@Component({
  selector: 'app-verification-new',
  templateUrl: './verification-new.component.html',
  styleUrls: ['./verification-new.component.css']
})
export class VerificationNewComponent implements OnInit {
  colors;
  url;
  hostname
  verifiedeMail: boolean = false;
  verification: boolean = false;
  private _window: ICustomWindow;
  constructor(
    private newBigPcService: BigpcService,
    private windowRef: WindowRefService
  ) {
    this._window = this.windowRef.nativeWindow;
  }

  async ngOnInit() {
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(this.url);/*testcalc*/

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    }

    // let data: any = await this.MybigpcService.getMinMaxData();
    // this.colors = data.colors;

    if (decodeURIComponent(params.get("view")) == 'verification') {
      this.verification = true;
      let object = { token: decodeURIComponent(params.get("token")) };
      this.newBigPcService.emailVerification(object, this.hostname).subscribe((data: any) => {

        if (data.success) {
          localStorage.setItem('aupris_token', JSON.stringify(data.token));
          this.verifiedeMail = true;
        }
        else {
          this.verifiedeMail = false;
        }
      })
    }
  }

}
