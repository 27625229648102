<div class="aupris_main my-full-screen-dialog">
    <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">
        {{languageData['Bestelloptionen']['Var_Geburtsdatum']|| 'Geburtsdatum' }}
    </div>
    <form [formGroup]="geburtstagForm">
        <div style="margin-top: 35px;">
            <div class="" style="width: 80%;margin-top: -8px;display: flex;">
                <label class="posAbs aupLabel">
                    {{languageData['Adresseingabe'].Var_date || 'Geburtstag(DD.MM.YYYY)'}}*
                </label>
                <input class="textbox-n" autocomplete="new-password" [matDatepicker]="picker" (click)="picker.open()"
                    focus="picker.open()" [max]="today" class="auform-control widthh" #lGeburtstag
                    (change)="dateChange($event.target.value)" formControlName="Geburtstag">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year">
                </mat-datepicker>
            </div>
            <div *ngIf="showError" class="alert-danger" style="width: 71%;margin-top: -6px;">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
            </div>
        </div>
        <div style="margin-bottom: 30px;">
            <input type="checkbox" name="adresses" (change)="checkValue($event.target)"
                style="margin-right: 10px;margin-bottom:15px">
            Diese Informationen unter Adressen abspeichern.
        </div>

        <div class="">
            <button class="au-btn btn-default" style="background-color: #ccc;"
                [ngStyle]="{background: 'var(--primaryColour)', color:'var(--primaryTextColour)'}"
                (click)="cancelPopup()">
                {{languageData['Address'].Var_Abbrechen || 'Abbrechen'}}
            </button>
            <button class="au-btn btn-default pull-right" type="submit"
                [ngStyle]="{background: 'var(--primaryColour)', color:'var(--primaryTextColour)'}" (click)="close()">
                {{languageData['Hamburger'].Var_save || 'Speichern'}}
            </button>
        </div>
    </form>
</div>