import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input('full-screen') fullscreen: boolean;
  @Input('loader-style') loaderStyle: string;
  @Input('color') color: string;

  loaderStyles: string[] = ['circle', 'bars', 'bubbles', 'fading-circle', 'bubble-circle'];
  constructor() { }

  ngOnInit() {
    this.fullscreen = this.fullscreen == true ? true : false;
    this.loaderStyle = this.loaderStyles.indexOf(this.loaderStyle) >= 0 ? this.loaderStyle : "bubble-circle";
    this.color = this.color || 'rgb(57, 186, 234, 1)';
  }

}
