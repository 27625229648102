import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'validateAddress'
})
export class ValidateAddressPipe implements PipeTransform {

  transform(value: any): any {
    if (value.plzId) {
      return false;
    };
    return true;
  };
};
