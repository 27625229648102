import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../Services/language.service';
@Component({
  selector: 'app-infopopup',
  templateUrl: './infopopup.component.html',
  styleUrls: ['./infopopup.component.css']
})
export class InfopopupComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  showPayments :boolean = false ;
  paymentVars: any;
  paymentOptions = [];
  PaymentOption: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,public dialog: MatDialog, private  langService: LanguageService,public dialogRef: MatDialogRef<InfopopupComponent>) { 
    let langData = this.langService.getLocalLanguageData();
 
    this.languageData = langData['web-pc'] || {};
    this.paymentVars = langData['unternehmen'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
      this.paymentVars = data['unternehmen'] || {};
     });
  }
  //  colors: any;

  infotext:any;

  ngOnInit() {
    console.log(this.data , 54564);
    if(Array.isArray(this.data) && this.data.length){
      this.paymentOptions = this.data || []
       this.showPayments = true ;
       this.PaymentOption = this.data[0];
    }
  }
  
  onclick() {
    this.dialogRef.close({sucess : true , selectedPayOption : this.PaymentOption});
  }
  onClick_Bestellen(){
    
  }
  selectedPaymentOption(option) {
    let selcted = this.paymentOptions.find(data => data._id == option)
    this.PaymentOption = selcted
    console.log(this.PaymentOption, 5645);

  }
}
