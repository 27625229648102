import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../Services/language.service';

@Component({
  selector: 'app-info-password-popup',
  templateUrl: './info-password-popup.component.html',
  styleUrls: ['./info-password-popup.component.css']
})
export class InfoPasswordPopupComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  colors: any;
  constructor(public dialog: MatDialog, 
    private  langService: LanguageService,
    public dialogRef: MatDialogRef<InfoPasswordPopupComponent>) { 
    let langData = this.langService.getLocalLanguageData();
 
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
     });
  }

  ngOnInit(): void {
  }
  onclick() {
    this.dialogRef.close();
  }
}
