<!-- <Aupris-Kleiner-Preisrechner>Kleiner Preisrechner Loading...</Aupris-Kleiner-Preisrechner>
<Aupris-Kleiner-Preisrechner-Heizol>Kleiner Preisrechner heizöl Loading...</Aupris-Kleiner-Preisrechner-Heizol>
<Aupris-Kleiner-Preisrechner-Pellets>Kleiner Preisrechner pellets Loading...</Aupris-Kleiner-Preisrechner-Pellets>
<Aupris-Bestell-Preisrechner>Bestell Preisrechner Loading...</Aupris-Bestell-Preisrechner> -->
<!-- <app-login></app-login> -->
<div *ngIf="!pcOff_Pbbackend">
  <div class="aupris_main" *ngIf="smallpc_ttr_fr" style="max-width:600px!important;margin:auto">
    <div class="au-container-fluid" style="padding-right: 0px;padding-left: 0px;min-width:200px">
      <div class="panel panel-default">
        <div class="panel-heading text-center"> hioffp
          <strong>{{languageData['Small-pc'].Var_Preisrechner || 'Preisrechner'}}</strong>
        </div>
        <div class="panel-body">
          <form class="form-horizontal" [formGroup]="myForm" *ngIf='myForm' novalidate
            (ngSubmit)="save(myForm, myForm.valid)">
            <div class="animate-bottom" style=" display: flex;margin-bottom:15px">
              <div style="min-width:130px;">
                <label>{{languageData['Small-pc'].Var_Produkt || 'Produkt'}}:</label>
              </div>
              <div style="width:100%">
                <select name="select" class="auform-control" formControlName="product" [ngModel]='selectedValue'
                  (change)="conversionchange($event.target)">
                  <option *ngIf="!myForm.value.product" [value]="" selected></option>
                  <option *ngFor="let prod of products" [value]="prod.name"
                    [selected]="prod.name === 'hello' ? true: null">
                    {{languageData['Small-pc']['Var_'+prod.name]|| prod.name }}</option>
                </select>
              </div>
            </div>
            <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
              *ngIf="productdup == '' || ActivateDeactivatePc[productdup]">
              <span style="min-width:130px;">
                <label>{{languageData['Small-pc'].Var_Lieferort || 'Lieferort'}}:</label>
              </span>
              <span style="width:100%">
                <input type="text" class="auform-control" formControlName="Lieferort" [matAutocomplete]="autoPlz"
                  (ngModelChange)="getPostalCode($event)" (keydown)="onKeyy($event)">
                <mat-autocomplete #autoPlz="matAutocomplete">
                  <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="selectPlz(option)"
                    (blur)="onNg2Blur($event)" (keypress)="validate($event)"
                    [value]="option.country_code +' ' + option.place_name +' '+option.suburb">
                    {{option.place_name}} {{option.country_code}} {{option.suburb}}
                  </mat-option>
                </mat-autocomplete>
                <span class="alert-danger"
                  *ngIf="myForm.controls.Lieferort.errors && (myForm.controls.Lieferort.touched && invalidLieferort )">
                  {{languageData['Small-pc'].Var_valid_deliverystation || 'Bitte gültigen Lieferort
                  auswählen!'}}</span>

                <span class="alert-danger"
                  *ngIf="errorMsg">{{languageData['Small-pc'].Var_validinput_deliveryarea_error||
                  'Bitte prüfen Sie Ihre Eingabe. Der Ort liegt nicht in unserem Liefergebiet.'}}</span>
                <span class="alert-danger" *ngIf="tabErrorMsg">
                  Bitte prüfen Sie Ihre Eingabe und wählen Sie einen Lieferort aus dem Auswahlfeld aus
                </span>
                <div class="alert"
                  *ngIf="myForm.controls.Liter.errors?.errorleiferort && myForm.controls.Liter.touched">
                  Max min values</div>
              </span>
              <div class="alert" *ngIf="myForm.controls.Liter.errors?.errorleiferort && myForm.controls.Liter.touched">
                Max min values</div>

            </div>

            <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
              *ngIf="productdup == '' || ActivateDeactivatePc[productdup]">
              <span style="min-width:130px;">
                <label class="amount">{{languageData['Small-pc']['Var_'+label]||label }}:</label>
              </span>
              <span style="width:100%">
                <input [appDisableInput]='productdup==""' (keyup)="validateMenge()" class="auform-control"
                  formControlName="Liter">
                <div *ngIf="label=='Liter'">
                  <span class="alert-danger"
                    *ngIf="(inLimitHouseHold && label == 'Kg'  && !myForm.controls.Liter.errors?.errormaxmin)">Die
                    Mindestbestellmenge für
                    {{myForm.controls['abladestellen'].value}} liegt bei
                    {{min_pellets | replaced}}. Bitte passen Sie
                    Ihre Werte in der Auswahl an!
                  </span>
                  <span class="alert-danger"
                    *ngIf="myForm.controls.Liter.errors?.errormaxmin && myForm.controls.Liter.touched ">
                    Bestellmenge {{minmaxdata.min.min_pellets_value | replaced}} bis
                    {{minmaxdata.max.max_pellets_value | replaced }} Kg!</span>
                </div>
                <span class="alert-danger"
                  *ngIf="(inLimitHouseHold && label == 'Kg'  && !myForm.controls.Liter.errors?.errormaxmin)">Die
                  Mindestbestellmenge für
                  {{myForm.controls['abladestellen'].value}} liegt bei
                  {{min_pellets | replaced}}. Bitte passen Sie
                  Ihre Werte in der Auswahl an!
                </span>
                <span class="alert-danger"
                  *ngIf="(inLimitHouseHold && label == 'Liter' && !myForm.controls.Liter.errors?.errormaxmin )">Die
                  Mindestbestellmenge für
                  {{myForm.controls['abladestellen'].value}} liegt bei
                  {{min_heizol | replaced}}. Bitte passen Sie
                  Ihre Werte in der Auswahl an!
                </span>
              </span>
            </div>
            <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
              *ngIf="(productdup == '' || ActivateDeactivatePc[productdup]) && showAbladestelle">
              <span style="min-width:130px;">
                <label class="amount">{{languageData['Bestelloptionen'].Var_Abladestellen || 'Abladestellen'}}:</label>
              </span>
              <span style="width:100%">
                <select name="Abladestellen" class="auform-control" (change)="validateMenge($event.target.value)"
                  formControlName="abladestellen">
                  <option *ngFor="let prod of abladestellen" [value]="prod?.value">{{prod?.value}}
                    <span *ngIf="prod?.value== 1">{{languageData['Bestelloptionen'].Var_Haushalt || 'Haushalt'}}</span>
                    <span *ngIf="prod?.value >= 2">{{languageData['Bestelloptionen'].Var_Haushalte ||
                      'Haushalte'}}</span>
                  </option>
                </select>
              </span>
            </div>


            <div class="auform-group animate-bottom" style="margin-bottom: -15px;"
              *ngIf="productdup == '' || ActivateDeactivatePc[productdup]">
              <button type="submit" class="au-btn au-btn-block"
                [ngStyle]="{background: colors.Grundfarbe,color: colors.Schriftfarbe_Buttons}"
                [disabled]="isvalid()">{{languageData['Small-pc'].Var_Preis_Berechnen || 'Preis Berechnen'}}</button>
            </div>

            <div class="auform-group" *ngIf="label=='Liter' && !ActivateDeactivatePc.Heizol"
              style="color: red; font-size: 18px; font-weight: 600;text-align: center">
              {{ActivateDeactivatePc.HeizolText }}</div>
            <div class="auform-group" *ngIf="label == 'Kg' && !ActivateDeactivatePc.Pellets"
              style="color: red; font-size: 18px; font-weight: 600;text-align: center">
              {{ActivateDeactivatePc.PelletsText }}</div>
          </form>
          <div *ngIf="!myForm && errMsg == '' ">
            <div class="loader"></div>
          </div>
          <div *ngIf="errMsg != '' " style="text-align:center">
            <label style="color:red">{{errMsg}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="pcOff_Pbbackend && smallpc_ttr_fr">
  {{languageData?.Loading?.Var_PC_Off_From_PbBackend || 'Unser Preisrechner befindet sich im Wartungsmodus!'}}
</div>


<div *ngIf="!pcOff_Pbbackend">
  <div class="aupris_main" *ngIf="smallpc_ttr_fr" style="max-width:600px!important;margin:auto">
    <div class="au-container-fluid" style="padding-right: 0px;padding-left: 0px;min-width:200px">
      <div class="panel panel-default">
        <div class="panel-heading text-center">
          <strong>{{languageData['Small-pc'].Var_Preisrechner || 'Preisrechner'}}</strong>
        </div>
        <div class="panel-body">
          <form class="form-horizontal" [formGroup]="myForm" *ngIf='myForm' novalidate
            (ngSubmit)="save(myForm, myForm.valid)">
            <div class="animate-bottom" style=" display: flex;margin-bottom:15px">
              <div style="min-width:130px;">
                <label>{{languageData['Small-pc'].Var_Produkt || 'Produkt'}}:</label>
              </div>
              <div style="width:100%">
                <select name="select" class="auform-control" formControlName="product" [ngModel]='selectedValue'
                  (change)="conversionchange($event.target)">
                  <option *ngIf="!myForm.value.product" [value]="" selected></option>
                  <option *ngFor="let prod of dynamicPCData" [value]="prod.dynamicProductId">
                    {{prod.namePc}}
                  </option>
                  <!-- [selected]="prod.namePc === 'hello' ? true: null" -->
                  <!-- {{languageData['Small-pc']['Var_'+prod.name]|| prod.name }} -->
                </select>
              </div>
            </div>
            <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
              *ngIf="productdup == '' || ActivateDeactivatePc[productdup]">
              <span style="min-width:130px;">
                <label>{{languageData['Small-pc'].Var_Lieferort || 'Lieferort'}}:</label>
              </span>
              <span style="width:100%">
                <input type="text" class="auform-control" formControlName="Lieferort" [matAutocomplete]="autoPlz"
                  (ngModelChange)="getPostalCode($event)" (keydown)="onKeyy($event)">
                <mat-autocomplete #autoPlz="matAutocomplete">
                  <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="selectPlz(option)"
                    (blur)="onNg2Blur()" (keypress)="validate($event)"
                    [value]="option.country_code +' ' + option.place_name +' '+option.suburb">
                    {{option.place_name}} {{option.country_code}} {{option.suburb}}
                  </mat-option>
                </mat-autocomplete>
                <span class="alert-danger"
                  *ngIf="myForm.controls.Lieferort.errors && (myForm.controls.Lieferort.touched && invalidLieferort )">
                  {{languageData['Small-pc'].Var_valid_deliverystation || 'Bitte gültigen Lieferort auswählen!'}}</span>

                <span class="alert-danger"
                  *ngIf="errorMsg">{{languageData['Small-pc'].Var_validinput_deliveryarea_error||
                  'Bitte prüfen Sie Ihre Eingabe. Der Ort liegt nicht in unserem Liefergebiet.'}}</span>
                <span class="alert-danger" *ngIf="tabErrorMsg">
                  Bitte prüfen Sie Ihre Eingabe und wählen Sie einen Lieferort aus dem Auswahlfeld aus
                </span>
                <div class="alert"
                  *ngIf="myForm.controls.Liter.errors?.errorleiferort && myForm.controls.Liter.touched">
                  Max min values</div>
              </span>
            </div>
            <div class="animate-bottom" style=" display: flex;margin-bottom:15px">
              <span style="min-width:130px;">
                <label class="amount">{{languageData['Small-pc']['Var_'+label]||label }}:</label>
              </span>
              <span style="width:100%">
                <input [ngClass]="{disabled: !showremainingValues}" (keyup)="validateMenge($event.target.value)"
                  class="auform-control" formControlName="Liter">

                <div *ngIf="minAmount">
                  <div class="alert-danger"
                    *ngIf="myForm?.get('Liter')?.invalid && (myForm?.get('Liter').touched || myForm?.get('Liter').dirty)">
                    Bestellmenge {{minimumAmount | replaced}} bis
                    {{maximumAmount | replaced}}
                  </div>
                </div>
                <div *ngIf="maxAmount">
                  <div class="alert-danger"
                    *ngIf="myForm?.get('Liter')?.invalid && (myForm?.get('Liter').touched || myForm?.get('Liter').dirty)">
                    Bestellmenge {{minimumAmount | replaced}} bis
                    {{maximumAmount | replaced}}
                  </div>
                </div>
              </span>
            </div>
            <!-- <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
              *ngIf="productdup == '' || ActivateDeactivatePc[productdup]">
              <span style="min-width:130px;">
                <label class="amount">{{languageData['Small-pc']['Var_'+label]||label }}:</label>
              </span>
              <span style="width:100%">
                <input [appDisableInput]='productdup==""' (keyup)="validateMenge()" class="auform-control"
                  formControlName="Liter">
                <div *ngIf="label=='Liter'">
                  <span class="alert-danger"
                    *ngIf="myForm.controls.Liter.errors?.errormaxmin && myForm.controls.Liter.touched ">
                    Bestellmenge {{minmaxdata.min.min_heatoil_value | replaced}} bis
                    {{minmaxdata.max.max_heatoil_value| replaced}} Liter!</span>
                </div>
                <div *ngIf="label=='Kg'">
                  <span class="alert-danger"
                    *ngIf="myForm.controls.Liter.errors?.errormaxmin && myForm.controls.Liter.touched ">
                    Bestellmenge {{minmaxdata.min.min_pellets_value | replaced}} bis
                    {{minmaxdata.max.max_pellets_value | replaced }} Kg!</span>
                </div>
                <span class="alert-danger"
                *ngIf="(inLimitHouseHold && label == 'Kg'  && !myForm.controls.Liter.errors?.errormaxmin)">Die
                  Mindestbestellmenge für
                  {{myForm.controls['abladestellen'].value}} liegt bei
                  {{min_pellets | replaced}}. Bitte passen Sie
                  Ihre Werte in der Auswahl an!
                </span>
                <span class="alert-danger"
                  *ngIf="(inLimitHouseHold && label == 'Liter' && !myForm.controls.Liter.errors?.errormaxmin )">Die
                  Mindestbestellmenge für
                  {{myForm.controls['abladestellen'].value}} liegt bei
                  {{min_heizol | replaced}}. Bitte passen Sie
                  Ihre Werte in der Auswahl an!
                </span>
              </span>
            </div> -->
            <div class="animate-bottom" style=" display: flex;margin-bottom:15px" *ngIf="showAbladestelle">
              <span style="min-width:130px;">
                <label class="amount">{{languageData['Bestelloptionen'].Var_Abladestellen || 'Abladestellen'}}:</label>
              </span>
              <span style="width:100%">
                <select name="Abladestellen" class="auform-control" (change)="validateMenge($event.target.value)"
                  formControlName="abladestellen">
                  <option *ngFor="let prod of loadingStations" [value]="prod?.value">{{prod?.value}}
                    <span *ngIf="prod?.value== 1">{{languageData['Bestelloptionen'].Var_Haushalt || 'Haushalt'}}</span>
                    <span *ngIf="prod?.value >= 2">{{languageData['Bestelloptionen'].Var_Haushalte ||
                      'Haushalte'}}</span>
                  </option>
                </select>
              </span>
            </div>




            <div class="auform-group animate-bottom" style="margin-bottom: -30px;"
              *ngIf="productdup == '' || ActivateDeactivatePc[productdup]">
              <button type="submit" class="au-btn au-btn-block"
                [ngStyle]="{background: colorsPc.colors.Grundfarbe,color: colorsPc.colors.Schriftfarbe_Buttons}"
                [disabled]="isvalid()">{{languageData['Small-pc'].Var_Preis_Berechnen || 'Preis Berechnen'}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="pcOff_Pbbackend && smallpc_ttr_fr">
  {{languageData?.Loading?.Var_PC_Off_From_PbBackend || 'Unser Preisrechner befindet sich im Wartungsmodus!'}}
</div>