import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  baseUrl: string = environment.baseUrl;
  onLanguageChange = new Subject();

  // token(): string {
  //   return JSON.parse(localStorage.getItem('token'));
  // }

  constructor(
    protected http: HttpClient
  ) {
    this.getPclanguage();
  }

  // getPclanguage(): Promise<any> {
  //   var url = window.location.href;
  //   var params = new URLSearchParams(url);
  //   let domainname = decodeURIComponent(params.get("Domainname"));
  //   var authHeader = new HttpHeaders({
  //     'domainname': domainname
  //   });

  //   let language = localStorage.getItem('appLanguage') || 'GEN';

  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.baseUrl + '/lang-data', {
  //       langauge: language
  //     }, {
  //       headers: authHeader
  //     }).toPromise().then((data: any) => {
  //       if (data.success) {
  //         localStorage.setItem('language', data.langauge);
  //         let languageData = JSON.parse(localStorage.getItem('languageData') || '{}');
  //         languageData[language] = data.languageData;
  //         localStorage.setItem('languageData', JSON.stringify(languageData));
  //         resolve(data.languageData);
  //         this.onLanguageChange.next(data.languageData);
  //       } else {
  //         reject();
  //       }
  //       resolve(data);
  //     }).catch(reject);
  //   });
  // };


  getPclanguage(): Promise<any> {
    let language = localStorage.getItem('appLanguage') || 'GEN';
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + '/lang-data', {
        langauge: language
      },
      ).toPromise().then((data: any) => {
        if (data.success) {
          let newlngData = {};
          localStorage.setItem('language', data.langauge);
          newlngData[data.langauge ? data.langauge : "GEN"] = data.languageData;
          localStorage.setItem('languageData', JSON.stringify(newlngData));
          resolve(data.languageData);
          this.onLanguageChange.next(data.languageData);
        } else {
          reject();
        }
        resolve(data);
      }).catch(reject);
    });
  };


  getLocalLanguageData() {
    let language = localStorage.getItem('appLanguage') || 'GEN';
    try {
      return JSON.parse(localStorage.getItem('languageData') || '{}')[language] || {};
    } catch (e) {
      return {}
    }
  }

  public getJSON(): Observable<any> {
    return this.http.get(environment.assetsUrl+"assets/mapStyles.json");
  }

  getImage(): Observable<any> {
    return this.http.get(environment.assetsUrl+"assets/mlogo.png")
  }
}
