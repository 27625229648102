import { Directive, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableInput]'
})
// export class DisableInputDirective {
//   @Input() set appDisableInput( condition) {
//     const action = condition ? 'disable' : 'enable';
//     this.ngControl.control[action]();
//   }
//   constructor( private ngControl : NgControl ) {
//   }
// }
export class DisableInputDirective implements OnChanges {
  @Input('appDisableInput') appDisableInput;
  constructor(private ngControl: NgControl) { }

  ngOnChanges(changes) {
    if (changes['appDisableInput']) {
      const action = this.appDisableInput ? 'disable' : 'enable';

      this.ngControl.control[action]();
    }
  }
}
