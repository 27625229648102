<div class="aupris_main my-full-screen-dialog">
<div class="headerCss">
    {{languageData['login']['VarWeb-PcLogin2']|| 'Mindestanforderungen an Ihr Passwort' }}
</div>
<div class="textCss" style="margin-top: 20px">
    {{languageData['login']['VarWeb-PcLogin3']|| '- Mind. 8 Zeichen' }}
</div>
<div class="textCss">
    {{languageData['login']['VarWeb-PcLogin5']|| '- Max. 20 Zeichen' }}
</div>
<div class="textCss">
    {{languageData['login']['VarWeb-PcLogin6']|| '- Muss Groß- und Kleinschreibung enthalten' }}
</div>
<div class="textCss">
    {{languageData['login']['VarWeb-PcLogin7']|| '- Muss mind. eine Zahl enthalten' }}
</div>
<div class="textCss">
    {{languageData['login']['VarWeb-PcLogin8']|| '- Keine Sonderzeichen' }}
</div>
    <button class="au-btn btn-default" [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
        (click)="onclick()">
        {{languageData['login']['VarWeb-PcLogin4']|| 'Schließen' }}
    </button>
</div>