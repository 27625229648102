import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PortalAuthService } from '../login/auth.service';
import { WindowRefService } from './window.service';
import { SmallPcService } from './small-pc.service';
import { UserAddress } from 'app/models/portalModels/portalUserAddress.interface';
import { BigpcService } from './bigpc.service';



@Directive()
@Injectable({
  providedIn: 'root'
})
export class AdminService {
  delete: any;
  @Output() Kundennummer: EventEmitter<any> = new EventEmitter();
  public notify = new BehaviorSubject<any>('');
  notifyObservable$ = this.notify.asObservable();
  domainname: string;
  ipaddress: any;
  // edit(arg0: { id: any; }) {
  //   throw new Error("Method not implemented.");
  // }
  baseUrl: string = environment.baseUrl;
  userAdresses: UserAddress[];
  constructor(
    private http: HttpClient,
    private authService: PortalAuthService,
    private newBigc: BigpcService,
    private windowRef: WindowRefService,
    private smallPcService: SmallPcService) {
    var _window = this.windowRef.nativeWindow;
    var url = _window.location.href;
    var params = new URLSearchParams(url);
    this.domainname = decodeURIComponent(params.get("Domainname"));
    // if (JSON.parse(localStorage.getItem('aupris_ipaddress'))) {
    //   // this.ipaddress = JSON.parse(localStorage.getItem('aupris_ipaddress'));
    // } else {
    //   this.smallPcService.getIP().subscribe(data => {
    //     if (data) {
    //       localStorage.setItem('aupris_ipaddress', JSON.stringify(data.ip));
    //       // this.ipaddress = JSON.parse(localStorage.getItem('aupris_ipaddress'));
    //     }
    //   });
    // }
  }
  
  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  getBestellung = (data): Observable<any> => {
    return this.http.post(`${this.baseUrl}/portal/portalBestellung`, data).pipe(catchError(error => this.handleError(error))
    );
  };


  getPortaladdresses = (): Observable<{ success: true, data: UserAddress[], error: any }> => {
    return this.http.get<{ success: true, data: UserAddress[], error: any }>(`${this.baseUrl}/portal/getaddresses`).pipe(
      map(result => {
        if (result.success)
          this.storeAddresses(result.data);
        return result;
      }),
      catchError(error => this.handleError(error))
    );
  };


  updateEmail = (body: any): Observable<any> => {
    return this.http.post<any>(`${this.baseUrl}/portal/updateemail`, body);
  };

  updatePassword(data) {
    if (this.authService.isUserLoggedIn()) {
      return this.http.post(`${this.baseUrl}/portal/updatepassword`, data).pipe(
        catchError(error => this.handleError(error))
      );
    };
  };

  getPortalnumber = (): Observable<{ success: true, data: UserAddress[], error: any }> => {
    return this.http.get<{ success: true, data: UserAddress[], error: any }>(`${this.baseUrl}/portal/getaddresses`).pipe(
      catchError(error => this.handleError(error))
    );
  };


  verifyUpdateEmail = (userDetails: { domainname: string, id: string, token: string }): Observable<any> => {
    let body = Object.assign({}, userDetails);
    return this.http.post<any>(`${this.baseUrl}/portal/verifyupdatedemail`, body).pipe(
      map(res => {
        if (res.success) {
          this.authService.updateEmailToken(res)
        }
        return res
      }),
      catchError(err => throwError(err))
    )
  };

  handleError = (error: HttpErrorResponse) => {
    if ([401, 403].includes(error.status)) {
      // logout user
      this.authService.logoutUser();
      console.log("predictions-8");
      console.log("case 9")
      this.newBigc.displayLoginPage(true);
      return throwError({ success: false, response: 'user forbidden' })
    } else if ([500].includes(error.status)) {
      return throwError({ success: false, response: 'internal server error' })
    } else {
      return throwError({ success: false, response: 'error occured' })
    }
  };

  setHeaders = (): HttpHeaders => {
    let headers: HttpHeaders = new HttpHeaders({});
    headers = new HttpHeaders({
      'authorization': `${this.authService.getAuprisToken()}`,
      'domainname': this.domainname,
      'thirdparty': (!environment.testSite_or_thirdPartySites).toString()
    })
    return headers;
  };



  postData(data) {
    if (this.authService.isUserLoggedIn()) {
      return this.http.post(`${this.baseUrl}/portal/storeData`, data).pipe(
        catchError(error => this.handleError(error))
      );
    }
  };

  storeAddresses = (address: UserAddress[]) => {
    this.userAdresses = address;
    return this.http.post(`${this.baseUrl}/portal/storeData`, address)
  };


  addAddress = (address: any): Observable<any> => {
    return this.http.post(`${this.baseUrl}/portal/addaddresses`, address).pipe(
      catchError(error => this.handleError(error))
    );
  };

  updateAddress = (address: any): Observable<any> => {
    return this.http.post(`${this.baseUrl}/portal/updateaddress`, address).pipe(
      catchError(error => this.handleError(error))
    );
  };

  // deleteAddress = (address: any): Observable<any> => {
  //   let headers = this.setHeaders()
  //   return this.http.post(`${this.baseUrl}/portal/deleteaddress`, address, { headers: headers }).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // };

  deleteAddress = (address: any): Observable<any> => {
    return this.http.post(`${this.baseUrl}/portal/deleteaddress`, address).pipe(
      catchError(error => this.handleError(error))
    );
  };

  setprimary = (data: any): Observable<any> => {
    return this.http.post(`${this.baseUrl}/portal/isprimary`, data).pipe(
      catchError(error => this.handleError(error))
    );
  };

  getLogoImageById(fileId): Promise<any> {
    return this.http.post(`${this.baseUrl}/portal/getimage`, { fileId }).toPromise()
  };

  getGermanPostal(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/allpostalcodes`, body).pipe(
      map((response: any) => {
        let local_verkaufsburos = response;
        if (local_verkaufsburos.success) {
          return local_verkaufsburos.data;
        } else {
          return local_verkaufsburos;
        }
      })
    )
  };

  savePortalOrderInfo(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/portal/addportalorderinfo`, body);
  };

  setAlarm(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/portal/setAlarm`, body);
  };

  getAlarm(): Observable<any> {
    return this.http.get(`${this.baseUrl}/portal/pricealarms`);
  };

  deleteAlarm(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/portal/deletealarm`, body);
  };
  
  deleteprofileImage(fileId): Observable<any> {
    return this.http.post(`${this.baseUrl}/portal/deleteProfileImage`, { fileId });
  };

  public deleteUserDetails = (data: { _id: string, kundenKontoId: string, domain }) => {
    return this.http.post(`${this.baseUrl}/bigpc/removeuserdetails`, data);
  };

  getDashboardData(): Observable<any> {
    return this.http.get(`${this.baseUrl}/portal/getDashboardData`);
  };

  getPrimaryAddress(): Observable<any> {
    return this.http.get(`${this.baseUrl}/portal/getPrimaryAddress`);
  };

  getLatestOrders(): Observable<any> {
    return this.http.get(`${this.baseUrl}/portal/getLatestOrders`);
  };

  getCustomerNewsLetter(): Observable<any> {
    return this.http.get(`${this.baseUrl}/portal/getCustomerAccountDetails`);
  };

  newsLetter(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/portal/newsLetterStatus`, body);
  };


  getTrackingDetails(trackdetails): Observable<any> {
    return this.http.post(`${this.baseUrl}/trackingInfo/getTrackingDetails`, trackdetails)
  };


  verifyUserInTracking(details): Observable<any> {
    return this.http.post(`${this.baseUrl}/trackingInfo/verifyUserByName`, details)
  };

  updateTrackingInfo(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/trackingInfo/saveTrackingInfoChanges`, data)
  };


}
