import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CommonServiceLatest {
  url: string;
  sessionId: any;
  sessionData: any;
  public ipaddress: any;
  constructor(
    private httpClient: HttpClient,
  ) { }

  getDomainName() {
    if (!environment.testSite_or_thirdPartySites) {
      this.url = window.location.href;
      let params = new URLSearchParams(this.url);
      let domainname = decodeURIComponent(params.get("Domainname"));
      localStorage.setItem('domain', domainname)
      return domainname;
    } else {
      return false
    }
  };

  getPostalCodeId() {
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    if (this.sessionId) {
      let currentStoredData = localStorage.getItem(this.sessionId);
      let sessionData = JSON.parse(currentStoredData);
      if (sessionData) {
        let takePlzId = sessionData.postalCode.plzId;
        return takePlzId;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  getDynamicProducId() {
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    if (this.sessionId) {
      let currentStoredData = localStorage.getItem(this.sessionId);
      let sessionData = JSON.parse(currentStoredData);
      if (sessionData) {
        let productId = sessionData?.product?._id;
        return productId;
      } else {
        return '';
      }
    } else {
      return '';
    };
  };

  getQuantity() {
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    if (this.sessionId) {
      let currentStoredData = localStorage.getItem(this.sessionId);
      let sessionData = JSON.parse(currentStoredData);
      if (sessionData) {
        let quantity = sessionData?.quantity;
        return quantity;
      } else {
        return 0;
      };
    } else {
      return 0;
    };
  };

  getTagNumber() {
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    if (localStorage.getItem(this.sessionId)) {
      let currentStoredData = localStorage.getItem(this.sessionId);
      let sessionData = JSON.parse(currentStoredData);
      // console.log(sessionData, 76);
      if (sessionData) {
        let tagNumber = sessionData['tag'] ? sessionData['tag'] : 1;
        // console.log(tagNumber, 76);
        return tagNumber.toString();
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  getIP(): Observable<any> {
    return this.httpClient.get('https://api.ipify.org?format=json')
      .pipe(
        map((response: Response) => {
          let data: any = response.json();
          this.ipaddress = data.ip;
          localStorage.setItem('aupris_ipaddress', JSON.stringify(data.ip));
          return data;
        }), catchError(e => {
          if (e.status === 401) {
            this.ipaddress = 'wrongip';
            return _throw('Unauthorized');
          }
          return _throw('Unknown Error');
        }),
      );
  };

  setSessionStroage() {
    this.url = window.location.href;
  };

  getColors(token) {
    return this.httpClient.post(environment.baseUrl + '/portal/getColors', token)
  };

  tradingSizeValidate(arg_quantity?, arg_tradeSize?) {
    let tradeSize = arg_tradeSize;
    let quantity = arg_quantity;
    let tradePoints = parseInt(quantity) / parseFloat(tradeSize);
    let tradePointRoundedValue = Math.round(tradePoints);
    let qunatityValueBasedTradeValue = tradePointRoundedValue * parseFloat(tradeSize);
    return Math.round(tradePointRoundedValue * parseFloat(tradeSize))
  };
  loaderTime: any = 10000;
  stopLoaderHere() {
    setTimeout(() => {
      return false;
    }, this.loaderTime);
  }
}
