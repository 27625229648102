import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'app/language.service';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.css']
})
export class PaymentErrorComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  popup: any = {};
  paymentError: any =' '
  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<PaymentErrorComponent>,private langService: LanguageService,) { }

  ngOnInit(): void {
    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['preisrechner'] || {};
    this.popup = langData['pop-up'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['preisrechner'] || {};
      this.popup = data['pop-up'] || {};
    });

  }

  onclick() {
    this.dialogRef.close({ close: true });
  }
}
