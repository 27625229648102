<div class="aupris_main scrolltotopprogress" [ngClass]="theme == 'Theme1' ? 'theme1Sec':'theme2Sec'">
  <div class="aurow mainSec" *ngIf="clickedPage=='Page1' &&theme == 'Theme1'">
    <form [formGroup]="priceAlarm">
      <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12" style="padding-left: 0px;padding-right: 0px;">
        <div class="aurow" style="margin-left: -15px;">
          <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12 larger">
            <div class="aurow" style="display: flex;">
              <div class="prodLabel">
                {{languageData['Bestelloptionen'].Var_Produkt || 'Produkt'}}:
              </div>
              <div class="prodInput">
                <select name="product" class="auform-control" formControlName="product" style="cursor:pointer"
                  (ngModelChange)="changeProduct($event)">
                  <option *ngFor="let prod of Produkt" [value]="prod._id">
                    {{languageData['Small-pc']['Var_'+prod.appName]|| prod.appName }}
                  </option>
                </select>

                <div *ngIf="priceAlarm.controls.product?.errors && (priceAlarm.controls.product.touched)||
              mandatoryCheck == true && priceAlarm.controls.product.status == 'INVALID' 
              || (submitted && func.product.errors &&func.product.errors.required)" class="alert-danger">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
              </div>

            </div>
            <div class="aurow alignmobile" style="display: flex;">
              <div class="prodLabel text-right" style="margin-top:30px; padding-right: 2px;">
                {{languageData['Bestelloptionen'].Var_quantity || 'Menge'}}:
              </div>
              <div class="prodInput prodInputresp" style="margin-top:30px">
                <input class="auform-control" formControlName="menge" OnlyNumberwithoutdot required
                  (blur)="checkMinMaxValue()">
                <div *ngIf="priceAlarm.controls.menge?.errors && (priceAlarm.controls.menge.touched)||
              mandatoryCheck == true && priceAlarm.controls.menge.status == 'INVALID'
              || (submitted && func.menge.errors &&func.menge.errors.required)" class="alert-danger">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
                <div *ngIf="showErrorMsg" class="alert-danger">
                  {{errorText}}!
                </div>
              </div>

            </div>
          </div>
          <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12 smalls ">
            <div class="aurow" style="display: flex;">
              <div class="prodLabel">
                {{languageData['Bestelloptionen'].Var_quality || 'Qualitat'}}:
              </div>
              <div class="prodInput1">
                <select class="auform-control" formControlName="quality" style="cursor:pointer">
                  <option [value]=""></option>
                  <option *ngFor="let qualityOptions of qualityArray" [value]="qualityOptions._id">
                    {{qualityOptions.qualityName}}
                  </option>
                </select>
                <div *ngIf="priceAlarm.controls.quality?.errors && (priceAlarm.controls.quality.touched)||
              mandatoryCheck == true && priceAlarm.controls.quality.status == 'INVALID'
              || (submitted && func.quality.errors &&func.quality.errors.required)" class="alert-danger">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
              </div>
            </div>
            <div class="aurow" style="display: flex;">
              <div class="prodLabel" style="margin-top:30px">
                {{languageData['Bestelloptionen'].Var_Lieferort || 'Lieferort'}}:
              </div>
              <div class="prodInput1" style="margin-top:30px">
                <input type="text" class="auform-control" formControlName="pincode" [matAutocomplete]="autoPlz"
                  (keyup)="getPostalCode($event.target.value)">
                <mat-autocomplete #autoPlz="matAutocomplete">
                  <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="selectPlz(option)"
                    [value]="option.country_code +' ' + option.place_name +' '+option.suburb">
                    {{option.place_name}} {{option.country_code}} {{option.suburb}}
                  </mat-option>
                </mat-autocomplete>
                <span class="alert-danger" *ngIf="plzOptions ==''">{{msg}}</span>
                <div *ngIf="priceAlarm.controls.pincode?.errors && (priceAlarm.controls.pincode.touched)||
              mandatoryCheck == true && priceAlarm.controls.pincode.status == 'INVALID'
              || (submitted && func.pincode.errors &&func.pincode.errors.required)" class="alert-danger">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 ">
        <div class="aurow">
          <div class="">
            <div class="pb20 aurow" style="margin-top:5%;">
              <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6">
                <button type="button" class="cnlBtn font20 smalBtn" (click)="abbrechen()"
                  [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                  [style.background]="'var(--primaryColour)'">
                  {{languageData['Address'].Var_Abbrechen || 'Abbrechen'}}
                </button>
              </div>
              <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6">
                <button type="button" class="nxtBtn font20 pull-right smalBtn"
                  [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                  [style.background]="'var(--primaryColour)'" (click)="submitValue()">
                  {{languageData['Adresseingabe'].Var_Weiter || 'Weiter'}}
                </button>
              </div>
            </div>
            <loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="aurow" style="margin-top:50px" *ngIf="theme =='Theme2'">
    <form [formGroup]="priceAlarm">
      <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
        <div class="aurow">
          <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12 lake mobile">
            <div class="mb30 aucol-md-12 aucol-sm-12">
              <div style="position: relative;">
                <label class="posAbs aupLabel">
                  {{languageData['Bestelloptionen'].Var_Produkt || 'Produkt'}}:
                </label>
                <select name="product" class="auform-control forLabel" style="cursor:pointer" formControlName="product"
                  (ngModelChange)="changeProduct($event)">

                  <option *ngFor="let prod of Produkt" [value]="prod._id">
                    {{languageData['Small-pc']['Var_'+prod.appName]|| prod.appName }}
                  </option>
                </select>
                <div *ngIf="priceAlarm.controls.product?.errors && (priceAlarm.controls.product.touched)||
              mandatoryCheck == true && priceAlarm.controls.product.status == 'INVALID'
              || (submitted && func.product.errors &&func.product.errors.required)" class="alert-danger alert-custom">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
              </div>
            </div>
            <div class="mb30 aucol-md-12 aucol-sm-12">
              <div style="position: relative;">
                <label class="posAbs aupLabel">
                  {{languageData['Bestelloptionen'].Var_quantity || 'Menge'}}:
                </label>
                <input class="auform-control forLabel" formControlName="menge" OnlyNumberwithoutdot required
                  (blur)="checkMinMaxValue()">
                <div *ngIf="priceAlarm.controls.menge?.errors && (priceAlarm.controls.menge.touched)||
              mandatoryCheck == true && priceAlarm.controls.menge.status == 'INVALID'
              || (submitted && func.menge.errors &&func.menge.errors.required)" class="alert-danger alert-custom">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
                <div *ngIf="showErrorMsg" class="alert-danger alert-custom">
                  {{errorText}}!
                </div>
              </div>
            </div>
          </div>
          <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12 res mobile">
            <div class="mb30 aucol-md-12 aucol-sm-12 styling">
              <div style="position: relative;">
                <label class="posAbs aupLabel">
                  {{languageData['Bestelloptionen'].Var_quality || 'Qualitat'}}:
                </label>
                <select class="auform-control forLabel" formControlName="quality" style="cursor:pointer">
                  <option [value]=""></option>
                  <option *ngFor="let qualityOptions of qualityArray" [ngValue]="qualityOptions">
                    {{qualityOptions.qualityName}}
                  </option>
                </select>
                <div *ngIf="priceAlarm.controls.quality?.errors && (priceAlarm.controls.quality.touched)||
              mandatoryCheck == true && priceAlarm.controls.quality.status == 'INVALID'
              || (submitted && func.quality.errors &&func.quality.errors.required)" class="alert-danger alert-custom">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
              </div>
            </div>

            <div class="mb30 aucol-md-12 aucol-sm-12 styling">
              <div style="position: relative;">
                <label class="posAbs aupLabel">
                  {{languageData['Bestelloptionen'].Var_Lieferort || 'Lieferort'}}:
                </label>
                <input type="text" class="auform-control forLabel" formControlName="pincode" [matAutocomplete]="autoPlz"
                  (keyup)="getPostalCode($event.target.value)">
                <mat-autocomplete #autoPlz="matAutocomplete">
                  <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="selectPlz(option)"
                    [value]="option.country_code +' ' + option.place_name +' '+option.suburb">
                    {{option.place_name}} {{option.country_code}} {{option.suburb}}
                  </mat-option>
                </mat-autocomplete>
                <div class="alert-danger alert-custom" *ngIf="plzOptions ==''">{{msg}}</div>
                <div *ngIf="priceAlarm.controls.pincode?.errors && (priceAlarm.controls.pincode.touched)||
              mandatoryCheck == true && priceAlarm.controls.pincode.status == 'INVALID'
              || (submitted && func.pincode.errors &&func.pincode.errors.required)" class="alert-danger alert-custom">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="aurow">
          <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 lake alignleft">
            <div class="pb20 " style="margin-top:5%;" [ngClass]="theme == 'Theme2' ? 'resp':'resp2'">
              <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6 styles">
                <button type="button" class="cnlBtn font20 smalBtn" (click)="abbrechen()"
                  [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                  [style.background]="'var(--primaryColour)'">
                  {{languageData['Address'].Var_Abbrechen || 'Abbrechen'}}
                </button>
              </div>
              <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6">
                <div class="style1">
                  <button type="button" class="nxtBtn font20 pull-right smalBtn "
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    [style.background]="'var(--primaryColour)'" (click)="submitValue()">
                    {{languageData['Adresseingabe'].Var_Weiter || 'Weiter'}}
                  </button>
                </div>
              </div>
            </div>
            <loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>

          </div>
        </div>
      </div>
    </form>
  </div>

  <loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>
</div>