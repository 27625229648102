import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-email-fb',
  templateUrl: './email-fb.component.html',
  styleUrls: ['./email-fb.component.css']
})
export class EmailFbComponent implements OnInit {
  @Output() onAddEmail: EventEmitter<any> = new EventEmitter();

  email: any;
  // id: any;
  color: any;
  value:any;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<EmailFbComponent>, private langService: LanguageService) {
    this.color =data.colors;
   };

   public emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  ngOnInit() {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  };

  abbort() {
    this.onAddEmail.emit(this.email);
  };

  submit(email) {
    this.email = email.control.value
    this.onAddEmail.emit(this.email);
  };


}
