<div class="aupris_main" style="margin:auto">
  <div class="au-container">
    <div class="aurow " style="margin-right: 0px;margin-left: 0px;">
      <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12 ">
        <div>
          <div class="card cardd" style="min-height: 120px;">
          <b class="Headerr">
            {{languageData['Edit']?.Var_header_optOutNewsletter || 'Abmeldung vom Newsletter'}}
          </b>
          <div class="text-center">
            <p class="msgg">
                {{languageData['Edit']?.Var_body_optOutNewsletter || 'Durch die Bestätigung der Abmeldung von unserem Newsletter, werden Sie in Zukunft kein Infomaterial bezüglich Sonderaktionen oder Energiepreisen mehr erhalten'}}
            </p>
            <button type="button" class="au-btn loginBtn" (click)="deactivateNewsletter()"
              [ngStyle]="{'background-color': colors?.Grundfarbe}">
              {{languageData['Edit']?.Var_btn_optOutNewsletter ||'Vom Newsletter abmelden'}}</button>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  



