import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { saveAs as importedSaveAs } from "file-saver";
import { BigpcService } from 'app/Services/bigpc.service';

@Component({
  selector: 'app-geburtstag-info',
  templateUrl: './geburtstag-info.component.html',
  styleUrls: ['./geburtstag-info.component.css']
})
export class GeburtstagInfoComponent implements OnInit {

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<GeburtstagInfoComponent>, public bigPCService: BigpcService) { }
  colors: any;

  zahlungsartdata: any;
  hostname: any;

  ngOnInit() {
  }

  onclick() {
    this.dialogRef.close();
  }
  viewsnack2: any;

  downloadFile(data) {
    let object = { type: data };
    this.bigPCService.pdfiledownload(object, this.hostname).subscribe((data123: any) => {
      if (data123.sucess) {
        var pdfdata = data123.data.data.data;
        var byteCharacters = atob((pdfdata));
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        };

        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: undefined
        });
        let newBlob = new Blob([byteArray], { type: 'application/pdf' })
        let bloburl = URL.createObjectURL(newBlob);
        if (navigator.userAgent.indexOf("Firefox") != -1) {
          importedSaveAs(blob, pdfdata);
          return false
        };

        try {
          window.open(bloburl, '_blank');
        } catch (error) {
          importedSaveAs(blob, pdfdata);
        };
      } else {
        this.viewsnack2 = true;
        setTimeout(() => {
          this.viewsnack2 = false;
        }, 4500);
      };
    });

  }

}