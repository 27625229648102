<div class="aurow aucol-md-12 section-container m-auto aupris_main scrolltotopprogress"
  *ngIf="clickedPage == 'page2' && !isPortalUser">
  <span class="headerclass aucol-md-12 aucol-sm-12 aucol-xs-12">
    <b style="padding-left: 6px;">{{languageData['Adresseingabe'].Var_Liefer || 'Liefer- und Rechnungsadresse'}}
    </b>
  </span>

  <div>
    <form [formGroup]="stationFormGroup" *ngIf="stationFormGroup">
      <div class="m-auto mainlie2" formArrayName="stations"
        *ngFor="let stationItem of stationArray().controls; let i = index; trackBy: track">
        <div [formGroupName]="i" class="delivery">
          <div class="pl5 pr5 pb15 mainaddress">
            <ng-container formGroupName="deliveryAddress">

              <div class="addrCard boxShadow">
                <h4 style="margin-bottom: 20px;text-align: left;" *ngIf="stationCount > 1">
                  {{languageData['Adresseingabe'].Var_Lieferadresse || 'Lieferadresse'}} {{i +1}}
                </h4>
                <h4 style="text-align: left;" *ngIf="stationCount == 1">
                  {{languageData['Adresseingabe'].Var_Lieferadresse || 'Lieferadresse'}}
                </h4>

                <div class="aurow">
                  <div class="aucol-lg-2 aucol-md-2 aucol-sm-2 aucol-xs-3 pright0">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Anrede || 'Anrede'}}
                    </label>
                    <select name="gender" class="auform-control anrede" style="color:black;font-size:15px"
                      formControlName="currentTitle"
                      [ngClass]="{'anrededisable':stationArray().controls[i].get('deliveryAddress').value.currentTitle}">

                      <option *ngFor="let g of gender" [value]="g.name">
                        {{languageData['Adresseingabe']['Var_'+g.name] || (g.name)}}
                      </option>
                    </select>
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('currentTitle').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('currentTitle').touched || stationArray().controls[i].get('deliveryAddress').get('currentTitle').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>

                  <div class="aucol-lg-5 aucol-md-5 aucol-sm-5 aucol-xs-5 pright0">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Vorname || 'Vorname'}}*
                    </label>
                    <input type="text" class="aupInput" autocomplete="Vorname" formControlName="firstName" />
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('firstName').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('firstName').touched || stationArray().controls[i].get('deliveryAddress').get('firstName').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>


                  <div class="aucol-lg-5 aucol-md-5 aucol-sm-5 aucol-xs-4 pright1">
                    <label class="posAbs aupLabel">Name*</label>
                    <input type="text" autocomplete="Name" class="aupInput" formControlName="lastName" />
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('lastName').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('lastName').touched || stationArray().controls[i].get('deliveryAddress').get('lastName').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>
                </div>

                <div class="aurow mt17">
                  <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-6 pright0">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Strabe || 'Straße'}}
                    </label>
                    <input type="text" autocomplete="Straße" class="aupInput" formControlName="streetName" />
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('streetName').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('streetName').touched || stationArray().controls[i].get('deliveryAddress').get('streetName').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>
                  <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-6 pright1">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Hausnummer || 'Hausnummer'}}*
                    </label>
                    <input type="text" autocomplete="Hausnummer" class="aupInput" formControlName="houseNo" />
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('houseNo').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('houseNo').touched || stationArray().controls[i].get('deliveryAddress').get('houseNo').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>
                </div>

                <div class="aurow mt17">
                  <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6 pright0">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Firma || 'Firma'}}
                    </label>
                    <input type="text" class="aupInput" autocomplete="companyName" formControlName="companyName" />
                  </div>
                  <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6 pright1">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Addresszusatz || 'Addresszusatz'}}
                    </label>
                    <input type="text" autocomplete="Addresszusatz" class="aupInput" formControlName="addressZusatz" />
                  </div>
                </div>
                <!-- disbale condition -->
                <div class="aurow mt17" *ngIf="stationArray().controls[i]?.get('deliveryAddress')?.value?.plzEnter">
                  <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-4 pright0">
                    <label class="posAbs aupLabel">{{languageData['Adresseingabe'].Var_PLZ || 'PLZ'}}*</label>
                    <input class="auform-control forLabel" disabled="true" formControlName="zipCode" />
                  </div>
                  <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-8 pright1">
                    <label class="posAbs aupLabel">{{languageData['Adresseingabe'].Var_Ort || 'Ort'}}*</label>
                    <input class="auform-control forLabel" disabled="true" formControlName="ort" />
                  </div>
                </div>
                <!-- enable condition -->
                <div class="aurow mt17" *ngIf="!stationArray().controls[i]?.get('deliveryAddress')?.value?.plzEnter">
                  <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-4 pright0">
                    <label class="posAbs aupLabel">{{languageData['Adresseingabe'].Var_PLZ || 'PLZ'}}*</label>
                    <input class="auform-control forLabel" formControlName="zipCode"
                      (ngModelChange)="getPostalCode($event,i)" [matAutocomplete]="autoPlz" />
                    <mat-autocomplete #autoPlz="matAutocomplete">
                      <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="matItemSelect(option)"
                        (click)="selectORT(option, i )" [value]="option.country_code" class="dropdownBottom">
                        {{option.place_name}}<br>{{option.country_code}}<br>{{option.suburb}}
                      </mat-option>
                    </mat-autocomplete>
                    <div *ngIf="postalCodeErrorMsg" class="alert-danger">
                      {{languageData['Adresseingabe'].VarPreisrechnerAdresseingabe2 || 'Die PLZ entspricht keinem gültigen Lieferort.'}}
                    </div>
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('zipCode').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('zipCode').touched || stationArray().controls[i].get('deliveryAddress').get('zipCode').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>

                  <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-8 pright1">
                    <label class="posAbs aupLabel">{{languageData['Adresseingabe'].Var_Ort || 'Ort'}}*</label>
                    <input class="auform-control forLabel" formControlName="ort" />
                    <div
                    *ngIf="stationArray().controls[i].get('deliveryAddress').get('ort').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('ort').touched || stationArray().controls[i].get('deliveryAddress').get('ort').dirty )"
                    class="alert-danger">
                    {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                  </div>
                  </div>
               
                </div>



                <div class="aurow mt17 mb10">
                  <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                    <label class="posAbs aupLabel">
                      {{languageData['Bestelloptionen'].Var_Telefon || 'Telefon'}}*</label>
                    <input type="text" autocomplete="phoneNumber" class="aupInput" formControlName="phoneNumber" />
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('phoneNumber').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('phoneNumber').touched || stationArray().controls[i].get('deliveryAddress').get('phoneNumber').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>
                </div>

                <div class="aurow mt17 mb10" *ngIf="dob && !dobRechnungsadresse">
                  <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                    <div style="display:flex;">
                      <label class="posAbs aupLabel">
                        {{languageData['Adresseingabe'].Var_date || 'Geburtstag(DD.MM.YYYY)'}}*
                      </label>

                      <input style="width:80%" class="textbox-n" matInput [matDatepicker]="picker"
                        (click)="picker.open()" focus="picker.open()" [max]="today" class="auform-control"
                        (dateChange)="onDateChange($event.target.value, i, 'deliveryAddress')"
                        formControlName="dateOfBirth">

                      <mat-datepicker-toggle matSuffix style="position: relative;bottom: 2px !important;"
                        [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker startView="multi-year" [startAt]="startDate"
                        [style.color]="'var(--primaryColour)'" style="position: relative;bottom: 1px !important;">
                      </mat-datepicker>
                      <span class="au-glyphicon au-glyphicon-info-sign" (click)="geburtstagPopup()"
                        style="cursor:pointer;margin-top: 10px;margin-left: 10px;" *ngIf="dobText">
                        <span [innerHTML]="dobsvg" *ngIf="dobsvg"
                          style="position: relative;bottom: 2px !important;"></span>
                        <img *ngIf="!dobsvg" [src]="'assets/svgs/dobsvg.svg' | assetsUrl">
                      </span>
                    </div>
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('dateOfBirth').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('dateOfBirth').touched || stationArray().controls[i].get('deliveryAddress').get('dateOfBirth').dirty )"
                      class="alert-danger" style="margin-top:-7px; width:80%">
                      {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                    </div>
                  </div>
                </div>
                <!-- *ngIf="i == 0" -->
                <div class="aurow mt17 mb10">
                  <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Email || 'Email'}}*
                    </label>
                    <input type="email" class="aupInput" autocomplete="email" formControlName="eMail" />
                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('eMail').errors && ( stationArray().controls[i].get('deliveryAddress').get('eMail').touched || stationArray().controls[i].get('deliveryAddress').get('eMail').dirty )">
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('eMail').errors?.required">
                        {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                      </div>
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('eMail').errors && stationArray().controls[i].get('deliveryAddress').get('eMail').touched">
                        {{langData['Labels']['Var_valid'] || 'Geben Sie eine gültige E-Mail-Adresse ein'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- *ngIf="i == 0" -->
                <div class="aurow mt17 mb10">
                  <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Email_wiederholen || 'Email wiederholen'}}*
                    </label>
                    <input type="email" autocomplete="email" class="aupInput" formControlName="emailRepeat" />

                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('emailRepeat').errors && ( stationArray().controls[i].get('deliveryAddress').get('emailRepeat').touched || stationArray().controls[i].get('deliveryAddress').get('emailRepeat').dirty )">
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('emailRepeat').errors?.required">
                        {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                      </div>
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('emailRepeat').errors?.notMatch">
                        {{languageData['Adresseingabe'].Var_matchemail|| 'Emails stimmen nicht überein'}}
                      </div>
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('eMail').errors && stationArray().controls[i].get('deliveryAddress').get('eMail').touched">
                        Geben Sie eine gültige E-Mail-Adresse ein
                      </div>
                    </div>
                  </div>
                </div>

                <div class="aurow mt17" *ngIf="i == 0">
                  <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-8 pright0  hidden-xs">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Kundennummer || 'Kundennummer(wennzur Hand)'}}
                    </label>
                    <input autocomplete="Kundennummer" class="auform-control forLabel" formControlName="kundenNummer" />
                  </div>

                  <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6 pright0 hidden-sm hidden-md hidden-lg ">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Kundennummer || 'Kundennummer(wenn zur Hand)' |
                      truncate:[15,'...']}}
                    </label>
                    <input autocomplete="Kundennummer" class="auform-control forLabel" formControlName="kundenNummer" />
                  </div>


                  <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6" *ngIf="stationCount == 1">
                    <label class="posAbs aupLabel">
                      {{languageData['Adresseingabe'].Var_Menge || 'Menge'}}*
                    </label>
                    <input class="auform-control forLabel wid_aupris_unique" autocomplete="quantity"
                      [attr.disabled]="true" formControlName="quantity" OnlyNumberwithoutdot />
                  </div>


                  <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6" *ngIf="stationCount  >=  2">
                    <label class="posAbs aupLabel">{{languageData['Adresseingabe'].Var_Menge || 'Menge'}}*</label>
                    <input autocomplete="quantity" class="auform-control forLabel wid_aupris_unique forLabel"
                      [ngClass]="{disabled:mengeno == true}" formControlName="quantity" OnlyNumberwithoutdot
                      (blur)="onBlur();checkMinQuantity(i)" />

                    <span>
                      <p style="width:10%; display:inline" class="text-left">{{label}}</p>
                    </span>

                    <span *ngIf="RemainingMenge == 0" class="text-right">
                      <p style="width:40%; display:inline;margin-left:10px">
                       {{ RemainingMenge | replaced : languageVar }} 
                      </p>
                      /
                     {{ selectedQuantity | replaced : languageVar }}
                    </span>

                    <span *ngIf="RemainingMenge != 0" class="text-right">
                      <p style="width:40%; display:inline;color:red;margin-left:10px">
                        {{ RemainingMenge | replaced : languageVar }} 
                      </p>
                      /
                      {{ selectedQuantity | replaced : languageVar }}
                    </span>
                    
                    <span *ngIf="mengecheck" class="au-glyphicon au-glyphicon-ok" style="color:green">
                      <span [innerHTML]="tickMark" *ngIf="tickMark"></span>
                      <img *ngIf="!tickMark" [src]="'assets/svgs/tickMark.svg' | assetsUrl">
                    </span>


                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors && ( stationArray().controls[i].get('deliveryAddress').get('quantity').touched || stationArray().controls[i].get('deliveryAddress').get('quantity').dirty )"
                      class="alert-danger" style="width:100%">
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                        {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                      </div>
                    </div>

                    <div class="alert-danger" style="width:100%"
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').touched && stationArray().controls[i].get('deliveryAddress').value.checkQuantity && !stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                      The minimum order quantity is {{ productInfoForWebPC.minAmount}}. Please adjust your
                      values ​​in the selection!
                    </div>

                  </div>
                </div>

                <div class="aurow mt17 mb10" *ngIf="i > 0">
                  <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12" *ngIf="stationCount  >=  2">

                    <label class="posAbs aupLabel">{{languageData['Adresseingabe'].Var_Menge || 'Menge'}}*</label>
                    <input autocomplete="quantity" class="auform-control forLabel wid_aupris_unique forLabel"
                      [ngClass]="{disabled:mengeno == true}" formControlName="quantity" OnlyNumberwithoutdot
                      (blur)="onBlur();checkMinQuantity(i)" />

                    <span>
                      <p style="width:10%; display:inline" class="text-left">{{label}}</p>
                    </span>

                    <span *ngIf="RemainingMenge == 0" class="text-right">
                      <p style="width:40%; display:inline;margin-left:10px">
                      {{RemainingMenge|replaced : languageVar}}
                      </p>
                      /
                      {{selectedQuantity}}
                    </span>

                    <span *ngIf="RemainingMenge != 0" class="text-right">
                      <p style="width:40%; display:inline;color:red;margin-left:10px">
                        {{ RemainingMenge | replaced : languageVar }}
                      </p>
                      /
                      {{ selectedQuantity | replaced : languageVar }}
                    </span>
                    
                    <span *ngIf="mengecheck" class="au-glyphicon au-glyphicon-ok" style="color:green">
                      <span [innerHTML]="tickMark" *ngIf="tickMark"></span>
                      <img *ngIf="!tickMark" [src]="'assets/svgs/tickMark.svg' | assetsUrl">
                    </span>

                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors && ( stationArray().controls[i].get('deliveryAddress').get('quantity').touched || stationArray().controls[i].get('deliveryAddress').get('quantity').dirty )"
                      class="alert-danger" style="width:100%">
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                        {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                      </div>
                    </div>

                    <div class="alert-danger" style="width:100%"
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').touched && stationArray().controls[i].get('deliveryAddress').value.checkQuantity && !stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                      The minimum order quantity is {{ productInfoForWebPC.minAmount}}. Please adjust your
                      values ​​in the selection!
                    </div>

                  </div>
                </div>

                <div class="aurow" [ngClass]="stationCount >= 2 ? 'mt0':'mt17'">
                  <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                    <textarea class="form-horizontal auform-control" style="color:black;font-size:15px"
                      placeholder="{{languageData['Adresseingabe'].Var_Zusatzliche_Lieferhinweise_an_uns || 'comments Lieferhinweise an uns'}}"
                      formControlName="comments">
                  </textarea>
                    <!-- <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('comments').errors?.required && ( stationArray().controls[i].get('deliveryAddress').get('comments').touched || stationArray().controls[i].get('deliveryAddress').get('comments').dirty )"
                      class="alert-danger">
                      {{languageData['Adresseingabe'].Var_maxlengthexceed || 'Maximale Länge überschritten!'}}
                    </div> -->
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="i == 0">
            <div class="pl5 pr5 biladress">
              <ng-container formGroupName="billingAddress">
                <div class="addrCard boxShadow_billaddr" [ngClass]="{'cardResp': (!billingAddressEnable == true)}">
                  <div class="edge" style="display: flex; align-items: center;">
                    <input *ngIf="stationCount == 1" autocomplete="foo1" type="checkbox"
                      [checked]="billingAddressEnable" class="rechCheckbox"
                      (change)="rechnungCheckBox($event.target.checked)" style="margin: 0;cursor: pointer;">

                    <h4 style="margin-left: 10px" *ngIf="stationCount == 1">
                      {{languageData['Adresseingabe'].Var_Abweichende || 'Abweichende Rechnungsadresse'}}:
                    </h4>

                    <h4 style="margin-top: 0px;" *ngIf="stationCount > 1">
                      {{languageData['Adresseingabe'].Var_Rechnungsart || 'Rechnungsart'}}:
                      <span class="tooltipppp" *ngIf="stationCount > 1">
                        <span class="au-glyphicon au-glyphicon-info-sign"
                          style="margin-right: 8px; width: 16px;height: 16px;margin-bottom: 12px;">
                          <span [innerHTML]="infoSign" *ngIf="infoSign"></span>
                          <img *ngIf="!infoSign" [src]="'assets/infoSign.svg' | assetsUrl">
                        </span>
                        <span class="tooltiptextttt">
                          <pre style="height: 223px;overflow: hidden;">
                              <span style="position: relative;bottom: 20px;font-weight: bold;white-space: pre-line;">
                                {{languageData?.Adresseingabe['Var_Einzelrechnung'] || 'Einzelrechnungt'}}:
                              </span>
                              <span style="position: relative;bottom: 52px;white-space: pre-line;">
                                {{languageData?.Adresseingabe['Var_Einzelrechnung_info'] || 'Alle Lieferadressen erhalten eine Einzelrechnung. Eine gesonderte Rechnungsadresse kann nicht angegeben werden.'}}
                              </span><br>
                              <div style="position: relative;bottom: 120px;font-weight: bold;white-space: pre-line;">
                                {{languageData?.Adresseingabe['Var_Sammelrechnung'] || 'Sammelrechnung'}}:
                              </div>
                               <span style="position: relative;bottom: 150px;white-space: pre-line;">
                                {{languageData?.Adresseingabe['Var_Sammelrechnung_info'] || 'Die Lieferadressen erhalten keine Einzelrechnungen. Geben Sie bitte eine gesonderte Rechnungsadresse an, an die die Sammelrechnung gestellt wird.'}}
                               </span>
                            </pre>
                        </span>
                      </span>
                    </h4>

                    <div class="auform-group field btnadjustment" *ngIf="stationCount > '1'">
                      <select (change)="billingAdressToggle($event.target)" class="auform-control forLabel">
                        <option [selected]="selectedBiilingOption == 'Einzelrechnungent'" [value]="'Einzelrechnungent'">
                          {{languageData['Adresseingabe'].Var_Einzelrechnung || 'Einzelrechnungent'}}</option>
                        <option [selected]="selectedBiilingOption == 'Sammelrechnung'" [value]="'Sammelrechnung'">
                          {{languageData['Adresseingabe'].Var_Sammelrechnung || 'Sammelrechnung'}}</option>
                      </select>
                    </div>

                  </div>

                  <div *ngIf="billingAddressEnable">
                    <div class="aurow">
                      <div class="aucol-lg-2 aucol-md-2 aucol-sm-2 aucol-xs-3 pright0">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Anrede || 'Anrede'}}
                        </label>
                        <select name="gender" class="auform-control anrede" style="color:black;font-size:15px"
                          formControlName="currentTitle"
                          [ngClass]="{'anrededisable':stationArray().controls[i].get('billingAddress').value.currentTitle}">

                          <option *ngFor="let g of gender" [value]="g.name">
                            {{languageData['Adresseingabe']['Var_'+g.name] || (g.name)}}
                          </option>
                        </select>
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('currentTitle').errors?.required && ( stationArray().controls[i].get('billingAddress').get('currentTitle').touched || stationArray().controls[i].get('billingAddress').get('currentTitle').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>

                      <div class="aucol-lg-5 aucol-md-5 aucol-sm-5 aucol-xs-5 pright0">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Vorname || 'Vorname'}}*
                        </label>
                        <input type="text" class="aupInput" autocomplete="Vorname" formControlName="firstName" />
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('firstName').errors?.required && ( stationArray().controls[i].get('billingAddress').get('firstName').touched || stationArray().controls[i].get('billingAddress').get('firstName').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>

                      </div>

                      <div class="aucol-lg-5 aucol-md-5 aucol-sm-5 aucol-xs-4 pright1">
                        <label class="posAbs aupLabel">Name*</label>
                        <input type="text" autocomplete="Name" class="aupInput" formControlName="lastName" />
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('lastName').errors?.required && ( stationArray().controls[i].get('billingAddress').get('lastName').touched || stationArray().controls[i].get('billingAddress').get('lastName').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>

                      </div>
                    </div>

                    <div class="aurow mt17">
                      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-6 pright0">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Strabe || 'Straße'}}
                        </label>
                        <input type="text" autocomplete="Straße" class="aupInput" formControlName="streetName" />
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('streetName').errors?.required && ( stationArray().controls[i].get('billingAddress').get('streetName').touched || stationArray().controls[i].get('billingAddress').get('streetName').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>

                      <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-6  pright1">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Hausnummer || 'Hausnummer'}}*
                        </label>
                        <input type="text" autocomplete="Hausnummer" class="aupInput" formControlName="houseNo" />
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('houseNo').errors?.required && ( stationArray().controls[i].get('billingAddress').get('houseNo').touched || stationArray().controls[i].get('billingAddress').get('houseNo').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>
                    </div>

                    <div class="aurow mt17">
                      <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6 pright0">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Firma || 'Firma'}}
                        </label>
                        <input type="text" class="aupInput" autocomplete="companyName" formControlName="companyName" />
                      </div>

                      <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6  pright1">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Addresszusatz || 'Addresszusatz'}}
                        </label>
                        <input type="text" autocomplete="Addresszusatz" class="aupInput"
                          formControlName="addressZusatz" />
                      </div>
                    </div>

                    <div class="aurow mt17">
                      <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-4 pright0">
                        <label class="posAbs aupLabel">PLZ*</label>
                        <input type="text" class="auform-control forLabel" formControlName="zipCode" />
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('zipCode').errors?.required && ( stationArray().controls[i].get('billingAddress').get('zipCode').touched || stationArray().controls[i].get('billingAddress').get('zipCode').dirty)"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>
                      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-8  pright1">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Ort || 'Ort'}}*
                        </label>
                        <input type="text" class="auform-control forLabel" formControlName="ort" />

                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('ort').errors?.required && ( stationArray().controls[i].get('billingAddress').get('ort').touched || stationArray().controls[i].get('billingAddress').get('ort').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>
                    </div>

                    <div class="aurow mt17">
                      <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 ">
                        <label class="posAbs aupLabel">
                          {{languageData['Bestelloptionen'].Var_Telefon || 'Telefon'}}*
                        </label>
                        <input type="text" autocomplete="phoneNumber" class="aupInput" formControlName="phoneNumber" />
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('phoneNumber').errors?.required && ( stationArray().controls[i].get('billingAddress').get('phoneNumber').touched || stationArray().controls[i].get('billingAddress').get('phoneNumber').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>
                    </div>

                    <div class="aurow mt17" *ngIf="dob && dobRechnungsadresse" style="margin-bottom: -6px;">
                      <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                        <div style="display:flex">
                          <label class="posAbs aupLabel">
                            {{languageData['Adresseingabe'].Var_date || 'Geburtstag(DD.MM.YYYY)'}}*
                          </label>
                          <input style="width:80%" class="textbox-n" matInput [matDatepicker]="picker"
                            (click)="picker.open()" focus="picker.open()" [max]="today" class="auform-control"
                            (dateChange)="onDateChange($event.target.value, i, 'billingAddress')"
                            formControlName="dateOfBirth">
                          <mat-datepicker-toggle matSuffix style="position: relative;bottom: 2px !important;"
                            [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker startView="multi-year" [startAt]="startDate" [style.color]="green">
                          </mat-datepicker>
                          <span class="au-glyphicon au-glyphicon-info-sign" style="position: relative; bottom: 2px;"
                            (click)="geburtstagPopup()" style="cursor:pointer;margin-top: 10px;margin-left: 10px;" *ngIf="dobText">
                            <span [innerHTML]="dobsvg" *ngIf="dobsvg"
                              style="position: relative;bottom: 2px !important;"></span>
                            <img *ngIf="!dobsvg" [src]="'assets/svgs/dobsvg.svg' | assetsUrl">
                          </span>
                        </div>
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('dateOfBirth').errors?.required && ( stationArray().controls[i].get('billingAddress').get('dateOfBirth').touched || stationArray().controls[i].get('billingAddress').get('dateOfBirth').dirty )"
                          class="alert-danger" style="margin-top:-7px; width:80%;">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>
                    </div>

                    <div class="aurow mt12">
                      <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Email || 'Email'}}*
                        </label>
                        <input type="email" class="aupInput" autocomplete="email" formControlName="eMail" />
                        <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('eMail').errors?.required && ( stationArray().controls[i].get('billingAddress').get('eMail').touched || stationArray().controls[i].get('billingAddress').get('eMail').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                        </div>
                      </div>
                    </div>

                    <div class="aurow mt17 hidden-xs">
                      <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12">
                        <label class="posAbs aupLabel">
                          {{languageData['Adresseingabe'].Var_Kundennummer || 'Kundennummer(wenn zurHand)' }}
                        </label>
                        <input autocomplete="Kundennummer" class="auform-control forLabel"
                          formControlName="kundenNummer" />
                      </div>
                      <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6 "></div>
                    </div>

                    <div class="aurow mt17 hidden-sm hidden-md  hidden-lg">
                      <div class="aucol-lg-6 aucol-md-6 aucol-sm-12 aucol-xs-12">
                        <label class="posAbs aupLabel">
                          {{ languageData['Adresseingabe'].Var_Kundennummer ||'Kundennummer(wenn zurHand)' |
                          truncate:[15,'...'] }}
                        </label>
                        <input autocomplete="Kundennummer" class="auform-control forLabel"
                          formControlName="kundenNummer" />
                      </div>
                      <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6"></div>
                    </div>
                    <div class="aurow mt17">
                      <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                        <textarea class="form-horizontal auform-control"
                          placeholder="{{languageData['Bestelloptionen'].Var_Zusatzliche_Lieferhinweise_an_uns || 'comments Lieferhinweise an uns'}}"
                          formControlName="comments">
                       </textarea>
                        <!-- <div
                          *ngIf="stationArray().controls[i].get('billingAddress').get('comments').errors?.required && ( stationArray().controls[i].get('billingAddress').get('comments').touched || stationArray().controls[i].get('billingAddress').get('comments').dirty )"
                          class="alert-danger">
                          {{languageData['Adresseingabe'].Var_maxlengthexceed || 'Maximale Länge überschritten!'}}
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>


  <div class="aucol-md-12 btn-p">
    <div class="aurow mr0 ml0 pl10 pr10 pb20">
      <button type="button" class="cnlBtn font20 smalBtn flt"
        [ngStyle]="{background:'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
        [style.background]="'var(--primaryColour)'" (click)="backButton()">{{languageData['Adresseingabe'].Var_Zuruck ||
        'Zurück'}}
      </button>
      <!-- submit button  appear at guest user second stage -->
      <button type="button" class="nxtBtn font20 pull-right smalBtn" cyContinueToThird="cyContinueToThird"
        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
        [style.background]="'var(--primaryColour)'" (click)="weiter();">{{languageData['Adresseingabe'].Var_Weiter ||
        'Weiter'}}
      </button>
    </div>
  </div>
  <loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>
</div>


<div class="aurow headerRow white aupris_main scrolltotopprogress"
  *ngIf="clickedPage == 'page2' && isPortalUser && !addNewAddress">

  <div class="aurow mr0 ml0 section-containers">

    <form [formGroup]="stationFormGroup" *ngIf="stationFormGroup">
      <div class="mainlie">
        <div formArrayName="stations" *ngFor="let stationItem of stationArray().controls; let i = index; trackBy: track"
          class="lie1">

          <div class="laddrCards" [formGroupName]="i">
            <div class="card posRel p15" formGroupName="deliveryAddress"
              style="color: #707070;border: 1px solid transparent;">


              <ng-container *ngIf="i == 0">
                <div class="pb20">
                  <span class="laddrHeading">{{languageData['Adresseingabe'].Var_pri_Lieferadresse || 'Lieferadresse'}}
                  </span>
                  <button class="au-btn btn-default btnalignment" [ngClass]="{btnliefer: stationCount == 1}"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    (click)="openDialog('primary', i)"
                    *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.lastName != ''">
                    {{languageData['Adresseingabe'].Var_andern || 'Ändern'}}
                  </button>
                  <button class="au-btn btn-default btnalignment" [ngClass]="{btnliefer: stationCount == 1}"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    (click)="openDialog('primary', i)"
                    *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.lastName == ''">
                    {{languageData['Adresseingabe'].Var_select || 'Auswählen'}}
                  </button>
                </div>
              </ng-container>

              <ng-container *ngIf="i > 0">
                <div class="pb20">
                  <span class="laddrHeading">
                    {{languageData['Adresseingabe'].Var_pri_Lieferadresse || 'Lieferadresse'}} {{ i +1 }}
                  </span>
                  <button class="au-btn btn-default btnalignment" [ngClass]="{btnliefer: stationCount == 1}"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    (click)="openDialog('secondary', i)"
                    *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.lastName != ''">
                    {{languageData['Adresseingabe'].Var_andern || 'Ändern'}}
                  </button>
                  <button class="au-btn btn-default btnalignment" [ngClass]="{btnliefer: stationCount == 1}"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    (click)="openDialog('secondary', i)"
                    *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.lastName == ''">
                    {{languageData['Adresseingabe'].Var_select || 'Auswählen'}}
                  </button>
                </div>
              </ng-container>


              <div class="p10">
                <div>
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.firstName }}
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.lastName }}
                </div>

                <div *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.companyName">
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.companyName }}
                </div>

                <div *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.addressZusatz">
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.addressZusatz }}
                </div>

                <div>
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.streetName }}
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.houseNo }}
                </div>

                <div>
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.zipCode }}
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.ort }}
                </div>
              </div>

              <div class="p10 pt0">
                {{ stationArray().controls[i]?.get('deliveryAddress').value.phoneNumber }}<br>
                {{ stationArray().controls[i]?.get('deliveryAddress').value.eMail }}
              </div>

              <div *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.comments" style="padding: 10px;">
                <div style=" border-radius: 6px; width: 100%;margin: auto; background: none !important; line-height: 20px;height: 65px;
                          border: 1px solid;padding: 5px;">
                  {{ stationArray().controls[i]?.get('deliveryAddress').value.comments }}
                </div>
              </div>

              <div class="p10 pt0">
                <ng-container *ngIf="stationCount == 1">
                  <div>
                    <span style="margin-left:0px">{{languageData['Adresseingabe'].Var_Menge || 'Menge'}}* : </span>
                    <input type="number" class="mengeInput" autocomplete="quantity" [attr.disabled]="true"
                      formControlName="quantity" OnlyNumberwithoutdot />
                  </div>
                </ng-container>


                <div [hidden]="true">
                  <!-- <input type="text" formControlName="kundenNummer"> -->
                  <input type="text">
                </div>

                <ng-container *ngIf="stationCount >= 2">
                  <div class="pt0">
                    <div>
                     <span style="width: 15px;"> {{languageData['Adresseingabe'].Var_Menge || 'Menge'}}* :</span>
                      <div style="    display: inline;
                      position: relative;">
                        <input autocomplete="quantity" class="mengeInput" [ngClass]="{disabled:mengeno == true}"
                        formControlName="quantity" OnlyNumberwithoutdot (blur)="onBlur(); checkMinQuantity(i)"  />
                        {{label}}
                        <div style="position: absolute;left:0px;width:97%">
                          <div
                            *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors && (stationArray().controls[i].get('deliveryAddress').get('quantity').touched || stationArray().controls[i].get('deliveryAddress').get('quantity').dirty )"
                            class="alert-danger" >
                               <div class="alert-danger"
                          *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                          {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                          </div>
                        </div>
  
                          <div class="alert-danger" style="width:100%"
                            *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').touched && stationArray().controls[i].get('deliveryAddress').value.checkQuantity && !stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                            The minimum order quantity is {{ productInfoForWebPC.minAmount}}. Please adjust your
                            values ​​in the selection!
                          </div>
                        </div>
                      </div>

                      <span class="fontSmaller" *ngIf="RemainingMenge == 0" class="text-right" style="float: right;
                      margin-right: 0px;margin-top: 5px;">
                        {{ RemainingMenge | replaced : languageVar}} / {{ selectedQuantity | replaced : languageVar}}
                      </span>

                      <span class="fontSmaller" *ngIf="RemainingMenge != 0">
                        <span style=" color: red;">
                          {{ RemainingMenge | replaced : languageVar }} / {{ selectedQuantity | replaced : languageVar }}
                        </span>
                      </span>
                      <span *ngIf="mengecheck" class="au-glyphicon au-glyphicon-ok" style="color:green">
                        <span [innerHTML]="tickMark" *ngIf="tickMark"></span>
                        <img *ngIf="!tickMark" [src]="'assets/svgs/tickMark.svg' | assetsUrl">
                      </span>
                     
                    </div>
                   
<!--                    
                    <span>
                      {{languageData['Adresseingabe'].Var_Menge || 'Menge'}}* :
                    </span>
                   
                    <input autocomplete="quantity" class="mengeInput" [ngClass]="{disabled:mengeno == true}"
                      formControlName="quantity" OnlyNumberwithoutdot (blur)="onBlur(); checkMinQuantity(i)" />
                    <span>
                      {{label}}
                    </span>

                    <span class="fontSmaller" *ngIf="RemainingMenge == 0" class="text-right" style="float: right;
                            margin-right: 0px;margin-top: 5px;">
                      {{ RemainingMenge | replaced}} / {{ selectedQuantity | replaced}}
                    </span>

                    <span class="fontSmaller" *ngIf="RemainingMenge != 0">
                      <span style=" color: red;">
                        {{ RemainingMenge | replaced }} / {{ selectedQuantity | replaced }}
                      </span>
                    </span>

                    <span *ngIf="mengecheck" class="au-glyphicon au-glyphicon-ok" style="color:green">
                      <span [innerHTML]="tickMark" *ngIf="tickMark"></span>
                      <img *ngIf="!tickMark" [src]="'assets/svgs/tickMark.svg' | assetsUrl">
                    </span>

                    <div
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors && (stationArray().controls[i].get('deliveryAddress').get('quantity').touched || stationArray().controls[i].get('deliveryAddress').get('quantity').dirty )"
                      class="alert-danger" style="width:50%">
                      <div class="alert-danger"
                        *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                        {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                      </div>
                    </div>


                    <div class="alert-danger" style="width:100%"
                      *ngIf="stationArray().controls[i].get('deliveryAddress').get('quantity').touched && stationArray().controls[i].get('deliveryAddress').value.checkQuantity && !stationArray().controls[i].get('deliveryAddress').get('quantity').errors?.required">
                      The minimum order quantity is {{ productInfoForWebPC.minAmount}}. Please adjust your
                      values ​​in the selection!
                    </div> -->

                  </div>
                </ng-container>
              </div>


              <div class="text-right" style="height: 50px;" *ngIf="i == 0">
                <button type="button" class="editBtn"
                  *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.zipCode != '' "
                  (click)="editAddressMethod(stationArray().controls[i]?.get('deliveryAddress').value ,'primary', i)">
                  {{ languageData['Adresseingabe'].Var_Edit|| 'Bearbeiten' }}
                </button>
              </div>


              <div class="text-right" style="height: 50px;" *ngIf="i > 0">
                <button type="button" class="editBtn"
                  *ngIf="stationArray().controls[i]?.get('deliveryAddress').value.zipCode != '' "
                  (click)="editAddressMethod(stationArray().controls[i]?.get('deliveryAddress').value ,'secondary', i)">
                  {{ languageData['Adresseingabe'].Var_Edit|| 'Bearbeiten' }}
                </button>
              </div>
            </div>
          </div>
          <div class="laddrCards">
            <ng-container *ngIf="i == 0">
              <div class="card posRel p15" formGroupName="billingAddress"
                style="color: #707070; border: 1px solid transparent;">
                <div style="padding-bottom: 12px;">
                  <span class="laddrHeading">
                    <label>
                      <input type="checkbox" *ngIf="stationCount == '1'" [checked]="billingAddressEnable"
                        (change)="rechnungCheckBox($event.target);">
                    </label>
                    {{languageData['Adresseingabe'].Var_pri_Rechnungsadresse || 'Rechnungsadresse'}}
                  </span>

                  <button class="au-btn btn-default btnalignment"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    [disabled]="!((billingAddressEnable || stationCount > '1' ) && billingAddressEnable) "
                    (click)="openDialog('billing', i)"
                    *ngIf="stationArray().controls[i]?.get('billingAddress').value.addressZusatz != ''">
                    {{languageData['Adresseingabe'].Var_andern|| 'Ändern'}}
                  </button>

                  <button class="au-btn btn-default btnalignment"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    [disabled]="!((billingAddressEnable || stationCount > '1' ) && billingAddressEnable) "
                    (click)="openDialog('billing', i)"
                    *ngIf="stationArray().controls[i]?.get('billingAddress').value.addressZusatz == ''">
                    {{languageData['Adresseingabe'].Var_select || 'Auswählen'}}
                  </button>

                </div>

                <div class="tooltipppp infosmallscreen" *ngIf="stationCount > '1'">
                  <span class="au-glyphicon au-glyphicon-info-sign"
                    style="margin-right: 8px; width: 16px;height: 16px;margin-bottom: 12px;">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 438.533 438.533"
                      style="enable-background:new 0 0 438.533 438.533;" [ngStyle]="{fill:'var(--primaryColour)'}"
                      xml:space="preserve">
                      <g>
                        <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
          c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
          c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
          s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
          C438.533,179.485,428.732,142.795,409.133,109.203z M182.727,54.813c0-2.666,0.855-4.853,2.57-6.565
          c1.712-1.711,3.903-2.57,6.567-2.57h54.82c2.662,0,4.853,0.859,6.561,2.57c1.711,1.712,2.573,3.899,2.573,6.565v45.682
          c0,2.664-0.862,4.854-2.573,6.564c-1.708,1.712-3.898,2.568-6.561,2.568h-54.82c-2.664,0-4.854-0.856-6.567-2.568
          c-1.715-1.709-2.57-3.9-2.57-6.564V54.813z M292.359,356.309c0,2.662-0.863,4.853-2.57,6.561c-1.704,1.714-3.895,2.57-6.563,2.57
          H155.317c-2.667,0-4.854-0.856-6.567-2.57c-1.712-1.708-2.568-3.898-2.568-6.564v-45.682c0-2.67,0.856-4.853,2.568-6.567
          c1.713-1.708,3.903-2.57,6.567-2.57h27.41v-91.358h-27.41c-2.667,0-4.853-0.855-6.567-2.568c-1.712-1.711-2.568-3.901-2.568-6.567
          v-45.679c0-2.666,0.856-4.853,2.568-6.567c1.715-1.713,3.905-2.568,6.567-2.568h91.367c2.662,0,4.853,0.855,6.561,2.568
          c1.711,1.714,2.573,3.901,2.573,6.567v146.179h27.401c2.669,0,4.859,0.855,6.57,2.566c1.704,1.712,2.566,3.901,2.566,6.567v45.683
          H292.359z" />
                      </g>
                    </svg>
                  </span>
                  <span class="tooltiptextttt">
                    <pre style="height: 223px;overflow: hidden;">
                      <span style="position: relative;bottom: 20px;font-weight: bold;white-space: pre-line;">
                        {{languageData?.Adresseingabe['Var_Einzelrechnung'] || 'Einzelrechnungt'}}:
                      </span>
                      <span style="position: relative;bottom: 52px;white-space: pre-line;">
                        {{languageData?.Adresseingabe['Var_Einzelrechnung_info'] || 'Alle Lieferadressen erhalten eine Einzelrechnung. Eine gesonderte Rechnungsadresse kann nicht angegeben werden.'}}
                      </span><br>
                      <div style="position: relative;bottom: 120px;font-weight: bold;white-space: pre-line;">
                        {{languageData?.Adresseingabe['Var_Sammelrechnung'] || 'Sammelrechnung'}}:
                      </div>
                       <span style="position: relative;bottom: 150px;white-space: pre-line;">
                        {{languageData?.Adresseingabe['Var_Sammelrechnung_info'] || 'Die Lieferadressen erhalten keine Einzelrechnungen. Geben Sie bitte eine gesonderte Rechnungsadresse an, an die die Sammelrechnung gestellt wird.'}}
                       </span>
                    </pre>
                  </span>
                </div>

                <div class="auform-group field" *ngIf="stationCount > '1'">
                  <select (change)="billingAdressToggle($event.target)" class="auform-control forLabel">
                    <option [selected]="selectedOptn == 'Einzelrechnungent'" [value]="'Einzelrechnungent'">
                      {{languageData['Adresseingabe'].Var_Einzelrechnung || 'Einzelrechnungent'}}</option>
                    <option [selected]="selectedOptn == 'Sammelrechnung'" [value]="'Sammelrechnung'">
                      {{languageData['Adresseingabe'].Var_Sammelrechnung || 'Sammelrechnung'}}</option>
                  </select>
                </div>

                <div class="p10 pb0 lhCard"
                  *ngIf="(billingAddressEnable || stationCount > '1') && billingAddressEnable">
                  {{ stationArray().controls[i]?.get('billingAddress').value.firstName }}
                  {{ stationArray().controls[i]?.get('billingAddress').value.lastName }}
                  {{ stationArray().controls[i]?.get('billingAddress').value.companyName }}<br>
                  {{ stationArray().controls[i]?.get('billingAddress').value.addressZusatz }}<br>
                  {{ stationArray().controls[i]?.get('billingAddress').value.streetName }}
                  {{ stationArray().controls[i]?.get('billingAddress').value.houseNo }}<br>

                  {{ stationArray().controls[i]?.get('billingAddress').value.zipCode }}
                  {{ stationArray().controls[i]?.get('billingAddress').value.ort }}<br>

                  {{ stationArray().controls[i]?.get('billingAddress').value.phoneNumber }}<br>


                  <!-- {{ stationArray().controls[i]?.get('billingAddress').value.city }} -->

                  {{ stationArray().controls[i]?.get('billingAddress').value.eMail }}
                </div>

                <div class="text-right">
                  <button type="button" class="editBtn"
                    *ngIf="stationArray().controls[i]?.get('billingAddress').value.zipCode != '' && billingAddressEnable"
                    (click)="editAddressMethod(stationArray().controls[i]?.get('billingAddress').value,'billing', null)">
                    {{languageData['Adresseingabe'].Var_Edit|| 'Bearbeiten'}}
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="aurow mr0 ml0 pl10 pr10 pb20 sectioncontainers">
    <button type="button" class="cnlBtn font20 smalBtn flt"
      [ngStyle]="{background:'var(--primaryColour)',color:'var(--primaryTextColour)'}"
      [style.background]="'var(--primaryColour)'" (click)="backButton()">
      {{languageData['Adresseingabe'].Var_Zuruck || 'Zurück'}}
    </button>
    <button type="button" class="nxtBtn font20 pull-right smalBtn" cyContinueToThird="cyContinueToThird"
      [ngStyle]="{background:'var(--primaryColour)',color:'var(--primaryTextColour)'}"
      [style.background]="'var(--primaryColour)'" (click)="weiter();">
      {{languageData['Adresseingabe'].Var_Weiter || 'Weiter'}}
    </button>
  </div>

  <loader [full-screen]="true" *ngIf="loader" [color]="'var(--primaryColour)'"></loader>
</div>

<app-pc-create-address *ngIf="addNewAddress" [addressType]="addressType" [addNewAddress]="addNewAddress"
  [lieferIndex]="lieferIndex" [editableAddress]="editableAddress" [editAddress]="editAddress" [plzId]="plzId"
  (abort)="cancelEdit($event)" [newAddress]='newAddress' (addAdressStagetwo)="addAdressInStagetwo($event)"
  [plzInfo]="plzInfo">
</app-pc-create-address>