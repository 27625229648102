<div class="aupris_main my-full-screen-dialog">
    <div class="text-center" style="margin-bottom:24px;">
        <button class="au-btn btn-default btncss"
            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
            (click)="openCalculator()">{{'Preisrechner'}}</button>
    </div>
    <div class="text-right">
        <button class="au-btn btn-default" [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
            (click)="onclick()" style="margin-top:5px">{{languageData['Popups'].Var_Schlieben || 'Schließen'}}</button>
    </div>
</div>