import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../Services/language.service';

@Component({
  selector: 'app-delete-alarm',
  templateUrl: './delete-alarm.component.html',
  styleUrls: ['./delete-alarm.component.css']
})
export class DeleteAlarmComponent implements OnInit {
  colors: any;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DeleteAlarmComponent>, private langService: LanguageService) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit() {
  }
}
