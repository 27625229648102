import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'securitycheck'
})
export class SecuritycheckPipe implements PipeTransform {
 
 
  
  transform(link): string {
    
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
      return link
    } else {
      return `https:\\${link}`
    }
  }


}
