<div *ngIf="dynamicPCData.length > 0">
    <div class="aupris_main" style="max-width:600px!important;margin:auto">
        <div class="au-container-fluid" style="padding-right: 0px;padding-left: 0px;min-width:200px">
            <div class="panel panel-default">

                <div class="panel-heading text-center">
                    <strong>{{languageData['Small-pc'].Var_Preisrechner || 'Preisrechner'}}</strong>
                </div>

                <div class="panel-body">

                    <form class="form-horizontal" [formGroup]="myForm" *ngIf='myForm' novalidate
                        (ngSubmit)="routeToPriceCalculator(myForm, myForm.valid)">

                        <!-- Product Selection Div Start -->
                        <div class="animate-bottom" style=" display: flex;margin-bottom:15px">
                            <div style="min-width:130px;">
                                <label>{{languageData['Small-pc'].Var_Produkt || 'Produkt'}}:</label>
                            </div>
                            <div style="width:100%">
                                <select name="select" class="auform-control" formControlName="product"
                                    (change)="productSelectionChange($event.target)">
                                    <option *ngIf="!myForm.value.product" [value]="" selected></option>
                                    <option *ngFor="let prod of dynamicPCData" [value]="prod.dynamicProductId">
                                        {{prod.appName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Product Selection Div End -->


                        <!-- Postal code Selection Div Start -->
                        <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
                            *ngIf="ActivateDeactivatePc?.activatePc">

                            <span style="min-width:130px;">
                                <label>{{languageData['Small-pc'].Var_Lieferort || 'Lieferort'}}:</label>
                            </span>

                            <span style="width:100%">
                                <input type="text" class="auform-control" formControlName="lieferort"
                                    [matAutocomplete]="autoPlz" (ngModelChange)="getPostalCode($event)"
                                    (keydown)="onKeyy($event)">

                                <mat-autocomplete #autoPlz="matAutocomplete">
                                    <mat-option *ngFor="let option of plzOptions"
                                        (onSelectionChange)="selectPlz(option)" (blur)="onNg2Blur()"
                                        (keypress)="validate($event)"
                                        [value]="option.country_code + ' ' + option.place_name + ' ' + option.suburb">
                                        {{option.place_name}} {{option.country_code}} {{option.suburb}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div class="alert-danger"
                                    *ngIf="myForm.controls.lieferort.errors && (myForm.controls.lieferort.touched && myForm.controls.lieferort.dirty)">
                                    {{languageData['Small-pc'].VarWeb-PcSmall-Pc1 || 'Pflichtfeld bitte ausfüllen!'}}
                                </div>

                                <div class="alert-danger" *ngIf="postalCodeErrorMsg">
                                    Nur Auswahl aus dem Dropdown Menü zulässig!
                                </div>
                                <div class="alert-danger" *ngIf="tabErrorMsg">
                                    {{languageData['Small-pc'].Var_empty_deliveryarea_error ||'Bitte prüfen Sie Ihre
                                    Eingabe und wählen
                                    Sie einen Lieferort aus dem Auswahlfeld aus'}}
                                </div>

                                <!-- <div class="alert-danger" *ngIf="errorMsg">
                                    {{ languageData['Small-pc'].Var_empty_deliveryarea_error || 'Bitte prüfen Sie Ihre
                                    Eingabe und wählen
                                    Sie einen Lieferort aus dem Auswahlfeld aus'}}
                                </div> -->


                                <div class="alert-danger"
                                    *ngIf="myForm.controls.liter.errors?.errorleiferort && myForm.controls.liter.touched">
                                    Max min values
                                </div>
                            </span>

                        </div>
                        <!-- Postal code Selection Div End -->

                        <!-- Quantity Selection Div Start -->
                        <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
                            *ngIf="ActivateDeactivatePc?.activatePc">
                            <span style="min-width:130px;">
                                <label class="amount">{{unitName}}:</label>
                            </span>
                            <span style="width:100%;cursor: not-allowed;">
                                <input [ngClass]="{disabled: !myForm.controls.product.value}" (keyup)="validateMenge()"
                                    class="auform-control" formControlName="liter">

                                <div class="alert-danger"
                                    *ngIf="myForm?.get('liter')?.invalid && (myForm?.get('liter').touched || myForm?.get('liter').dirty)">
                                    Bestellmenge
                                    {{minimumAmount | replaced}}
                                    bis
                                    {{maximumAmount | replaced}}
                                </div>
                                <div class="alert-danger"
                                    *ngIf="(inLimitHouseHold && !myForm.controls.liter.errors?.errormaxmin)">Die
                                    Mindestbestellmenge für
                                    {{myForm.controls['abladestellen'].value}} liegt bei
                                    {{minimumAmount * myForm.controls['abladestellen'].value | replaced}}. Bitte passen
                                    Sie
                                    Ihre Werte in der Auswahl an!
                                </div>
                            </span>
                        </div>
                        <!-- Quantity Selection Div End -->

                        <!--Delivery Station Selection Div Start -->
                        <div class="animate-bottom" style=" display: flex;margin-bottom:15px"
                            *ngIf="showAbladestelle && ActivateDeactivatePc?.activatePc">
                            <span style="min-width:130px;">
                                <label class="amount">
                                    {{languageData['Bestelloptionen'].Var_Abladestellen ||'Abladestellen'}}:
                                </label>
                            </span>
                            <span style="width:100%">
                                <select name="Abladestellen" class="auform-control" (change)="validateMenge()"
                                    formControlName="abladestellen">
                                    <option *ngFor="let station of abladestellen" [value]="station?.value">
                                        {{ station?.value }}
                                        <span *ngIf="station?.value == 1">
                                            {{ languageData['Bestelloptionen'].Var_Haushalt || 'Haushalt' }}
                                        </span>
                                        <span *ngIf="station?.value >= 2">
                                            {{ languageData['Bestelloptionen'].Var_Haushalte || 'Haushalte' }}
                                        </span>
                                    </option>
                                </select>
                            </span>
                        </div>
                        <!--Delivery Station Selection Div End -->


                        <div class="auform-group animate-bottom" style="margin-bottom: -15px;"
                            *ngIf="ActivateDeactivatePc?.activatePc">
                            <button type="submit" class="au-btn au-btn-block"
                                [ngStyle]="{background: colorsPc?.primaryColour, color: colorsPc.primaryTextColour}"
                                [disabled]="isvalid()">
                                {{languageData['Small-pc'].Var_Preis_Berechnen || 'Preis Berechnen'}}
                            </button>
                        </div>

                        <div class="auform-group"
                            *ngIf="ActivateDeactivatePc.productText && !ActivateDeactivatePc?.activatePc"
                            style="color: red; font-size: 18px; font-weight: 600;text-align: center">
                            {{ActivateDeactivatePc.productText }}
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="pcOffPbbackend && smallpc_ttr_fr">
    {{languageData?.Loading?.Var_PC_Off_From_PbBackend || 'Unser Preisrechner befindet sich im Wartungsmodus!'}}
</div> -->