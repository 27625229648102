import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }
  public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    // let [accentedPart, nonAccentedPart] = this.splitAccentedString(value);
    // console.log(accentedPart, nonAccentedPart)
    // if (accentedPart != '') {
    //   const finaldata = this.removeStringBetweenScriptTags(accentedPart)
    //   accentedPart = finaldata;
    // };
    let formatedText = this.removeStringBetweenScriptTags(value);


    // switch (type) {
    //   case 'html': return accentedPart + this.sanitizer.sanitize(SecurityContext.HTML, nonAccentedPart);
    //   case 'style': return accentedPart + this.sanitizer.sanitize(SecurityContext.STYLE, nonAccentedPart);
    //   case 'script': return accentedPart + this.sanitizer.sanitize(SecurityContext.SCRIPT, nonAccentedPart);
    //   case 'url': return accentedPart + this.sanitizer.sanitize(SecurityContext.URL, nonAccentedPart);
    //   case 'resourceUrl': return accentedPart + this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, nonAccentedPart);
    //   default: throw new Error(`Invalid safe type specified: ${type}`);
    // };
    if (type == 'html' || type == 'style' || type == 'script') {
      return formatedText
    };

    switch (type) {
      case 'url': return this.sanitizer.sanitize(SecurityContext.URL, formatedText);
      case 'resourceUrl': return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, formatedText);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  };

  // private splitAccentedString(inputString: string): [string, string] {
  //   const accentedPartRegex = /([\u0080-\uFFFF])/;
  //   const match = accentedPartRegex.exec(inputString);
  //   console.log(inputString, match, 123)
  //   if (match) {
  //     const index = match.input;
  //     return [index, '']
  //   } else {
  //     return ['', inputString];
  //   };
  // };

  private removeStringBetweenScriptTags(inputString: string): string {
    // console.log(typeof inputString)
    if (!inputString || typeof inputString != 'string') return inputString;
    const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>|<[a-zA-z]\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/[a-zA-z]>|<style[\w="'\s-]*>(.*?)<\/\s*style>/gi;
    return inputString.replace(regex, '');
  };

}
