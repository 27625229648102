import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import $ from 'jquery';
import { AdminService } from '../Services/admin.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent implements OnInit {
  line: any;

  constructor(private adminService: AdminService,) { }
  @Input() clickedoption: any;
  @Input() clickedPage: any;
  @Input() colors: any;
  @Input() theme: any;
  @Input() languageData: any;
  @Input() showFullScreenLoading: boolean;
  @Output() selectedPage = new EventEmitter<object>();
  @Input() statusChange: any;
  @Input() typeDriverApp: boolean;
  @Input() screenType: boolean;


  ngOnInit(): void {
    // setTimeout(() => {
      // console.log(this.statusChange,this.screenType)
      // this.statusChange = this.statusChange==0 ? 3 : 2
      // this.progressbar(this.statusChange)
    // }, 3000)
    this.adminService.notifyObservable$.subscribe(res => {
      console.log(res)
      this.statusChange = res
      this.progressbar(this.statusChange)
    })
  }

  progressbar(e) {
    // console.log(e)
    this.line = e;
    if (e == 0) {
      setTimeout(() => {
        $(".progressbar li:first").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 100)
    } else if (e == 1) {
      setTimeout(() => {
        $(".progressbar li:first").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 100)
      setTimeout(() => {
        $(".progressbar li:nth-child(2)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 4500)
    } else if (e == 2) {
      setTimeout(() => {
        $(".progressbar li:first").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 100)
      setTimeout(() => {
        $(".progressbar li:nth-child(2)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 1900)
      setTimeout(() => {
        $(".progressbar li:nth-child(3)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 6100)
    } else if (e == 3) {
      setTimeout(() => {
        $(".progressbar li:first").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 100)
      setTimeout(() => {
        $(".progressbar li:nth-child(2)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 945)
      setTimeout(() => {
        $(".progressbar li:nth-child(3)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 4400)
      setTimeout(() => {
        $(".progressbar li:nth-child(4)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 6900)
    } else {
      setTimeout(() => {
        $(".progressbar li:first").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 100)
      setTimeout(() => {
        $(".progressbar li:nth-child(2)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 950)
      setTimeout(() => {
        $(".progressbar li:nth-child(3)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 3500)
      setTimeout(() => {
        $(".progressbar li:nth-child(4)").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 5800)
      setTimeout(() => {
        $(".progressbar li:last").toggleClass('active');
        $(".percent").css({ border: '2px solid var(--primaryColour)' })
      }, 7500)
    }
  }

  page(page) {
    // console.log(this.statusChange,)
    if (!this.showFullScreenLoading) {
      if (this.clickedPage != 'page4') {
        this.selectedPage.emit(page)
      }
    }
  }

}
