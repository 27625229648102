<div class="aupris_main">
    <h4 class="deleteHeader" [ngStyle]="{color: 'var(--primaryColour)'}"><b>
            {{languageData['Bestelloptionen'].Var_deletealarmheader || 'Preiswecker löschen'}} </b></h4>
    <p class="deleteText">{{languageData['Bestelloptionen'].Var_deletealarmtext || 'Wenn Sie
        den Preiswecker löschen erhalten Sie keine Nachricht bei einer Preisänderung. Sind Sie sich sicher, dass Sie
        diese Option löschen wollen?'}}
    </p>

    <button mat-raised-button mat-dialog-close class="au-btn borderCss" style="float:left;"
        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}">
        {{languageData?.login?.Var_Abbrechen || 'Abbrechen'}}</button>
    <button mat-raised-button mat-dialog-close="proceed" class="au-btn borderCss" style="float:right;"
        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}">
        {{languageData['Edit'].Var_Bestatigen || 'Bestätigen'}}</button>
</div>