<div class="aupris_main my-full-screen-dialog">
  <p>
    {{languageData['Popups'].Var_Bestellen_Text || 'Sie haben die Standard Bestelloptionen nicht verändert. Sind Sie sicher, dass Sie die Bestellung mit diesen Optionen abgeben wollen?'}}

  </p>
  <div *ngFor="let names of offernames">
    <div class="aurow auform-group">
      <label
        class="control-label aucol-sm-5 text-right">{{languageData['Bestelloptionen']['Var_' + names.name] || names.name }}:</label>
      <div class="aucol-sm-7">{{languageData['Bestelloptionen']['Var_'+names.selectedname]|| names.selectedname }}
        <span *ngIf="names.name == 'Abladestellen'">
          {{(names.selectedname == 1)? languageData['Bestelloptionen'].Var_Haushalt || 'Haushalt': languageData['Bestelloptionen'].Var_Haushalte || 'Haushalte'}}
        </span>
      </div>
    </div>
  </div>
  <button (click)="contiuneu()" class="au-btn btn-default"
    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
    style="float:right;">{{languageData['Popups'].Var_Bestellen || 'Bestellen'}}</button>
  <button mat-dialog-close="Korrigieren" class="au-btn btn-default"
    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
    style="float:left;">{{languageData['Popups'].Var_Korrigieren || 'Korrigieren'}}</button>
</div>