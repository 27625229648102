import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-delete-onlineaccount',
  templateUrl: './delete-onlineaccount.component.html',
  styleUrls: ['./delete-onlineaccount.component.css']
})
export class DeleteOnlineaccountComponent implements OnInit {
  colors: any;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DeleteOnlineaccountComponent>, private langService: LanguageService) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit() {
  }

}
