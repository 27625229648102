<!-- if rate payment == true -->
<div class="aupris_main my-full-screen-dialog" *ngIf="offer.ratePayment">
  <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">{{ languageData?.Bestelloptionen['Var_Preisdetails'] || 'Preisdetails'}} </div>
  <table>
    <tr>
      <td *ngIf="otherdata.label == 'Heizöl'">{{ languageData?.Bestelloptionen['Var_Basispreis_100l_netto'] || 'Basispreis 100'}}{{volume_short}} {{languageData['Bestelloptionen'].Var_netto || 'netto'}}</td>
      <td *ngIf="otherdata.label == 'Pellets'">{{ languageData?.Bestelloptionen['Var_Basispreis_1000kg_netto'] || 'Basispreis 1000'}}{{weight_short}} {{languageData['Bestelloptionen'].Var_netto || 'netto'}}</td>
      <td>
        <span><span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.basePrice100lNetto | replace}}</span>
      </td>
    </tr>
    <br>
    <tr>
      <td>{{ languageData?.Bestelloptionen['Var_Basispreis'] || 'Basispreis '}} ({{otherdata.menge | replaced}}<span *ngIf="otherdata.label == 'Heizöl'">{{volume_short}}</span>
        <span *ngIf="otherdata.label == 'Pellets'">{{weight_short}}</span>) {{languageData['Bestelloptionen'].Var_netto || 'netto'}}</td>
      <td><span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.pricedetails_gesamptprice_netto | replace}}</td>
    </tr>
    <tr>
      <td>
        <span>+
          {{ languageData?.Bestelloptionen['Var_servicefeefinancing'] || 'Servicegebühr Finanzierung '}}
           </span>
      </td>
      <td>
        <span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.servicegebuehr | replace}}
      </td>
    </tr>

    <tr>
      <td>
        <span *ngIf="!offer.Pauschalen_generalsettings">+ {{ languageData?.Popups['Var_Zuschlage_und_Pauschalen'] || 'Zuschläge und Pauschalen'}}</span>
        <span *ngIf="offer.Pauschalen_generalsettings">
          + {{languageData['Popups']['Var_' + offer.Pauschalen_PC_name] || offer.Pauschalen_PC_name }}
        </span>
      </td>
      <td>
        <span *ngIf="!offer.Pauschalen_generalsettings"> <span [innerHTML]="currency_symbol"></span> 0,00</span>
        <span *ngIf="offer.Pauschalen_generalsettings">
          <span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_name_value_for_details | replace}}
       </span>
        
      </td>
    </tr>

    <tr *ngIf="offer.ratePaymentDetails.gutschein">
      <td >
        <span>- {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</span>
      </td>
      <td>
        <span>
           <span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.gutschein | replace}}
        </span>
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td>
        <span>+ {{offer.otherdetails.vat_heatoil | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
      </td>
      <td>
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}} 
        <span> <span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.mwst | replace}}</span>
      </td>
    </tr>

    <tr>
      <td>= {{ languageData?.Bestelloptionen['Var_Warenpreis'] || 'Warenpreis'}} ({{otherdata.menge | replaced}}<span *ngIf="otherdata.label == 'Heizöl'">{{volume_short}}</span>
          <span *ngIf="otherdata.label == 'Pellets'">{{weight_short}}</span>) {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
      </td>
      <td>
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}} 
        <span  [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.GesamtpreisBrutto | replace}} 
      </td>
    </tr>
  </table>
  <br />
  <!-- time depended Sec start -->
  <table class="frame" style="background: #dfdfdf;" *ngIf="offer.alternativePrices && (offer.alternativePrices.length >0)">
    <tr>
      <td>{{languageData['Bestelloptionen'].Var_Alternative || 'Alternativ'}}:
      </td>
      <td></td>
    </tr>
    <tr *ngFor="let altr of offer.alternativePrices">
      <td>
        + <span>{{altr.name}} </span>
        <span class="au-glyphicon au-glyphicon-info-sign" >
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" (click)="alternativePopup(altr.specialText)"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 438.533 438.533"
            style="enable-background:new 0 0 438.533 438.533;" [ngStyle]="{fill: colors?.Grundfarbe}"
            xml:space="preserve">
            <g>
              <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
            c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
            c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
            s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
            C438.533,179.485,428.732,142.795,409.133,109.203z M182.727,54.813c0-2.666,0.855-4.853,2.57-6.565
            c1.712-1.711,3.903-2.57,6.567-2.57h54.82c2.662,0,4.853,0.859,6.561,2.57c1.711,1.712,2.573,3.899,2.573,6.565v45.682
            c0,2.664-0.862,4.854-2.573,6.564c-1.708,1.712-3.898,2.568-6.561,2.568h-54.82c-2.664,0-4.854-0.856-6.567-2.568
            c-1.715-1.709-2.57-3.9-2.57-6.564V54.813z M292.359,356.309c0,2.662-0.863,4.853-2.57,6.561c-1.704,1.714-3.895,2.57-6.563,2.57
            H155.317c-2.667,0-4.854-0.856-6.567-2.57c-1.712-1.708-2.568-3.898-2.568-6.564v-45.682c0-2.67,0.856-4.853,2.568-6.567
            c1.713-1.708,3.903-2.57,6.567-2.57h27.41v-91.358h-27.41c-2.667,0-4.853-0.855-6.567-2.568c-1.712-1.711-2.568-3.901-2.568-6.567
            v-45.679c0-2.666,0.856-4.853,2.568-6.567c1.715-1.713,3.905-2.568,6.567-2.568h91.367c2.662,0,4.853,0.855,6.561,2.568
            c1.711,1.714,2.573,3.901,2.573,6.567v146.179h27.401c2.669,0,4.859,0.855,6.57,2.566c1.704,1.712,2.566,3.901,2.566,6.567v45.683
            H292.359z" />
            </g>
          </svg>
        </span>
      </td>
      <td>
        <span [innerHTML]="currency_symbol"></span> {{altr.amount | replace}}
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td>
        <!-- + 16,00% MwSt. -->
      
        + {{offer.otherdetails.vat_heatoil | replace}}% {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}. 
      </td>
      <td>
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}}
        <span [innerHTML]="currency_symbol"></span> {{alternativeMwst | replace}}
      </td>
    </tr>
    <tr>
      <td>
        = {{ languageData?.Bestelloptionen['Var_Warenpreis'] || 'Warenpreis'}} ({{otherdata.menge | replaced}}<span *ngIf="otherdata.label == 'Heizöl'">{{volume_short}}</span>
        <span *ngIf="otherdata.label == 'Pellets'">{{weight_short}}</span>) {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
      </td>
      <td>
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}}
        <span [innerHTML]="currency_symbol"></span> {{alternativeWarenpries | replace}}
      </td>
    </tr>
  </table>
  <br />
  <!-- time depended Sec end -->
  <br />
  <table>
    <tr>
      <td>
        {{ languageData?.Bestelloptionen['Var_financingterm'] || 'Finanzierung Laufzeit: '}}:
       {{offer.ratePaymentDetails.anzahlRaten}} 
       {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}
      </td>
      <td></td>
    </tr>
    <tr>
      <td>{{offer.ratePaymentDetails.anzahlRaten - 1}} X 
        {{ languageData?.Bestelloptionen['Var_monthly'] || 'Monatsrate brutto'}}
        </td>
      <td>{{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}} 
        <span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.MonatsrateBrutto | replace}}</td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td>1 X {{ languageData?.Bestelloptionen['Var_finalinstallment'] || 'Schlussrate brutto'}}</td>
      <td>{{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}} 
        <span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.SchlussrateBrutto | replace}}</td>
    </tr>
    <tr>
      <td><b>= {{ languageData?.Bestelloptionen['Var_totalfinance'] || 'Gesamtsumme Finanzierung'}}
      </b>
        </td>
      <td><b> 
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}} 
        <span [innerHTML]="currency_symbol"></span> {{offer.ratePaymentDetails.GesamtsummeFinanzierung | replace}} </b>
      </td>
    </tr>

  </table>
  <!-- ------------------------- -->
  <div class="text-right">
  <button class="au-btn btn-default" [ngStyle]="{background: colors.Grundfarbe,color: colors.Schriftfarbe_Buttons}" (click)="onclick()"
    style="margin-top:5px">{{languageData['Popups'].Var_Schlieben || 'Schließen'}}</button>
  </div>
  <div class="inline-block" *ngIf="otherdata.label == 'Heizöl' && data.vatSettings.Heizol">
    <span class="dblock">**{{languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis'}}</span>
    <span class="dblock">{{data.vatSettings.HeizolText}}</span>
  </div>
  <div class="inline-block" *ngIf="otherdata.label == 'Pellets' && data.vatSettings.Pellets">
    <span class="dblock">**{{languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis'}}</span>
    <span class="dblock">{{data.vatSettings.PelletsText}}</span>
  </div>
</div>
<!-- if rate payment == false -->
<div class="aupris_main my-full-screen-dialog" *ngIf="!offer.ratePayment">
  <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">{{ languageData?.Bestelloptionen['Var_Preisdetails'] || 'Preisdetails'}} </div>
  <table *ngIf="otherdata.label == 'Heizöl'">
    <tr>
      <td>{{ languageData?.Bestelloptionen['Var_Basispreis_100l_netto'] || 'Basispreis 100'}}{{volume_short}} {{languageData['Bestelloptionen'].Var_netto || 'netto'}}</td>
      <td *ngIf="otherdata.label == 'Heizöl' && offer.Pauschalen_PC">
        <span *ngIf="offer.otherdetails.Pauschalen_PC_100l"><span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withoutTax | replace}}</span>
        <span *ngIf="!offer.otherdetails.Pauschalen_PC_100l"><span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withoutTax | replace}}</span>
      </td>
      <td *ngIf="otherdata.label == 'Heizöl' && !offer.Pauschalen_PC">
        <span *ngIf="!offer.Pauschalen_generalsettings"><span [innerHTML]="currency_symbol"></span> {{offer.final_100liters_ton | replace}}</span>
        <span *ngIf="offer.Pauschalen_generalsettings">
          <span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withoutTax | replace}}
        </span>
      </td>
    </tr>
    <tr *ngIf="otherdata.label == 'Heizöl' && offer.Pauschalen_PC && !offer.otherdetails.Pauschalen_PC_100l">
      <td>
        <span *ngIf="!offer.otherdetails.Pauschalen_PC_100l">+ {{languageData['Popups']['Var_' + offer.Pauschalen_PC_name] || offer.Pauschalen_PC_name }}</span>
      </td>
      <td>
        <span *ngIf="!offer.otherdetails.Pauschalen_PC_100l"> <span [innerHTML]="currency_symbol"></span> {{offer.pricedeails_pauschalen_val | replace}}</span>
      </td>
    </tr>

    <tr *ngIf="otherdata.label == 'Heizöl' && !offer.Pauschalen_PC">
      <td >
        <span *ngIf="!offer.Pauschalen_generalsettings">+ {{ languageData?.Popups['Var_Zuschlage_und_Pauschalen'] || 'Zuschläge und Pauschalen'}}</span>
        <span *ngIf="offer.Pauschalen_generalsettings">
          + {{languageData['Popups']['Var_' + offer.Pauschalen_PC_name] || offer.Pauschalen_PC_name }}
        </span>
      </td>
      <td>
        <span *ngIf="!offer.Pauschalen_generalsettings"> <span [innerHTML]="currency_symbol"></span> 0,00</span>
        <span *ngIf="offer.Pauschalen_generalsettings">
           <span [innerHTML]="currency_symbol"></span> {{offer.pricedeails_pauschalen_val | replace}}
        </span>
      </td>
    </tr>
    <tr *ngIf="offer.gutschein && offer.gutschein?.valid">
      <td >
        <span>- {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</span>
      </td>
      <td>
        <span>
           <span [innerHTML]="currency_symbol"></span> {{offer.gutschein?.gutschein_discount_conversion | replace}}
        </span>
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td *ngIf="otherdata.label == 'Heizöl' && offer.Pauschalen_PC">
        <span *ngIf="offer.otherdetails.Pauschalen_PC_100l">+ {{offer.otherdetails.vat_heatoil | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
        <span *ngIf="!offer.otherdetails.Pauschalen_PC_100l">+ {{offer.otherdetails.vat_heatoil | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
      </td>
      <td *ngIf="otherdata.label == 'Heizöl' && !offer.Pauschalen_PC">
        <span *ngIf="!offer.Pauschalen_generalsettings">+ {{offer.otherdetails.vat_heatoil | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
        <span *ngIf="offer.Pauschalen_generalsettings">
           + {{offer.otherdetails.vat_heatoil | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.
        </span>
      </td>

      <td *ngIf="otherdata.label == 'Heizöl' && offer.Pauschalen_PC">
        {{(data.vatSettings.Heizol?'**':'')}}
        <span *ngIf="offer.otherdetails.Pauschalen_PC_100l"> <span [innerHTML]="currency_symbol"></span> {{offer.pricedeails_pauschalen_tax_for_100l | replace}}</span>
        <span *ngIf="!offer.otherdetails.Pauschalen_PC_100l"> <span [innerHTML]="currency_symbol"></span> {{offer.onlytax_for_100l_ton | replace}}</span>
      </td>
      <td *ngIf="otherdata.label == 'Heizöl' && !offer.Pauschalen_PC">
        {{(data.vatSettings.Heizol?'**':'')}}
        <span *ngIf="!offer.Pauschalen_generalsettings"> <span [innerHTML]="currency_symbol"></span> {{offer.onlytax_for_100l_ton | replace}}</span>
        <span *ngIf="offer.Pauschalen_generalsettings">
           <span [innerHTML]="currency_symbol"></span> {{offer.onlytax_for_100l_ton | replace}}
        </span>
      </td>
    </tr>
    <tr>
      <td><b>= {{ languageData?.Bestelloptionen['Var_Preis_pro_100l_brutto'] || 'Preis pro 100'}}{{volume_short}} {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}</b></td>
      <td *ngIf="otherdata.label == 'Heizöl' && offer.Pauschalen_PC">
        {{(data.vatSettings.Heizol?'**':'')}}
        <span *ngIf="offer.otherdetails.Pauschalen_PC_100l"> <b><span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withTax | replace}}</b></span>
        <span *ngIf="!offer.otherdetails.Pauschalen_PC_100l"> <b><span [innerHTML]="currency_symbol"></span> {{offer.tax_for_100l_ton | replace}}</b></span>
      </td>
      <td *ngIf="otherdata.label == 'Heizöl' && !offer.Pauschalen_PC">
        {{(data.vatSettings.Heizol?'**':'')}}
        <span *ngIf="!offer.Pauschalen_generalsettings"> <b><span [innerHTML]="currency_symbol"></span> {{offer.tax_for_100l_ton | replace}}</b></span>
        <span *ngIf="offer.Pauschalen_generalsettings">
           <b><span [innerHTML]="currency_symbol"></span> {{offer.tax_for_100l_ton | replace}}</b>
        </span>
      </td>
    </tr>
  </table>
  <table *ngIf="otherdata.label == 'Pellets'">
      <tr>
        <td>{{ languageData?.Bestelloptionen['Var_Basispreis_1000kg_netto'] || 'Basispreis 1000'}}{{weight_short}} {{languageData['Bestelloptionen'].Var_netto || 'netto'}}</td>
        <td *ngIf="otherdata.label == 'Pellets' && offer.Pauschalen_PC">
          <span *ngIf="offer.otherdetails.Pauschalen_PC_pellets_100l"><span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withoutTax | replace}}</span>
          <span *ngIf="!offer.otherdetails.Pauschalen_PC_pellets_100l"><span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withoutTax | replace}}</span>
        </td>
        <td *ngIf="otherdata.label == 'Pellets' && !offer.Pauschalen_PC">
          <span *ngIf="!offer.Pauschalen_generalsettings"><span [innerHTML]="currency_symbol"></span> {{offer.final_100liters_ton | replace}}</span>
          <span *ngIf="offer.Pauschalen_generalsettings">
            <span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withoutTax | replace}}
          </span>
        </td>
      </tr>

      <tr *ngIf="otherdata.label == 'Pellets' && offer.Pauschalen_PC && !offer.otherdetails.Pauschalen_PC_pellets_100l">
          <td>
              <span *ngIf="!offer.otherdetails.Pauschalen_PC_pellets_100l">+ {{languageData['Popups']['Var_' + offer.Pauschalen_PC_name] || offer.Pauschalen_PC_name }}</span>
          </td>
          <td>
              <span *ngIf="!offer.otherdetails.Pauschalen_PC_pellets_100l"> <span [innerHTML]="currency_symbol"></span> {{offer.pricedeails_pauschalen_val | replace}}</span>
          </td>
        </tr>

        <tr *ngIf="otherdata.label == 'Pellets' && !offer.Pauschalen_PC">
          <td >
              <span *ngIf="!offer.Pauschalen_generalsettings">+ {{ languageData?.Popups['Var_Zuschlage_und_Pauschalen'] || 'Zuschläge und Pauschalen'}}</span>
              <span *ngIf="offer.Pauschalen_generalsettings">
                + {{languageData['Popups']['Var_' + offer.Pauschalen_PC_name] || offer.Pauschalen_PC_name}}
              </span>
          </td>
          <td>
              <span *ngIf="!offer.Pauschalen_generalsettings"> <span [innerHTML]="currency_symbol"></span> 0,00</span>
              <span *ngIf="offer.Pauschalen_generalsettings">
                 <span [innerHTML]="currency_symbol"></span> {{offer.pricedeails_pauschalen_val | replace}}
              </span>
          </td>
        </tr>
        <tr *ngIf="offer.gutschein && offer.gutschein?.valid">
          <td >
            <span>- {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</span>
          </td>
          <td>
            <span>
               <span [innerHTML]="currency_symbol"></span> {{offer.gutschein?.gutschein_discount_conversion | replace}}
            </span>
          </td>
        </tr>
      <tr style="border-bottom: 1px solid black">
        <td *ngIf="otherdata.label == 'Pellets' && offer.Pauschalen_PC">
          <span *ngIf="offer.otherdetails.Pauschalen_PC_pellets_100l">+ {{offer.otherdetails.vat_pellets | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
          <span *ngIf="!offer.otherdetails.Pauschalen_PC_pellets_100l">+ {{offer.otherdetails.vat_pellets | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
        </td>
        <td *ngIf="otherdata.label == 'Pellets' && !offer.Pauschalen_PC">
          <span *ngIf="!offer.Pauschalen_generalsettings">+ {{offer.otherdetails.vat_pellets | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.</span>
          <span *ngIf="offer.Pauschalen_generalsettings">
            + {{offer.otherdetails.vat_pellets | replace}}% {{ languageData?.Bestelloptionen['Var_MwSt'] || 'MwSt'}}.
          </span>
        </td>

        <td *ngIf="otherdata.label == 'Pellets' && offer.Pauschalen_PC">
          {{(data.vatSettings.Pellets?'**':'')}}
          <span *ngIf="offer.otherdetails.Pauschalen_PC_pellets_100l"> <span [innerHTML]="currency_symbol"></span> {{offer.pricedeails_pauschalen_tax_for_100l | replace}}</span>
          <span *ngIf="!offer.otherdetails.Pauschalen_PC_pellets_100l"> <span [innerHTML]="currency_symbol"></span> {{offer.onlytax_for_100l_ton | replace}}</span>
        </td>
        <td *ngIf="otherdata.label == 'Pellets' && !offer.Pauschalen_PC">
          {{(data.vatSettings.Pellets?'**':'')}}
          <span *ngIf="!offer.Pauschalen_generalsettings"> <span [innerHTML]="currency_symbol"></span> {{offer.onlytax_for_100l_ton | replace}}</span>
          <span *ngIf="offer.Pauschalen_generalsettings">
             <span [innerHTML]="currency_symbol"></span> {{offer.onlytax_for_100l_ton | replace}}
          </span>
        </td>
      </tr>
      <tr>
        <td><b>= {{languageData['Bestelloptionen'].Var_Preis_pro_1000Kg_netto || 'Preis pro 1000'}}{{weight_short}} {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}</b></td>
        <td *ngIf="otherdata.label == 'Pellets' && offer.Pauschalen_PC">
          <span *ngIf="offer.otherdetails.Pauschalen_PC_pellets_100l"> <b><span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_100l_withTax | replace}}</b></span>
          <span *ngIf="!offer.otherdetails.Pauschalen_PC_pellets_100l"> <b><span [innerHTML]="currency_symbol"></span> {{offer.tax_for_100l_ton | replace}}</b></span>
        </td>
        <td *ngIf="otherdata.label == 'Pellets' && !offer.Pauschalen_PC">

          <span *ngIf="!offer.Pauschalen_generalsettings"> <b><span [innerHTML]="currency_symbol"></span> {{offer.tax_for_100l_ton | replace}}</b></span>
          <span *ngIf="offer.Pauschalen_generalsettings">
             <b><span [innerHTML]="currency_symbol"></span> {{offer.tax_for_100l_ton | replace}}</b>
          </span>
        </td>
      </tr>
    </table>
  <br />

  <table>
    <tr>
      <td>{{ languageData?.Bestelloptionen['Var_Basispreis'] || 'Basispreis '}} ({{otherdata.menge | replaced}}<span *ngIf="otherdata.label == 'Heizöl'">{{volume_short}}</span>
        <span *ngIf="otherdata.label == 'Pellets'">{{weight_short}}</span>) {{languageData['Bestelloptionen'].Var_netto || 'netto'}}</td>
      <td *ngIf="!offer.Pauschalen_generalsettings"><span [innerHTML]="currency_symbol"></span> {{offer.finalprice | replace}}</td>
      <td *ngIf="offer.Pauschalen_generalsettings"><span [innerHTML]="currency_symbol"></span> {{offer.pricedetails_gesamptprice | replace}}</td>
    </tr>
    <tr *ngIf="offer.Pauschalen_generalsettings">
      <td>+ {{offer.AbladestellenLabel}} {{languageData['Popups']['Var_' + offer.Pauschalen_PC_name] || offer.Pauschalen_PC_name }}
        <span class="au-glyphicon au-glyphicon-info-sign" *ngIf = "offer.Pauschalen_specialText" (click)="specialtextpopup1(offer.Pauschalen_specialText)">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 438.533 438.533"
            style="enable-background:new 0 0 438.533 438.533;" [ngStyle]="{fill: colors?.Grundfarbe}"
            xml:space="preserve">
            <g>
              <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
           c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
           c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
           s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
           C438.533,179.485,428.732,142.795,409.133,109.203z M182.727,54.813c0-2.666,0.855-4.853,2.57-6.565
           c1.712-1.711,3.903-2.57,6.567-2.57h54.82c2.662,0,4.853,0.859,6.561,2.57c1.711,1.712,2.573,3.899,2.573,6.565v45.682
           c0,2.664-0.862,4.854-2.573,6.564c-1.708,1.712-3.898,2.568-6.561,2.568h-54.82c-2.664,0-4.854-0.856-6.567-2.568
           c-1.715-1.709-2.57-3.9-2.57-6.564V54.813z M292.359,356.309c0,2.662-0.863,4.853-2.57,6.561c-1.704,1.714-3.895,2.57-6.563,2.57
           H155.317c-2.667,0-4.854-0.856-6.567-2.57c-1.712-1.708-2.568-3.898-2.568-6.564v-45.682c0-2.67,0.856-4.853,2.568-6.567
           c1.713-1.708,3.903-2.57,6.567-2.57h27.41v-91.358h-27.41c-2.667,0-4.853-0.855-6.567-2.568c-1.712-1.711-2.568-3.901-2.568-6.567
           v-45.679c0-2.666,0.856-4.853,2.568-6.567c1.715-1.713,3.905-2.568,6.567-2.568h91.367c2.662,0,4.853,0.855,6.561,2.568
           c1.711,1.714,2.573,3.901,2.573,6.567v146.179h27.401c2.669,0,4.859,0.855,6.57,2.566c1.704,1.712,2.566,3.901,2.566,6.567v45.683
           H292.359z" />
            </g>
          </svg>
        </span>
      </td>

      <td> <span [innerHTML]="currency_symbol"></span> {{offer.Pauschalen_PC_name_value_for_details | replace}}</td>
    </tr>
    <tr *ngIf="!offer.Pauschalen_generalsettings">
      <td>+ {{languageData['Popups'].Var_Zuschlage_und_Pauschalen || 'Zuschläge und Pauschalen'}}
        <span class="au-glyphicon au-glyphicon-info-sign" *ngIf = "offer.Pauschalen_specialText" (click)="specialtextpopup1(offer.Pauschalen_specialText)">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 438.533 438.533"
            style="enable-background:new 0 0 438.533 438.533;" [ngStyle]="{fill: colors?.Grundfarbe}"
            xml:space="preserve">
            <g>
              <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
           c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
           c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
           s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
           C438.533,179.485,428.732,142.795,409.133,109.203z M182.727,54.813c0-2.666,0.855-4.853,2.57-6.565
           c1.712-1.711,3.903-2.57,6.567-2.57h54.82c2.662,0,4.853,0.859,6.561,2.57c1.711,1.712,2.573,3.899,2.573,6.565v45.682
           c0,2.664-0.862,4.854-2.573,6.564c-1.708,1.712-3.898,2.568-6.561,2.568h-54.82c-2.664,0-4.854-0.856-6.567-2.568
           c-1.715-1.709-2.57-3.9-2.57-6.564V54.813z M292.359,356.309c0,2.662-0.863,4.853-2.57,6.561c-1.704,1.714-3.895,2.57-6.563,2.57
           H155.317c-2.667,0-4.854-0.856-6.567-2.57c-1.712-1.708-2.568-3.898-2.568-6.564v-45.682c0-2.67,0.856-4.853,2.568-6.567
           c1.713-1.708,3.903-2.57,6.567-2.57h27.41v-91.358h-27.41c-2.667,0-4.853-0.855-6.567-2.568c-1.712-1.711-2.568-3.901-2.568-6.567
           v-45.679c0-2.666,0.856-4.853,2.568-6.567c1.715-1.713,3.905-2.568,6.567-2.568h91.367c2.662,0,4.853,0.855,6.561,2.568
           c1.711,1.714,2.573,3.901,2.573,6.567v146.179h27.401c2.669,0,4.859,0.855,6.57,2.566c1.704,1.712,2.566,3.901,2.566,6.567v45.683
           H292.359z" />
            </g>
          </svg>
        </span>
      </td>
   
      <td> <span [innerHTML]="currency_symbol"></span> 0,00</td>
    </tr>
    <tr *ngIf="offer.gutschein && offer.gutschein?.valid">
      <td >
        <span>- {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</span>
      </td>
      <td>
        <span>
           <span [innerHTML]="currency_symbol"></span> {{offer.gutschein?.gutschein_discount_overall | replace}}
        </span>
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td *ngIf="otherdata.label == 'Heizöl'">+ {{offer.otherdetails.vat_heatoil | replace}}% {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}.</td>
      <td *ngIf="otherdata.label == 'Pellets'">+ {{offer.otherdetails.vat_pellets | replace}}%  {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}.</td>
      <td>
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}} 
        <span [innerHTML]="currency_symbol"></span> {{offer.onlytax_for_finalprice | replace}} </td>
    </tr>
    <tr>
      <td><b>= {{ languageData?.Bestelloptionen['Var_Gesamtpreis'] || 'Gesamtpreis'}} ({{otherdata.menge | replaced}}<span *ngIf="otherdata.label == 'Heizöl'">{{volume_short}}</span>
          <span *ngIf="otherdata.label == 'Pellets'">{{weight_short}}</span>) {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}</b>
        </td>
      <td><b> 
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}} 
        <span [innerHTML]="currency_symbol"></span> {{offer.tax_for_finalprice | replace}} </b>
      </td>
    </tr>
  </table>
  <!-- time depended Sec start -->
  <br *ngIf="offer.alternativePrices && (offer.alternativePrices.length >0)" />
  <table class="frame" style="background: #dfdfdf;" *ngIf="offer.alternativePrices && (offer.alternativePrices.length >0)">
    <tr>
      <td>{{languageData['Bestelloptionen'].Var_Alternative || 'Alternativ'}}:
      </td>
      <td></td>
    </tr>
    <tr *ngFor="let altr of offer.alternativePrices">
      <td>
        + <span>{{altr.name}} </span>
        <span class="au-glyphicon au-glyphicon-info-sign" >
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" (click)="alternativePopup(altr.specialText)"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 438.533 438.533"
            style="enable-background:new 0 0 438.533 438.533;" [ngStyle]="{fill: colors?.Grundfarbe}"
            xml:space="preserve">
            <g>
              <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
            c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
            c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
            s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
            C438.533,179.485,428.732,142.795,409.133,109.203z M182.727,54.813c0-2.666,0.855-4.853,2.57-6.565
            c1.712-1.711,3.903-2.57,6.567-2.57h54.82c2.662,0,4.853,0.859,6.561,2.57c1.711,1.712,2.573,3.899,2.573,6.565v45.682
            c0,2.664-0.862,4.854-2.573,6.564c-1.708,1.712-3.898,2.568-6.561,2.568h-54.82c-2.664,0-4.854-0.856-6.567-2.568
            c-1.715-1.709-2.57-3.9-2.57-6.564V54.813z M292.359,356.309c0,2.662-0.863,4.853-2.57,6.561c-1.704,1.714-3.895,2.57-6.563,2.57
            H155.317c-2.667,0-4.854-0.856-6.567-2.57c-1.712-1.708-2.568-3.898-2.568-6.564v-45.682c0-2.67,0.856-4.853,2.568-6.567
            c1.713-1.708,3.903-2.57,6.567-2.57h27.41v-91.358h-27.41c-2.667,0-4.853-0.855-6.567-2.568c-1.712-1.711-2.568-3.901-2.568-6.567
            v-45.679c0-2.666,0.856-4.853,2.568-6.567c1.715-1.713,3.905-2.568,6.567-2.568h91.367c2.662,0,4.853,0.855,6.561,2.568
            c1.711,1.714,2.573,3.901,2.573,6.567v146.179h27.401c2.669,0,4.859,0.855,6.57,2.566c1.704,1.712,2.566,3.901,2.566,6.567v45.683
            H292.359z" />
            </g>
          </svg>
        </span>
      </td>
      <td>
        <span [innerHTML]="currency_symbol"></span> {{altr.amount | replace}}
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td>
        <!-- + 16,00% MwSt. -->
       
        + {{offer.otherdetails.vat_heatoil | replace}}% {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}. 
      </td>
      <td>
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}}
        <span [innerHTML]="currency_symbol"></span> {{alternativeMwst | replace}}
      </td>
    </tr>
    <tr>
      <td>
        = {{ languageData?.Bestelloptionen['Var_Gesamtpreis'] || 'Gesamtpreis'}} ({{otherdata.menge | replaced}}<span *ngIf="otherdata.label == 'Heizöl'">{{volume_short}}</span>
        <span *ngIf="otherdata.label == 'Pellets'">{{weight_short}}</span>) {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
      </td>
      <td>
        {{((otherdata.label == 'Heizöl' && data.vatSettings.Heizol)?'**':'')}} 
        {{((otherdata.label == 'Pellets' && data.vatSettings.Pellets)?'**':'')}}
        <span [innerHTML]="currency_symbol"></span> {{alternativeWarenpries | replace}}
      </td>
    </tr>
  </table>
  <br />
  <!-- time depended Sec end -->
  <div class="text-right">
  <button class="au-btn btn-default" [ngStyle]="{background: colors.Grundfarbe,color: colors.Schriftfarbe_Buttons}" (click)="onclick()"
    style="margin-top:5px">{{languageData['Popups'].Var_Schlieben || 'Schließen'}}</button>
  </div>
  <div class="inline-block" *ngIf="otherdata.label == 'Heizöl' && data.vatSettings.Heizol">
    <span class="dblock">**{{languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis'}}</span>
    <span class="dblock">{{data.vatSettings.HeizolText}}</span>
  </div>
  <div class="inline-block" *ngIf="otherdata.label == 'Pellets' && data.vatSettings.Pellets">
    <span class="dblock">**{{languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis'}}</span>
    <span class="dblock">{{data.vatSettings.PelletsText}}</span>
  </div>
</div>
