import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../Services/language.service';
// import { MybigpcService } from '../../Services/mybigpc.service';

import { MatDialogRef, } from '@angular/material/dialog';
@Component({
  selector: 'app-tracking-info-popup',
  templateUrl: './tracking-info-popup.component.html',
  styleUrls: ['./tracking-info-popup.component.css']
})
export class TrackingInfoPopupComponent implements OnInit {
  languageData: any = {};
  colors: any;
  constructor(
    public dialogRef: MatDialogRef<TrackingInfoPopupComponent>,
    private langService: LanguageService,
    // private MybigpcService: MybigpcService,
  ) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData.administration || {};
    this.languageData['customersLiveTrackings'] = this.languageData?.customersLiveTrackings || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data.administration || {};
      this.languageData['customersLiveTrackings'] = this.languageData?.customersLiveTrackings || {};
    });
    // this.MybigpcService.getMinMaxData().then((res: any) => {
    //   this.colors = res.colors;
    //   console.log(this.colors);
    // });
  }

  ngOnInit(): void {
  }

  closePopup() {
    this.dialogRef.close();
  }
}
