import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { SessionData, SmallPcService } from '../Services/small-pc.service';
import { PortalAuthService } from 'app/login/auth.service';
import { LanguageService } from '../Services/language.service';
import { ICustomWindow, WindowRefService } from '../Services/window.service';


@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
  colors: any;
  url: any;
  hostname: any;
  verifiedeMail: boolean = false;
  verification: boolean = false;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  private _window: ICustomWindow;
  bigpclink: any;
  Liter: string;
  view: string;
  Product: string;
  Lieferort: string;
  href: string;
  verified: boolean = false;
  languageData: any = {};
  object: { language: string; };
  smallpclink: any;
  params: URLSearchParams;
  sessionId: string;
  abladestellen: string;
  @Input() set _minMaxData(minmaxdata) {
    this.minMaxData = minmaxdata;
    this.setMinMaxData(minmaxdata);
  };

  get _minMaxData() {
    return this.minMaxData
  };

  minMaxData: any;
  constructor(
    private windowRef: WindowRefService,
    private authService: PortalAuthService,
    private langService: LanguageService,
    private smallPCService: SmallPcService,
  ) {
    this._window = this.windowRef.nativeWindow;
  }

  async ngOnInit() {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(this.url);/*testcalc*/
    sessionStorage.setItem('isNewVisit', 'yes');
    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    };

    if (decodeURIComponent(params.get("new_user")).match(/^[0-9a-fA-F]{24}$/) && this.bigpclink != '') {
      this.Liter = decodeURIComponent(params.get('Liter')) || null;
      this.Product = decodeURIComponent(params.get('Product')) || null;
      this.view = decodeURIComponent(params.get('nextview')) || 'bigpc';
      this.Lieferort = decodeURIComponent(params.get('Lieferort')) || null;
      this.abladestellen = decodeURIComponent(params.get('abladestellen')) || '1';

      this.authService.verifyUser({ domainname: this.hostname, id: decodeURIComponent(params.get('new_user')) }).subscribe(
        result => {
          if (result.success && this.authService.isUserLoggedIn()) {
            this.verified = true
            if (this.Liter) this.href = `${this.href}&Liter=${this.Liter}`;
            if (this.Product) this.href = `${this.href}&Product=${this.Product}`;
            if (this.Lieferort) this.href = `${this.href}&Lieferort=${this.Lieferort}`;
            if (this.view) this.href = `${this.href}&view=${this.view}&Product${this.Product}&Liter=${this.Liter}&Lieferort=${this.Lieferort}`;
          }
          else if (result = "user not found") {
            this.verified = false
          }
          else {
            this.href = `http://${this.bigpclink}#&Domainname=${this.hostname}&view=bigpc`;
          }
        }
      )
    }
  };

  async login() {
    this.params = new URLSearchParams(this.url);
    this.Liter = this.params.get('Liter');
    this.Product = this.params.get('Product');
    this.Lieferort = this.params.get('Lieferort');
    this.abladestellen = this.params.get('abladestellen') || '1';
    this.sessionId = this.params.get('sid');
    if (!this.sessionId) {
      this.sessionId = this.smallPCService.getSidFromlocalStorage();
    }
    if (!this.sessionId) {
      this.sessionId = this.smallPCService.genereateNewSessionId();
    }
    // get session data from localstorage
    let currentSessionData = await this.smallPCService.getSessionData(this.sessionId);
    let newSession = <SessionData>{};
    newSession = {
      ...newSession,
      liter: this.Liter,
      lieferort: this.Lieferort,
      product: this.Product,
      abladestellen: this.abladestellen,
    };
    if (!currentSessionData) {
      currentSessionData = <SessionData>{};
      currentSessionData = { ...newSession };
    } else {
      currentSessionData = { ...currentSessionData, ...newSession };
    }
    this.smallPCService.setSessionData(this.sessionId, currentSessionData);
    if (!environment.testSite_or_thirdPartySites) {
      let url = environment.pricecalc;
      let domainname = decodeURIComponent(this.params.get("Domainname"));
      this.href = `${url}#&Domainname=${domainname}&sid=${this.sessionId}&view=bigpc&page=Dashboard`;
    } else {
      this.href = `https://${this.bigpclink}#&sid=${this.sessionId}&view=bigpc&page=Dashboard`;
    };
    this._window.location.href = this.href;
  };

  setMinMaxData(minmaxdata) {
    this.minMaxData = minmaxdata;
    this.bigpclink = this.minMaxData.matcheddomainlink;
    this.smallpclink = this.minMaxData.domain;
  };
}
