import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { AdminService } from 'app/Services/admin.service';
import { HostListener } from "@angular/core";
import { LanguageService } from '../Services/language.service';
// import { style } from '@angular/animations';
import { InvoiceComponent } from 'app/invoice/invoice.component';
import { WindowRefService, ICustomWindow } from '../Services/window.service';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { BigpcService } from 'app/Services/bigpc.service';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
// import { isEmptyObject } from 'jquery';
// import { SmallPcService } from '../Services/small-pc.service';




@Component({
  selector: 'app-bestellung',
  templateUrl: './bestellung.component.html',
  styleUrls: ['./bestellung.component.css']
})
export class BestellungComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  repeatedwordsData: any = {};
  @Output() setScroll = new EventEmitter();
  @Input('color') color;
  num = 10;
  bestellung: any;
  hostheight: number = 722;
  // document: any;
  order: any;
  rechnungAddress_object: any;
  private _window: ICustomWindow;
  isScrolled: any;
  style = [
    `
    .search-results{
      display: block;
      // max-height:${this.hostheight}px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    // body{
    //   overflow-y: scroll;
    //   display: block;
    //   max-height:${this.hostheight}px;
    // }
    `
  ]
  loader: any;
  colors: any;
  orderdetails: boolean = true;
  scrollheight: any = 0;
  billingAddress: any;
  deliveryAddresses: any[];
  showOrderDetails: boolean = false;
  selectedIndex: any;
  minMaxdata: any;
  pdf: any;
  pdf1: any;
  pdf2: any;
  viewsnack2: boolean;
  disableAnchor: boolean = false;
  hostname: any;
  co2Serverbaseurl = environment.co2Server;
  timeMode: any;
  timeZone: any;
  url: string;
  agbRequired: boolean = false;
  pdfsData: any = [];
  svgs = [
    environment.assetsUrl+'assets/svgs/search.svg',
    environment.assetsUrl+'assets/svgs/phoone.svg',
    environment.assetsUrl+'assets/svgs/ordermail.svg',
    environment.assetsUrl+'assets/svgs/pdfmodel.svg',
  ];

  pageName = "page=Bestellung";
  timeZoneFormat: any = {
    timeMode: "24h",
    timeZone: "Europe/Berlin"
  }
  loaderForScreen: boolean = false;

  constructor(
    private adminService: AdminService,
    private el: ElementRef,
    // private renderer: Renderer2,
    private windowRef: WindowRefService,
    private newBigPC: BigpcService,
    private langService: LanguageService,
    private dialog: MatDialog,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    // private smallpcService: SmallPcService
  ) {
    this._window = this.windowRef.nativeWindow;
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['administration-popups'] || {};
    this.repeatedwordsData = langData.Languagetranslation || {};
    this.languageData = langData['web-pc'] || {};

    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['administration-popups'] || {};
      this.repeatedwordsData = data.Languagetranslation || {};
      this.languageData = langData['web-pc'] || {};
    });
    this.getDynamicSVG(this.svgs)
    this._window = windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;
  }
  header = ['Date/Time', 'Produkt', 'Menge', 'Status', 'Preis(1001)', 'preis(1001)']
  bestellung_id = '';
  listData: boolean = true;
  orderId: any;



  async ngOnInit() {
    this.colors = '5px solid ' + this.color;
    await this.getBestellung();
    let langData = this.langService.getLocalLanguageData();
    this.hostname = this._window.location.hostname;
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    var params = new URLSearchParams(this.url);
    this.selectedIndex = decodeURIComponent(params.get("orderIndex"));
    if (this.url.includes("subPage=showDetails")) {
      setTimeout(() => {
        this.viewDetails(this.selectedIndex);
      }, 200);
    };

  };

  getBestellung = () => {
    this.loaderForScreen = true;
    this.adminService.getBestellung({ datalimit: 0 }).subscribe((result: any) => {
      this.loaderForScreen = false;
      if (result.loader == true) {
        this.bestellung = result.allOrders;
        if (result.timeZoneSettings) {
          this.timeZoneFormat = {
            timeMode: result.timeZoneSettings?.timeMode, 
            timeZone: result.timeZoneSettings?.timeZone
          }
        }
        this.pdfsData = result.pdfsData || [];
        (this.bestellung || []).forEach(element => {
          let newPdfData = [];
          (element?.termsAndConditions || []).forEach(pdf => {
            let find = this.pdfsData.find(e => e._id == pdf._id && pdf.checked == true);
            if (find) {
              newPdfData.push({ ...find, ...pdf })
            }
          });
          element.termsAndConditions = newPdfData;
        });
        this.loader = result.loader;
        this.loading = false;

        if (this.url.includes("subPage=showInvoice")) {
          var params = new URLSearchParams(this.url);
          this.orderId = decodeURIComponent(params.get("_id"));
          let findDoc = (this.bestellung || []).find((doc) => doc._id == this.orderId);
          this.invoice(findDoc);
        };
      }
      this.loading = false;
    }, () => {
      this.loaderForScreen = false;
    });
  };

  protected items = ['Item1', 'Item2', 'Item3'];

  protected buffer = [1, 2];
  protected loading: boolean = false;

  // protected fetchMore(event: IPageInfo) {
  //   this.loading = true;
  //     if (event.endIndex !== this.buffer.length-1) return;
  //     this.loading = true;
  //     this.fetchNextChunk(10, 20).then(chunk => {
  //       this.loading = true;
  //         this.buffer = this.buffer.concat(chunk);
  //         this.loading = false;
  //     }, () => this.loading = false);
  // }
  // // : Promise<>
  // protected fetchNextChunk(skip: number, limit: number):Promise<any> {
  //   return new Promise((resolve, reject) => {
  //       this.loading = true;
  //       this.adminService.getBestellung({datalimit:this.bestellung.data.limit}).subscribe(result => {
  //         if (result.success) {
  //           this.bestellung = result.data;
  //           this.hostheight =this.hostheight + 700 ;
  //           this.loading = true;
  //         }
  //       })
  //       resolve(this.bestellung);
  //       reject("err")
  //       this.loading = false;
  //       // this.fetchMore(event: IPageInfo)
  //           })
  // }
  // @HostBinding('class.search-results')
  @HostListener('scroll', ['$event.target'])
  onScroll(elem) {
    if (this.orderdetails == true) {
      this.scrollheight = elem.scrollTop;
      this.scrollheight = elem.scrollTop;
      if ((elem.offsetHeight + elem.scrollTop) >= elem.scrollHeight) {
        if (this.loader == true) {
          if (this.bestellung.length > 12) {
            this.loading = true;
          }
          this.adminService.getBestellung({ datalimit: this.bestellung.length }).subscribe(result => {
            if (result.loader == true) {
              this.bestellung = result.allOrders;
              this.loader = result.loader;
              this.hostheight = this.hostheight + 700;
              this.loading = false;
              return this.bestellung;
            } else {
              this.loading = false;
              this.loader = false;
            }
          })
        }
      }
    }
  }
  viewDetails(index) {
    if (this.selectedIndex == index) {
      this.showOrderDetails = !this.showOrderDetails;
    } else {
      this.showOrderDetails = true;
      let url = window.location.href; /*testcalc*/
      let newUrl = url;
      newUrl = newUrl.replace(`&subPage=showDetails&orderIndex=${this.selectedIndex}`, "");
      window.history.replaceState('', document.title, newUrl);
    }
    this.selectedIndex = index;
    let keyName = `&subPage=showDetails&orderIndex=${index}`;
    this.addKeyToUrl(this.pageName, keyName);

    // this.deliveryAddresses = [];
    // this.el.nativeElement.scrollTop = 0;
    // this.orderdetails = false;
    // this.bestellung_id = orderInfo.id;
    // this.order = orderInfo;
    // this.billingAddress = orderInfo.addresses.find(adrs => adrs.type == "Billing");
    // this.deliveryAddresses = orderInfo.addresses.filter(adrs => adrs.type == "Delivery");
    // this.listData = false;

  };

  addKeyToUrl(page, key) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;

    if (newUrl.includes(page) && !url.includes(key)) {
      newUrl = `${newUrl}${key}`;
      window.history.replaceState('', document.title, newUrl);
    };
  }

  removeKeyFromUrl(page, key) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;

    if (newUrl.includes(page)) {
      newUrl = newUrl.replace(key, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  closeOrderDetails(selectedIndex) {
    this.showOrderDetails = false;
    let keyName = `&subPage=showDetails&orderIndex=${selectedIndex}`;
    this.removeKeyFromUrl(this.pageName, keyName);
  };


  viewList() {
    this.el.nativeElement.scrollTop = this.scrollheight;
    this.orderdetails = true;
    this.listData = true;
    this.bestellung_id = '';
  };

  invoice(item) {
    let invoiceOrderId = item._id
    let keyName = `&subPage=showInvoice&_id=${invoiceOrderId}`;
    this.addKeyToUrl(this.pageName, keyName);
    if (item && item.invoicePdf && item.invoicePdf._id) {
      this.newBigPC.downloadInvoice({ gridFsId: item.invoicePdf._id, pdfname: item.invoicePdf.filename, domain: this.hostname }).subscribe((data123: any) => {
        var pdfdata = data123.data.data.data;
        var byteCharacters = atob((pdfdata));
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        // var blob = new Blob([byteArray], {
        //   type: undefined
        // });
        let newBlob = new Blob([byteArray], { type: 'application/pdf' })
        let bloburl = URL.createObjectURL(newBlob);
        // if (navigator.userAgent.indexOf("Firefox") != -1) {
        //   // importedSaveAs(blob, pdfdata);
        //   return false
        // }
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {// If Internet Explorer
          FileSaver.saveAs(newBlob, `${item.invoicePdf.filename}`);
          return false
        }
        if (!!navigator.userAgent.match(/Edge/)) {// If edge Explorer
          FileSaver.saveAs(newBlob, `${item.invoicePdf.filename}`);
          return false
        }
        try {
          let win = window.open('about:blank', '_blank'); // window.open(bloburl, '_blank')
          win.document.write('<iframe src="' + bloburl + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
          win.document.title = item.invoicePdf.filename;
          win.document.close();

          if (!win || win.closed || typeof win.closed == 'undefined') {
            FileSaver.saveAs(newBlob, `${item.invoicePdf.filename}`);
            return false;
          }
        } catch (error) {
          FileSaver.saveAs(newBlob, `${item.invoicePdf.filename}`);
        }
      })
    } else {
      const dialogRef = this.dialog.open(InvoiceComponent,
        {
          width: '500px',
          disableClose: true,
          data: { colors: this.color }
        }
      );
      dialogRef.afterClosed().subscribe(() => {
        let keyName = `&subPage=showInvoice&_id=${invoiceOrderId}`;
        this.removeKeyFromUrl(this.pageName, keyName);
      });
    }

  };

  downloadFile(data) {
    let object = { pdfId: data };
    if (this.disableAnchor) {
      return false;
    }
    this.disableAnchor = true;
    this.newBigPC.pdfiledownload(object, this.hostname).subscribe(
      (data123: any) => {
        this.disableAnchor = false;
        if (data123.sucess) {
          var pdfdata = data123.data.data.data;
          var byteCharacters = atob(pdfdata);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let fileName = `${data}.pdf`;
          if (data123.data.data.name) {
            if (fileName.includes("pdf")) {
              fileName = data123.data.data.name;
            }
          }
          var byteArray = new Uint8Array(byteNumbers);
          new Blob([byteArray], {
            type: undefined,
          });
          let newBlob = new Blob([byteArray], { type: "application/pdf" });
          let bloburl = URL.createObjectURL(newBlob);
          // if (navigator.userAgent.indexOf("Firefox") != -1) {
          //   // importedSaveAs(blob, pdfdata);
          //   return false
          // }
          let ua = window.navigator.userAgent;
          let msie = ua.indexOf("MSIE ");
          if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer
            FileSaver.saveAs(newBlob, `${fileName}`);
            return false;
          }
          if (!!navigator.userAgent.match(/Edge/)) {
            // If edge Explorer
            FileSaver.saveAs(newBlob, `${fileName}`);
            return false;
          }
          try {
            let win = window.open("about:blank", "_blank"); // window.open(bloburl, '_blank')
            win.document.write(
              '<iframe src="' +
              bloburl +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            );
            win.document.title = fileName;
            win.document.close();

            if (!win || win.closed || typeof win.closed == "undefined") {
              FileSaver.saveAs(newBlob, `${fileName}`);
              return false;
            }
          } catch (error) {
            FileSaver.saveAs(newBlob, `${fileName}`);
          }
        } else {
          this.viewsnack2 = true;
          setTimeout(() => {
            this.viewsnack2 = false;
          }, 4500);
        }
      }, () => {

      }
    );
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
     
      this.httpClient.get(item, { responseType: 'text' })
        .subscribe((logo) => {
          logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
          logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
          logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
          logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
          this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
        })
    });
  };
}
