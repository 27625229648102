<!-- If ratePayment false -->
<div class="aupris_main my-full-screen-dialog" *ngIf="!sessionData?.ratePaymentSettings?.status">
  <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">{{
    languageData?.Bestelloptionen['Var_Preisdetails'] || 'Preisdetails'}} </div>
  <!-- Baseprice pricefactor -->
  <table>
    <tr>
      <td>
        Basispreis
        {{priceData?.priceFactor}}
        {{symbol}}
        {{languageData['Bestelloptionen'].Var_netto || 'netto'}}
      </td>
      <td>
        <span>
          {{priceData?.currencySymbol}}
        </span>
        {{priceData?.basicPricePriceFactorNett | langReplacer : languageVar }}
      </td>
    </tr>
    <tr *ngIf="(!priceData.pc100lWithoutFlatRate || !priceData.identifyPackagesInPc) && priceData.surcharge">
      <td>+ {{priceData?.surcharge?.name}}</td>
      <td><span>{{priceData.currencySymbol}}</span>
        {{priceData.surchargePriceFactorNett | langReplacer : languageVar }}
      </td>
    </tr>
    <!-- Vouchers -->
    <tr *ngIf="priceData.voucherInfo">
      <td>
        <span>- {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</span>
      </td>
      <td>
        <span>
          <span>{{priceData.currencySymbol}}</span>
          {{priceData.voucherPriceFactorNett | langReplacer : languageVar }}
        </span>
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black;">
      <td>+
        {{priceData.productTax.taxRate | langReplacer : languageVar }}
        % {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}.
      </td>
      <td><span>{{priceData?.productTax?.status ? '**' : '' }} {{priceData.currencySymbol}}</span>
        {{priceData.taxPriceFactor | langReplacer : languageVar}}
      </td>
    </tr>
    <tr>
      <td><b>= Preis pro {{priceData?.priceFactor}}{{symbol}}
          {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}</b></td>
      <td>
        <b>
          <span>
            {{priceData?.productTax?.status ? '**' : '' }}
            {{priceData.currencySymbol}}
          </span>
          {{priceData.grossPriceFactor | langReplacer : languageVar }}
        </b>
      </td>
    </tr>
  </table>
  <br>
  <!-- Baseprice without pricefactor -->
  <table>
    <tr>
      <td>{{ languageData?.Bestelloptionen['Var_Basispreis'] || 'Basispreis '}}
        ({{priceData?.quantity | replaced}}{{symbol}})
        {{languageData['Bestelloptionen'].Var_netto || 'netto'}}
      </td>
      <td>
        <span>{{priceData?.currencySymbol}}</span>
        {{priceData.basicPriceNett | langReplacer : languageVar }}
      </td>
    </tr>
    <tr *ngIf="priceData?.surchargeNett">
      <td>+ {{sessionData?.stations}} x {{priceData?.surcharge?.name}}</td>
      <td><span>{{priceData?.currencySymbol}}</span>
        {{priceData?.surchargeNett | langReplacer : languageVar }}
      </td>
    </tr>
    <!-- Vouchers -->
    <tr *ngIf="priceData.voucherInfo">
      <td>
        <span>- {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</span>
      </td>
      <td>
        <span>
          <span>{{priceData.currencySymbol}}</span>
          {{priceData.voucherNett | langReplacer : languageVar }}
        </span>
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black;">
      <td>+
        {{priceData.productTax.taxRate | langReplacer : languageVar }}
        % {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}.
      </td>
      <td><span>{{priceData?.productTax?.status ? '**' : '' }} {{priceData.currencySymbol}}</span>
        {{priceData.tax | langReplacer : languageVar }}
      </td>
    </tr>
    <tr>
      <td>
        <b>
          = {{ languageData?.Bestelloptionen['Var_Gesamtpreis'] || 'Gesamtpreis'}}
          ({{priceData?.quantity | replaced}}{{symbol}})
          {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
        </b>
      </td>
      <td>
        <b>
          <span>
            {{priceData?.productTax?.status ? '**' : '' }}
            {{priceData?.currencySymbol}}
          </span>
          {{priceData?.gross | langReplacer : languageVar }}
        </b>
      </td>
    </tr>
  </table>
  <br>
  <!-- timeDepandentSurcharges start -->
  <table class="frame table table-striped"
    *ngIf="priceData.timeDepandentSurcharges &&(priceData.timeDepandentSurcharges.length>0)">
    <tbody>
      <tr>
        <td>{{languageData['Bestelloptionen'].Var_Alternative || 'Alternativ'}}:</td>
        <td></td>
      </tr>
      <tr *ngFor="let item of timeDepandentSurcharges">
        <td>+ {{item?.name}} <span class="au-glyphicon au-glyphicon-info-sign">
            <span [innerHTML]="infoSvg" *ngIf="infoSvg" (click)="alternativePopup(item.specialText)"></span>
            <img *ngIf="!infoSvg" [src]="'assets/infoSign.svg' | assetsUrl"
              (click)="alternativePopup(item.specialText)">
          </span></td>
        <td *ngIf="!item.conversion == 1"><span>{{priceData.currencySymbol}}</span>
          {{item.timeDepandantSurchargeNett | langReplacer : languageVar }}
        </td>
        <td *ngIf="item.conversion == 1"><span>{{priceData.currencySymbol}}</span>
          {{item.timeDepandantSurchargeNett| langReplacer : languageVar}}
        </td>
      </tr>
      <tr style="border-bottom: 1px solid black;">
        <td>+
          {{priceData.productTax.taxRate | langReplacer : languageVar}}%
          {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}.
        </td>
        <td><span>{{priceData?.productTax?.status ? '**' : '' }} {{priceData.currencySymbol}}</span>
          {{priceData.taxTimeDepandandentSurchargeNett | langReplacer : languageVar }}
        </td>
      </tr>
      <tr>
        <td>
          = {{ languageData?.Bestelloptionen['Var_Gesamtpreis'] || 'Gesamtpreis'}}
          ({{priceData?.quantity | replaced}}{{symbol}})
          {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
        </td>
        <td>
          <span>
            {{priceData?.productTax?.status == true ? '**' : '' }}
            {{priceData?.currencySymbol}}
          </span>
          {{priceData?.grossWithTimeDepandantCharges | langReplacer : languageVar}}
        </td>
      </tr>
    </tbody>
  </table>
  <!-- timeDepandentSurcharges end -->
  <br>
  <!-- Schließen Button -->
  <div class="text-right">
    <button class="au-btn btn-default"
      [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="onclick()"
      style="margin-top:5px">{{languageData['Popups'].Var_Schlieben || 'Schließen'}}</button>
  </div>
  <!-- If Producttax status true -->
  <div class="inline-block" *ngIf="priceData.productTax.status">
    <span class="dblock">**{{languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis'}}</span>
    <span class="dblock">{{priceData.productTax.infoText}}</span>
  </div>
</div>

<!-- If ratePayment true -->
<div class="aupris_main my-full-screen-dialog" *ngIf="sessionData?.ratePaymentSettings?.status">
  <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">
    {{ languageData?.Bestelloptionen['Var_Preisdetails'] || 'Preisdetails'}}
  </div>
  <!-- Baseprice pricefactor -->
  <table>
    <tr>
      <td> Basispreis {{priceFactor}}
        {{symbol}}{{languageData['Bestelloptionen'].Var_netto || 'netto'}}</td>
      <td><span>{{priceData.currencySymbol}}</span>
        {{priceData.basicPricePriceFactorNett | langReplacer : languageVar }}
      </td>
    </tr>
    <br>
    <tr>
      <td>
        {{ languageData?.Bestelloptionen['Var_Basispreis'] || 'Basispreis '}}
        ({{priceData.quantity | replaced}}{{symbol}})
        {{languageData['Bestelloptionen'].Var_netto || 'netto'}}
      </td>
      <td>
        <span>{{priceData.currencySymbol}}</span>
        {{priceData.basicPriceNett | langReplacer : languageVar }}
      </td>
    </tr>
    <tr>
      <td>
        <span>+ {{ languageData?.Bestelloptionen['Var_servicefeefinancing'] || 'Servicegebühr Finanzierung '}}</span>
      </td>
      <td>
        <span>{{priceData?.currencySymbol}}</span>
        {{priceData.serviceChargeForFinance | langReplacer : languageVar }}
      </td>
    </tr>
    <tr *ngIf="priceData?.surchargeNett">
      <td>+ {{priceData?.surcharge?.name}}</td>
      <td><span>{{priceData.currencySymbol}}</span>
        {{priceData.surchargeNett | langReplacer : languageVar}}
      </td>
    </tr>
    <!-- Vouchers -->
    <tr *ngIf="priceData?.ratePaymentDetails?.gutschein">
      <td>
        <span>- {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}</span>
      </td>
      <td>
        <span>
          <span>{{priceData.currencySymbol}}</span>
          {{priceData?.ratePaymentDetails?.gutschein | langReplacer : languageVar}}
        </span>
      </td>
    </tr>
    <!-- Voucher end -->
    <tr style="border-bottom: 1px solid black;">
      <td>+
        {{priceData.productTax.taxRate | langReplacer : languageVar }}%
        {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}.
      </td>
      <td><span>{{priceData?.productTax?.status ? '**' : '' }} {{priceData.currencySymbol}}</span>
        {{priceData.tax | langReplacer : languageVar}}
      </td>
    </tr>
    <tr>
      <td>
        <b>
          = {{ languageData?.Bestelloptionen['Var_Warenpreis'] || 'Warenpreis'}}
          ({{priceData.quantity | replaced}}{{symbol}})
          {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
        </b>
      </td>
      <td>
        <b>
          <span>
            {{priceData?.productTax?.status ? '**' : '' }} {{priceData.currencySymbol}}
          </span>
          {{priceData.gross | langReplacer : languageVar}}
        </b>
      </td>
    </tr>
  </table>
  <br>
  <!-- timeDepandentSurcharges start -->
  <table class="frame" style="background: #dfdfdf;"
    *ngIf="priceData.timeDepandentSurcharges &&(priceData.timeDepandentSurcharges.length>0)">
    <tr>
      <td>{{languageData['Bestelloptionen'].Var_Alternative || 'Alternativ'}}:</td>
      <td></td>
    </tr>
    <tr *ngFor="let item of timeDepandentSurcharges">
      <td>+ {{item?.name}} <span class="au-glyphicon au-glyphicon-info-sign">
          <span [innerHTML]="infoSvg" *ngIf="infoSvg" (click)="alternativePopup(item.specialText)"></span>
          <img *ngIf="!infoSvg" [src]="'assets/infoSign.svg' | assetsUrl" (click)="alternativePopup(item.specialText)">
        </span></td>
      <td *ngIf="!item.conversion == 1"><span>{{priceData.currencySymbol}}</span>
        {{item.timeDepandantSurchargeNett | langReplacer : languageVar }}
      </td>
      <td *ngIf="item.conversion == 1"><span>{{priceData.currencySymbol}}</span>
        {{item.timeDepandantSurchargeNett | langReplacer : languageVar}}
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black;">
      <td>+
        {{priceData.productTax.taxRate | langReplacer : languageVar }}%
        {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}.
      </td>

      <td><span>{{priceData?.productTax?.status ? '**' : '' }} {{priceData.currencySymbol}}</span>
        {{priceData.taxTimeDepandandentSurchargeNett | langReplacer : languageVar}}
      </td>
    </tr>
    <tr>
      <td>
        = {{ languageData?.Bestelloptionen['Var_Warenpreis'] || 'Warenpreis'}}
        ({{priceData.quantity | replaced}}{{symbol}})
        {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
      </td>
      <td>
        <span>
          {{priceData?.productTax?.status ? '**' : '' }} {{priceData.currencySymbol}}
        </span>
        {{priceData.grossWithTimeDepandantCharges | langReplacer : languageVar}}
      </td>
    </tr>
  </table>
  <!-- timeDepandentSurcharges end -->
  <br>
  <!-- Ratepayment bifurication -->
  <table>
    <tr>
      <td>
        {{ languageData?.Bestelloptionen['Var_financingterm'] || 'Finanzierung Laufzeit: '}}:
        {{sessionData.ratePaymentSettings?.zahlungRate}}
        {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}
      </td>
      <td></td>
    </tr>
    <tr>
      <td>{{sessionData.ratePaymentSettings?.zahlungRate - 1}} X {{ languageData?.Bestelloptionen['Var_monthly'] ||
        'Monatsrate
        brutto'}}</td>
      <td><span>{{priceData.currencySymbol}}</span>
        {{priceData.installment | langReplacer : languageVar}}
      </td>
    </tr>
    <tr style="border-bottom: 1px solid black">
      <td>1 X {{ languageData?.Bestelloptionen['Var_finalinstallment'] || 'Schlussrate brutto'}}</td>
      <td><span>{{priceData.currencySymbol}}</span>
        {{priceData.lastInstallment | langReplacer : languageVar}}
      </td>
    </tr>
    <tr>
      <td>
        <b>
          = {{ languageData?.Bestelloptionen['Var_totalfinance'] || 'Gesamtsumme Finanzierung'}}
        </b>
      </td>
      <td>
        <b>
          <span>{{priceData.currencySymbol}}</span>
          {{priceData?.grossWithFinance | langReplacer : languageVar }}
        </b>
      </td>
    </tr>
  </table>
  <!-- Ratepayment bifurication end -->
  <!-- Schließen Button -->
  <div class="text-right">
    <button class="au-btn btn-default"
      [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="onclick()"
      style="margin-top:5px">{{languageData['Popups'].Var_Schlieben || 'Schließen'}}</button>
  </div>
  <!-- If Producttax status true -->
  <div class="inline-block" *ngIf="priceData.productTax.status">
    <span class="dblock">**{{languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis'}}</span>
    <span class="dblock">{{priceData.productTax.infoText}}</span>
  </div>
</div>