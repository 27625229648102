import { Component, OnInit, Input, Output, EventEmitter, DoCheck, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { LanguageService } from '../Services/language.service';
import { AdminService } from 'app/Services/admin.service';
// import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CompleterService } from '../ng2Completer/ng2-completer';
import { UserAddress } from '../models/portalModels/portalUserAddress.interface';
import { SafePipe } from '../pipes/safe.pipe';

import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const names = super.getDayOfWeekNames('short');
    return names.map(name => name.substr(0, 3));
  }

  format(date: Date, displayFormat: Object): string {
    // console.log("case1")
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // Return the format as per your requirement
    return `${this._to2digit(day)}.${this._to2digit(month)}.${year}`;
  };

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}
@Component({
  selector: 'app-pc-create-address',
  templateUrl: './pc-create-address.component.html',
  styleUrls: ['./pc-create-address.component.css'],
  providers: [SafePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS] }
  ]
})
export class PcCreateAddressComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  @Input('colors') colors;
  @ViewChild('autocomplete') input;

  languageData: any = {};
  Rechungsadresse: boolean;
  lieferAdresseForm: FormGroup;
  manualaddress: boolean = false;
  rechangecheckbox: boolean;
  _editAddress: UserAddress;
  hideBillingAddress: boolean = true;
  savenewAddress: boolean = false;
  datepicker: string;
  kundennummer: any = '';
  today = new Date(); oday = new Date();
  plzOptions: any = [];
  option: any;
  dataService: any;
  address: number;
  locationarray = [] as any;
  lieferplz: any;
  lieferort: any;

  @Input() addressType: string;
  @Input() addNewAddress: boolean;
  @Input() lieferIndex: any;
  g
  @Input() plzInfo: string;
  @Input() plzId;
  @Input() createAddress;

  plz: string;
  stadt: string;
  edit: boolean;
  existedAddress: boolean;
  // selectedOption: any;
  formSubmitted: boolean = false;
  msg: string;
  innerWidth: number;

  @HostListener('window:resize', ['$event'])

  @Input('editAddress')
  set editAddress(editAdd) {
    this.edit = editAdd;
  }
  get editAddress() {
    return this.edit
  };

  previousplz: string;
  postcode: any;
  plzValue: string;
  plzCity: string;
  plzSuburb: string;
  @Input('editableAddress')
  set editableAddress(address: UserAddress) {
    if (!address || (Object.keys(address) && Object.keys(address).length == 0)) {
      this.hideBillingAddress = true;
      this._editAddress = address;
    } else {
      this._editAddress = address;
      this.previousplz = address.zipCode;
      this.hideBillingAddress = false;
      address.zipCode = `${address.zipCode} ${address.city} ${address.suburb}`;
      delete address.driverInformation;
      this.lieferAdresseForm.patchValue(address);

      if (address.dateOfBirth == 'Invalid date') {
        this.lieferAdresseForm.controls['dateOfBirth'].setValue('')
      };
      if (address.dateOfBirth) {
        this.onDateChange(address.dateOfBirth)
      };

      if (!address.country || !address.countryCode) {
        this.lieferAdresseForm.controls['country'].setValue('Germany');
        this.lieferAdresseForm.controls['countryCode'].setValue('DE')
      };

      this.lieferAdresseForm.controls['dateOfBirth'].setValidators([]);
    };
  };

  get editableAddress(): UserAddress {
    return this._editAddress;
  };

  @Output('abort') abort: EventEmitter<boolean> = new EventEmitter();
  @Output() addAdressStagetwo = new EventEmitter();

  public currentTitle = [
    { name: "" },
    { name: "Herr" },
    { name: "Frau" },
    { name: "Divers" },
  ];
  datePatternRegex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;

  constructor(public fb: FormBuilder,
    private langService: LanguageService,
    private admin: AdminService,
    public dialog: MatDialog,
    private SafePipe: SafePipe,
    public completerService: CompleterService,
    private adapter: DateAdapter<any>) {
    const expiryRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.lieferAdresseForm = this.fb.group({
      currentTitle: ['', Validators.compose([Validators.required])],
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      companyName: [''],
      addressZusatz: [''],
      streetName: ['', [Validators.required]],
      houseNo: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      city: ['',],
      phoneNumber: ['', [Validators.required]],
      eMail: ['', [Validators.required, Validators.pattern(expiryRegex)]],
      comments: [''],
      dateOfBirth: [''],
      _id: [''],
      auprisId: [''],
      isPrimary: [false],
      accountType: ['portal'],
      plzId: [''],
      country: ['Germany'],
      countryCode: ['DE'],
      suburb: [''],
      ort: [''],
    });


    this.today.setDate(this.today.getDate() + 0);
    let plzFormControl = this.lieferAdresseForm.controls['zipCode'];
    // console.log(plzFormControl)
    this.plzChanges(plzFormControl);
  };

  ngOnInit() {
    let langCode = localStorage.getItem('language') == "GEN" ? 'de' : 'en';
    this.adapter.setLocale(langCode);
    this.innerWidth = window.innerWidth;
    if (this.addNewAddress && !this.editAddress) {
      this.lieferAdresseForm.reset();
      this.savenewAddress = this.addressType !== 'billing';

      this.lieferAdresseForm.controls['countryCode'].setValue('DE');
      this.lieferAdresseForm.controls['country'].setValue('Germany');
      this.lieferAdresseForm.controls['accountType'].setValue('portal');
    };

    this.lieferAdresseForm.controls['countryCode'].setValue('DE');
    this.lieferAdresseForm.controls['country'].setValue('Germany');
    this.lieferAdresseForm.controls['accountType'].setValue('portal');
    this.plzValue = this.plzInfo;

    if (this.plzValue) {
      let plzArry = this.plzInfo.split(' ');
      if (plzArry.length) {
        this.plz = plzArry[0];
        this.plzCity = plzArry[1];
        this.plzSuburb = plzArry[2];
        this.stadt = `${plzArry[1]} ${plzArry[2]}`;
      }
    };

    if (this.addressType == 'primary' && this.addNewAddress) {
      this.lieferAdresseForm.controls['zipCode'].setValue(this.plzValue);
      this.lieferAdresseForm.controls['plzId'].setValue(this.plzId);
    };

    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this.lieferAdresseForm.valueChanges.subscribe(val => {
      if (this.lieferAdresseForm.dirty) {
        Object.keys(this.lieferAdresseForm.controls).forEach(field => {
          this.lieferAdresseForm.value[`${field}`] = this.SafePipe.transform(this.lieferAdresseForm.value[`${field}`], 'html');
          if (this.lieferAdresseForm.value[`${field}`] == ' ' || this.lieferAdresseForm.value[`${field}`] == 'null') {
            this.lieferAdresseForm.value[`${field}`] = '';
          };
        });
      };
    });
  };

  plzChanges(plzFormControl: AbstractControl,) {
    let plZVal = this.lieferAdresseForm.controls['zipCode'].value;
    if (plZVal) {
      this.getPlz(plZVal)
    };

    // plzFormControl.valueChanges.pipe(
    //   debounceTime(0),
    //   switchMap(term => {
    //     console.log(term)
    //     if (term && term.length >= 4) {
    //       let zipCodeTrim = term.substring(0, 5);
    //       let objectname = { event: zipCodeTrim, product: "", addressType: this.addressType };
    //       return this.admin.getGermanPostal(objectname);
    //     } else {
    //       return this.plzOptions = [];
    //     }
    //   })
    // ).subscribe(data => {
    //   if (data && data.length !== 0) {
    //     let Options = data
    //     this.plzOptions = data.data;
    //     this.plzOptions = Options.sort((a, b) => {
    //       if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
    //       if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
    //     })
    //   } else {
    //     this.plzOptions = [];
    //     this.msg = "Nur Auswahl aus dem Dropdown Menü zulässig!"
    //   }
    // });
  };

  validate(e) {
    var regex = new RegExp("[a-zA-ZäöüÄÖÜß]");
    var key = e.keyCode || e.which;
    key = String.fromCharCode(key);
    // console.log(key)
    if (!regex.test(key)) {
      e.returnValue = false;
      if (e.preventDefault) {
        e.preventDefault();
      };
    };
  };

  changeMyControl(): void {
    // if (this.selectedOption == undefined) {
    //   this.lieferAdresseForm.controls['zipCode'].setErrors({ 'incorrect': true });
    // }
  };

  checkValue(value: any) {
    this.savenewAddress = value.checked;
  };

  async submitForm() {
    Object.keys(this.lieferAdresseForm.controls).forEach(field => {
      if (this.lieferAdresseForm.value[`${field}`] == '' || this.lieferAdresseForm.value[`${field}`] == undefined) {
        this.lieferAdresseForm.controls[`${field}`].setValue('');
        this.lieferAdresseForm.controls[`${field}`].markAsDirty();
      };
    });

    if (this.addNewAddress) {
      if (this.lieferAdresseForm.invalid) {
        Object.keys(this.lieferAdresseForm.controls).forEach(field => {
          const control = this.lieferAdresseForm.get(field);
          if (control instanceof FormControl) {
            control.markAsTouched({
              onlySelf: true
            });
          }
        });
      };

      if (this.lieferAdresseForm.valid) {
        if (this.edit) {
          if (this.addressType === 'primary') {
            this.lieferAdresseForm.controls['zipCode'].setValue(this.plz);
            this.lieferAdresseForm.controls['plzId'].setValue(this.plzId);
            this.lieferAdresseForm.controls['ort'].setValue(this.stadt);
            this.lieferAdresseForm.controls['city'].setValue(this.plzCity);
            this.lieferAdresseForm.controls['suburb'].setValue(this.plzSuburb);
          } else {
            this.lieferAdresseForm.controls['zipCode'].setValue(this.lieferplz || this.previousplz);
          };
        } else {
          if (this.addressType === 'primary') {
            this.lieferAdresseForm.controls['zipCode'].setValue(this.plz);
            this.lieferAdresseForm.controls['plzId'].setValue(this.plzId);
            this.lieferAdresseForm.controls['ort'].setValue(this.stadt);
            this.lieferAdresseForm.controls['city'].setValue(this.plzCity);
            this.lieferAdresseForm.controls['suburb'].setValue(this.plzSuburb);
          } else {
            this.lieferAdresseForm.controls['zipCode'].setValue(this.lieferplz || this.previousplz);
          };;
        };


        let obj = this.lieferAdresseForm.value;
        if (this.savenewAddress) {
          if (this._editAddress && this._editAddress._id) {
            await this.updateAddress(obj);
            this.addAdressStagetwo.emit(
              {
                address: obj,
                addressType: this.addressType,
                lieferIndex: this.lieferIndex,
                changeRemaining: true
              }
            );
            this.abort.emit(true);
          } else {
            this.formSubmitted = true;
            this.admin.addAddress({ addresses: obj }).subscribe(result => {
              this.formSubmitted = false;
              if (result.success) {
                this.lieferAdresseForm.controls['_id'].setValue(result.data._id);
                this.addAdressStagetwo.emit(
                  {
                    address: obj,
                    addressType: this.addressType,
                    lieferIndex: this.lieferIndex,
                    changeRemaining: true
                  }
                );
                this.abort.emit(true);
              };
            }, error => {
              this.formSubmitted = false;
            });
          };
        } else {
          this.addAdressStagetwo.emit(
            {
              address: obj,
              addressType: this.addressType,
              lieferIndex: this.lieferIndex,
              changeRemaining: false
            }
          );
          this.abort.emit(true);
        };
      } else {
        this.lieferAdresseForm.controls.markAllAsTouched;
      }
    } else {
      // console.log(this.lieferAdresseForm.value, this.lieferAdresseForm.valid);
      if (this.lieferAdresseForm.invalid) {
        Object.keys(this.lieferAdresseForm.controls).forEach(field => {
          const control = this.lieferAdresseForm.get(field);
          if (control instanceof FormControl) {
            control.markAsTouched({
              onlySelf: true
            });
          }
        });
      };
      this.submit();
    };
  };

  submit() {
    var obj = [];
    if (this.formSubmitted) {
      return;
    };

    if (this.lieferAdresseForm.invalid) {
      return;
    };

    if (this.rechangecheckbox && this.lieferAdresseForm.invalid) {
      return;
    };

    let formHere = {
      ... this.lieferAdresseForm.value,
      zipCode: this.lieferplz
    };

    if (!this.hideBillingAddress && this.lieferAdresseForm.controls['_id'].value) {
      return this.updateAddress(formHere);
    };

    if (this.lieferAdresseForm.valid) {
      // this.lieferAdresseForm.controls['zipCode'].setValue(this.lieferplz);
      this.lieferAdresseForm.controls['ort'].setValue(this.lieferort);
      obj = [
        ...obj,
        formHere,
      ];
    };

    this.formSubmitted = true;
    this.admin.addAddress({ addresses: obj }).subscribe(result => {
      this.formSubmitted = false;
      if (result.success) {
        this.lieferAdresseForm.reset();
        this.abort.emit(true)
      };
    }, error => {
      this.formSubmitted = false;
    });
  };

  updateAddress = (address) => {
    if (this.lieferAdresseForm.controls['zipCode'].touched) {
      this.postcode = address.zipCode;
    } else {
      this.postcode = this.previousplz
    };

    let addressObj = {
      ...address,
      zipCode: this.postcode
    };
    this.admin.updateAddress(addressObj).subscribe(result => {
      this.lieferAdresseForm.reset();
      this.abort.emit(true);
    }, error => {
      console.log(error)
    });
  };

  addAdress() {
    let obj = this.lieferAdresseForm.value
    this.formSubmitted = true;
    this.admin.addAddress({ addresses: obj }).subscribe(result => {
      this.formSubmitted = false;
      if (result.success) {
        this.lieferAdresseForm.reset();
        this.abort.emit(true);
      } else {
        this.formSubmitted = false;
      }
    })
  };

  cancelBtn() {
    this.abort.emit(true);
  };

  onTouch(formName, controlName) {
    formName.controls[controlName].markAsTouched()
  };

  sortedArray;
  getPlz(e) {
    if (e?.length >= 4) {
      let objectname = {
        event: e,
        product: "",
        addressType: this.addressType
      };
      let existedPostalCode = this.plzOptions.find((item) => objectname.event.trim() == item.Lieferort.trim());
      if (!existedPostalCode) {
        this.admin.getGermanPostal(objectname).subscribe((data: any) => {
          if (data && data.length != 0) {
            this.plzOptions = data;
            let Options = data;
            this.plzOptions = Options.sort((a, b) => {
              if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
              if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
            });
            // console.log('options', Options)
            // console.log('plzOptions', this.plzOptions)
          } else {
            this.plzOptions.length = 0;
            this.msg = "Nur Auswahl aus dem Dropdown Menü zulässig!";
          };
        });
      }

    } else {
      this.plzOptions = [];
      // this.msg = "Nur Auswahl aus dem Dropdown Menü zulässig!";
    };
  };

  // getPlz(Event: any) {
  //   if (Event && Event !== undefined && Event.length >= 4) {
  //     let objectname = { event: Event, product: "", addressType: this.addressType };
  //     this.admin.getGermanPostal(objectname).subscribe(data => {
  //       if (data && data.length != 0) {
  //         let Options = data;
  //         this.plzOptions = Options.sort((a, b) => {
  //           if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
  //           if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
  //         });
  //       };
  //     });
  //   } else {
  //     this.plzOptions = [];
  //   };
  // };

  selectRegionLiefer(option) {
    if (!option) {
      return false;
    }

    this.option = option;
    this.lieferAdresseForm.controls['zipCode'].setErrors(null);
    this.lieferAdresseForm.controls['city'].setErrors(null);
    this.lieferAdresseForm.controls['ort'].setErrors(null);

    this.lieferAdresseForm.controls['plzId'].setValue(option.plzId);
    // this.lieferAdresseForm.controls['zipCode'].setValue(option.country_code + ' ' + option.place_name + ' ' + option.suburb);
    this.lieferAdresseForm.controls['city'].setValue(`${option.place_name}`);
    this.lieferAdresseForm.controls['ort'].setValue(`${option.place_name}  ${option.suburb} `);
    this.lieferAdresseForm.controls['suburb'].setValue(option.suburb);
    this.lieferplz = option.country_code;
    this.lieferort = `${option.place_name} ${option.suburb}`;
  };

  telephonevalidation(e) {
    return new RegExp(/^[\+\d]?(?:[\d-/\s()]*)$/).test(e);
  };

  onDateChange(event: any) {
    let date = this.datePatternRegex.test(event);
    if (date) {
      let dateSplit = event.split(".");
      if (parseInt(dateSplit[1]) > 12) {
        this.LieferGeburtstagValidateEvent({ status: false, value: null });
      } else {
        let date = dateSplit[2] + '-' + ('0' + dateSplit[1]).slice(-2) + '-' + ('0' + dateSplit[0]).slice(-2);
        if (date) {
          this.LieferGeburtstagValidateEvent({ status: true, value: date });
        } else {
          this.LieferGeburtstagValidateEvent({ status: false, value: null });
        }
      };
    } else {
      if (event) {
        let takeDate = new Date(event);
        let takeMonth = takeDate.getMonth() + 1;
        let takeDay = takeDate.getDate();
        let takeYear = takeDate.getFullYear();
        let date = takeYear + '-' + ('0' + takeMonth).slice(-2) + '-' + ('0' + takeDay).slice(-2);
        if (date) {
          this.LieferGeburtstagValidateEvent({ status: true, value: date });
        } else {
          this.LieferGeburtstagValidateEvent({ status: false, value: null });
        };
      } else {
        this.LieferGeburtstagValidateEvent({ status: false, value: null });
      }
    };
  };

  LieferGeburtstagValidateEvent(event) {
    // console.log(event, 4877);
    if (!event.status) {
      this.lieferAdresseForm.controls['dateOfBirth'].setValue('');
    } else {
      this.lieferAdresseForm.controls['dateOfBirth'].setValue(event.value);
    }
  };
};