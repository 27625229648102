import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'app/Services/admin.service';
import { LanguageService } from '../Services/language.service';

@Component({
  selector: 'app-emergency-hotline',
  templateUrl: './emergency-hotline.component.html',
  styleUrls: ['./emergency-hotline.component.css']
})
export class EmergencyHotlineComponent implements OnInit {

  languageData: any;
  dashboardData: any;
  constructor(public dialogRef: MatDialogRef<EmergencyHotlineComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private langService: LanguageService,
    public adminService: AdminService) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit() {
    this.getDashboardData();
  }
  getDashboardData() {
    this.adminService.getDashboardData().subscribe(result => {
      if (result.success) {
        this.dashboardData = result.data;
      }
    })
  }
  onclick() {
    this.dialogRef.close();
  }
}
