import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../Services/language.service';
import { ICustomWindow, WindowRefService } from '../Services/window.service';
import { SmallPcService } from '../Services/small-pc.service';
import { AdminService } from 'app/Services/admin.service';
import { DeleteAlarmComponent } from 'app/delete-alarm/delete-alarm.component';
import { MatDialog } from '@angular/material/dialog';
import { PriceAlarmDetails } from 'app/models/priceAlaramDetails.interface';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-pricealarm',
  templateUrl: './pricealarm.component.html',
  styleUrls: ['./pricealarm.component.css']
})
export class PricealarmComponent implements OnInit {
  languageVar: any;
  hostname: any;
  @Input() colors;
  //@Input('pricealarm') pricealarm: boolean = true;
  @Input('pricealarm') pricealarm: boolean;
  @Input("productInfoForWebPC") productInfoForWebPC: any;
  showPriceData: boolean = true;
  loader: boolean = false;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  private _window: ICustomWindow;
  volume: any;
  weight: any;
  currency: any;
  currency_symbol: any;
  currency_short: any;
  volume_short: string;
  weight_short: string;
  priceAlarmDetails: PriceAlarmDetails;

  iconColor1;
  iconColor2;
  priceTypes: { final_100liters_ton: { ton: boolean; tax: boolean; text: any; }; tax_for_100l_ton: { ton: boolean; tax: boolean; text: any; }; finalprice: { ton: boolean; tax: boolean; text: any; }; tax_for_finalprice: { ton: boolean; tax: boolean; text: any; }; };
  checkData: any;
  enableZero: boolean;
  primaryColor: any;
  spotcolor: any;
  svgs = [
    environment.assetsUrl + 'assets/svgs/sidePricealaram.svg',
    environment.assetsUrl + 'assets/svgs/phone.svg',
    environment.assetsUrl + 'assets/svgs/alam.svg'
  ]
  labels: any;
  prisAlarmText: string;
  url: any;
  pageName: string = "pricealarm";

  createOrEditAlarm: boolean = false;
  currentStage: any;

  constructor(private langService: LanguageService,
    private SmallPcService: SmallPcService,
    private adminService: AdminService,
    private windowRef: WindowRefService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,) {
    this._window = this.windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    // this.getPriceAlarm();
    this.getDynamicSVG(this.svgs)
  }

  ngOnInit() {
    this.url = this._window.location.href;
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
    this.getPriceAlarm();
    this.priceTypes = {
      final_100liters_ton: { ton: true, tax: false, text: this.languageData.Bestelloptionen.Var_price_per },
      tax_for_100l_ton: { ton: true, tax: true, text: this.languageData['Bestelloptionen']['Var_price_per'] },
      finalprice: { ton: false, tax: false, text: this.languageData['Bestelloptionen']['Var_overall_price'] },
      tax_for_finalprice: { ton: false, tax: true, text: this.languageData['Bestelloptionen']['Var_overall_price'] },
    };
    this.languageVar = this.productInfoForWebPC?.language;
    let params = new URLSearchParams(this.url);
    this.currentStage = decodeURIComponent(params.get("priceAlarmStage"));
    if (this.currentStage != "null") {
      this.openPriceAlaram();
    } else {
      this.createOrEditAlarm = false;
    }

  };


  getPriceAlarm() {
    this.loader = true;
    this.adminService.getAlarm().subscribe((resp: any) => {
      this.loader = false;
      if (resp.success && resp.data) {
        this.priceAlarmDetails = resp.data;

        this.showPriceData = true;
        this.checkData = resp.data.sms.substring(0, 1)
        if (this.checkData == 0) {
          this.enableZero = false;
        } else {
          this.enableZero = true;
        };
      } else {
        this.showPriceData = false;
      };
    }, () => {
      this.loader = false;
    });
  };

  deletePriceAlarm() {
    const dialogRef = this.dialog.open(DeleteAlarmComponent, {
      width: '500px',
      disableClose: true,
    });
    dialogRef.componentInstance.colors = this.colors;
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'proceed') {
        this.loader = true;
        this.adminService.deleteAlarm({ alarm_id: this.priceAlarmDetails._id }).subscribe(() => {
          this.createOrEditAlarm = false;
          this.showPriceData = false;
          this.loader = false;
        }, () => {
          this.loader = false;
        })
      }
    });

  };

  showOverview() {
    // this.pricealarm = false;
    this.createOrEditAlarm = false;
    this.showPriceData = true;
    // let keyName = "&subPage=createPricealaram";
    // this.removeKeyFromUrl(this.pageName, keyName);
    this.getPriceAlarm();
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: 'text' })
        .subscribe((logo) => {
          // logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
          logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
          logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
          logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
          this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
        })
    });
  };

  setUpData(alaramData, key) {
    let priceKey = key == 'wunchPrice' ? "desiredPriceType" : "priceLimitType"
    let value = alaramData[priceKey]
    switch (value) {
      case 'grossPriceFactor':
       return this.languageData['Bestelloptionen']['Var_price_per'] + ' ' + alaramData.priceFactor + alaramData.volume_short + ' ' + this.languageData['Bestelloptionen']['Var_incl_vat'];
     
      case 'basicSurgargePriceFactorNett':
       return this.languageData['Bestelloptionen']['Var_price_per'] + ' ' + alaramData.priceFactor + alaramData.volume_short + ' ' + this.languageData['Bestelloptionen']['Var_excl_vat'];
     
      case 'gross':
       return this.languageData['Bestelloptionen']['Var_overall_price'] + ' ' + alaramData.quantity + ' ' + alaramData.volume_short + ' ' + this.languageData['Bestelloptionen']['Var_incl_vat'];
     
      case 'basicSurgargePriceNett':
       return this.languageData['Bestelloptionen']['Var_overall_price'] + ' ' + alaramData.quantity + ' ' + alaramData.volume_short + ' ' + this.languageData['Bestelloptionen']['Var_excl_vat'];
     
    }
  };

  openPriceAlaram() {
    this.createOrEditAlarm = true;


    // let keyName = "&subPage=createPricealaram&stage=one";
    // this.addKeyToUrl(this.pageName, keyName);
  };


  addKeyToUrl(page, key) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;

    if (newUrl.includes(page) && !url.includes(key)) {
      newUrl = `${newUrl}${key}`;
      window.history.replaceState('', document.title, newUrl);
    };
  };

  removeKeyFromUrl(page, key) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;

    if (newUrl.includes(page)) {
      newUrl = newUrl.replace(key, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  callTheExit() {
    this.createOrEditAlarm = false;
    // let keyName = "&subPage=createPricealaram";
    // this.removeKeyFromUrl(this.pageName, keyName);
  };
  textIdentifer(key) {
   return this.setUpData(this.priceAlarmDetails, key)
  }
}

//old code
// deletePriceAlarm() {
//   this.adminService.deleteAlarm({ alarm_id: this.priceAlarmDetails._id }).subscribe(resp => {
//     this.pricealarm = false;
//     this.showPriceData = false;
//   })
// }
// this.convertToColors(this.primaryColor)
// convertToColors(color){
//   let rgb = this.hextoRGB(color);
//   if(rgb){
//     this.iconColor1 = 'rgb('+rgb.r+','+rgb.g+','+rgb.b+')';
//     let r = (255-Number(rgb.r))/3;
//     let g = (255-Number(rgb.g))/3;
//     let b = (255-Number(rgb.b))/3;

//     this.iconColor2 = 'rgb('+(Number(rgb.r) + Number(r))+','+(Number(rgb.g) + Number(g)) +','+(Number(rgb.b)+ Number(b))+')';
//   }
// }

// hextoRGB(hex){
//   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//   return result ? {
//     r: parseInt(result[1], 16),
//     g: parseInt(result[2], 16),
//     b: parseInt(result[3], 16)
//   } : this.rgbToArray(hex);
// }

// rgbToArray(hex){
//   let result = hex.split("(")[1].split(")")[0];
//   result = result.split(',');
//   return {
//     r: parseInt(result[0]),
//     g: parseInt(result[1]),
//     b: parseInt(result[2])
//   }
// }
