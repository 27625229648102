import { Injectable } from '@angular/core';
import { SmallPcService } from './small-pc.service';
import { ICustomWindow, WindowRefService } from './window.service';
import { BigpcService } from './bigpc.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private _window: ICustomWindow;
  url: string;
  domainname: string;
  Liter: string;
  Product: string;
  view: string;
  Lieferort: string;
  affliatePostalCodes: any;
  private _affliateDetails: {
    amount?: any;
    zip?: any;
    productId?: any;
    unloadingPoints?: any;
    product?: any;
    productName?: any,
    affiliateId?: any;
    callBackUrl?: any;
    consumerId?: any
  };
  public get affliateDetails(): {
    amount?: any;
    zip?: any;
    productId?: any;
    unloadingPoints?: any;
    product?: any;
    productName?: any,
    affiliateId?: any;
    callBackUrl?: any;
    consumerId?: any
  } {
    return this._affliateDetails;
  }
  public set affliateDetails(value: { amount?: any; zip?: any; productId?: any; unloadingPoints?: any; product?: any; productName?: any; affiliateId?: any; callBackUrl?: any; consumerId?: any }) {
    this._affliateDetails = value;
  }
  constructor(
    private windowRef: WindowRefService,
    private smallPcService: SmallPcService,
    private bgPCService: BigpcService

  ) {
    this._window = this.windowRef.nativeWindow;
    this.url = this._window.location.href;
    var params = new URLSearchParams(this.url);
    this.domainname = decodeURIComponent(params.get("Domainname"));
    this.Liter = decodeURIComponent(params.get("Liter"));
    this.Product = decodeURIComponent(params.get("Product"));
    this.view = decodeURIComponent(params.get("view"));
    this.Lieferort = decodeURIComponent(params.get("Lieferort"));
  }

  async affiliateSessionCreation(hostName): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let affliateDetails = this.affliateDetails;
        this.url = this._window.location.href;
        var params = new URLSearchParams(this.url);
        let affliatePostalCodes = {
          city: '',
          latitude: '',
          longitude: '',
          plzId: '',
          postalcode: '',
          suburb: '',
        };
        let postalCodes = await this.bgPCService.postalCode({ event: affliateDetails.zip, dynamicProductId: affliateDetails.product }).toPromise();
        if (postalCodes.success && postalCodes.data && postalCodes.data.length > 0) {
          let primaryPostalCode = postalCodes.data[0];

          affliatePostalCodes = {
            city: primaryPostalCode.place_name,
            latitude: primaryPostalCode.latitude,
            longitude: primaryPostalCode.longitude,
            plzId: primaryPostalCode.plzId,
            postalcode: primaryPostalCode.country_code,
            suburb: primaryPostalCode.suburb,
          };
        } else {
          affliatePostalCodes = null;
        };

        let sessionId = decodeURIComponent(params.get("sid"));
        if (!sessionId || sessionId == 'null') {
          sessionId = this.smallPcService.genereateNewSessionId();
          let newURL = this.url;
          newURL = `${newURL}&sid=${sessionId}`
          window.history.pushState('', document.title, this.url);
          window.history.replaceState('', document.title, newURL);
        };

        let sessionDataFromLoacl = await this.smallPcService.getNewSessionData(sessionId);

        // let localOptions = sessionData && sessionData.localOptions ? sessionData.localOptions : {};

        let sessionDataFromLtest = {
          priceCalcUrl: this._window.location.hostname,
          domainName: hostName,
          postalCode: affliatePostalCodes,
          quantity: affliateDetails.amount,
          co2Compensation: [],
          stations: affliateDetails.unloadingPoints,
          product: {
            _id: affliateDetails.product,
            name: affliateDetails.productName,
          },
          localOptions: [
            { optionSetId: '', optionId: '', sort: 1, dynamicPcOptionsSetId: '', name: '', lable: '', DOB: '' },
            { optionSetId: '', optionId: '', sort: 2, dynamicPcOptionsSetId: '', name: '', lable: '', DOB: '' },
            { optionSetId: '', optionId: '', sort: 3, dynamicPcOptionsSetId: '', name: '', lable: '', DOB: '' },
            { optionSetId: '', optionId: '', sort: 4, dynamicPcOptionsSetId: '', name: '', lable: '', DOB: '' },
            { optionSetId: '', optionId: '', sort: 5, dynamicPcOptionsSetId: '', name: '', lable: '', DOB: '' }
          ],
          ratePaymentSettings: {
            status: false,
            zahlungRate: '',
            zahlungspans: []
          },
          desiredDateSettings: {
            fromDate: new Date() || '',
            toDate: new Date() || '',
            range: '',
            rangeAvailable: false,
            wunstermin: false,
            days: 0
          },
          cart: [
            { _id: '' }
          ],
          tag: 1,
        };


        let sessionData = { ...sessionDataFromLoacl, ...sessionDataFromLtest }
        await this.smallPcService.setSessionData(sessionId, sessionData);
        return resolve({ affliatePostalCodes, sessionId, showGuestButton: true });
      } catch (err) {
        reject(err)
      }
    })
  };


  async checkForAffliateQueryParams(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.url = this._window.location.href;
        let params = new URLSearchParams(this.url);
        let amount = params.get('amount');
        let zip = params.get('zip');
        let productId = params.get('product');
        let unloadingPoints = params.get('unloadingPoints');
        let affiliateId = params.get('affiliateId');
        let callBackUrl = params.get('callBackUrl');
        let consumerId = params.get('consumerId');
        let affliateDetails = { amount, zip, productId, unloadingPoints, affiliateId, callBackUrl, consumerId };
        if (
          (amount && amount != '') &&
          (zip && zip != '') &&
          (productId && productId != '') &&
          (consumerId && consumerId != '') &&
          (unloadingPoints && unloadingPoints != '') &&
          (callBackUrl && callBackUrl != '')
        ) {
          this.affliateDetails = affliateDetails;
          let dynamicPCList = [];
          let findDynamicProduct;
          await this.smallPcService.getWebTags('1').subscribe((webLinkData) => {
            if (webLinkData.success) {
              dynamicPCList = webLinkData.data[0]?.products;
              findDynamicProduct = dynamicPCList.find(productInfo => productInfo.articleId == productId);
              if (findDynamicProduct) {
                affliateDetails['product'] = findDynamicProduct.dynamicProductId;
                affliateDetails['productName'] = findDynamicProduct.namePc;
              };
              return resolve({ productInfoWebPc: findDynamicProduct, status: true });
            }
          });
        } else {
          this.affliateDetails = affliateDetails;
          resolve(false);
        };
      } catch (err) {
        reject(err)
      }
    });
  };
}
