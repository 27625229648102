<div class="aupris_main">
  <div class="au-container">
    <div class="aucol-md-6 aucol-md-offset-3 aucol-sm-6 aucol-sm-offset-3 aucol-lg-6 aucol-lg-offset-3">
      <div class="panel panel-primary">
        <div class="panel-heading text-center" style="background: 'var(--primaryColour)'">
          <h4 style="color:'var(--primaryTextColour)'">Email Verifizierung</h4>
        </div>
        <div class="panel-body-aupris" *ngIf="!verifiedeMail">
          Hopla! Da ist etwas schief gegangen... Bitte versuchen Sie es erneut!
        </div>
        <div class="panel-body-aupris" *ngIf="verifiedeMail">
          Ihre Email wurde erfolgreich verifiziert!
        </div>
      </div>
    </div>
  </div>
</div>
