<div class="aupris_main scrolltotopprogress">
  <section>
    <div class="addressSection" *ngFor="let address of addresses;let i = index;" (click)="selectAddress(address, i)">
      <div class="checkSec">
        <label class="checkbox-label" [ngClass]="{checked: address.class=='tick'}">
          <input type="checkbox" class="checkbox" (change)="selectAddress(address, i)"
            [checked]="address.class == 'tick'" />
          <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
            <span class="checkbox-tick" [ngStyle]="{'border-color': 'black'}"></span>
          </span>
        </label>
      </div>
      <div class="addrSec">

        <span class="name">
          <b>
            {{ address.firstName ? " "+address.firstName: ''}}
            {{address?.lastName}}
            {{ address.companyName ? ", "+address.companyName : "" }}
          </b>
          {{address.Addresszusatz ? ", "+address.Addresszusatz:"" }}
        </span>
        <span class="dblock">
          {{address?.streetName}}
          {{address?.houseNo}},
          {{address?.zipCode}}
          {{address?.city}}
          {{address?.suburb}}
        </span>
        <span class="dblock">
          {{address?.phoneNumber}},
          {{address?.eMail}}
        </span>
      </div>
    </div>

    <div class="" *ngIf="addresses.length == 0 && addressType == 'primary'">
      <p>
        {{languageData.Address['Var_unübertroffene_adresse_1'] ?
        languageData.Address['Var_unübertroffene_adresse_1']+":" : "Für den gewählten Lieferort ist keine Adresse
        gespeichert:"}}
      </p>
      <p class="plzWarn">
        {{lieferort}}
      </p>
      <!-- <p>
        {{languageData.Address['Var_unübertroffene_adresse_2'] ? languageData.Address['Var_unübertroffene_adresse_2'] : "Fügen Sie eine passende Adresse hinzu."}}
      </p> -->
    </div>
    <div cyAddNewAddress="cyAddNewAddress" class="addAddr pointer" (click)="addAddress()">
      <svg fill="rgba(0,0,0,.87)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px">
        <path fill-rule="evenodd"
          d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z" />
      </svg>
      <span style="vertical-align: top;font-size: 16px;color:rgba(0,0,0,.87)"> {{languageData?.Address?.Var_add_address
        || ' Adresse hinzufügen'}}</span>
    </div>

    <div style="text-align: center;margin-top: 15px;">
      <button _ngcontent-tue-c28="" class="au-btn btn-default"
        [ngStyle]="{background: 'var(--primaryColour)', color:'var(--primaryTextColour)'}" (click)="dialog.close(false)"
        type="button">{{languageData?.Address?.Var_close || 'Schließen'}}
      </button>
    </div>
    
  </section>
</div>