import {  Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalpipe'
})
export class DecimalpipePipe implements PipeTransform {

  transform(value: any): any {
    var regex = new RegExp("^[0-9,.]");
    if (value && regex.test(value)) {
        value = value.toString();
        if (value.includes(',') && value.includes('.')) {
            value = value.replace('.', '');
        }
        if (value.includes(',')) {
            value = value.replace(',', '.');
        }
        let v = value.split('.');
        if (v.length > 2) {
            value = v[0] + '' + v[1] + '.' + v[2];
        }
        let n = new Number(value);
        let val = n.toLocaleString("de-DE");
        return val;
    } else {
        return value;
    }

}

}
